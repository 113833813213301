import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import {
  FaShoppingCart,
  FaUser,
  FaCalendarAlt,
  FaRegKissWinkHeart,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaRoad,
  FaInfo,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaGlobeAmericas,
  FaBuilding,
  FaNapster,
  FaSearch,
  FaExclamation,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { getAddress } from 'address-br';
import {
  BodyFormComponent,
  LabelForm,
  PrependInputLabel,
  SectionFormHeader,
} from '../styles';
import Api from '../../services/api';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import { Warning } from './styles';

function CadastrarAssessoria(props) {
  const { toggle, modal, getAssessorias, assessorias, loadingAssessorias, gruposVenda } =
    props;
  const [loading, setLoading] = useState(false);
  const [assessoriaInput, setAssessoriaInput] = useState({
    razaoSocial: '',
    nomeFantasia: '',
    cnpj: '',
    nome: '',
    cpf: '',
    data_nascimento: '',
    estado_civil: '',
    sexo: '',
    telefone: '',
    celular: '',
    email: '',
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    anotacoes: '',
    tipo: '',
    id_assessoria_master: '',
    link_logotipo: '',
    id_grupo_vendas: ''
  });

  const [comissaoInput, setComissaoInput] = useState({
    primeiraParcela: '',
    carteira: '',
    pagamentoAVista: '',
    idUsuario: 0,
  });

  const cadastrarAssessoriaForm = async e => {
    setLoading(true);
    e.preventDefault();
    const authStr = `Bearer ${props.user.user.token}`;

    let idUsuario;
    let idAssessoria;

    try {
      const result = await Api.post('/assessoria', assessoriaInput, {
        headers: { Authorization: authStr },
      });

      idUsuario = result.data.output.idUsuario;
      idAssessoria = result.data.output.idInserido;

      if (result.data.ok) {
        alertSuccess('Distribuidor cadastrado com sucesso');
        toggle();
      } else {
        const errorsMessages = JSON.parse(result.data.errors);

        const errors = [];
        for (let i = 0; i < errorsMessages.length; i++) {
          errors.push(new Error(errorsMessages[i]));
        }

        if (errors.length > 0) {
          throw errors;
        }
      }
    } catch (error) {
      for (let i = 0; i < error.length; i++) {
        alertError(error[i].message);
      }
    }

    try {
      const response = await Api.post(
        `/comissao`,
        { ...comissaoInput, idUsuario },
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao salvar comissão: ', error.message);
    }

    getAssessorias();
    setLoading(false);
  };

  const [comissaoAsaasAssessoriaMaster, setComissaoAsaasAssessoriaMaster] = useState({
    primeiraParcela: 0,
    demaisParcelas: 0,
  });

  const handleOnChange = async e => {
    const { name, value } = e.target;
    setAssessoriaInput({ ...assessoriaInput, [name]: value });

    if (name == 'id_assessoria_master') {
      try {
        const response = await Api.get(
          `/assessoria/${value}`,
          { headers: { Authorization: `Bearer ${props.user.user.token}` } },
        );
        if (response.data.ok) {

          setComissaoAsaasAssessoriaMaster({
            primeiraParcela: response.data.output.comissao_primeira_parcela_asaas,
            demaisParcelas: response.data.output.comissao_asaas,
          })
        }
      } catch (error) {
        alertError('Distribuidor master não encontrado: ', error.message);
      }
    }
  };

  const handleChangeRadio = e => {
    const { id } = e.target;

    if (id) {
      setAssessoriaInput({ ...assessoriaInput, id_grupo_vendas: id });
    } else {
      setAssessoriaInput({ ...assessoriaInput, id_grupo_vendas: 0 });
    }

  };

  const handleComissaoChange = e => {
    const { name, value } = e.target;
    if (value < 0 || value > 100 || Number.isNaN(value)) {
      return;
    }
    setComissaoInput({ ...comissaoInput, [name]: value });
  };

  const updateAdress = () => {
    const cepOnlyNumbers = assessoriaInput.cep.replace(/\D/g, '');
    getAddress(cepOnlyNumbers).then(vl => {
      setAssessoriaInput({
        ...assessoriaInput,
        cep: assessoriaInput.cep,
        logradouro: vl.rua || '',
        cidade: vl.cidade || '',
        bairro: vl.bairro || '',
        uf: vl.estado || '',
      });
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <Form onSubmit={cadastrarAssessoriaForm}>
        <ModalHeader toggle={toggle}>
          <Row>
            <Col md={12}>
              <FaShoppingCart />
              &nbsp;Cadastrar Distribuidor
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <BodyFormComponent>
            <Row>
              <Col>
                <SectionFormHeader>Nível do distribuidor</SectionFormHeader>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mt-3">
                <LabelForm>Tipo do distribuidor *</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRegKissWinkHeart />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    type="select"
                    name="tipo"
                    value={assessoriaInput.tipo}
                    onChange={e => handleOnChange(e)}
                  >
                    <option value="">Selecione</option>
                    <option value="master">Master</option>
                    <option value="afiliado">Afiliado</option>
                  </Input>
                </InputGroup>
              </Col>
              {assessoriaInput.tipo == 'afiliado' && (
                <Col lg={6} className="mt-3">
                  <LabelForm>Distribuidor Master *</LabelForm>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaRegKissWinkHeart />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      type="select"
                      name="id_assessoria_master"
                      value={assessoriaInput.id_assessoria_master}
                      onChange={e => handleOnChange(e)}
                    >
                      <option value="0">Selecione...</option>

                      {loadingAssessorias ? (
                        <option value="">Carregando...</option>
                      ) : (
                        assessorias.output.map(assessoria =>
                          assessoria.tipo == 'master' ? (
                            <option
                              value={assessoria.id_assessoria}
                              key={assessoria.id_assessoria}
                            >
                              {assessoria.nome_fantasia}
                            </option>
                          ) : null,
                        )
                      )}
                    </Input>
                  </InputGroup>
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <Col>
                <SectionFormHeader>
                  Informações do Distribuidor
                </SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaBuilding />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Razão Social *"
                    name="razaoSocial"
                    value={assessoriaInput.razaoSocial}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>
                        <FaNapster />
                      </PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome Fantasia *"
                    name="nomeFantasia"
                    value={assessoriaInput.nomeFantasia}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={12}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CNPJ *</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="99.999.999/9999-99"
                    className="form-control"
                    required
                    name="cnpj"
                    value={assessoriaInput.cnpj}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUser />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome *"
                    name="nome"
                    value={assessoriaInput.nome}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CPF *</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="999.999.999-99"
                    className="form-control"
                    required
                    name="cpf"
                    value={assessoriaInput.cpf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>
                <LabelForm>Data de Nascimento *</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaCalendarAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="date"
                    required
                    name="data_nascimento"
                    value={assessoriaInput.data_nascimento}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={4}>
                <LabelForm>Estado Civil *</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRegKissWinkHeart />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="select"
                    required
                    name="estado_civil"
                    value={assessoriaInput.estado_civil}
                    onChange={e => handleOnChange(e)}
                  >
                    <option value="">Selecione</option>
                    <option>Solteiro(a)</option>
                    <option>Casado(a) /união estável</option>
                    <option>Divorciado(a)</option>
                    <option>Viúvo(a)</option>
                  </Input>
                </InputGroup>
              </Col>
              <Col lg={4}>
                <LabelForm>Sexo *</LabelForm>
                <Input
                  type="select"
                  required
                  name="sexo"
                  value={assessoriaInput.sexo}
                  onChange={e => handleOnChange(e)}
                >
                  <option value="">Selecione</option>
                  <option>Masculino</option>
                  <option>Feminino</option>
                </Input>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone *"
                    required
                    name="telefone"
                    value={assessoriaInput.telefone}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>

              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMobileAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone 2"
                    name="celular"
                    value={assessoriaInput.celular}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    required
                    placeholder="E-mail *"
                    name="email"
                    value={assessoriaInput.email}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <SectionFormHeader>Informações de Endereço</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CEP *</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="CEP sem pontuação"
                    required
                    name="cep"
                    value={assessoriaInput.cep}
                    onChange={e => handleOnChange(e)}
                  />
                  <Button size="sm" onClick={() => updateAdress()}>
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRoad />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Rua *"
                    required
                    name="logradouro"
                    value={assessoriaInput.logradouro}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={2}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>Nº</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nº *"
                    required
                    name="numero_endereco"
                    value={assessoriaInput.numero_endereco}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaInfo />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Complemento"
                    name="complemento_endereco"
                    value={assessoriaInput.complemento_endereco}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkerAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Bairro *"
                    required
                    name="bairro"
                    value={assessoriaInput.bairro}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkedAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Cidade *"
                    required
                    name="cidade"
                    value={assessoriaInput.cidade}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaGlobeAmericas />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Estado *"
                    required
                    name="uf"
                    value={assessoriaInput.uf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <SectionFormHeader>Comissões - Asaas</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              {
                assessoriaInput.tipo == 'afiliado' && assessoriaInput.id_assessoria_master > 0 ?
                (
                  <Col xs={12} sm={12} className="mb-2">
                  <Warning>
                    <FaExclamation />
                    <p>
                      <b>Distribuidor Master: </b>
                    </p>
                    <p>
                      1ª Parcela Asaas: {comissaoAsaasAssessoriaMaster.primeiraParcela} %
                    </p>
                    <p>
                      Demais Parcelas:  {comissaoAsaasAssessoriaMaster.demaisParcelas} %
                    </p>
                  </Warning>
                </Col>
                ) : null
              }

              <Col xs={12} sm={6} className="mb-2">
                <LabelForm>1ª Parcela Asaas *</LabelForm>
                <InputGroup>
                  <Input
                    name="comissao_primeira_parcela_asaas"
                    type="number"
                    value={assessoriaInput.comissao_primeira_parcela_asaas}
                    onChange={e => handleOnChange(e)}
                    min={0}
                    max={100 - comissaoAsaasAssessoriaMaster.primeiraParcela}
                    required
                    placeholder="10.0"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <LabelForm>Demais cobranças *</LabelForm>
                <InputGroup>
                  <Input
                    name="comissao_asaas"
                    type="number"
                    value={assessoriaInput.comissao_asaas}
                    onChange={e => handleOnChange(e)}
                    min={0}
                    max={100 - comissaoAsaasAssessoriaMaster.demaisParcelas}
                    required
                    placeholder="10.0"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <SectionFormHeader>Comissões - Vendedores</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} sm={6} className="mb-2">
                <LabelForm>1ª Parcela</LabelForm>
                <InputGroup>
                  <Input
                    name="primeiraParcela"
                    type="number"
                    value={comissaoInput.primeiraParcela}
                    onChange={e => handleComissaoChange(e)}
                    min={0}
                    max={100}
                    required
                    placeholder="10.0"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <LabelForm>Carteira</LabelForm>
                <InputGroup>
                  <Input
                    name="carteira"
                    type="number"
                    value={comissaoInput.carteira}
                    onChange={e => handleComissaoChange(e)}
                    min={0}
                    max={100}
                    required
                    placeholder="10.0"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <LabelForm>Pagamento à vista</LabelForm>
                <InputGroup>
                  <Input
                    name="pagamentoAVista"
                    type="number"
                    value={comissaoInput.pagamentoAVista}
                    onChange={e => handleComissaoChange(e)}
                    min={0}
                    max={100}
                    required
                    placeholder="10.0"
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>%</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            {gruposVenda != undefined && (
              <Row className="section-header mt-4">
                <Col>
                  <SectionFormHeader>Grupos de Vendas *</SectionFormHeader>
                </Col>
              </Row>
            )}
            <Row className="mt-4">
              {gruposVenda != undefined &&
                gruposVenda.map((gpVenda, i) => {
                  return (
                    <Col lg={4} key={i} className="mt-2 mb-4">
                      <FormGroup check>
                        <Label check>
                          <Input
                            id={gpVenda.id}
                            name="id_grupo_vendas"
                            type="radio"
                            required
                            onChange={e => handleChangeRadio(e)}
                          />{' '}
                          {gpVenda.nome}
                        </Label>
                      </FormGroup>
                    </Col>
                  );
                })}
            </Row>
          </BodyFormComponent>
        </ModalBody>
        <ModalFooter>
          <Row className="text-right">
            <Col className="d-flex align-items-center">
              <Button color="danger" onClick={toggle}>
                Fechar
              </Button>
              &nbsp;
              <SubmitBtn
                label="Salvar"
                labelOnClick="aguardando"
                loading={loading}
                typeSubmit="submit"
                color="#0A7A73"
              />
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

// Token de produção, teste, user token
const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(CadastrarAssessoria);
