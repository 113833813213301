import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Api from '../../services/api';
import Page from './Page';
import { checkSafePassword } from '../../services/service';

function Controller(props) {
  const { usuario, nivel_usuario, assessoria } = props.user.user;
  const [vendas, setVendas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msgNotFound, setMsgNotFound] = useState('');
  const [corretorRepresentanteMsg, setCorretorRepresentanteMsg] = useState('');
  const [idCorretorRepresentante, setIdCorretorRepresentante] = useState(0);

  const authStr = `Bearer ${props.user.user.token}`;

  let idCorretor = '';
  if (props.user.user.corretor_representante) {
    idCorretor = props.user.user.corretor_representante;
  }

  useEffect(() => {

    checkSafePassword(props.user);

    if (idCorretor) {
      buscaVendaCorretor(idCorretor);
      setIdCorretorRepresentante(idCorretor);
    } else {
      buscaIdAssessoriaPeloUsuario();
    }
  }, []);

  const buscaVendaCorretor = async idCorretor => {
    let vendasArr = [];

    const requestRelatorio = await Api.get(
      `relatorio-venda/corretor-representante/${idCorretor}`,
      { headers: { Authorization: authStr } },
    );

    if (nivel_usuario == 3 || nivel_usuario == 4) {
      if (requestRelatorio.data.ok) {
        requestRelatorio.data.output.reverse();
        setVendas(requestRelatorio.data.output);
      } else {
        setMsgNotFound(requestRelatorio.data.message);
      }
      setLoading(false);
    }

    if (nivel_usuario == 2 || nivel_usuario == 5) {
      if (requestRelatorio.data.ok) {
        requestRelatorio.data.output.reverse();
        vendasArr = requestRelatorio.data.output;
      } else {
        setMsgNotFound(requestRelatorio.data.message);
      }

      return vendasArr;
    }
  };

  const buscaIdAssessoriaPeloUsuario = async _ => {
    let vendasArr = [];
    let result = '';
    if (assessoria != undefined) {
      result = await Api.get(
        `/corretor-representante/assessoria-master/${assessoria}`,
        { headers: { Authorization: authStr } },
      );
    } else {
      result = await Api.get(`corretor-representante/usuario/${usuario}`, {
        headers: { Authorization: authStr },
      });
    }

    if (result.data.ok) {
      const corretores = result.data.output;

      corretores.map(async (elCorretor, index) => {
        const teste = await buscaVendaCorretor(elCorretor.id);
        vendasArr = [].concat(vendasArr, teste);
        setVendas(vendasArr);
      });
    } else {
      setCorretorRepresentanteMsg(result.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Page
        vendas={vendas}
        loading={loading}
        msgNotFound={msgNotFound}
        corretorRepresentanteMsg={corretorRepresentanteMsg}
        idCorretorRepresentante={idCorretorRepresentante}
        nivel_usuario={nivel_usuario}
        idAssessoria={assessoria}
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
