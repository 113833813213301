import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Api from '../../services/api';
import { checkSafePassword } from '../../services/service';
import Page from './Page';

function ClinicaClientes(props) {
  const history = useHistory();
  const [clientes, setClientes] = useState();
  const [loadingClientes, setLoadingClientes] = useState(true);
  const [gruposVenda, setGruposVenda] = useState(true);

  useEffect(() => {
    checkSafePassword(props.user);
    getClients();
    getGruposVenda();
  }, []);

  const getClients = async _ => {
    const fetchClients = await Api.get('clinica-clientes/', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    fetchClients.data.output.reverse();
    setClientes(fetchClients.data);
    setLoadingClientes(false);
  };

  const getGruposVenda = async _ => {
    const fetchGrupoVendas = await Api.get('/grupovendas', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    if (fetchGrupoVendas.data.ok) {
      setGruposVenda(fetchGrupoVendas.data.output);
    }
  };

  return (
    <>
      {clientes && (
        <Page
          clientes={clientes}
          loadingClientes={loadingClientes}
          history={history}
          gruposVenda={gruposVenda}
          nivelUsuario={props.user.user.nivel_usuario}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(ClinicaClientes);
