import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import {
  FaShoppingCart,
  FaUser,
  FaCalendarAlt,
  FaRegKissWinkHeart,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
  FaRoad,
  FaInfo,
  FaMapMarkerAlt,
  FaMapMarkedAlt,
  FaGlobeAmericas,
  FaBuilding,
  FaNapster,
  FaSearch,
  FaExclamation,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { getAddress } from 'address-br';
import {
  BodyFormComponent,
  LabelForm,
  PrependInputLabel,
  SectionFormHeader,
} from '../styles';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import MaterialButton from '../../components/Buttons/MaterialButton';
import { Warning } from './styles';

function EditarClinica(props) {
  const { toggle, modal, getClinica, clinica, gruposVenda, user } = props;
  const [loading, setLoading] = useState(false);
  const [clinicaInput, setClinicaInput] = useState({});

  const [comissaoAsaasClinicaMaster, setComissaoAsaasClinicaMaster] = useState({
    primeiraParcela: 0,
    demaisParcelas: 0,
  });

  useEffect(() => {
    setClinicaInput({ ...clinica.endereco, ...clinica.data });

    if (clinica.data.id_clinica_master) {
      getClinicaMaster(clinica.data.id_clinica_master);
    }

  }, [clinica]);

  const getClinicaMaster = async (id) => {
    try {
      const response = await Api.get(
        `/clinica/master/${id}`,
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (response.data.ok) {

        setComissaoAsaasClinicaMaster({
          primeiraParcela: response.data.output.comissao_primeira_parcela_asaas,
          demaisParcelas: response.data.output.comissao_asaas,
        })
      }
    } catch (error) {
      alertError('Distribuidor master não encontrado: ', error.message);
    }
  };

  const editarClinicaForm = async e => {
    setLoading(true);
    e.preventDefault();
    const authStr = `Bearer ${props.user.user.token}`;

    try {
      const result = await Api.put('/clinica', clinicaInput, {
        headers: { Authorization: authStr },
      });

      if (result.data.ok) {
        alertSuccess('Clínica editada com sucesso');
      } else {
        throw new Error(result.data.message);
      }
    } catch (error) {
      alertError('Erro ao salvar a clínica: ', error.message);
    }
    setLoading(false);
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    setClinicaInput({ ...clinicaInput, [name]: value });
  };

  const handleChangeRadio = e => {
    const { id } = e.target;

    if (id) {
      setClinicaInput({ ...clinicaInput, id_grupo_vendas: id });
    }

  };

  const updateAdress = () => {
    const cepOnlyNumbers = clinicaInput.cep.replace(/\D/g, '');
    getAddress(cepOnlyNumbers).then(vl => {
      setClinicaInput({
        ...clinicaInput,
        cep: clinicaInput.cep,
        logradouro: vl.rua || '',
        cidade: vl.cidade || '',
        bairro: vl.bairro || '',
        uf: vl.estado || '',
      });
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <Form onSubmit={editarClinicaForm}>
        <ModalHeader toggle={toggle}>
          <Row>
            <Col md={12}>
              <FaShoppingCart />
              &nbsp;Editar Clínica
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <BodyFormComponent>
            <Row>
              <Col>
                <SectionFormHeader>
                  Informações da Clínica
                </SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaBuilding />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Razão Social"
                    name="razao_social"
                    value={clinicaInput.razao_social}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>
                        <FaNapster />
                      </PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome Fantasia"
                    name="nome_fantasia"
                    value={clinicaInput.nome_fantasia}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={12}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CNPJ</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="99.999.999/9999-99"
                    className="form-control"
                    // required
                    disabled
                    name="cnpj"
                    value={clinicaInput.cnpj}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUser />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Nome"
                    name="nome"
                    value={clinicaInput.nome}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CPF</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="999.999.999-99"
                    className="form-control"
                    required
                    name="cpf"
                    value={clinicaInput.cpf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>
                <LabelForm>Data de Nascimento</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaCalendarAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="date"
                    required
                    name="data_nascimento"
                    value={clinicaInput.data_nascimento}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={4}>
                <LabelForm>Estado Civil</LabelForm>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRegKissWinkHeart />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="select"
                    name="estado_civil"
                    value={clinicaInput.estado_civil}
                    onChange={e => handleOnChange(e)}
                  >
                    <option value="">Selecione</option>
                    <option>Solteiro(a)</option>
                    <option>Casado(a) /união estável</option>
                    <option>Divorciado(a)</option>
                    <option>Viúvo(a)</option>
                  </Input>
                </InputGroup>
              </Col>
              <Col lg={4}>
                <LabelForm>Sexo</LabelForm>
                <Input
                  type="select"
                  name="sexo"
                  value={clinicaInput.sexo}
                  onChange={e => handleOnChange(e)}
                >
                  <option value="">Selecione</option>
                  <option>Masculino</option>
                  <option>Feminino</option>
                </Input>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    required
                    name="telefone"
                    value={clinicaInput.telefone}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>

              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMobileAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone 2"
                    name="celular"
                    value={clinicaInput.celular}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    // required
                    disabled
                    placeholder="E-mail"
                    name="email"
                    value={clinicaInput.email}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <SectionFormHeader>Informações de Endereço</SectionFormHeader>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>CEP</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="CEP sem pontuação"
                    name="cep"
                    value={clinicaInput.cep}
                    onChange={e => handleOnChange(e)}
                  />
                  <Button size="sm" onClick={() => updateAdress()}>
                    <FaSearch />
                  </Button>
                </InputGroup>
              </Col>
              <Col lg={6}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaRoad />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Rua"
                    name="logradouro"
                    value={clinicaInput.logradouro}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={2}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PrependInputLabel>Nº</PrependInputLabel>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nº"
                    name="numero_endereco"
                    value={clinicaInput.numero_endereco}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaInfo />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Complemento"
                    name="complemento_endereco"
                    value={clinicaInput.complemento_endereco}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkerAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Bairro"
                    name="bairro"
                    value={clinicaInput.bairro}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMapMarkedAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Cidade"
                    name="cidade"
                    value={clinicaInput.cidade}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaGlobeAmericas />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Estado"
                    name="uf"
                    value={clinicaInput.uf}
                    onChange={e => handleOnChange(e)}
                  />
                </InputGroup>
              </Col>
            </Row>
            {
              clinicaInput.tipo && clinicaInput.tipo == 'afiliado' ? (
              <>
                <Row className="mt-4">
                  <Col>
                    <SectionFormHeader>Comissões</SectionFormHeader>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={6} sm={3} className="mb-2">
                    <LabelForm>Viver</LabelForm>
                    <InputGroup>
                      <Input
                        name="comissao_viver"
                        type="number"
                        value={clinicaInput.comissao_viver}
                        onChange={e => handleOnChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col xs={6} sm={3} className="mb-2">
                    <LabelForm>Convênio</LabelForm>
                    <InputGroup>
                      <Input
                        name="comissao_convenio"
                        type="number"
                        value={clinicaInput.comissao_convenio}
                        onChange={e => handleOnChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col xs={6} sm={3} className="mb-2">
                    <LabelForm>Particular</LabelForm>
                    <InputGroup>
                      <Input
                        name="comissao_particular"
                        type="number"
                        value={clinicaInput.comissao_particular}
                        onChange={e => handleOnChange(e)}
                        min={0}
                        max={100}
                        required
                        placeholder="10.0"
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <PrependInputLabel>%</PrependInputLabel>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </>
              ) : null
            }
            {(gruposVenda != undefined && user.user.nivel_usuario == 1) && (
              <Row className="section-header mt-4">
                <Col>
                  <SectionFormHeader>Grupos de Vendas *</SectionFormHeader>
                </Col>
              </Row>
            )}
            <Row className="mt-4">
              {gruposVenda != undefined && user.user.nivel_usuario == 1 &&
                gruposVenda.map((gpVenda, i) => {
                  return (
                    <Col lg={4} key={i} className="mt-2 mb-4">
                      <FormGroup check>
                        <Label check>
                          <Input
                            id={gpVenda.id}
                            name="id_grupo_vendas"
                            type="radio"
                            required
                            onChange={e => handleChangeRadio(e)}
                            checked={gpVenda.id == clinicaInput.id_grupo_vendas ? true : false}
                          />{' '}
                          {gpVenda.nome}
                        </Label>
                      </FormGroup>
                    </Col>
                  );
                })}
            </Row>
          </BodyFormComponent>
        </ModalBody>
        <ModalFooter>
          <Row className="text-right">
            <Col className="d-flex align-items-center">
              <MaterialButton color="danger" onClick={toggle}>
                Fechar
              </MaterialButton>
              &nbsp;
              <MaterialButton loading={loading} type="submit">
                Salvar
              </MaterialButton>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

// Token de produção, teste, user token
const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(EditarClinica);
