import React, { useEffect, useState } from 'react';
import {
  Box,
  // CircularProgress,
  // IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableFooter,
  TableHead,
  // TablePagination,
  TableRow,
} from '@material-ui/core';

// import { FaFileDownload } from 'react-icons/fa';
import CustomSkeleton from '../../components/Loading/Skeleton';

function MaterialUITable(props) {
  const {
    rows,
    // getExcelCorretor, loadingExcelCorretor
  } = props;

  const [rowsState, setRowsState] = useState(rows.data);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleBaixarExcel = async id => {
  //   const excel = await getExcelCorretor(id);
  //   window.open(`${process.env.REACT_APP_API_ERP}/${excel.arquivo}`, '_blank');
  // };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  useEffect(() => {
    setRowsState(rows.data);
  }, [rows]);

  const classes = useStyles();

  return (
    <Box textAlign="left" mb="1rem">
      <Box mb="1rem">
        <h4>Valor do Vendedor:</h4>
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Vendedor</TableCell>
              <TableCell>Primeiras Faturas</TableCell>
              <TableCell>Demais Faturas</TableCell>
              <TableCell>Total Carteira</TableCell>
              {/* <TableCell>Ações</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.loading ? (
              <>
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomSkeleton height={20} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomSkeleton height={20} />
                  </TableCell>
                </TableRow>
              </>
            ) : rowsState.length ? (
              rowsState.map(row => (
                <TableRow key={row.idCorretor}>
                  <TableCell>{row.vendedor}</TableCell>
                  <TableCell>{row.numPrimeirasFaturas}</TableCell>
                  <TableCell>{row.numDemaisFaturas}</TableCell>
                  <TableCell>{row.totalCarteira}</TableCell>
                  {/* <TableCell>
                    <Box color="#2e6d09">
                      {loadingExcelCorretor ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <IconButton
                          color="inherit"
                          size="small"
                          onClick={() => handleBaixarExcel(row.idCorretor)}
                        >
                          <FaFileDownload />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell> */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>Sem dados</TableCell>
              </TableRow>
            )}
          </TableBody>
          {/* <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={4}
                count={rowsState.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Vendedores por página:"
              />
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MaterialUITable;
