import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import theme from '../../styles/theme';
import Page from './Page';
import api from '../../services/api';
import { formatDate } from '../../services/service';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

const Controller = props => {
  const { id } = useParams();
  const { user, history } = props;
  const { token, usuario, nivel_usuario } = user.user;
  const authStr = `Bearer ${token}`;
  const defaultAccount = {
    // Dados do usuário
    user: {
      nome: '',
      email: '',
      senha: '',
      nivel: 1,
      id_assessoria: null,
      ativo: true
    },
    // Dados da pessoa
    person: {
      cpf: '',
      estado_civil: '',
      sexo: '',
      data_nascimento: '',
      telefone: '',
      celular: '',
      numero_endereco: 0,
      complemento_endereco: '',
    },
    // Dados do endereço
    address: {
      cep: '',
      logradouro: '',
      cidade: '',
      bairro: '',
      uf: '',
    },
  };
  const [account, setAccount] = useState({
    user: defaultAccount.user,
    person: defaultAccount.person,
    address: defaultAccount.address,
    loading: false,
  });

  const salvarUsuario = async (data) => {
    // setLoading(true);

    try {
      const response = await api.post(`usuario`, data, {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if (response.data.ok) {
        // setLoading(false);
        history.goBack();
        alertSuccess('Usuário adicionado com sucesso!');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Page
        history={history}
        paramsId={id}
        sessionUserId={usuario}
        sessionUserLevel={nivel_usuario}
        loadingPage={account.loading}
        account={account}
        salvarUsuario={salvarUsuario}
      />
    </ThemeProvider>
  );
};

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
