import React from 'react';
import { Input, Col } from 'reactstrap';
import { LabelForm } from '../../views/styles';

function EscolhaClinica(props) {
  const {
    clinica,
    handleOnChange,
    clientInput,
    nivelUsuario,
    usuario,
    idClinicaDoAtendente,
    setParams,
  } = props;

  return (
    <>
      <LabelForm>Clínica *</LabelForm>
      <Input
        type="select"
        name="clinica"
        value={clientInput.clinica}
        onChange={e => handleOnChange(e)}
      >
        <option value="0">Escolha a clínica</option>
        {clinica.map((el, i) => {
          if (el.tipo == 'afiliado') {
            if (nivelUsuario == 8 || nivelUsuario == 9) {
              if (
                el.id == idClinicaDoAtendente ||
                (el.tipo == 'afiliado' &&
                  el.id_clinica_master == idClinicaDoAtendente)
              ) {
                return (
                  <option key={i} value={el.id}>
                    {el.razao_social ? el.razao_social : '(Sem nome)'} / {el.name}
                  </option>
                );
              }
            } else if (nivelUsuario == 7) {
              if (el.id == idClinicaDoAtendente) {
                return (
                  <option key={i} value={el.id}>
                    {el.razao_social ? el.razao_social : '(Sem nome)'} / {el.name}
                  </option>
                );
              }
            } else {
              return (
                <option key={i} value={el.id}>
                  {el.razao_social ? el.razao_social : '(Sem nome)'} / {el.name}
                </option>
              );
            }
          }
        })}
      </Input>
    </>
  );
}

export default EscolhaClinica;
