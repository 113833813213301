import React, { useState } from 'react';
import { Container, Box, Typography, Paper, Tab } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ContactsIcon from '@material-ui/icons/Contacts';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import NoContent from '../../components/Card/NoContent';
import ListaLeads from '../../views/Leads/ListaLeads';

const useStyles = makeStyles(() => ({
  container: {
    padding: '40px 30px 30px',
    '& > .MuiBox-root': {
      marginBottom: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  'article-header': {
    marginBottom: '1.875em',
  },
  'article-header--title': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gridColumnGap: '1em',
  },
  content: {
    // marginTop: 30,
    padding: 0,
  },
}));

export function Page(props) {
  const { leads, loading } = props;

  const classes = useStyles();

  const statusList = [
    {
      value: 0,
      name: 'pendente',
      newValue: 1,
    },
    {
      value: 1,
      name: 'encerrado',
      newValue: 0,
    },
  ];
  const [tabStatusLead, setTabStatusLead] = useState(statusList[0].name);

  const handleChangeTab = (event, newStatus) => {
    setTabStatusLead(newStatus);
  };

  return (
    <Container
      component="article"
      role="main"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <header className={classes['article-header']}>
        <Box className={classes['article-header--title']} itemProp="title">
          <ContactsIcon />
          <Typography variant="h1">Leads</Typography>
        </Box>
      </header>
      {loading || leads?.output ? (
        <TabContext value={tabStatusLead}>
          <TabList
            aria-label=""
            onChange={handleChangeTab}
            selectionFollowsFocus
            style={{ marginLeft: 8 }}
          >
            <Tab label="Pendentes" value={statusList[0].name} />
            <Tab label="Encerrados" value={statusList[1].name} />
          </TabList>

          <Box component={Paper}>
            {leads?.output ? (
              statusList.map(status => (
                <TabPanel value={status.name} key={status.value}>
                  <ListaLeads statusList={status} {...props} />
                </TabPanel>
              ))
            ) : (
              <Box p={3}>
                <Box mb={1}>
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={200}
                    height={40}
                  />
                </Box>
                <Box mb={1}>
                  <Skeleton variant="rect" animation="wave" height={56} />
                </Box>
                <Box mb={1}>
                  <Skeleton variant="rect" animation="wave" height={100} />
                </Box>
                <Box align="right">
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={200}
                    height={52}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </TabContext>
      ) : (
        <NoContent text={leads?.message} hasImage />
      )}
    </Container>
  );
}
