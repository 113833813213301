import React from 'react';
import { Input, Col } from 'reactstrap';
import { LabelForm } from '../../views/styles';

function EscolhaAssessoria(props) {
  const {
    assessoria,
    handleOnChange,
    clientInput,
    nivelUsuario,
    usuario,
    idAssessoriaDoCorretor,
    setParams,
  } = props;

  return (
    <>
      <LabelForm>Distribuidor *</LabelForm>
      <Input
        type="select"
        name="assessoria"
        value={clientInput.assessoria}
        onChange={e => handleOnChange(e)}
      >
        <option value="0">Escolha o distribuidor</option>
        {assessoria.map((el, i) => {
          if (nivelUsuario == 2 || nivelUsuario == 5) {
            if (
              el.id == idAssessoriaDoCorretor ||
              (el.tipo == 'afiliado' &&
                el.id_assessoria_master == idAssessoriaDoCorretor)
            ) {
              return (
                <option key={i} value={el.id}>
                  {el.razao_social ? el.razao_social : '(Sem nome)'} / {el.name}
                </option>
              );
            }
          } else if (nivelUsuario == 3 || nivelUsuario == 4) {
            if (el.id == idAssessoriaDoCorretor) {
              return (
                <option key={i} value={el.id}>
                  {el.razao_social ? el.razao_social : '(Sem nome)'} / {el.name}
                </option>
              );
            }
          } else {
            return (
              <option key={i} value={el.id}>
                {el.razao_social ? el.razao_social : '(Sem nome)'} / {el.name}
              </option>
            );
          }
        })}
      </Input>
    </>
  );
}

export default EscolhaAssessoria;
