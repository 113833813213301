import React, { useState } from 'react';
import { FaFileContract, FaHeartbeat, FaRegSave, FaExclamationCircle } from 'react-icons/fa';
import { Card, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Alert } from 'reactstrap';
import { CardComponent, LabelForm } from '../styles';
import EscolhaAssessoria from '../../components/Forms/EscolhaAssessoria';
import Api from '../../services/api';
import { verificaCamposVaziosInput } from '../../services/service';
import { connect } from 'react-redux';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';
import { MsgErrorDataTable } from '../styles';

function CadastrarNovaApolice(props) {

	const { assessoria, contatos, resultBuscaProd, idEstipulante, token, infosContatos, getApolices, history } = props;
	const { nivel_usuario } = props.user.user;
	const [ vendedor, setVendedor ] = useState([]);
	const [ msgError, setMsgError ] = useState(false);
	const [ disabled, setDisabled ] = useState({ assessoriaDisabled: true });
	const [ loading, setLoading ] = useState(false);

	// const [ propostaInput, setPropostaInput ] = useState({
  //   dep_1_gratis: '',
  //   desconto_titular: '',
  //   primeiro_pagamento: '',
  //   // id_apolice: idApolice,
  //   id_estipulante: idEstipulante,
  // });

	// const [ pagamentoInput, setPagamentoInput ] = useState({
  //   possuiAssinatura: '0',
  //   assinatura_asaas: '',
  //   cliente_asaas: '',
  //   email: '',
  //   primeiro_pagamento: propostaInput.primeiro_pagamento,
  //   documento: [],
  //   id_empresa: idEstipulante,
  //   // id_apolice: idApolice
  // })

	const [ clientInput, setClientInput ] = useState({
		assessoria: '',
		id_produto: '',
		id_estipulante: idEstipulante,
		id_usuario: props.user.user.usuario,
	});

	const authStr = `Bearer ${token}`;

	const handleOnChange = async event => {
		setMsgError(false);
		const { name, value } = event.target;

		if(name === 'assessoria' && value !== ''){
			const vendedor = await Api.get(`corretor-representante/assessoria/${value}`, { headers: { Authorization: authStr }});

			let vendedorArr = [];
			for(const i in vendedor.data.output){
				let tmpObj = {
					id_pessoa: vendedor.data.output[i].id_pessoa,
					id: vendedor.data.output[i].id,
					nome: vendedor.data.output[i].nome,
				}
				vendedorArr = [ ...vendedorArr, tmpObj ];
			}
			setVendedor(vendedorArr);
			setDisabled({ assessoriaDisabled: false });
		}

		setClientInput({ ...clientInput, [name]: value });
	}

	const efetivarApolice = async _ => {

		verificaCamposVaziosInput(
			[
				{ "nomeInput": "assessoria", "valorInput": clientInput.assessoria },
				{	"nomeInput": "corretor representante", "valorInput": clientInput.id_corretor_representante }
			]
		);

		setLoading(true);

		const resultReq = await Api.post('apolice', clientInput, { headers: { Authorization: authStr }});

		if(resultReq.data.ok){
			history.push(`/apolice/${resultReq.data.output.idApolice}/${idEstipulante}/0`);
		}else{
			alertError(resultReq.data.message);
		}
	}

	return (
    <Card>
			<ToastContainer/>
			<CardComponent>
				<Col md={12} className="text-left">
					<h4><FaFileContract/>&nbsp;Efetivar Contrato</h4>
				</Col>
				<Col md={12} className="mt-4">
					{
					assessoria&&
					assessoria.length > 0&&
						<EscolhaAssessoria
							assessoria={assessoria}
							clientInput={clientInput}
							handleOnChange={handleOnChange}
							nivelUsuario={props.user.user.nivel_usuario}
							usuario={props.user.user.usuario}
							idAssessoriaDoCorretor={props.user.user.assessoria}/>
					}
				</Col>
				<Col md={12} className="text-left mt-4">
					<LabelForm>Escolha o Consultor</LabelForm>
				</Col>
				<Col>
					{/* <Col md={12} className="text-left mb-2"><b>Escolha o Vendedor</b></Col> */}
					<Input type="select" name="id_corretor_representante" value={clientInput.id_corretor_representante} disabled={disabled.assessoriaDisabled} onChange={e => handleOnChange(e)}>
						<option value="0">Selecione</option>
						{
						vendedor&&
						vendedor.map((el, i) => (
							<option key={i} value={el.id}>{el.nome}</option>
						))
						}
					</Input>
				</Col>

				{
					resultBuscaProd.output&&
					(resultBuscaProd.output.length > 0)
					?
					<>
					<Col md={12} className="mt-4 text-left">
						<LabelForm>Produto</LabelForm>
					</Col>
					<Col>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText><FaHeartbeat/></InputGroupText>
							</InputGroupAddon>
							<Input type="select" name="id_produto" value={clientInput.id_produto} onChange={e => handleOnChange(e)} >
								<option value="">Selecione</option>
								{
								resultBuscaProd.output.map((el, i) => {
									// if(el.plano_empresarial === 1 && el.produto_adicional === 0){
										return <option key={i} value={el.id}>{el.nome}</option>
									// }
								})
								}
							</Input>
						</InputGroup>
					</Col>
					</>
					:
					<MsgErrorDataTable><FaExclamationCircle/>&nbsp;&nbsp;{resultBuscaProd.message}</MsgErrorDataTable>
				}
				<Col md={12} className="mb-4 mt-4">
					<Col md={12} className="text-right">
						<Button color='success' size="sm" onClick={() => efetivarApolice() }>
							<FaRegSave />&nbsp;&nbsp;Efetivar
						</Button>
						{msgError&&
							<Alert color="danger">{msgError}</Alert>
						}
					</Col>
				</Col>
			</CardComponent>
		</Card>
  )
}

const mapStateToProps = store => ({
	user: store.user,
})

export default connect(mapStateToProps)(CadastrarNovaApolice);
