import React, { useState } from 'react';
import { BodyFormComponent, LabelForm, SectionFormHeader } from '../styles';
import { FaArrowLeft, FaBuilding, FaDownload, FaFilePdf } from 'react-icons/fa';
import { Button, Card, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner } from 'reactstrap';
import Api from '../../services/api';
import { Switch } from '@material-ui/core';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { alertSuccess, alertError, alertWarning } from '../../components/Alerts/Alerts';
import { Editor } from '@tinymce/tinymce-react';


function EditarProduto(props) {

  const { history, resultProduto, setResultProduto, token, gruposVenda } = props;
  const { nome, descricao, ativo, limite_idade, id_grupo_vendas, contrato } = props.resultProduto;
  const [ loading, setLoading ] = useState(false);
  const [ loadingExportarPDF, setLoadingExportarPDF ] = useState(false);
  const [ linkBaixarPDF, setLinkBaixarPDF ] = useState('');
  const [ text, setText ] = useState('');

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleOnChange = event => {
    const { name, value } = event.target;
    setResultProduto({ ...resultProduto, [name]: value });
  }

  const handleSubmit = async e => {

    e.preventDefault();
    setLoading(true);

    const response = await Api.post('produto/editar', resultProduto, { headers: { Authorization: `Bearer ${token}` }} );

    if(response.data.ok){
      setLoading(false);
      // window.history.back();
      alertSuccess('Atualizado com sucesso');
    }
  }

  const gerarPDF = async e => {
    setLoadingExportarPDF(true)
    const response = await Api.post('produto/preview-contrato', resultProduto, { headers: { Authorization: `Bearer ${token}` }} );
    if(response.data.ok){
      setLoadingExportarPDF(false)
      setLinkBaixarPDF(response.data.output.arquivo);
    }
  }

  const toggleTaxaAdesao = e => {
    setResultProduto({ ...resultProduto, ativo: e.target.checked });
  };

  const toggleLimiteIdade = e => {
    setResultProduto({ ...resultProduto, limite_idade: e.target.checked });
  };

  const handleEditorChange = (content, editor) => {
    setResultProduto({ ...resultProduto, contrato: content.target.getContent() });
  }

  return (
     <Card style={{ marginTop: "30px" }}>
      <Form onSubmit={handleSubmit}>
      <BodyFormComponent>
        <Row className="mt-2 mb-4">
          <Col md={12}><SectionFormHeader>Dados do produto</SectionFormHeader></Col>
          <Col md={4} className="mt-2">
          <LabelForm>Nome</LabelForm>
            <InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText><FaBuilding /></InputGroupText>
							</InputGroupAddon>
              <Input
                type="text"
                name="nome"
                value={nome}
                placeholder="Nome do produto"
                onChange={e => {handleOnChange(e)}} />
						</InputGroup>
          </Col>
          <Col md={3} className="mt-2">
            <LabelForm>Grupos de Venda</LabelForm>
            <Input
            type="select"
            className="input-filter-dist"
            name="id_grupo_vendas"
            value={id_grupo_vendas}
            onChange={e => handleOnChange(e)}>
              <option value="">Escolha um Grupo de Venda</option>
              {
                gruposVenda.map((gpVenda, i) => {
                  return <option key={i} value={gpVenda.id}>{gpVenda.nome}</option>
                })
              }
            </Input>
          </Col>
          <Col md={3} className="mt-2">
            <LabelForm style={{marginTop: '32px' }}>Possui limite de idade? &nbsp;</LabelForm>
            <Switch
              checked={limite_idade ? limite_idade : 0}
              onChange={toggleLimiteIdade}
              name="limite_idade"
              size="small"
              color="secondary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
          <Col md={2} className="mt-2">
            <LabelForm style={{marginTop: '32px' }}>Ativo? &nbsp;</LabelForm>
            <Switch
              checked={ativo ? ativo : 0}
              onChange={toggleTaxaAdesao}
              name="ativo"
              size="small"
              color="secondary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col md={12} className="mt-2">
            <LabelForm>Descrição</LabelForm>
            <Input
              type="textarea"
              name="descricao"
              rows="6"
              value={descricao}
              onChange={e => handleOnChange(e)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <LabelForm>Contrato</LabelForm>
            <Editor
              apiKey='j76q3j3i4r0fwsilb6y0n04ylc4p0iet4j9nv3yakpv4z8a0'
              // onInit={(evt, editor) => editorRef.current = editor}
              onChange={handleEditorChange}
              initialValue={contrato}
              init={{
                  height: 500,
                  menubar: false,
                  plugins: [],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }
              }
            />
          </Col>
        </Row>
        <Row>
        <Col md={6}>
            {linkBaixarPDF ? (
              <a
                href={`${process.env.REACT_APP_API_ERP}/${linkBaixarPDF}`}
                rel="noopener noreferrer"
                download={`${process.env.REACT_APP_API_ERP}/${linkBaixarPDF}`}
                target="_blank"
              >
                <Button className="mt-3" color="success" size="sm" outline  onClick={() => false}>
                  <FaDownload /> Pré-Visualizar
                </Button>
              </a>
            ) : (
              <Button
                className="mt-3" color="warning" size="sm" outline
                onClick={() => gerarPDF()}
                disabled={loadingExportarPDF}
              >
                {loadingExportarPDF ? (
                  <>
                    <Spinner size="sm" /> Carregando...
                  </>
                ) : (
                  <>
                    <FaFilePdf /> Carregar Pré-visualização do contrato
                  </>
                )}
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mt-4 text-right">
          <Col>
            <Button color="danger" onClick={() => history.goBack()}><FaArrowLeft />&nbsp;&nbsp;Voltar</Button>&nbsp;
            <SubmitBtn
              label="Salvar Alterações"
              labelOnClick="Aguarde..."
              loading={loading}
              typeSubmit="submit"
              metodo={handleSubmit}
              color="#0A7A73"
            />
          </Col>
        </Row>
      </BodyFormComponent>
      </Form>
    </Card>
  )
}

export default EditarProduto;
