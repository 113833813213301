import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Input,
  Table,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  ListGroup,
  ListGroupItem,
  Badge,
} from 'reactstrap';
import {
  FaFileInvoiceDollar,
  FaLink,
  FaCalendar,
  FaExclamationTriangle,
  FaMoneyBillAlt,
  FaHandHoldingUsd,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import ResgatarFaturasAsaas from './ResgatarFaturasAsaas';
import { OverflowContainer, CardComponent } from '../styles';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';
import { formatDate } from '../../services/service';
import Api from '../../services/api';
import Loading from '../../components/Loading/Loading';

function Faturas(props) {
  const arrayLinksPendentes = [];
  const {
    detalhesApolice,
    faturas,
    configPayment,
    idUsuario,
    valorApolice,
    segurados,
    gerarCobrancaEmpresarial,
    archivesPolicies,
  } = props;

  // const [loading, setLoading] = useState(false);
  const [loadingBaixa, setLoadingBaixa] = useState(false);
  // const [ loadingCobranca, setLoadingCobranca ] = useState(false);
  const [loadingCobrancaEmpresarial, setLoadingCobrancaEmpresarial] =
    useState(false);
  const [loadingReagendar, setLoadingReagendar] = useState(false);
  // const [ loadingAtualizarAssinatura, setLoadingAtualizarAssinatura ] = useState(false);
  const [modalSelected, setModalSelected] = useState({
    valor: '',
    dataVencimento: '',
  });
  const [cancelarFaturaInput, setCancelarFaturaInput] = useState('');
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalDarBaixa, setModalDarBaixa] = useState(false);
  // const [ modalCobranca, setModalCobranca ] = useState(false);
  const [modalCobrancaEmpresarial, setModalCobrancaEmpresarial] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [postData, setPostData] = useState({
    dataReagendamento: '',
    idFatura: '',
    primeiroPagamento: false,
  });

  const toggleModalCancelar = idAsaas => {
    setPostData({ idFatura: idAsaas });
    setModalCancelar(!modalCancelar);
  };

  const toggleModalDarBaixa = idAsaas => {
    setPostData({ idFatura: idAsaas });
    setModalDarBaixa(!modalDarBaixa);
  };

  const toggleModalCobrancaEmpresarial = () => {
    setModalCobrancaEmpresarial(!modalCobrancaEmpresarial);
  };

  // const toggleModalCobranca = () => {
  //     setModalCobranca(!modalCobranca);
  // }

  const toggleModalOpen = (valor, dataVencimento, idFatura) => {
    setModalSelected({
      ...modalSelected,
      valor,
      dataVencimento,
    });
    setPostData({
      ...postData,
      idFatura,
    });
    setModalOpen(!modalOpen);
  };

  const [faturasRenderButton, setFaturasRenderButton] = useState();
  // const [ actionBtn, setActionBtn ] = useState(false);

  // const baixarCarne = async () => {
  //     setLoading(true);
  //     let data = { links: arrayLinksPendentes };
  //     try {
  //         const response = await Api.post(`faturas/gerar-carne`, data , { headers: { Authorization: `Bearer ${props.user.user.token}` }});
  //     if(response.data.ok) {
  //         window.open(`${process.env.REACT_APP_API_ERP}/${response.data.output.link}`);
  //     } else {
  //         throw new Error(response.data.message)
  //     }
  //     } catch (error) {
  //         alertError("Erro ao baixar Carnê: ", error.message);
  //     }
  //     setLoading(false);
  // }

  const cancelaFatura = async () => {
    if (cancelarFaturaInput === 'SIM') {
      const result = await Api.post('fatura/cancelar', postData, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
      if (result.data.ok) {
        window.location.reload();
      } else {
        alertError(result.data.message);
      }
    } else {
      alertWarning('Informe corretamente a palavra para cancelar a fatura');
      return false;
    }
  };

  const darBaixaFatura = async () => {
    const form = document.forms.formDarBaixaFatura;
    const motivo = form.motivo.value;
    const descricao = form.descricao.value;

    if (motivo === '') {
      alertWarning('Selecione o MOTIVO.');
      return;
    }
    setLoadingBaixa(true);
    const objBaixaFatura = {
      idFatura: postData.idFatura,
      motivo,
      descricao,
      idUsuario,
    };

    const result = await Api.post('fatura/baixa', objBaixaFatura, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    if (result.data.ok) {
      setLoadingBaixa(false);
      window.location.reload();
    } else {
      setLoadingBaixa(false);
      alertError(result.data.message);
    }
  };

  const reagendarFatura = async () => {
    setLoadingReagendar(true);
    try {
      const authStr = `Bearer ${props.user.user.token}`;
      const response = await Api.post('fatura/alterar-data', postData, {
        headers: { Authorization: authStr },
      });

      if (response.data.ok) {
        window.location.reload();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    } finally {
      setLoadingReagendar(false);
    }
  };

  // const gerarCobranca = async () => {
  //     const form = document.forms.formGerarCobranca;
  //     const descricao = form.descricao.value;
  //     const dataCobranca = form.dataCobranca.value;
  //     const price = form.price.value;

  //     if (descricao==="") {
  //         alertWarning("Descrição não pode ser nula.");
  //         return;
  //     }

  //     if (dataCobranca==="") {
  //         alertWarning("Escolha a data de vencimento");
  //         return;
  //     }

  //     if (price==="" || price<=0) {
  //         alertWarning("O valor é inválido.");
  //         return;
  //     }

  //     setLoadingCobranca(true);
  //     const authStr = `Bearer ${props.user.user.token}`;
  //     const objNewFatura = {
  //         idApolice: detalhesApolice?.id,
  //         cobrancaInfo: {
  //             description: descricao,
  //             dataCobranca: dataCobranca,
  //             price: price
  //         }
  //     };
  //     const result = await Api.post("faturas/gerar-cobranca", objNewFatura, { headers: { Authorization: authStr }});
  //     if(result.data.ok){
  //         setLoadingCobranca(false);
  //         window.location.reload();
  //     }else{
  //         setLoadingCobranca(false);
  //         alertError(result.data.message);
  //     }

  //     setLoadingCobranca(false);
  // }

  useEffect(() => {
    renderButtons();
  }, [detalhesApolice, archivesPolicies, faturas]);

  const renderButtons = () => {
    let urlAsaas = '';
    let posicaoContratoArray = '';
    let contrato = '';
    let linkCompleto = '';
    const renderBtn = [];
    if (detalhesApolice.status === 'Aguardando primeiro pagamento') {
      setPostData({ ...postData, primeiroPagamento: true });
      faturas.data.map(el => {
        if (el.status === 'Pendente') {
          urlAsaas = el?.link_arquivo.split('/');
          archivesPolicies.data.forEach((element, i) => {
            if (element.contrato === '1') {
              posicaoContratoArray = i;
            }
          });
          contrato = archivesPolicies.data[posicaoContratoArray];
          linkCompleto = `${process.env.REACT_APP_API_ERP.replace(
            '/api',
            '',
          )}pagamento-primeira-fatura/?fatura=${urlAsaas[4]}&contrato=${
            contrato?.link_arquivo
          }`;
          renderBtn.push(
            <a
              key={el.id}
              style={{
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
              }}
              href={linkCompleto}
              rel="noopener noreferrer"
              className="btn btn-outline-danger"
              target="_blank"
            >
              <FaFileInvoiceDollar className="mr-2" />
              Baixar Primeira Fatura
            </a>,
          );
        }
        // else {
        //     setActionBtn(true)
        // }
      });
      setFaturasRenderButton(renderBtn);
    }
    // else {
    //     setFaturasRenderButton(
    //         <Button color="danger" outline size="sm" onClick={() => baixarCarne() } >
    //             <FaDownload className="mr-2"/>Baixar Carnê
    //         </Button>
    //     );
    // }
  };

  const handleGerarCobrancaEmpresarial = async () => {
    setLoadingCobrancaEmpresarial(true);
    const form = document.forms.formCobrancaEmpresarial;
    const dataVenc = form.inputDataVenc.value;
    if (dataVenc === '') {
      alertWarning('Selecione a data de vencimento da cobrança.');
      setLoadingCobrancaEmpresarial(false);
      return;
    }

    await gerarCobrancaEmpresarial(dataVenc);

    toggleModalCobrancaEmpresarial();
    setLoadingCobrancaEmpresarial(false);
  };

  // const handleAtualizarAssinatura = async () => {
  //     setLoadingAtualizarAssinatura(true)
  //     try {
  //         const result = await Api.post(`apolice/${detalhesApolice?.id}/atualizar-assinatura`, {} , { headers: { Authorization: `Bearer ${props.user.user.token}` }});
  //         if(result.data.ok){
  //             alertSuccess(result.data.message)
  //         }else{
  //             alertWarning(result.data.message)
  //         }
  //     } catch(err) {
  //         alertError("Houve um erro na requisição. Caso persistir, contate o suporte técnico.")
  //     }
  //     setLoadingAtualizarAssinatura(false)
  // }

  return (
    <>
      <Card className="my-4">
        <ToastContainer />
        <CardComponent className="mb-2">
          <Col className="header-content text-left">
            <h2>
              <FaFileInvoiceDollar className="mr-2" />
              Faturas
            </h2>
          </Col>
        </CardComponent>
        <hr />
        <Row className="mt-1">
          <Col xs="auto" className="mb-2">
            <ResgatarFaturasAsaas
              idClienteAsaas={configPayment?.id_cliente_asaas}
              props={props}
              idApolice={detalhesApolice?.id}
            />
          </Col>
          <Col xs="auto" className="mb-2">
            {detalhesApolice?.id_estipulante != 1 && (
              <Button
                color="success"
                outline
                size="sm"
                onClick={toggleModalCobrancaEmpresarial}
              >
                <FaHandHoldingUsd className="mr-2" />
                Gerar Cobrança Empresarial
              </Button>
            )}
          </Col>
          {faturasRenderButton?.map((fatura, index) => (
            <Col xs="auto" className="mb-2" key={index}>
              {fatura}
            </Col>
          ))}
        </Row>
        <OverflowContainer className="mt-3">
          {!faturas.loading ? (
            faturas.error ? (
              <div className="ml-2 mb-4 text-left">
                <span style={{ color: 'red' }}>
                  <FaExclamationTriangle /> {faturas.error}
                </span>
              </div>
            ) : (
              <Table>
                <thead className="text-left">
                  <tr>
                    <th>Valor do Boleto</th>
                    <th>Data Vencimento</th>
                    <th>Status</th>
                    <th>Link Boleto</th>
                    <th />
                    <th>Funções</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {faturas.data.length ? (
                    faturas.data.map((el, i) => {
                      if (el.status !== 'Cancelada' && el.tipo_cobranca == 1) {
                        if (el.status === 'Pendente') {
                          arrayLinksPendentes.push(el?.link_arquivo);
                        }
                        return (
                          <tr key={i}>
                            <td>R$ {el.valor}</td>
                            <td>{formatDate(el.data_vencimento)}</td>
                            <td>{el.status}</td>
                            <td>
                              <a
                                href={el?.link_arquivo}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <FaLink />
                                &nbsp;Clique Aqui
                              </a>
                            </td>
                            <td>
                              <span
                                style={{
                                  display: 'flex',
                                  color: '#007bff',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  toggleModalOpen(
                                    el.valor,
                                    el.data_vencimento,
                                    el.id_asaas,
                                  )
                                }
                              >
                                <FaCalendar />
                                &nbsp;Reagendar
                              </span>
                            </td>
                            <td>
                              {props.user.user.nivel_usuario == 1 && (
                                <>
                                  <Button
                                    disabled={el.status !== 'Pendente'}
                                    size="sm"
                                    color="warning"
                                    onClick={() =>
                                      toggleModalDarBaixa(el.id_asaas)
                                    }
                                    title="Dar baixa"
                                    className="ml-3"
                                  >
                                    <FaMoneyBillAlt
                                      color="white"
                                      title="Dar baixa"
                                    />
                                  </Button>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr className="text-center">
                      <td>Sem faturas</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )
          ) : (
            <Loading />
          )}
        </OverflowContainer>
      </Card>
      {modalOpen && (
        <Modal isOpen={modalOpen} toggle={toggleModalOpen}>
          <ModalHeader toggle={toggleModalOpen}>Reagendar Fatura</ModalHeader>
          <ModalBody>
            <p>
              Reagendamento de R${modalSelected.valor} da data{' '}
              {formatDate(modalSelected.dataVencimento)}
            </p>
            <Input
              type="date"
              name="dataReagendamento"
              value={postData.dataReagendamento}
              onChange={e =>
                setPostData({
                  ...postData,
                  dataReagendamento: e.target.value,
                })
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              size="sm"
              onClick={toggleModalOpen}
              disabled={loadingReagendar}
            >
              Cancelar
            </Button>
            <Button
              color="warning"
              size="sm"
              onClick={() => reagendarFatura()}
              disabled={loadingReagendar}
            >
              {loadingReagendar ? (
                <>
                  <Spinner size="sm" className="mr-2" />
                  <span>Reagendando...</span>
                </>
              ) : (
                'Reagendar'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {modalCancelar && (
        <Modal isOpen={modalCancelar} toggle={toggleModalCancelar}>
          <ModalHeader toggle={toggleModalCancelar}>
            CANCELAR Fatura
          </ModalHeader>
          <ModalBody>
            <p>Se você deseja CANCELAR essa fatura escreva SIM</p>
            <Input
              placeholder="Deseja Continuar?"
              name="cancelarFaturaInput"
              value={cancelarFaturaInput}
              onChange={e => setCancelarFaturaInput(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" size="sm" onClick={toggleModalCancelar}>
              Cancelar
            </Button>
            <Button color="success" size="sm" onClick={() => cancelaFatura()}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {modalDarBaixa && (
        <Modal isOpen={modalDarBaixa} toggle={toggleModalDarBaixa}>
          <form name="formDarBaixaFatura">
            <ModalHeader toggle={toggleModalDarBaixa}>
              Dar baixa na Fatura
            </ModalHeader>
            <ModalBody>
              <p>
                Dar baixa nesta fatura irá cancelar no Asaas e mudar o status no
                ERP para &apos;Baixa Manual&apos; ou &apos;Cancelada&apos;
              </p>
              <Input type="select" name="motivo">
                <option value="">Selecione o motivo:</option>
                <option value="Pagamento em dinheiro">
                  Pagamento em dinheiro
                </option>
                <option value="Pagamento na máquina de cartão">
                  Pagamento na máquina de cartão
                </option>
                <option value="Campanha ou Desconto">
                  Campanha ou Desconto
                </option>
                <option value="Renegociado">Renegociado</option>
                <option value="Cancelada">Cancelada</option>
              </Input>
              <Input
                name="descricao"
                type="textarea"
                placeholder="Descrição: Cliente pagou a primeira fatura no dinheiro e teve que dar troco; Fatura com baixa devido à campanha X ou desconto."
                style={{ minHeight: '100px', marginTop: 4 }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                size="sm"
                onClick={toggleModalDarBaixa}
                disabled={loadingBaixa}
              >
                Voltar
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => darBaixaFatura()}
                disabled={loadingBaixa}
              >
                {loadingBaixa ? (
                  <>
                    <Spinner className="mr-2" color="white" size="sm" />
                    <span>Confirmando...</span>
                  </>
                ) : (
                  'Confirmar'
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
      {/* {
            modalCobranca&&
            <Modal isOpen={modalCobranca} toggle={toggleModalCobranca} >
                <form name="formGerarCobranca">
                    <ModalHeader toggle={toggleModalCobranca}>Gerar uma cobrança</ModalHeader>
                    <ModalBody>
                        <p>Criar uma cobrança irá gerar um boleto para o cliente desta apólice.</p>

                        <Row>
                            <Col md={12}>
                                <label htmlFor="descricao">Descrição</label>
                                <Input
                                    name="descricao"
                                    type="text"
                                    placeholder="Ex.: Abatimento de 2 boletos atrasados."
                                />
                            </Col>
                        </Row>

                        <Row style={{marginTop: 6}}>
                            <Col md={6}>
                                <label htmlFor="dataCobranca">Data de Vencimento</label>
                                <Input name="dataCobranca" type="date" />
                            </Col>

                            <Col md={6}>
                                <label htmlFor="price">Valor</label>
                                <Input name="price" type="number" placeholder="Ex.: 27.80" />
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" size="sm" onClick={toggleModalCobranca}>Cancelar</Button>
                        <Button color="success" size="sm" onClick={() => gerarCobranca()} disabled={loadingCobranca}>
                            {
                                loadingCobranca?
                                <Spinner color="white" size="sm"/>
                                :
                                'Confirmar'
                            }
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        } */}
      {modalCobrancaEmpresarial && (
        <Modal
          isOpen={modalCobrancaEmpresarial}
          toggle={toggleModalCobrancaEmpresarial}
        >
          <ModalHeader toggle={toggleModalCobrancaEmpresarial}>
            Gerar Cobrança Empresarial
          </ModalHeader>
          <ModalBody>
            <p>
              Esta ação irá gerar uma fatura com base nos segurados ativos nesta
              apólice e seus produtos. Criará também um relátorio CSV que deve
              ser enviado juntamente com a fatura para o cliente.
            </p>
            <OverflowContainer height="180px">
              <ListGroup>
                {segurados.data.map((el, i) => (
                  <ListGroupItem key={i}>
                    <span className="mr-1">{el.nome}</span>
                    <Badge className="mr-1" color="info" size="lg">
                      {el.produto}
                    </Badge>
                    <Badge color="success" size="lg">
                      R$ {el.total_segurado.toFixed(2)}
                    </Badge>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </OverflowContainer>

            <form name="formCobrancaEmpresarial">
              <Row>
                <Col md={6}>
                  <label htmlFor="inputDataVenc">Data de Vencimento</label>
                  <Input type="date" name="inputDataVenc" />
                </Col>
              </Row>
            </form>

            <Row className="mt-3 text-right">
              <Col> Valor da fatura: R$ {valorApolice.toFixed(2)} </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              disabled={loadingCobrancaEmpresarial}
              size="sm"
              onClick={toggleModalCobrancaEmpresarial}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              disabled={loadingCobrancaEmpresarial}
              size="sm"
              onClick={handleGerarCobrancaEmpresarial}
            >
              {loadingCobrancaEmpresarial ? (
                <>
                  <Spinner color="dark" size="sm" className="mr-2" />
                  <span>Confirmando...</span>
                </>
              ) : (
                'Confirmar'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Faturas);
