import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const alertWarning = msg => {
  toast.warning(msg, {
    position: 'bottom-right',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: { borderRadius: '5px', color: '#000' },
  });
};

export const alertError = msg => {
  toast.error(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: { borderRadius: '5px' },
  });
};

export const alertSuccess = msg => {
  toast.success(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: { borderRadius: '5px' },
  });
};
