import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Page from './Page';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

function Controller(props) {
  const { idClinica } = useParams();

  const { user } = props;
  const { token } = user.user;

  const history = useHistory();

  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [clinicas, setClinicas] = useState([]);

  const [clinica, setClinica] = useState({
    data: {},
    endereco: {},
    loading: true,
  });

  const [atendentes, setAtendentes] = useState({
    data: [],
    loading: true,
  });

  const [ gruposVenda, setGruposVenda ] = useState([{}]);

  useEffect(() => {
    if (idClinica !== 0) {
      getDadosClinica();
      getAtendentes();
      getGruposVenda();
      buscarClinicas();
    }
  }, [idClinica]);

  const getDadosClinica = async () => {
    try {
      const response = await Api.get(`/clinica/${idClinica}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const idPessoa = response.data.output.id_pessoa;

      if (response.data.ok) {
        const responseEndereco = await Api.get(`/enderecos/${idPessoa}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setClinica({
          data: response.data.output,
          endereco: responseEndereco.data.output[0],
          loading: false,
        });
      }
    } catch (error) {
      history.goBack()
    }
  };

  const getAtendentes = async () => {
    setAtendentes({ data: [], loading: true });

    try {
      const response = await Api.get(
        `/clinica-atendente/clinica/${idClinica}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.ok) {
        setAtendentes({ data: response.data.output, loading: false });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log('Erro ao buscar atendentes', error.message);
      setAtendentes({ data: [], loading: false });
    }
  };

  const getGruposVenda = async _ => {
    let fetchGrupoVendas = await Api.get('/grupovendas', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
    if(fetchGrupoVendas.data.ok) {
        setGruposVenda(fetchGrupoVendas.data.output);
    }
  }

  const buscarClinicas = async () => {
    const request = await Api.get('/clinicas/', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setClinicas(request.data);
  };

  const submitChangeStatus = async atendentesEditados => {
    setLoadingChangeStatus(true);
    try {
      const response = await Api.put(
        '/clinica-atendente',
        atendentesEditados,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.ok) {
        alertSuccess('Atendente(s) editado(s) com sucesso!');
        getAtendentes();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao editar atendente(s)', error.message);
    }
    setLoadingChangeStatus(false);
  };

  const excluirAtendente = async data => {
    const response = await Api.post('clinica-atendente/deletar', data, { headers: { Authorization: `Bearer ${token}` }} );
    if(response.data.ok){
      alertSuccess('Atendente excluído com sucesso.');
      getAtendentes();
    } else {
      alertError(`${response.data.message}`);
    }
  }

  return (
    <>
      <Page
        clinica={clinica}
        atendentes={atendentes}
        submitChangeStatus={submitChangeStatus}
        loadingChangeStatus={loadingChangeStatus}
        getAtendentes={getAtendentes}
        getDadosClinica={getDadosClinica}
        gruposVenda={gruposVenda}
        user={user}
        excluirAtendente={excluirAtendente}
        clinicas={clinicas}
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
