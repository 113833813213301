import React, { useState } from 'react';

import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';

import { DeleteForever, Edit } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import DialogDelete from '../Dialog/Delete';
import DialogVideo from '../Dialog/Video';
import ModalEditMaterialApoio from './ModalCadastro';

import imageDefault from '../../assets/img/default-image-material-apoio.png';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 336,
    maxHeight: 270,
    cursor: 'pointer',
    transition: theme.transitions.create(['transform'], { duration: 0.3 }),
    '&:hover': {
      transform: 'scale(1.02)',
      '& $cardActions': {
        opacity: 1,
      },
    },
    position: 'relative',
    hover: {},
  },

  cardActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 0,
    padding: 0,
    opacity: 0,
  },

  media: {
    width: '100%',
    height: 192,
  },

  content: {
    padding: '10px 10px 20px',
    height: 80,
    textAlign: 'left',
  },

  buttonDelete: {
    margin: 0,
    padding: 0,
    width: 30,
    height: 30,
    minWidth: 30,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 5,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },

  buttonEdit: {
    margin: 0,
    padding: 0,
    width: 30,
    height: 30,
    minWidth: 30,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      background: theme.palette.secondary.medium,
    },
  },

  paper: {
    background: theme.palette.background.paper,
    width: 700,
    padding: 0,
    borderRadius: 5,
    boxShadow: '7px -7px 10px rgba(0, 0, 0, 0.05)',
    border: '1px solid #C9C9C9',
  },
}));

function CardTreinamento(props) {
  const {
    nivelUsuario,
    idMaterial,
    img,
    nome,
    descricao,
    tipoApresentacao,
    linkApresentacao,
    actionType,
    modalAddEditMaterialApoio,
    showModalEditMaterialApoio,
    showDialogDeleteMaterialApoio,
    dialogDeleteMaterialApoio,
    toggleDialogDeleteMaterialApoio,
    confirmDeleteMaterialApoio,
  } = props;

  const classes = useStyles();

  const [dialogVideo, setDialogVideo] = useState(false);
  const [idMaterialDelete, setIdMaterialDelete] = useState(0);

  const toggleDialogVideo = () => {
    if (
      modalAddEditMaterialApoio === false ||
      dialogDeleteMaterialApoio === false
    ) {
      if (linkApresentacao !== undefined && tipoApresentacao !== 'video') {
        window.open(linkApresentacao, '_blank');
        window.focus();
        return;
      }
      setDialogVideo(!dialogVideo);
    }
  };

  const handleOnClickDeleteMaterialApoio = () => {
    setIdMaterialDelete(idMaterial);
    showDialogDeleteMaterialApoio(idMaterial);
  };

  const handleOnClickEditMaterialApoio = () => {
    showModalEditMaterialApoio(idMaterial);
  };

  const toggleIsVideo = tipoApresentacao === 'video';

  const imageServer = img
    ? `${process.env.REACT_APP_API_ERP}/files/docs/${img}`
    : img;

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea onClick={toggleDialogVideo}>
          <CardMedia
            image={imageServer || imageDefault}
            title={nome}
            className={classes.media}
          />
          <CardContent className={classes.content}>
            <Typography variant="body2" component="h2" paragraph>
              {nome}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              noWrap
            >
              {descricao}
            </Typography>
          </CardContent>
        </CardActionArea>
        {nivelUsuario && nivelUsuario == 1 && (
          <CardActions className={classes.cardActions} disableSpacing>
            <Tooltip title="Deletar">
              <Button
                className={classes.buttonDelete}
                variant="contained"
                onClick={() => handleOnClickDeleteMaterialApoio()}
              >
                <DeleteForever fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                className={classes.buttonEdit}
                variant="contained"
                onClick={handleOnClickEditMaterialApoio}
              >
                <Edit fontSize="small" />
              </Button>
            </Tooltip>
          </CardActions>
        )}
      </Card>

      {dialogDeleteMaterialApoio && idMaterialDelete > 0 && (
        <DialogDelete
          id="delete-confirmation"
          keepMounted
          open={dialogDeleteMaterialApoio}
          onClose={toggleDialogDeleteMaterialApoio}
          title="Excluir material de apoio?"
          description={`Você realmente deseja excluir o material de apoio "${nome}"?`}
          itemId={idMaterialDelete}
          confirmDelete={confirmDeleteMaterialApoio}
          setIdMaterialDelete={setIdMaterialDelete}
        />
      )}

      {modalAddEditMaterialApoio && actionType === 'editar' && (
        <ModalEditMaterialApoio {...props} />
      )}

      {dialogVideo && (
        <DialogVideo
          keepMounted
          id="dialog-video"
          open={dialogVideo}
          onClose={toggleDialogVideo}
          title={nome}
          link={linkApresentacao}
          toggleIsVideo={toggleIsVideo}
        />
      )}
    </>
  );
}

export default CardTreinamento;
