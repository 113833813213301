import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from '../../services/api';

import Page from './Page';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';

function Client(props) {
  const [client, setClient] = useState();
  const [apolices, setApolices] = useState();
  const [dependents, setDependents] = useState();
  const [loadingApolice, setLoadingApolice] = useState(false);
  const [produtos, setProdutos] = useState();
  const [assessorias, setAssessorias] = useState();

  const [endossosEdicao, setEndossosEdicao] = useState([]);
  const [loadingEndossos, setLoadingEndossos] = useState(false);

  const authStr = `Bearer ${props.user.user.token}`;

  const { idClient } = useParams();
  const history = useHistory();

  useEffect(() => {
    inicia();
  }, []);

  const inicia = () => {
    getClient();
    getInfosPolicies();
    getDependents();
    getProdutos();
    getAssessorias();
    getEndossosEdicao();
  };

  const getClient = async _ => {
    const result = await Api.get(`segurado/detalhe/${idClient}`, {
      headers: { Authorization: authStr },
    });
    setClient(result.data.output);
  };

  const getInfosPolicies = async _ => {
    setLoadingApolice(true);
    const result = await Api.get(`segurado/${idClient}/apolices`, {
      headers: { Authorization: authStr },
    });
    setApolices(result.data);
    setLoadingApolice(false);
  };

  const getDependents = async _ => {
    const result = await Api.get(`segurado/dependentes/${idClient}`, {
      headers: { Authorization: authStr },
    });
    setDependents(result.data);
  };

  const getProdutos = async _ => {
    const fetchProducts = await Api.get(`produtos/`, {
      headers: { Authorization: authStr },
    });
    if (fetchProducts.data.ok) {
      setProdutos(fetchProducts.data.output);
    }
  };

  const getAssessorias = async _ => {
    const response = await Api.get('/assessorias/', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    setAssessorias(response.data.output);
    setLoadingApolice(false);
  };

  const getEndossosEdicao = async _ => {
    setLoadingEndossos(true);
    try {
      const response = await Api.get(
        `segurado/${idClient}/endossos-edicao-dados`,
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (response.data.ok) {
        setEndossosEdicao(response.data.output);
      } else {
        setEndossosEdicao([]);
        // alertWarning(response.data.message);
      }
    } catch (e) {
      alertError('Erro na requisição dos endossos.');
    }
    setLoadingEndossos(false);
  };

  return (
    <>
      {client && (
        <Page
          client={client}
          apolices={apolices}
          dependents={dependents}
          idClient={idClient}
          loadingApolice={loadingApolice}
          history={history}
          token={props.user.user.token}
          produtos={produtos}
          assessorias={assessorias}
          loadingEndossos={loadingEndossos}
          endossosEdicao={endossosEdicao}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Client);
