import React, { useState } from 'react';
import * as cep from 'cep-promise';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendFormData } from '../../services/getApi';
import Page from './Page';
import { alertError } from '../../components/Alerts/Alerts';

function Controller(props) {
  const history = useHistory();
  const [alertErrorMsg, setAlertErrorMsg] = useState();
  const [empresa, setEmpresa] = useState({
    cnpj: '',
    nome: '',
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    id_usuario: props.user.user.usuario,
  });

  const handleOnChange = event => {
    const { name, value } = event.target;
    setEmpresa({ ...empresa, [name]: value });
  };

  const handleCepOnChange = event => {
    const { name, value } = event.target;

    if (value.length === 8 && name === 'cep') {
      cep(value).then(vl => {
        setEmpresa({
          ...empresa,
          [name]: value,
          logradouro: vl.street,
          cidade: vl.city,
          bairro: vl.neighborhood,
          uf: vl.state,
        });
      });
    } else {
      setEmpresa({ ...empresa, [name]: value });
    }
  };

  const cadastrarEmpresa = async e => {
    e.preventDefault();
    const response = await sendFormData('empresa', empresa, props);
    if (response.data.ok) {
      history.push({
        pathname: `/cadastro-contato-empresa/${response.data.output.idEstipulante}`,
        state: { empresaInfo: empresa },
      });
    } else {
      alertError(
        'Houve um erro ao cadastrar empresa. Por favor, tente novamente.',
      );
    }
  };

  return (
    <Page
      empresa={empresa}
      handleOnChange={handleOnChange}
      handleCepOnChange={handleCepOnChange}
      cadastrarEmpresa={cadastrarEmpresa}
      alertErrorMsg={alertErrorMsg}
      setAlertErrorMsg={setAlertErrorMsg}
      usuario={props.user.user.usuario}
      idAssessoriaDoCorretor={props.user.user.assessoria}
      nivelUsuario={props.user.user.nivel_usuario}
      token={props.user.user.token}
      />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
