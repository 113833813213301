import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page';

function Controller(props) {

  let history = useHistory();
  let { id } = useParams();

  const [ resultProduto, setResultProduto ] = useState({
    id: 0,
    nome: '',
    descricao: '',
    ativo: 0,
    limite_idade: 0
  });

  const [ gruposVenda, setGruposVenda ] = useState([{}]);

  useEffect(
    () => {
      getInfosProduto();
      getGruposVenda();
    }, []
  )

  const getInfosProduto = async _ => {
    const request = await Api.get(`produto/${id}`, { headers: { Authorization: `Bearer ${props.user.user.token}` }})
    console.log('a',request.data.output);
    if(request.data.ok){
      setResultProduto({
        id: request.data.output.id,
        nome: request.data.output.nome,
        descricao: request.data.output.descricao,
        ativo: request.data.output.ativo,
        limite_idade: request.data.output.limite_idade,
        id_grupo_vendas: request.data.output.id_grupo_vendas,
        contrato: request.data.output.contrato
      });
    }
  }

  const getGruposVenda = async _ => {
    let fetchGrupoVendas = await Api.get('/grupovendas', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
    if(fetchGrupoVendas.data.ok) {
        setGruposVenda(fetchGrupoVendas.data.output);
    }
  }

  return (
    <>
      {resultProduto&&
        (<Page
          resultProduto={resultProduto}
          setResultProduto={setResultProduto}
          history={history}
          token={props.user.user.token}
          gruposVenda={gruposVenda}
          />)
      }
    </>
  )
}

const maapStateToProps = store => ({
  user: store.user,
})

export default connect(maapStateToProps)(Controller);
