import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditarUsuario from '../../views/EditarUsuario/EditarUsuario';
import { GoBackButton } from '../../components/Buttons/Buttons';
import { genders, maritalStatus, states } from '../../utils/options';
import { ReactComponent as EditIcon } from '../../assets/img/icon/editar.svg';
import 'yup-phone-lite';
import { validateCPF } from '../../utils/validate';

const useStyles = makeStyles(() => ({
  container: {
    padding: '40px 30px 30px',
    '& > .MuiBox-root': {
      marginBottom: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  'article-header': {
    marginBottom: '1.875em',
  },
  'article-header--title': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gridColumnGap: '1em',
  },
  content: {
    // marginTop: 30,
    padding: 0,
  },
}));

const userLevel = [
  { value: 1, label: 'Administrador' },
  { value: 2, label: 'Assessoria' },
  { value: 3, label: 'Vendedor' },
  { value: 4, label: 'Vendedor Empresarial' },
  { value: 5, label: 'Assessoria Master' },
  { value: 6, label: 'Cobrança' },
];

const accountSchema = yup.object().shape({
  user: yup.object().shape({
    nome: yup
      .string()
      .min(6, 'Nome completo precisa ter ao menos 6 caracteres')
      .required('Nome completo é obrigatório'),
    email: yup
      .string()
      .email('Formato de email inválido')
      .required('Email é obrigatório'),
    nivel: yup
      .mixed()
      .oneOf(
        [...userLevel.map(ul => ul.value)],
        'Nível de usuário é obrigatório',
      )
      .defined(),
  }),
  person: yup.object().shape({
    cpf: yup
      .string()
      .required('CPF é obrigatório')
      .test(
        'test-cpf-valid',
        'CPF incorreto e/ou sequência de digitos informada',
        cpf => validateCPF(cpf),
      ),
    telefone: yup
      .string()
      .phone('BR', 'Telefone incorreto para o padrão BR.')
      .required('Telefone é obrigatório')
      .min(14, 'Telefone incompleto'),
    celular: yup
      .string()
      .matches(/(^\D([1-9][0-9])*\D*\s(9)(\d{4})-(\d{4})$)|(^\D?)/, {
        message: 'Celular inválido. Verifique se inicia com 9 após o DDD',
        excludeEmptyString: true,
      })
      .optional(),
    data_nascimento: yup
      // .date()
      .string()
      .nullable()
      // .min(new Date(1900, 0, 1))
      .required('Informe a data de nascimento'),
    sexo: yup
      .mixed()
      .oneOf([...genders.map(g => g.value)], 'Informe o gênero')
      .defined(),
    estado_civil: yup
      .mixed()
      .oneOf([...maritalStatus.map(m => m.value)], 'Informe o estado civil')
      .defined(),
    numero_endereco: yup
      .number()
      .typeError('Informe o número')
      .optional()
      .integer(),
    complemento_endereco: yup.string().notRequired(),
  }),
  address: yup.object().shape({
    cep: yup
      .string()
      .required('CEP é obrigatório'),
      // .length(8, 'CEP deve conter 8 dígitos'),
    logradouro: yup.string().required('Logradouro é obrigatório'),
    bairro: yup.string().required('Bairro é obrigatório'),
    cidade: yup.string().required('Cidade é obrigatório'),
    uf: yup
      .mixed()
      .oneOf([...states.map(s => s.value)], 'Estado é obrigatório')
      .defined(),
  }),
  password: yup.object().shape({
    novaSenha: yup.string().when('isChangingPassword', {
      is: isChangingPassword => isChangingPassword,
      then: yup
        .string()
        .required('Informe a senha')
        .min(4, 'Senha muito curta -  precisa ter ao menos 4 caracteres')
        .max(20, 'Senha no máximo com 20 caracteres'),
    }),
    confirmaNovaSenha: yup.string().when('novaSenha', {
      is: novaSenha => novaSenha && novaSenha.length > 0,
      then: yup
        .string()
        .required('Favor confirmar a senha')
        .oneOf([yup.ref('novaSenha'), null], 'Senhas não são as mesmas'),
    }),
  }),
});

const Page = props => {
  const { history, account, salvarUsuario, isEditing, setIsEditing } = props;
  const classes = useStyles();
  const firstName = account.user.nome.split(' ')[0];
  const methods = useForm({
    resolver: yupResolver(accountSchema),
    defaultValues: account,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { handleSubmit } = methods;

  const onSubmitHandler = data => {
    console.log('🚀 ~ file: Page.jsx ~ line 58 ~ onSubmitHandler ~~~ data', data);
    salvarUsuario(data);
  };

  return (
    <Container
      component="article"
      role="main"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <header className={classes['article-header']}>
        <Box className={classes['article-header--title']} itemProp="title">
          <GoBackButton
            onClick={() => history.push('/usuarios')}
            style={{ marginRight: '1em' }}
          />
          {isEditing ? (
            <>
              <EditIcon fill="#525151" />
              <Typography variant="h1">Editar - {firstName}</Typography>
            </>
          ) : (
            <>
              <InfoIcon />
              <Typography variant="h1">
                Informa&ccedil;&otilde;es - {firstName}
              </Typography>
            </>
          )}
        </Box>
      </header>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <EditarUsuario
            {...props}
            genders={genders}
            maritalStatus={maritalStatus}
            states={states}
            userLevel={userLevel}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </form>
      </FormProvider>
    </Container>
  );
};

export default Page;
