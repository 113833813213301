import React, { useState } from 'react';
import { Card, Col, Button, Table, Spinner, Row } from 'reactstrap';
import {
  FaTrash,
  FaPlus,
  FaExclamationCircle,
  FaAddressBook,
} from 'react-icons/fa';
import {
  OverflowContainer,
  MsgErrorDataTable,
  SectionFormHeader,
} from '../styles';

function ContatosConvenio(props) {
  const { deletarContato, infosContatos, openModalCadastroContato, loading } =
    props;

  const [loadingDeleteContato, setLoadingDeleteContato] = useState(false);

  const handleDeletarContato = async id => {
    if (window.confirm(`Deseja mesmo excluir a apólice ${id}?`)) {
      setLoadingDeleteContato(true);
      await deletarContato(id);
      setLoadingDeleteContato(false);
    }
  };

  return (
    <Card>
      <Row className="text-left mt-2 mb-3">
        <Col>
          <SectionFormHeader>
            <FaAddressBook className="mr-2" />
            Contato dos Responsáveis
          </SectionFormHeader>
        </Col>
      </Row>
      <OverflowContainer>
        {loading ? (
          <Spinner />
        ) : infosContatos.length ? (
          <Table>
            <thead>
              <tr>
                <th>Nome Responsável</th>
                <th>Telefone</th>
                <th>Celular</th>
                <th>E-mail</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {infosContatos.map((elContato, i) => {
                return (
                  <tr key={i}>
                    <td>{elContato.nome_responsavel}</td>
                    <td>{elContato.telefone}</td>
                    <td>{elContato.celular}</td>
                    <td>{elContato.email}</td>
                    <td>
                      <Button
                        onClick={() => handleDeletarContato(elContato.id)}
                        size="sm"
                        color="danger"
                        outline
                        disabled={loadingDeleteContato}
                      >
                        {loadingDeleteContato ? (
                          <Spinner size="sm" />
                        ) : (
                          <FaTrash />
                        )}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <MsgErrorDataTable>
            <FaExclamationCircle />
            &nbsp;&nbsp;{infosContatos.message}
          </MsgErrorDataTable>
        )}
      </OverflowContainer>
      <Row>
        <Col className="my-3 text-right">
          <Button
            color="success"
            outline
            size="sm"
            onClick={() => openModalCadastroContato()}
          >
            <FaPlus />
            &nbsp;&nbsp;Cadastrar Contato
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default ContatosConvenio;
