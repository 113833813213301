import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Input, Spinner, Button, InputGroup, InputGroupAddon, InputGroupText, Table, ModalFooter, ModalBody, ModalHeader, Modal, Form } from 'reactstrap';
import {
  FaAddressBook,
  FaExclamationTriangle,
  FaFile,
  FaMailBulk,
  FaMobileAlt,
  FaPhoneSquareAlt,
  FaRegSave,
  FaSearch,
  FaShoppingCart,
  FaUser,
} from 'react-icons/fa';
import { BodyFormComponent, CardComponent, LabelForm } from '../styles';
import Loading from '../../components/Loading/Loading';
import { alertError } from '../../components/Alerts/Alerts';
import api from '../../services/api';
import SubmitBtn from '../../components/Buttons/SubmitBtn';

function DocumentosClinica(props) {
  const { clinica } = props;

  const setDate = fullDate => {
    let dateArr = fullDate.split(" ");
    let newDate = dateArr[0].split("-");
    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
  }

  const setTime = fullDate => {
      let timeArr = fullDate.split(" ");
      return timeArr[1];
  }

  const [documentInput, setDocumentInput] = useState({
    id_clinica: 0,
    nome: '',
    documento: [],
  });

  const [loadingSalvarDocumento, setLoadingSalvarDocumento] = useState(false);
  const [cadastrarDocumento, setCadastrarDocumento] = useState(false);

  const [modalCadastro, setModalCadastro] = useState(false);
  const toggleModalCadastro = () => setModalCadastro(!modalCadastro);

  const salvarDocumento = async (e) => {
    e.preventDefault();

    setLoadingSalvarDocumento(true);
    const dataForm = new FormData();

    Object.keys(documentInput).map(el => {
      dataForm.append(el, documentInput[el]);
    });

    const result = await api.post(`clinica/${clinica.data.id}/enviarDocumento`, dataForm, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });

    if (result.data.ok) {
      window.location.reload();
    } else {
      alertError(result.data.message);
    }
    setLoadingSalvarDocumento(false);
  };

  const [documentos, setDocumentos] = useState();

  const getDocumentosClinica = async () => {
    const requestArchives = await api.get(`clinica/documentosClinica/${clinica.data.id}`, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    setDocumentos(requestArchives.data);
  };

  useEffect(() => {
    if (clinica.data.id > 0) {
    getDocumentosClinica();
  }
  }, [clinica]);

  return (
    <>
    <Card className="mt-4">
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaAddressBook className="mr-2" />
              Documentos
            </h2>
          </Col>
        </Row>
        <hr />
        {clinica.loading ? (
          <Loading />
        ) : (
          <>
          {
          documentos && documentos.output.length > 0
          ?
          <Row style={{'marginTop':'0px'}}>
            <div style={{'width':'100%'}}>
              <Button
                outline
                color="primary"
                style={
                  {
                    'fontSize': '12px',
                    'padding': '2px 6px',
                    'float': 'right',
                    'marginRight': '15px',
                    'marginBottom': '15px'
                  }
                }
                onClick={e => toggleModalCadastro()}>
                Adicionar
              </Button>
          </div>
          <Table  style={{'marginRight':'15px','marginLeft':'15px' }}>
              <thead className="text-left">
                  <tr>
                      <th>Data Inclusão</th>
                      <th>Hora Inclusão</th>
                      <th>Documento</th>
                      <th>Link Arq.</th>
                  </tr>
              </thead>
              <tbody className="text-left">
                  {
                      documentos.output.map((el, index) => (
                          <tr key={index}>
                              <td>{setDate(el.data_inclusao)}</td>
                              <td>{setTime(el.data_inclusao)}</td>
                              <td>{el.descricao}</td>
                              <td className="text-center">
                                  <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_API_ERP}/files/docs/${el.link_arquivo}`}>
                                      <FaSearch/>
                                  </a>
                              </td>
                          </tr>
                      ))
                  }
              </tbody>
          </Table>
          </Row>
          :
          <div className="mb-4">
            <span style={{color: "red"}}><FaExclamationTriangle/>
                  Nenhum documento encontrado.
            </span>
            <p>
            <Button
              color="link"
              style={{'fontSize': '11px', 'padding': '0px'}}
              onClick={e => toggleModalCadastro()}>
              Clique aqui para adicionar
            </Button>
            </p>
          </div>
          }

          </>
        )}
      </CardComponent>
    </Card>
    <Modal isOpen={modalCadastro} toggle={toggleModalCadastro} size="md">
      <Form onSubmit={salvarDocumento}>
        <ModalHeader toggle={toggleModalCadastro}>
          <Row>
            <Col md={12}>
              <FaShoppingCart />
              &nbsp;Cadastrar Documento
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <BodyFormComponent>
          <Row className="text-left mt-2">
            <Col lg={6}>
              <LabelForm>Nome *</LabelForm>
              <InputGroup>
                <Input
                  name="nome"
                  placeholder="Informe o nome do documento"
                  required
                  value={documentInput.nome}
                  onChange={e =>
                    setDocumentInput({
                      ...documentInput,
                      nome: e.target.value,
                    })
                  }
                  />
              </InputGroup>
            </Col>
            <Col lg={6} className="text-left">
              <LabelForm>
                Documento *
              </LabelForm>
              <InputGroup>
                <Input
                  type="file"
                  name="documento"
                  onChange={e =>
                    setDocumentInput({
                      ...documentInput,
                      id_clinica: clinica.data.id,
                      documento: e.target.files[0],
                    })
                  }
                />
              </InputGroup>
            </Col>
          </Row>
          </BodyFormComponent>
        </ModalBody>
        <ModalFooter>
          <Row className="text-right">
            <Col className="d-flex align-items-center">
              <Button color="danger" onClick={toggleModalCadastro}>
                Fechar
              </Button>
              &nbsp;
              <SubmitBtn
                label="Salvar"
                labelOnClick="aguardando"
                loading={loadingSalvarDocumento}
                typeSubmit="submit"
                color="#0A7A73"
              />
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
    </>
  );
}

export default DocumentosClinica;
