import React from 'react';

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';

import { Cancel, DeleteForever } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
    padding: 20,
  },
  content: {
    padding: '10px 20px 30px',
  },
  actions: {
    padding: 20,
  },
  iconCancel: {
    position: 'absolute',
    top: 10,
    right: 8,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  buttonDelete: {
    padding: '13px 16px',
    height: 40,
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

function DialogDelete(props) {
  const {
    itemId,
    title,
    description,
    onClose,
    open,
    confirmDelete,
    setIdMaterialDelete,
    ...other
  } = props;

  const classes = useStyles();

  const handleCancel = () => {
    onClose();
  };

  const handleOnClickConfirmDelete = () => {
    confirmDelete(itemId);
    setIdMaterialDelete(0);
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-delete-dialog"
      open={open}
      {...other}
      scroll="body"
    >
      <DialogTitle id="confirmation-delete-dialog" className={classes.title}>
        <Typography variant="h2">{title}</Typography>
        <Tooltip title="Fechar">
          <IconButton className={classes.iconCancel} onClick={handleCancel}>
            <Cancel />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h4" component="p">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleCancel} color="default" variant="text">
          <Typography variant="h4" className={classes.buttonCancel}>
            Cancelar
          </Typography>
        </Button>
        <Button
          onClick={handleOnClickConfirmDelete}
          startIcon={<DeleteForever />}
          variant="contained"
          className={classes.buttonDelete}
        >
          <Typography variant="button">Excluir material de apoio</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDelete;
