import React from 'react';
import { Grid } from '@material-ui/core';
import DataGridLayout from '../../components/TablesGrid/DataGrid';

import {
  DefaultButton,
  RegisterButton,
} from '../../components/Buttons/Buttons';

export default function ListaLeads(props) {
  const { leads, handleChangeStatus, statusList } = props;

  const rows = leads.output.filter(
    lead => lead.acao_conclusao === statusList.value,
  );

  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',
      headerAlign: 'center',
      flex: 0.4,
      minWidth: 160,
      sortable: false,
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      headerAlign: 'center',
      // flex: 0.4,
      minWidth: 130,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      flex: 0.2,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'created',
      headerName: 'Criado em',
      headerAlign: 'center',
      // flex: 0.4,
      minWidth: 160,
      sortable: false,
    },
    {
      field: 'nome_fantasia',
      headerName: 'Distribuidor',
      headerAlign: 'center',
      flex: 0.2,
      minWidth: 160,
      sortable: false,
    },
    {
      field: '',
      // flex: 0.2,
      minWidth: 220,
      // maxWidth: 220,
      sortable: false,
      renderCell: params => (
        <Grid container spacing={1} wrap="nowrap">
          <Grid item hidden={statusList.name === 'encerrado'}>
            {addLead(
              params.row.id,
              params.row.nome,
              params.row.telefone,
              params.row.email,
              params.row.id_produto,
              params.row.id_assessoria,
              params.row.id_corretor_representante,
            )}
          </Grid>
          <Grid item>{changeStatus(statusList, params.row)}</Grid>
        </Grid>
      ),
    },
  ];

  const addLead = (
    id,
    nome,
    telefone,
    email,
    idProduto,
    idAssessoria,
    idCorretorRepresentante,
  ) => {
    let url = '';

    if (nome) {
      url += `&nome=${encodeURI(nome)}`;
    }

    if (email) {
      url += `&email=${encodeURI(email)}`;
    }

    if (telefone) {
      url += `&telefone=${encodeURI(telefone)}`;
    }

    if (idProduto) {
      url += `&idProduto=${encodeURI(idProduto)}`;
    }

    if (idAssessoria) {
      url += `&idAssessoria=${encodeURI(idAssessoria)}`;
    }

    if (idCorretorRepresentante) {
      url += `&idCorretorRepresentante=${encodeURI(idCorretorRepresentante)}`;
    }

    return (
      <RegisterButton onClick={() => redirecionaParaTelaDeCadastro(url)}>
        Cadastrar
      </RegisterButton>
    );
  };

  const changeStatus = (status, leadSelected) => {
    return (
      <DefaultButton
        color="secondary"
        onClick={() => handleChangeStatus(status, leadSelected)}
      >
        {status.name === 'pendente' ? 'Encerrar' : 'Retomar'}
      </DefaultButton>
    );
  };

  const redirecionaParaTelaDeCadastro = async url => {
    window.location.href = `/cadastrar-cliente-individual?${url}`;
  };

  return (
    <DataGridLayout
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      hasToolbar
    />
  );
}
