import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Badge,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  FaHeartbeat,
  FaCreditCard,
  FaCalendarAlt,
  FaExclamationTriangle,
} from 'react-icons/fa';
import Switch from '@material-ui/core/Switch';
import {
  SectionFormHeader,
  LabelForm,
  PrependInputLabel,
  MsgInputError,
} from '../../../views/styles';
import Api from '../../../services/api';
import { verificaCodigoDesconto, formatDate } from '../../../services/service';

function InfoPagamento(props) {
  const {
    clientPayment,
    setClientPayment,
    setDisabled,
    disabled,
    token,
    setProducts,
    products,
    edit,
    setProductSelected,
    clientInput,
  } = props;

  useEffect(() => {
    // if (clientInput.clinica > 0) {
    //   getProduct(clientInput.clinica);
    // }
  }, [clientInput.clinica]);

  const handleClientPayment = async event => {
    const { name, value } = event.target;
    setClientPayment({ ...clientPayment, [name]: value });
  };

  // const getProduct = async idClinica => {
  //   const fetchProducts = await Api.get(`clinica/produtos/${idClinica}`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });

  //   let productsArr = [];
  //   for (const i in fetchProducts.data.output) {
  //     if (fetchProducts.data.output[i].plano_empresarial == 0) {
  //       const tmpObj = {
  //         name: fetchProducts.data.output[i].nome,
  //         id: fetchProducts.data.output[i].id,
  //         price: fetchProducts.data.output[i].preco
  //       };

  //       productsArr = [...productsArr, tmpObj];
  //     }
  //   }

  //   setClientPayment({
  //     ...clientPayment,
  //     id_produto: productsArr[productsArr.length - 1].id,
  //   });
  //   setProducts(productsArr);
  //   getPrecoProduto(productsArr[productsArr.length - 1].id, productsArr);

  //   const queryParams = new URLSearchParams(window.location.search);
  //   const idProduto = queryParams.get('idProduto');

  //   if (idProduto && idProduto > 0) {
  //     setClientPayment({ ...clientPayment, id_produto: idProduto });
  //   }
  // };

  // const getPrecoProduto = (idPlan, inicialProductArr) => {
  //   let productSelected = [];

  //   if (inicialProductArr) {
  //     productSelected = inicialProductArr.find(el => el.id === idPlan);
  //   } else {
  //     productSelected = products.find(el => el.id == idPlan);
  //   }
  //   setProductSelected(productSelected);
  // };

  return (
    <>
      <hr className="mt-4" />
      {!edit && (
        <>
          <Row className="section-header">
            <Col>
              <SectionFormHeader>Informações de Pagamento</SectionFormHeader>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={4}>
              <LabelForm>Nome do Produto *</LabelForm>
                <InputGroup>
                  <Input
                    type="text"
                    required
                    placeholder="Informe o nome do produto"
                    name="nome_produto"
                    value={clientPayment.nome_produto}
                    onChange={e => handleClientPayment(e)}
                  />
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Forma de Pagamento *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCreditCard />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  required
                  name="forma_pagamento"
                  value={clientPayment.forma_pagamento}
                  onChange={e => handleClientPayment(e)}
                >
                  <option value="">Selecione</option>
                  <option value="carne-impresso">Carnê</option>
                  {/* <option value="cartao-credito">Cartão de Crédito</option> */}
                </Input>
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Primeiro Pagamento *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCalendarAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-control"
                  required
                  type="date"
                  placeholder="Data Primeiro Pagamento"
                  name="primeiro_pagamento"
                  value={clientPayment.primeiro_pagamento}
                  onChange={e => handleClientPayment(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <LabelForm>Modalidade *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCreditCard />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  required
                  name="modalidade"
                  value={clientPayment.modalidade}
                  onChange={e => handleClientPayment(e)}
                >
                  <option value="">Selecione</option>
                  <option value="viver">Viver</option>
                  <option value="convenio">Convênio</option>
                  <option value="particular">Particular</option>
                </Input>
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Valor *</LabelForm>
              <InputGroup>
                <Input
                  type="number"
                  required
                  placeholder="Informe o valor"
                  name="valor_venda"
                  value={clientPayment.valor_venda}
                  onChange={e => handleClientPayment(e)}
                />
              </InputGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default InfoPagamento;
