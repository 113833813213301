import React, { useState } from 'react';
import { BodyFormComponent, LabelForm, SectionFormHeader } from '../styles';
import { FaArrowLeft, FaBuilding, FaDownload, FaFilePdf } from 'react-icons/fa';
import { Button, Card, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner } from 'reactstrap';
import Api from '../../services/api';
import { Switch } from '@material-ui/core';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { alertSuccess, alertError, alertWarning } from '../../components/Alerts/Alerts';
import InputMask from 'react-input-mask';


function EditarBeneficio(props) {

  const { history, beneficio, setBeneficio, token } = props;
  const [ loading, setLoading ] = useState(false);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleOnChange = event => {
    const { name, value } = event.target;
    setBeneficio({ ...beneficio, [name]: value });
  }

  const handleSubmit = async e => {

    e.preventDefault();
    setLoading(true);

    const response = await Api.post('terceiro/editar', beneficio, { headers: { Authorization: `Bearer ${token}` }} );

    if(response.data.ok){
      setLoading(false);
      alertSuccess('Atualizado com sucesso');
    }
  }


  return (
     <Card style={{ marginTop: "30px" }}>
      <Form onSubmit={handleSubmit}>
      <BodyFormComponent>
        <Row className="mt-2 mb-4">
        <Col lg={12}>
            <LabelForm>Nome do Benefício</LabelForm>
            <Input type="text" name="nome" value={beneficio.nome} onChange={e => handleOnChange(e) }/>
          </Col>
          <Col className="mt-2" lg={12}>
            <LabelForm>Descrição</LabelForm>
            <Input type="textarea" name="descricao" value={beneficio.descricao} onChange={e => handleOnChange(e)} />
          </Col>
          <Col className="mt-2" lg={6}>
            <LabelForm>Custo</LabelForm>
            <Input type="number" name="custo" value={beneficio.custo} onChange={e => handleOnChange(e)} />
          </Col>
          <Col className="mt-2"  lg={6}>
            <LabelForm>Contato para Acionamento</LabelForm>
            <InputMask mask="(99) 99999-9999" className="form-control" name="contatoAcionamento" value={beneficio.contatoAcionamento} onChange={e => handleOnChange(e)}/>
          </Col>
        </Row>
        <Row className="mt-4 text-right">
          <Col>
            <Button color="danger" onClick={() => history.goBack()}><FaArrowLeft />&nbsp;&nbsp;Voltar</Button>&nbsp;
            <SubmitBtn
              label="Salvar Alterações"
              labelOnClick="Aguarde..."
              loading={loading}
              typeSubmit="submit"
              metodo={handleSubmit}
              color="#0A7A73"
            />
          </Col>
        </Row>
      </BodyFormComponent>
      </Form>
    </Card>
  )
}

export default EditarBeneficio;
