import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alertError } from '../../components/Alerts/Alerts';
import api from '../../services/api';
import { checkSafePassword, padLeadingZeros } from '../../services/service';
import Page from './Page';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

function Controller(props) {
  const { user } = props;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const periodoParams = urlParams.get('periodo');
  const pesquisaParams = urlParams.get('pesquisa');
  const statusPagamentoParams = urlParams.get('status');
  const distribuidorMasterParams = urlParams.get('distribuidorMaster');
  const distribuidorAfiliadoParams = urlParams.get('distribuidorAfiliado');

  const [assessorias, setAssessorias] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [comissoes, setComissoes] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [periodo, setPeriodo] = useState(
    periodoParams ?? `${currentYear}-${padLeadingZeros(currentMonth, 2)}`,
  );

  const [pesquisa, setPesquisa] = useState(pesquisaParams ?? pesquisaParams);

  const [statusPagamento, setStatusPagamento] = useState(
    statusPagamentoParams ?? statusPagamentoParams,
  );

  const [distribuidorMaster, setDistribuidorMaster] = useState(
    distribuidorMasterParams ?? distribuidorMasterParams,
  );

  const [distribuidorAfiliado, setDistribuidorAfiliado] = useState(
    distribuidorAfiliadoParams ?? distribuidorAfiliadoParams,
  );

  const [nivelPermissao, setNivelPermissao] = useState('afiliado');

  const [distribuidorMasterSelect, setDistribuidorMasterSelect] = useState([]);
  const [distribuidorAfiliadoSelect, setDistribuidorAfiliadoSelect] = useState(
    [],
  );

  const [fireGetComissoes, setFireGetComissoes] = useState(false);

	const [ modalBaixar, setModalBaixar ] = useState(false);
	const [ linkBaixar, setLinkBaixar ] = useState("");

  const getAssessorias = async () => {
    try {
      const response = await api.get('/assessorias/', {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if (response.data.ok) {
        setAssessorias({
          data: response.data.output,
          loading: false,
        });

        let assessoriasList = response.data.output;
        assessoriasList = assessoriasList.map(({ id, razao_social, tipo }) => ({
          id,
          razao_social,
          tipo,
        }));
        setDistribuidorMasterSelect(assessoriasList);
        setDistribuidorAfiliadoSelect(assessoriasList);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAssessorias({
        data: [],
        loading: false,
      });
      console.log(error.message);
    }
  };

  const getAssessoriasMaster = async () => {
    try {
      const response = await api.get(
        `assessoria/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );

      if (response.data.ok) {
        const responseAssessoriasMaster = await api.get(
          `assessorias-master/${response.data.output.id}`,
          {
            headers: { Authorization: `Bearer ${user.user.token}` },
          },
        );

        if (responseAssessoriasMaster.data.ok) {
          setAssessorias({
            data: responseAssessoriasMaster.data.output,
            loading: false,
          });

          let assessoriasList = responseAssessoriasMaster.data.output;
          assessoriasList = assessoriasList.map(
            ({ id, razao_social, tipo }) => ({
              id,
              razao_social,
              tipo,
            }),
          );
          setDistribuidorAfiliadoSelect(assessoriasList);
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAssessorias({
        data: [],
        loading: false,
      });
      console.log('Erro ao buscar distribuidor: ', error.message);
    }
  };

  const getAssessoriaAfiliado = async () => {
    try {
      const response = await api.get(
        `assessoria/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );
      if (response.data.ok) {
        setAssessorias({
          data: [response.data.output],
          loading: false,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAssessorias({
        data: [],
        loading: false,
      });
      console.log('Erro ao buscar distribuidor: ', error.message);
    }
  };

  const getComissoes = async distribuidorId => {
    try {
      let filter = `periodo=${periodo}`;

      if (pesquisa) {
        filter += `&pesquisa=${pesquisa}`;
      }
      if (statusPagamento && statusPagamento != 'Todos') {
        filter += `&status=${statusPagamento}`;
      }

      if (distribuidorMaster && distribuidorMaster != 'Todos') {
        filter += `&distribuidor-master=${distribuidorMaster}`;
      }

      if (distribuidorAfiliado && distribuidorAfiliado != 'Todos') {
        filter += `&distribuidor-afiliado=${distribuidorAfiliado}`;
      }

      const response = await api.get(
        `/comissao-split/relatorio/${distribuidorId}?${filter}`,
        { headers: { Authorization: `Bearer ${user.user.token}` } },
      );
      if (response.data.ok) {
        return response.data.output;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const exportar = async () => {

    let filter = `periodo=${periodo}`;

    if (pesquisa) {
      filter += `&pesquisa=${pesquisa}`;
    }
    if (statusPagamento && statusPagamento != 'Todos') {
      filter += `&status=${statusPagamento}`;
    }

    if (distribuidorMaster && distribuidorMaster != 'Todos') {
      filter += `&distribuidor-master=${distribuidorMaster}`;
    }

    if (distribuidorAfiliado && distribuidorAfiliado != 'Todos') {
      filter += `&distribuidor-afiliado=${distribuidorAfiliado}`;
    }

    if (user.user.nivel_usuario == 1) {
      const response = await api.get(`/comissao-split/exportar-todos?${filter}`, {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if(response.data.ok){
        setLinkBaixar(response.data.output.arquivo)
        setModalBaixar(true)

      }else{
        alertError(response.data.message)
      }
    } else {
      const responseAssessoriaUsuario = await api.get(
        `assessoria/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );

      if(responseAssessoriaUsuario.data.ok){
        const distribuidorId = responseAssessoriaUsuario.data.output.id;
        const response = await api.get(`/comissao-split/exportar/${distribuidorId}?${filter}`, {
          headers: { Authorization: `Bearer ${user.user.token}` },
        });

        if(response.data.ok){
          setLinkBaixar(response.data.output.arquivo)
          setModalBaixar(true)

        }else{
          alertError(response.data.message)
        }
      }
    }
  }

  useEffect(() => {
    checkSafePassword(user);
    if (user.user.nivel_usuario == 1) {
      getAssessorias();
      setNivelPermissao('admin');
    } else if (user.user.nivel_usuario == 2) {
      getAssessoriaAfiliado();
      setNivelPermissao('afiliado');
    } else if (user.user.nivel_usuario == 5) {
      getAssessoriasMaster();
      setNivelPermissao('master');
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    setComissoes({ data: [], loading: true });

    const getData = async () => {
      if (assessorias.data.length) {
        let comissoesData = await Promise.all(
          assessorias.data.map(async assessoria => {
            const comissao = await getComissoes(assessoria.id);
            return comissao;
          }),
        );

        const ar = [];

        for (let i = 0; i < comissoesData.length; i++) {
          for (let j = 0; j < comissoesData[i].length; j++) {
            ar.push(comissoesData[i][j]);
          }
        }

        comissoesData = ar;

        const comissoesDataFiltered = comissoesData.filter(
          comissao => comissao != 'erro',
        );
        setComissoes({ data: comissoesDataFiltered, loading: false });
      }
    };

    getData();
  }, [fireGetComissoes, assessorias]);

  return (
    <Page
      comissoes={comissoes}
      setPesquisa={setPesquisa}
      pesquisa={pesquisa}
      setPeriodo={setPeriodo}
      periodo={periodo}
      setStatusPagamento={setStatusPagamento}
      statusPagamento={statusPagamento}
      setDistribuidorMaster={setDistribuidorMaster}
      distribuidorMaster={distribuidorMaster}
      setDistribuidorAfiliado={setDistribuidorAfiliado}
      distribuidorAfiliado={distribuidorAfiliado}
      setFireGetComissoes={setFireGetComissoes}
      fireGetComissoes={fireGetComissoes}
      nivelPermissao={nivelPermissao}
      distribuidorMasterSelect={distribuidorMasterSelect}
      distribuidorAfiliadoSelect={distribuidorAfiliadoSelect}
      exportar={exportar}
      linkBaixar={linkBaixar}
      setModalBaixar={setModalBaixar}
      modalBaixar={modalBaixar}
    />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
