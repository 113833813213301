import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
// import { Card, Col, Row, Button, Spinner } from 'reactstrap';
// import {
//   FaWrench,
//   FaMapMarkedAlt,
//   FaMapMarkerAlt,
//   FaRoad,
//   FaInfo,
//   FaPercentage,
//   FaUserTag,
//   FaDollarSign,
//   FaMoneyBillAlt,
// } from 'react-icons/fa';

import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PhoneIcon from '@material-ui/icons/Phone';
import { makeStyles } from '@material-ui/core/styles';
// import theme from '../../theme';
import ContatosConvenio from './ContatosConvenio';
// import { SectionFormHeader } from '../styles';

import {
  DeleteButtonSmall,
  EditButton,
  RegisterButton,
} from '../../components/Buttons/Buttons';
import { ReactComponent as BenefitsIcon } from '../../assets/img/icon/beneficios.svg';

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.secondary.medium,
    borderRadius: '4px 4px 0 0',
    padding: '20px 25px',
  },
  header: {
    alignItems: 'center',
  },
  titleHeader: {
    marginLeft: 20,
  },
  buttonEdit: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiButton-label': {
      color: theme.palette.grey[400],
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fill: theme.palette.grey[400],
    },
    '&:hover': {
      backgroundColor: '#D7E4E4',
      // color: theme.palette.background.paper,
    },
    '&:hover .MuiButton-label': {
      color: theme.palette.grey[400],
      // color: theme.palette.grey[400],
    },
    '&:hover .MuiButton-iconSizeMedium > *:first-child': {
      // fill: theme.palette.background.paper,
      fill: theme.palette.grey[400],
    },
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 20,
  },
}));

function InformacoesConvenio(props) {
  const history = useHistory();
  const {
    infosConvenio,
    loading,
    deletarContato,
    // InfosContato,
    openModalCadastroContato,
    // idEstipulante,
    // loadingContato,
    infosContatos,
    // modalContatos,
    // CadastrarContatoEmpresa,
    // handleContatoOnChange,
    // contato,
    // cadastrarContatoForm,
  } = props;
  const {
    nome,
    id,
    cnpj,
    logradouro,
    bairro,
    cidade,
    uf,
    numero_endereco,
    desconto_fatura_porcentagem,
    primeiro_dep_gratis,
    dia_proximos_pagamentos,
  } = infosConvenio;

  const classes = useStyles();

  const [loadingDeleteContato, setLoadingDeleteContato] = useState(false);

  const handleDeletarContato = async (id, nomeResponsavel) => {
    if (
      window.confirm(`Deseja mesmo excluir o contato "${nomeResponsavel}"?`)
    ) {
      setLoadingDeleteContato(true);
      await deletarContato(id);
      setLoadingDeleteContato(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={8}>
              <Grid component="header" container>
                <Grid item xs="auto" container className={classes.header}>
                  <ImportContactsIcon style={{ color: '#FAFAFA' }} />
                  <Typography
                    variant="h2"
                    style={{ color: '#FAFAFA', marginLeft: '10px' }}
                  >
                    {nome}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    variant="body1"
                    align="left"
                    style={{ color: '#FAFAFA' }}
                  >
                    CNPJ: {cnpj}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <EditButton
                className={classes.buttonEdit}
                onClick={() => history.push(`/editar-convenio/${id}`)}
              />
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <Grid item xs={12} style={{ padding: 25 }}>
            <Spinner />
          </Grid>
        ) : (
          <Paper className={classes.paper}>
            <Grid container spacing={4}>
              <Grid item xs={12} style={{ margin: '0 5px' }}>
                <Grid container align="left" spacing={3}>
                  <Grid item xs="auto">
                    <Typography variant="h3">
                      <PinDropIcon
                        style={{ marginRight: '10px', fontSize: '16px' }}
                      />
                      Endereço
                    </Typography>
                  </Grid>
                  <Grid item xs="auto" container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <Typography variant="body1">
                        <strong>Rua: </strong> {logradouro}, n<sup>o </sup>
                        {numero_endereco}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="body1">
                        <strong>Bairro: </strong>
                        {bairro}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography variant="body1">
                        <strong>Cidade: </strong>
                        {cidade}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="body1">
                        <strong>Estado: </strong>
                        {uf}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ margin: '0 5px' }}>
                <Grid container align="left" spacing={3}>
                  <Grid item xs="auto">
                    <Typography variant="h3">
                      <BenefitsIcon
                        style={{ marginRight: '10px', width: '16px' }}
                      />
                      Informações dos benefícios
                    </Typography>
                  </Grid>
                  <Grid item xs="auto" container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <strong>Primeiro dependente gratuito: </strong>
                        {primeiro_dep_gratis == '1' ? 'Sim' : 'Não'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography variant="body1">
                        <strong>Desconto na fatura: </strong>
                        {desconto_fatura_porcentagem}%
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography variant="body1">
                        <strong>Vencimento: </strong>
                        dia {dia_proximos_pagamentos}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ margin: '0 5px' }}>
                <Grid container align="left" spacing={3}>
                  <Grid item xs="auto">
                    <Typography variant="h3">
                      <PhoneIcon
                        style={{ marginRight: '10px', fontSize: '16px' }}
                      />
                      Contato dos responsáveis
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    container
                    spacing={2}
                    // overflow
                    // style={{ height: 160 }}
                  >
                    {infosContatos.map(elContato => {
                      return (
                        <Grid
                          key={elContato.id}
                          item
                          xs={12}
                          // spacing={2}
                        >
                          <Grid
                            container
                            spacing={1}
                            className={classes.contatos}
                          >
                            <Grid item xs={11}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body1">
                                    <strong>Nome: </strong>
                                    {elContato.nome_responsavel}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body1">
                                    <strong>Cargo: </strong>
                                    {elContato.cargo}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body1">
                                    <strong>Telefone: </strong>
                                    {elContato.telefone}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="body1">
                                    <strong>Email: </strong>
                                    {elContato.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={1} container justifyContent="center">
                              <DeleteButtonSmall
                                onClick={() =>
                                  handleDeletarContato(
                                    elContato.id,
                                    elContato.nome_responsavel,
                                  )
                                }
                                loading={loadingDeleteContato}
                              />
                            </Grid>
                            {/* <Grid item xs={12}>
                              <Divider
                                variant="middle"
                                style={{ marginTop: 16 }}
                              />
                            </Grid> */}
                          </Grid>
                          <Divider style={{ marginTop: 16 }} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: -8 }}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <RegisterButton onClick={() => openModalCadastroContato()}>
                      Cadastrar novo contato
                    </RegisterButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/* <Card>
        <Row className="text-left mt-2">
          <Col>
            <SectionFormHeader>
              <FaInfo className="mr-2" />
              Informações
            </SectionFormHeader>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <Row>
            <Col className="w-100 d-flex justify-content-center mb-3">
              <Spinner />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="justify-content-between m-0 p-0">
              <Col xs="auto">
                <Button
                  color="success"
                  outline
                  size="sm"
                  className="mb-2"
                  onClick={() => history.push(`/editar-convenio/${id}`)}
                >
                  <FaWrench />
                  &nbsp;Editar
                </Button>
              </Col>
            </Row>
            <Row className="text-left mt-2">
              <Col xs={12}>
                <h6>
                  {' '}
                  <strong>CNPJ:</strong> {cnpj}
                </h6>
              </Col>
              <Col md={12} className="mt-2">
                <p>
                  <FaRoad />
                  &nbsp;&nbsp;<b>Rua:</b> {logradouro}, {numero_endereco}
                </p>
              </Col>
              <Col md={12}>
                <p>
                  <FaMapMarkerAlt />
                  &nbsp;&nbsp;<b>Bairro:</b> {bairro}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <FaMapMarkedAlt />
                  &nbsp;&nbsp;<b>Cidade:</b> {cidade} - {uf}
                </p>
              </Col>
            </Row>
            <hr />
            <Row className="text-left">
              <Col>
                <SectionFormHeader>
                  <FaDollarSign className="mr-2" />
                  Benefícios
                </SectionFormHeader>
              </Col>
            </Row>
            <hr />
            <Row className="text-left">
              <Col>
                <p>
                  <FaPercentage className="mr-2" />
                  <strong>Desconto :</strong> {desconto_fatura_porcentagem} %
                </p>
                <p>
                  <FaUserTag className="mr-2" />
                  <strong>Primeiro Dependente Gratuito? </strong>{' '}
                  {primeiro_dep_gratis == '1' ? 'Sim' : 'Não'}
                </p>
                <p>
                  <FaMoneyBillAlt className="mr-2" />
                  <strong>Dia dos próximos pagamentos: </strong>{' '}
                  {dia_proximos_pagamentos}
                </p>
              </Col>
            </Row>
            <hr />
            <Row className="text-left">
            <Col>
              <p>
                <strong>Forma de liberação do débito: </strong>
              </p>
            </Col>
            {formaLiberacaoDebito}
          </Row>
          </>
        )}
      </Card> */}
      </Grid>
    </Grid>
  );
}

export default InformacoesConvenio;
