import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  Switch,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import PinDropIcon from '@material-ui/icons/PinDrop';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  CEPTextField,
  DefaultFormTextField,
  PhoneTextField,
  SelectTextField,
  CPFTextField,
  DateTextField,
} from '../../components/Inputs/TextFields';
import { SaveButton } from '../../components/Buttons/Buttons';
import theme from '../../styles/theme';
import { FaSearch } from 'react-icons/fa';
import { getAddress } from 'address-br';

const EditarUsuario = props => {
  const methods = useFormContext();
  const {
    control,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = methods;
  const {
    history,
    sessionUserLevel,
    loadingPage,
    account,
    genders,
    maritalStatus,
    states,
    userLevel,
    isEditing,
    setIsEditing
  } = props;

  const isChangingPassword = useWatch({
    control,
    name: 'password.isChangingPassword',
  });

  const watchNewPassword = useWatch({
    control,
    name: 'password.novaSenha',
  });

  const searchCep = async () => {
    if (watch('address.cep').length === 9) {

      const cepOnlyNumbers = watch('address.cep').replace(/\D/g, '');
			const result = await getAddress(cepOnlyNumbers);

      setValue('address.logradouro', result.rua);
      setValue('address.cidade', result.cidade);
      setValue('address.bairro', result.bairro);
      setValue('address.uf', result.estado);
    }
  }

  useEffect(() => {
    setValue('user', {
      ...account.user,
      nome: account.user.nome.toUpperCase(),
    });

    setValue('person', {
      ...account.person,
    });

    setValue('address', {
      ...account.address,
      id: account.person.id_endereco,
    });
  }, [account, setValue]);

  const TitleContent = ({
    title = '',
    hierarchy = 'h1',
    icon = null,
    loading = false,
  }) => {
    return (
      <Box
        component="header"
        role="heading"
        display="flex"
        alignItems="center"
        mb={2}
      >
        {icon && (
          <Box m={1}>
            {loading ? (
              <Skeleton animation="wave" variant="circle">
                {icon}
              </Skeleton>
            ) : (
              icon
            )}
          </Box>
        )}
        <Box width="100%">
          <Typography variant={hierarchy} style={{ textAlign: 'left' }}>
            {loading ? <Skeleton animation="wave" width="50%" /> : title}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box component={Paper} p={3}>
      <Box component="section" about="Informações pessoais do usuário" mb={4}>
        <TitleContent
          title="Dados pessoais"
          hierarchy="h2"
          loading={loadingPage}
          icon={<PersonIcon style={{ width: '0.875em', height: '0.875em' }} />}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="user.nome"
              control={control}
              defaultValue=""
              render={({ field: { ref, onChange, ...rest } }) => {
                return (
                  <DefaultFormTextField
                    {...rest}
                    inputRef={ref}
                    id="user-name"
                    label="Nome completo"
                    loading={loadingPage}
                    onChange={e => {
                      onChange(e.target.value.toUpperCase());
                    }}
                    hasError={!!errors.user?.nome}
                    errorMessage={errors.user?.nome?.message}
                    disabled={isEditing ? false : true}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Controller
              name="person.cpf"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <CPFTextField
                  {...rest}
                  inputRef={ref}
                  id="person-cpf"
                  label="CPF"
                  loading={loadingPage}
                  hasError={!!errors.person?.cpf}
                  errorMessage={errors.person?.cpf?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Controller
              name="person.telefone"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <PhoneTextField
                  {...rest}
                  inputRef={ref}
                  id="person-phone"
                  label="Telefone"
                  loading={loadingPage}
                  hasError={!!errors.person?.telefone}
                  errorMessage={errors.person?.telefone?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Controller
              name="person.celular"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <PhoneTextField
                  {...rest}
                  inputRef={ref}
                  id="person-cellphone"
                  label="Celular"
                  isOptional
                  loading={loadingPage}
                  hasError={!!errors.person?.celular}
                  errorMessage={errors.person?.celular?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <Controller
              name="person.data_nascimento"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DateTextField
                  {...rest}
                  inputRef={ref}
                  id="person-birthday"
                  label="Data Nascimento"
                  inputMode="numeric"
                  loading={loadingPage}
                  hasError={!!errors.person?.data_nascimento}
                  errorMessage={errors.person?.data_nascimento?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Controller
              name="person.sexo"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextField
                  {...rest}
                  inputRef={ref}
                  id="person-gender"
                  label="Gênero"
                  options={genders}
                  loading={loadingPage}
                  hasError={!!errors.person?.sexo}
                  errorMessage={errors.person?.sexo?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Controller
              name="person.estado_civil"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextField
                  {...rest}
                  inputRef={ref}
                  id="person-maritalStatus"
                  label="Estado Civil"
                  options={maritalStatus}
                  loading={loadingPage}
                  hasError={!!errors.person?.estado_civil}
                  errorMessage={errors.person?.estado_civil?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3} style={{ textAlign: 'left' }}>
            <InputLabel htmlFor="change-password" disabled={isEditing ? false : true}>Usuário ativo?</InputLabel>
            <Controller
              name="user.ativo"
              control={control}
              defaultValue={true}
              render={({ field: { ref, onChange, value, ...rest } }) => (
                <Switch
                  {...rest}
                  inputRef={ref}
                  checked={value}
                  id="change-password"
                  onChange={e => {onChange(e.target.checked)}}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={4}>
            <Controller
              name="assessoria.razao_social"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DefaultFormTextField
                  {...rest}
                  inputRef={ref}
                  id="ass-id"
                  label="Assessoria"
                  disabled
                  loading={loadingPage}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid> */}
        </Grid>
      </Box>

      <Box component="section" about="Endereço do usuário" my={4}>
        <TitleContent
          title="Endereço"
          hierarchy="h2"
          loading={loadingPage}
          icon={<PinDropIcon style={{ width: '0.875em', height: '0.875em' }} />}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Controller
              name="address.cep"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <CEPTextField
                  {...rest}
                  inputRef={ref}
                  id="person-cep"
                  label="CEP"
                  loading={loadingPage}
                  hasError={!!errors.address?.cep}
                  errorMessage={errors.address?.cep?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item md={1} lg={1}>
              <InputLabel>
                &nbsp;
              </InputLabel>
              <Button onClick={() => searchCep()} color="secondary" variant="outlined">
                <FaSearch />
              </Button>
          </Grid>
          <Grid item xs={9} sm={9} md={7} lg={6}>
            <Controller
              name="address.logradouro"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DefaultFormTextField
                  {...rest}
                  inputRef={ref}
                  id="person-street"
                  label="Logradouro"
                  loading={loadingPage}
                  hasError={!!errors.address?.logradouro}
                  errorMessage={errors.address?.logradouro?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={1}>
            <Controller
              name="person.numero_endereco"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DefaultFormTextField
                  {...rest}
                  inputRef={ref}
                  id="person-street-number"
                  label="Número"
                  loading={loadingPage}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 9999, length: 4 } }}
                  onInput={e => {
                    e.target.value =
                      e.target.value &&
                      Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 4);
                  }}
                  inputMode="numeric"
                  hasError={!!errors.person?.numero_endereco}
                  errorMessage={errors.person?.numero_endereco?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              name="address.bairro"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DefaultFormTextField
                  {...rest}
                  inputRef={ref}
                  id="person-neighborhood"
                  label="Bairro"
                  loading={loadingPage}
                  hasError={!!errors.address?.bairro}
                  errorMessage={errors.address?.bairro?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={2}>
            <Controller
              name="address.cidade"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DefaultFormTextField
                  {...rest}
                  inputRef={ref}
                  id="person-city"
                  label="Cidade"
                  loading={loadingPage}
                  hasError={!!errors.address?.cidade}
                  errorMessage={errors.address?.cidade?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3} lg={2}>
            <Controller
              name="address.uf"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <SelectTextField
                  id="person-state"
                  {...rest}
                  inputRef={ref}
                  label="Estado"
                  loading={loadingPage}
                  options={states}
                  hasError={!!errors.address?.uf}
                  errorMessage={errors.address?.uf?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={6}>
            <Controller
              name="person.complemento_endereco"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...rest } }) => (
                <DefaultFormTextField
                  {...rest}
                  inputRef={ref}
                  id="person-address-complement"
                  label="Complemento"
                  loading={loadingPage}
                  isOptional
                  hasError={!!errors.person?.complemente_endereco}
                  errorMessage={errors.person?.complemente_endereco?.message}
                  disabled={isEditing ? false : true}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box component="section" about="Dados de acesso ao sistema" my={4}>
        <TitleContent
          title="Acesso ao sistema"
          hierarchy="h2"
          loading={loadingPage}
          icon={
            <SettingsIcon style={{ width: '0.875em', height: '0.875em' }} />
          }
        />

        <Grid container spacing={6}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="user.email"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...rest } }) => (
                  <DefaultFormTextField
                    {...rest}
                    inputRef={ref}
                    id="user-email"
                    label="Email"
                    loading={loadingPage}
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Controller
                name="user.nivel"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...rest } }) => (
                  <SelectTextField
                    {...rest}
                    inputRef={ref}
                    id="user-level"
                    label="Nível"
                    loading={loadingPage}
                    disabled
                    options={userLevel}
                  />
                )}
              />
            </Grid>
            {isEditing && (
              <Grid item xs={12} sm={6} md={2} lg={3} style={{ textAlign: 'left' }}>
                <InputLabel htmlFor="change-password">Trocar senha?</InputLabel>
                  <Controller
                    name="password.isChangingPassword"
                    control={control}
                    defaultValue={false}
                    render={({ field: { ref, onChange, value, ...rest } }) => (
                      <Switch
                        {...rest}
                        inputRef={ref}
                        checked={value}
                        id="change-password"
                        onChange={e => {
                          onChange(
                            e.target.checked,
                            resetField('password.novaSenha', { defaultValue: '' }),
                            resetField('password.confirmaNovaSenha', {
                              defaultValue: '',
                            }),
                          );
                        }}
                        disabled={isEditing ? false : true}
                      />
                    )}
                  />
              </Grid>
            )}
          </Grid>
          {isChangingPassword && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="left" minWidth={366} maxWidth={600} mb={2}>
                  <Alert severity="info">
                    <Typography
                      variant="h4"
                      style={{ fontWeight: theme.typography.fontWeightRegular }}
                    >
                      Regras para alteração da senha:
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemIcon>
                          {watchNewPassword?.length >= 4 ? (
                            <CheckCircleOutlineIcon color="secondary" />
                          ) : (
                            <ErrorOutlineIcon color="error" />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="Mínimo 4 caracteres" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {watchNewPassword?.length >= 4 &&
                          watchNewPassword?.length <= 20 ? (
                            <CheckCircleOutlineIcon color="secondary" />
                          ) : (
                            <ErrorOutlineIcon color="error" />
                          )}
                        </ListItemIcon>
                        <ListItemText primary="Máximo 20 caracteres" />
                      </ListItem>
                    </List>
                  </Alert>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  name="password.novaSenha"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...rest } }) => (
                    <DefaultFormTextField
                      {...rest}
                      inputRef={ref}
                      id="pwd-new"
                      label="Senha"
                      type="password"
                      loading={loadingPage}
                      disabled={sessionUserLevel !== '1'}
                      hasError={!!errors.password?.novaSenha}
                      errorMessage={errors.password?.novaSenha?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  name="password.confirmaNovaSenha"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...rest } }) => (
                    <DefaultFormTextField
                      {...rest}
                      inputRef={ref}
                      id="pwd-check-pwd"
                      label="Confirmar senha"
                      type="password"
                      loading={loadingPage}
                      disabled={sessionUserLevel !== '1'}
                      hasError={!!errors.password?.confirmaNovaSenha}
                      errorMessage={errors.password?.confirmaNovaSenha?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box
        about="Ações do formulário"
        component="section"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gridColumnGap={16}
        mt={5}
        mb={1}
      >
        {loadingPage ? (
          <Skeleton variant="rect" width={100} height={40} />
        ) : (
          <Button onClick={() => history.push('/usuarios/todos')}>
            Voltar
          </Button>
        )}
        {isEditing ? (
          <SaveButton loadingPage={loadingPage}/>
        ) : (
          <Button onClick={() => setIsEditing(true)} color="secondary" variant="contained" >
            Habilitar edição
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EditarUsuario;
