import React from 'react';
import { FaFileDownload, FaFileExcel } from 'react-icons/fa';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

function ExportarComissoes(props) {
  const { exportar, linkBaixar, setModalBaixar, modalBaixar } = props;

	const toggle = () => setModalBaixar(!modalBaixar);

  return (
    <>
			<Button color="secondary" outline size="sm" onClick={() => exportar()}><FaFileExcel /> Exportar XLSX</Button>
      {
        modalBaixar&&
        <Modal isOpen={modalBaixar} toggle={toggle}>
          <ModalHeader toggle={toggle}><FaFileDownload /> Seu arquivo está pronto!</ModalHeader>
          <ModalBody>
            <Row className="text-right">
              <Col>
              <a href={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`} rel="noopener noreferrer" download={`${process.env.REACT_APP_API_ERP}/${linkBaixar}`}>
                <Button color="success" size="sm" outline>Baixar Arquivo</Button>
              </a>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        }
    </>
  );
}

export default ExportarComissoes;


