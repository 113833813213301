import React, {useState} from 'react';

import Api from '../../services/api';
import {Input, Button, ModalHeader, Modal, ModalBody, Spinner} from 'reactstrap';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';

import { FaDownload } from 'react-icons/fa';

export default function RelatorioClientes (props) 
{
    const { token } = props;
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ linkToDownload, setLinkToDownload ] = useState();
    const [ disabledBtn, setDisabledBtn ] = useState(false);
    const toggle = () => setModal(!modal);

    const handleClickBaixar = async _ => {
        setDisabledBtn(true);
        setLoading(true);

        const authStr = `Bearer ${token}`;
        const result = await Api.get(`/relatorio-venda/clientes` ,{ headers: { Authorization: authStr }});
        console.log(result);
        if(result.data.ok){
            setLoading(false);
			setModal(true);
			setLinkToDownload(result.data.output.arquivo)
		}else{
            setLoading(false);
			alertError(result.data.message);			
		}
        setLoading(false);
        setDisabledBtn(false);
    }

    return (
        <>
            <Button className="mt-4" disabled={disabledBtn} style={{width: "100%", height: "50px"}} color="success" size="sm" onClick={handleClickBaixar} >
				{
                    loading===true
                    ?
                        <Spinner color="primary" size="sm"/>
                    :
                     <><FaDownload />&nbsp;Baixar Relatório Clientes</>
                }
			</Button>
            {
                modal&&
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Baixar arquivo</ModalHeader>
                    <ModalBody className="text-right">
                        <a href={`${process.env.REACT_APP_API_ERP}/${linkToDownload}`} rel="noopener noreferrer" download={`${process.env.REACT_APP_API_ERP}/${linkToDownload}`}>
                            <Button size="sm" color="success"><FaDownload /> Baixar</Button>
                        </a>
                    </ModalBody>
                </Modal>
            }

        </>
    );
}