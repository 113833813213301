import React, { useEffect, useState } from 'react';

import {
  Grid,
  SearchPanel,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IntegratedFiltering, SearchState } from '@devexpress/dx-react-grid';
import { Spinner } from 'reactstrap';
import { OverflowContainer } from '../../views/styles';

export default function SimpleTable(props) {
  const [rowsLength, setRowsLength] = useState(props.rows.length);
  const [columns, setColumns] = useState(props.columns);
  const [hiddenColumns] = useState(props.hiddenColumns);
  const [rows, setRows] = useState(props.rows);
  const [searchValue, setSearchState] = useState('');
  const searchMessages = { searchPlaceholder: 'Procurar ...' };

  const { loading = false } = props;

  const [tableColumnExtensions] = useState(
    props.tableColumnExtensions ||
      props.columns.map(column => ({
        columnName: column.name,
        align: 'center',
      })),
  );

  const [hiddenColumnNames, setHiddenColumnNames] = useState(hiddenColumns);

  useEffect(() => {
    setRowsLength(props.rows.length);
    setColumns(props.columns);
    setRows(props.rows);
  }, [props]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {rowsLength ? (
            <OverflowContainer height={props.height}>
              <Grid rows={rows} columns={columns}>
                <SearchState
                  value={searchValue}
                  onValueChange={setSearchState}
                />
                <IntegratedFiltering />
                <Table columnExtensions={tableColumnExtensions} />

                <Toolbar />
                <SearchPanel messages={searchMessages} />
                <TableHeaderRow />
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={setHiddenColumnNames}
                />
              </Grid>
            </OverflowContainer>
          ) : (
            <div className="m-3 w-100">Sem Dados</div>
          )}
        </>
      )}
    </>
  );
}
