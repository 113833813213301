import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .left': {
      textAlign: 'left',
    },
  },
}));

export default useStyles;
