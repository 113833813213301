import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {FaInfo } from 'react-icons/fa';
import { CardComponent } from '../styles';
import Loading from '../../components/Loading/Loading';

function InformacoesClinica(props) {
  const { clinica } = props;
  const { data, endereco, loading } = clinica;
  return (
    <Card>
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaInfo className="mr-2" />
              Informações
            </h2>
          </Col>
        </Row>
        <hr />
        {loading ? (
          <Loading />
        ) : (
          <Row className="text-left mt-2">
            <Col md={12}>
              <p>
                <b>Razão Social:</b> {data.razao_social}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b>Nome Fantasia:</b> {data.nome_fantasia}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b>CNPJ:</b> {data.cnpj}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b>Tipo: </b>
                {data.tipo
                  ? data.tipo.charAt(0).toUpperCase() + data.tipo.slice(1)
                  : ' - '}
              </p>
            </Col>
            <Col md={12}>
              {data.tipo == 'afiliado' && (
                <p>
                  <b>Clínica Master:</b>{' '}
                  {data.razao_social_clinica_master}
                </p>
              )}
            </Col>
            <Col md={12}>
              <p>
                <b>Grupo de vendas:</b> {data.nome_grupo_vendas}
              </p>
            </Col>
            {
              data.descricao &&
              (
                <Col md={12}>
                    <p>
                      <b>Anotação:</b> {data.descricao}
                    </p>
                </Col>
              )
            }
            <Col md={12}>
              <p>
                <b>Rua:</b> {endereco.logradouro}, {data.numero_endereco}{' '}
                {data.complemento_endereco}
              </p>
            </Col>
            <Col md={12}>
              <p>
                <b>Bairro:</b> {endereco.bairro}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <b>Cidade:</b> {endereco.cidade} - {endereco.uf}
              </p>
            </Col>
          </Row>
        )}
      </CardComponent>
    </Card>
  );
}

export default InformacoesClinica;
