import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alertError } from '../../components/Alerts/Alerts';
import api from '../../services/api';
import { checkSafePassword, padLeadingZeros } from '../../services/service';
import Page from './Page';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

function Controller(props) {
  const { user } = props;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const periodoParams = urlParams.get('periodo');
  const pesquisaParams = urlParams.get('pesquisa');
  const statusPagamentoParams = urlParams.get('status');
  const clinicaMasterParams = urlParams.get('clinicaMaster');
  const clinicaAfiliadaParams = urlParams.get('clinicaAfiliada');

  const [clinicas, setClinicas] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [comissoes, setComissoes] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const [periodo, setPeriodo] = useState(
    periodoParams ?? `${currentYear}-${padLeadingZeros(currentMonth, 2)}`,
  );

  const [pesquisa, setPesquisa] = useState(pesquisaParams ?? pesquisaParams);

  const [statusPagamento, setStatusPagamento] = useState(
    statusPagamentoParams ?? statusPagamentoParams,
  );

  const [clinicaMaster, setClinicaMaster] = useState(
    clinicaMasterParams ?? clinicaMasterParams,
  );

  const [clinicaAfiliada, setClinicaAfiliada] = useState(
    clinicaAfiliadaParams ?? clinicaAfiliadaParams,
  );

  const [nivelPermissao, setNivelPermissao] = useState('afiliado');

  const [clinicaMasterSelect, setClinicaMasterSelect] = useState([]);
  const [clinicaAfiliadaSelect, setClinicaAfiliadaSelect] = useState(
    [],
  );

  const [fireGetComissoes, setFireGetComissoes] = useState(false);

	const [ modalBaixar, setModalBaixar ] = useState(false);
	const [ linkBaixar, setLinkBaixar ] = useState("");

  const getClinicas = async () => {
    try {
      const response = await api.get('/clinicas/', {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if (response.data.ok) {
        setClinicas({
          data: response.data.output,
          loading: false,
        });

        let clinicasList = response.data.output;
        clinicasList = clinicasList.map(({ id, razao_social, tipo }) => ({
          id,
          razao_social,
          tipo,
        }));
        setClinicaMasterSelect(clinicasList);
        setClinicaAfiliadaSelect(clinicasList);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setClinicas({
        data: [],
        loading: false,
      });
      console.log(error.message);
    }
  };

  const getClinicasMaster = async () => {
    try {
      const response = await api.get(
        `clinica/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );

      if (response.data.ok) {
        const responseClinicasMaster = await api.get(
          `clinicas-master/${response.data.output.id}`,
          {
            headers: { Authorization: `Bearer ${user.user.token}` },
          },
        );

        if (responseClinicasMaster.data.ok) {
          setClinicas({
            data: responseClinicasMaster.data.output,
            loading: false,
          });

          let clinicasList = responseClinicasMaster.data.output;
          clinicasList = clinicasList.map(
            ({ id, razao_social, tipo }) => ({
              id,
              razao_social,
              tipo,
            }),
          );
          setClinicaAfiliadaSelect(clinicasList);
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setClinicas({
        data: [],
        loading: false,
      });
      console.log('Erro ao buscar clínicas: ', error.message);
    }
  };

  const getClinicaAfiliada = async () => {
    try {
      const response = await api.get(
        `clinica/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );
      if (response.data.ok) {
        setClinicas({
          data: [response.data.output],
          loading: false,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setClinicas({
        data: [],
        loading: false,
      });
      console.log('Erro ao buscar clinica: ', error.message);
    }
  };

  const getComissoes = async clinicaId => {
    try {
      let filter = `periodo=${periodo}`;

      if (pesquisa) {
        filter += `&pesquisa=${pesquisa}`;
      }
      if (statusPagamento && statusPagamento != 'Todos') {
        filter += `&status=${statusPagamento}`;
      }

      if (clinicaMaster && clinicaMaster != 'Todos') {
        filter += `&clinica-master=${clinicaMaster}`;
      }

      if (clinicaAfiliada && clinicaAfiliada != 'Todos') {
        filter += `&clinica-afiliada=${clinicaAfiliada}`;
      }

      const response = await api.get(
        `/clinica/relatorio-split/relatorio/${clinicaId}?${filter}`,
        { headers: { Authorization: `Bearer ${user.user.token}` } },
      );
      if (response.data.ok) {
        return response.data.output;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  const exportar = async () => {
    console.log('exportar');
  }

  useEffect(() => {
    checkSafePassword(user);
    if (user.user.nivel_usuario == 1) {
      getClinicas();
      setNivelPermissao('admin');
    } else if (user.user.nivel_usuario == 8) {
      getClinicaAfiliada();
      setNivelPermissao('afiliado');
    } else if (user.user.nivel_usuario == 9) {
      getClinicasMaster();
      setNivelPermissao('master');
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    setComissoes({ data: [], loading: true });

    const getData = async () => {
      if (clinicas.data.length) {
        let comissoesData = await Promise.all(
          clinicas.data.map(async clinica => {
            const comissao = await getComissoes(clinica.id);
            return comissao;
          }),
        );

        const ar = [];

        for (let i = 0; i < comissoesData.length; i++) {
          for (let j = 0; j < comissoesData[i].length; j++) {
            ar.push(comissoesData[i][j]);
          }
        }

        comissoesData = ar;

        const comissoesDataFiltered = comissoesData.filter(
          comissao => comissao != 'erro',
        );
        setComissoes({ data: comissoesDataFiltered, loading: false });
      }
    };

    getData();
  }, [fireGetComissoes, clinicas]);

  return (
    <Page
      comissoes={comissoes}
      setPesquisa={setPesquisa}
      pesquisa={pesquisa}
      setPeriodo={setPeriodo}
      periodo={periodo}
      setStatusPagamento={setStatusPagamento}
      statusPagamento={statusPagamento}
      setClinicaMaster={setClinicaMaster}
      clinicaMaster={clinicaMaster}
      setClinicaAfiliada={setClinicaAfiliada}
      clinicaAfiliada={clinicaAfiliada}
      setFireGetComissoes={setFireGetComissoes}
      fireGetComissoes={fireGetComissoes}
      nivelPermissao={nivelPermissao}
      clinicaMasterSelect={clinicaMasterSelect}
      clinicaAfiliadaSelect={clinicaAfiliadaSelect}
      exportar={exportar}
      linkBaixar={linkBaixar}
      setModalBaixar={setModalBaixar}
      modalBaixar={modalBaixar}
    />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
