import React, { useState } from 'react';

import {
  Button,
  // FormGroup,
  FormControl,
  // FormLabel,
  IconButton,
  // InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Modal,
  // Backdrop,
  // Fade,
  Container,
  Grid,
  Tooltip,
  Input,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

import { Cancel } from '@material-ui/icons';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import SaveIcon from '@material-ui/icons/Save';
// import ErrorIcon from '@material-ui/icons/Error';

import theme from '../../styles/theme';

// import { SectionFormHeader, LabelForm } from '../../views/styles';
// import MaterialModal from './MaterialModal';

const useStyles = makeStyles(() => ({
  modal: {
    overflowY: 'scroll',
  },
  paper: {
    background: theme.palette.background.paper,
    width: 560,
    padding: 0,
    borderRadius: 5,
    boxShadow: '7px -7px 10px rgba(0, 0, 0, 0.05)',
    border: '1px solid #C9C9C9',
  },
  header: {
    position: 'relative',
    padding: '30px 30px 20px',
    alignItems: 'center',
  },
  headerIcon: {
    '&.MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  textHeader: {
    marginLeft: 10,
  },
  closeIcon: {
    position: 'absolute',
    top: 20,
    right: 18,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  content: {
    margin: 0,
    padding: '0 30px 40px',
    '& .MuiGrid-container': {
      flexDirection: 'column',
    },
  },
  label: {
    color: '#525151',
    padding: '20px 0 10px 0',
  },
  thumbsContainer: {
    display: 'flex',
  },
  thumbsPreview: {
    padding: 0,
    width: 150,
    height: 90,
    border: '1px dashed #C9C9C9',
    borderRadius: 5,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    height: 90,
    border: '1px dashed #C9C9C9',
    borderRadius: 5,
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 5,
    '& > span.MuiButton-label': {
      padding: 0,
      flexDirection: 'column',
      color: theme.palette.text.secondary,
    },
  },
  footer: {
    padding: '20px 30px 30px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  saveButton: {
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: 0,
    width: 94,
    height: 40,
    marginLeft: 30,
    // '& .MuiButton-iconSizeMedium > *:first-child': { fontSize: '0.75rem' },
  },
}));

function AddEditMaterialApoio(props) {
  const {
    toggleModalAddEditMaterialApoio,
    modalAddEditMaterialApoio,
    cadastroMaterialApoio,
    handleOnChangeInputFields,
    salvarMaterialApoio,
    actionType,
    setActionType,
  } = props;

  const classes = useStyles();

  const titleHeader =
    actionType === 'cadastrar'
      ? 'Cadastrar novo material de apoio'
      : 'Editar material de apoio';

  const handleOnCloseModal = () => {
    setActionType('');
    toggleModalAddEditMaterialApoio();
  };

  return (
    <Modal
      open={modalAddEditMaterialApoio}
      onClose={handleOnCloseModal}
      className={classes.modal}
    >
      <Container maxWidth="sm" className={classes.paper}>
        <Grid container className={classes.header}>
          <AddBoxIcon className={classes.headerIcon} />
          <Typography
            variant="h2"
            component="h1"
            color="textPrimary"
            className={classes.textHeader}
          >
            {titleHeader}
          </Typography>
          <Tooltip title="Fechar">
            <IconButton
              aria-label="fechar"
              className={classes.closeIcon}
              onClick={handleOnCloseModal}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        </Grid>

        <form className="formMaterialApoio">
          <Grid container className={classes.content}>
            <TextField
              type="hidden"
              name="id"
              value={cadastroMaterialApoio.id}
            />
            <Grid container item>
              <Grid item lg={12}>
                <Typography variant="body2" className={classes.label}>
                  Nome do material de apoio
                </Typography>
                <TextField
                  id="nome-material-apoio"
                  name="nome"
                  value={cadastroMaterialApoio.nome}
                  variant="outlined"
                  onChange={e => handleOnChangeInputFields(e)}
                  size="small"
                  fullWidth
                  required
                  autoFocus={actionType === 'cadastrar'}
                />
              </Grid>
            </Grid>

            <Grid container item>
              <Typography variant="body2" className={classes.label}>
                Descrição do material de apoio
                <Typography variant="caption" style={{ fontSize: '0.625rem' }}>
                  {actionType === 'cadastrar' ? `${' '}(Opcional)` : ''}
                </Typography>
              </Typography>
              <TextField
                id="desc-material-apoio"
                name="descricao"
                value={cadastroMaterialApoio.descricao}
                variant="outlined"
                onChange={e => handleOnChangeInputFields(e)}
                size="small"
                fullWidth
                multiline
                minRows={2}
                maxRows={2}
              />
            </Grid>

            <Grid container item>
              <Typography variant="body2" className={classes.label}>
                Classificação do material de apoio
              </Typography>
              <FormControl
                variant="outlined"
                className={classes.select}
                size="small"
              >
                <Select
                  id="tipo-material-apoio"
                  name="tipo"
                  value={cadastroMaterialApoio.tipo}
                  onChange={e => handleOnChangeInputFields(e)}
                  fullWidth
                  required
                >
                  <MenuItem className={classes.menuItem} value="" disabled>
                    <em>Escolha entre Treinamentos ou Vendas</em>
                  </MenuItem>
                  <MenuItem value="treinamento">Treinamentos</MenuItem>
                  <MenuItem value="material-de-venda">
                    Material de Vendas
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid container item>
              <Typography variant="body2" className={classes.label}>
                Fazer upload de capa
                <Typography variant="caption" style={{ fontSize: '0.625rem' }}>
                  {actionType === 'cadastrar' ? `${' '}(Opcional)` : ''}
                </Typography>
              </Typography>
              <Box>
                <Box className={classes.thumbsContainer}>
                  {cadastroMaterialApoio.linkImagem && (
                    <Box
                      className={classes.thumbsPreview}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_ERP}/files/docs/${cadastroMaterialApoio.linkImagem})`,
                      }}
                    />
                  )}
                  <Box className={classes.dropzone}>
                    {/* <label htmlFor="inputFile"> */}
                    <Button
                      className={classes.uploadButton}
                      component="label"
                      variant="text"
                    >
                      <ImageSearchIcon />
                      <Typography variant="body1">
                        {/* {descriptionUpload} */}
                        Clique aqui para inserir uma imagem de capa
                      </Typography>
                      <Input
                        id="inputFile"
                        type="file"
                        accept="image/*"
                        name="imagemCapa"
                        onChange={e => handleOnChangeInputFields(e)}
                        hidden
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid container item>
              <Typography variant="body2" className={classes.label}>
                Escolha o tipo de arquivo que deseja inserir ao material de
                apoio
              </Typography>
              <FormControl
                variant="outlined"
                className="input-filter-dist"
                size="small"
              >
                <Select
                  id="apres-material-apoio"
                  name="tipoApresentacao"
                  value={cadastroMaterialApoio.tipoApresentacao}
                  onChange={e => handleOnChangeInputFields(e)}
                  fullWidth
                  required
                >
                  <MenuItem value="" disabled>
                    <em>Selecione o tipo do arquivo</em>
                  </MenuItem>
                  <MenuItem value="video">Video do YouTube</MenuItem>
                  <MenuItem value="link-externo">Link externo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container item>
              <Typography variant="body2" className={classes.label}>
                Insira o link do material que deseja cadastrar
              </Typography>
              <TextField
                id="link-material-apoio"
                name="link"
                value={cadastroMaterialApoio.link}
                variant="outlined"
                onChange={e => handleOnChangeInputFields(e)}
                size="small"
                placeholder="Cole aqui o link do arquivo do material de apoio"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Grid container className={classes.footer}>
            <Grid item>
              <Button
                variant="text"
                size="large"
                onClick={toggleModalAddEditMaterialApoio}
              >
                <Typography variant="body1">Cancelar</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={() => salvarMaterialApoio()}
                startIcon={<SaveIcon />}
              >
                <Typography variant="button">Salvar</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Modal>
  );
}

export default AddEditMaterialApoio;
