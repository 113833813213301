import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Page from './Page';
import Api from '../../services/api';
import formatValueToPrice from '../../utils/format';
import {
  alertSuccess,
  alertError,
  alertWarning,
} from '../../components/Alerts/Alerts';

function Controller(props) {
  const { idEmpresaCadastro } = useParams();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idApoliceParams = urlParams.get('idApolice');

  const authStr = `Bearer ${props.user.user.token}`;

  const userId = props.user.user.usuario;
  const nivelUsuario = props.user.user.nivel_usuario;

  const [funcionarios, setFuncionarios] = useState([]);
  const [resumoValores, setResumoValores] = useState({});

  const [produtos, setProdutos] = useState([]);
  const [distribuidorOptions, setDistribuidorOptions] = useState([]);
  const [emailCobrancaOptions, setEmailCobrancaOptions] = useState([]);
  const [corretorOptions, setCorretorOptions] = useState([]);
  const [idApolice, setIdApolice] = useState(idApoliceParams);
  const [isSecondRequestDone, setIsSecondRequestDone] = useState(
    localStorage.getItem(`SecondReq-${idApolice}-${idEmpresaCadastro}`),
  );
  const [desconto, setDesconto] = useState('');
  const [tipoDesconto, setTipoDesconto] = useState('');
  const [isPrimeiroDepGratuito, setIsPrimeiroDepGratuito] = useState('');
  const [planoHasChanged, setPlanoHasChanged] = useState(false);
  const [idDistribuidor, setIdDistribuidor] = useState(0);
  const [idCorretor, setIdCorretor] = useState(0);
  const [idAssessoria, setIdAssessoria] = useState(0);

  const [loading, setLoading] = useState({
    distribuidor: true,
    corretor: true,
    funcionarios: true,
    efetivar: false,
  });

  useEffect(() => {
    setFuncionarios(
      funcionarios.map(funcionario => {
        let valor = 0;
        let valorComDesconto = 0;
        let descontoPorcent = 0;
        let descontoValor = 0;

        if (tipoDesconto == 0) {
          descontoPorcent = desconto;
        } else {
          descontoValor = desconto;
        }

        if (funcionario.plano_id != 0) {
          const { preco, preco_dependente_tipo_1, preco_dependente_tipo_2 } =
            produtos.find(produto => produto.id == funcionario.plano_id);

          const precoComDesconto =
            preco * (1 - descontoPorcent / 100) - descontoValor;

          let dependentesTipo1 = funcionario.qtd_dependentes_tipo_1;
          const dependentesTipo2 = funcionario.qtd_dependentes_tipo_2;

          valor =
            preco +
            preco_dependente_tipo_1 * dependentesTipo1 +
            preco_dependente_tipo_2 * dependentesTipo2;

          if (isPrimeiroDepGratuito == '1') {
            if (dependentesTipo1 > 0) {
              dependentesTipo1 -= 1;
            }
          }
          valorComDesconto =
            precoComDesconto +
            preco_dependente_tipo_1 * dependentesTipo1 +
            preco_dependente_tipo_2 * dependentesTipo2;
        }

        return {
          ...funcionario,
          valor,
          valor_com_desconto: valorComDesconto,
        };
      }),
    );
  }, [
    desconto,
    isPrimeiroDepGratuito,
    produtos,
    planoHasChanged,
    tipoDesconto,
  ]);

  useEffect(() => {
    if (!isSecondRequestDone) {
      const { total, totalComDesconto } = funcionarios.reduce(
        (acc, current) => ({
          total: current.valor + acc.total,
          totalComDesconto: current.valor_com_desconto + acc.totalComDesconto,
        }),
        {
          total: 0,
          totalComDesconto: 0,
        },
      );

      setResumoValores({
        valorTotal: formatValueToPrice(total),
        valorDesconto: formatValueToPrice(total - totalComDesconto),
        valorTotalComDesconto: formatValueToPrice(totalComDesconto),
      });
    }
  }, [funcionarios]);

  const handleEfetivarContrato = useCallback(
    async values => {
      setLoading({
        efetivar: true,
      });

      const { primeiroDepGratuito, dataPrimeiraCobranca, emailCobranca } =
        values;

      let { distribuidor, corretor } = values;

      if (nivelUsuario == 2 || nivelUsuario == 5) {
        distribuidor = idDistribuidor;
      } else if (nivelUsuario == 4) {
        distribuidor = idDistribuidor;
        corretor = idCorretor;
      }

      const firstDataRequest = {
        assessoria: distribuidor,
        id_estipulante: idEmpresaCadastro,
        id_usuario: userId,
        id_corretor_representante: corretor,
        seguradosProdutos: funcionarios.map(funcionario => ({
          idSegurado: funcionario.id,
          idProduto: funcionario.plano_id,
        })),
      };

      try {
        let currentIdApolice = idApolice;

        if (!idApolice) {
          const firstResponse = await Api.post('/apolice', firstDataRequest, {
            headers: { Authorization: authStr },
          });

          if (!firstResponse.data.ok) {
            console.log(firstResponse.data.output);
            throw new Error(`${firstResponse.data.message}(1ª request)`);
          }

          currentIdApolice = firstResponse.data.output.idApolice;
          setIdApolice(currentIdApolice);
        }

        const isDescontoValor = tipoDesconto == 1;

        if (!isSecondRequestDone) {
          const secondDataRequest = {
            dep_1_gratis: primeiroDepGratuito,
            desconto_titular: isDescontoValor ? 0 : desconto,
            desconto_valor: isDescontoValor ? desconto : 0,
            primeiro_pagamento: dataPrimeiraCobranca,
            id_apolice: currentIdApolice,
            id_estipulante: idEmpresaCadastro,
            id_config_pagamento: null,
          };

          const secondResponse = await Api.post(
            '/proposta-empresarial',
            secondDataRequest,
            { headers: { Authorization: authStr } },
          );

          if (!secondResponse.data.ok) {
            console.log(secondResponse.data.output);
            throw new Error(`${secondResponse.data.message}(2ª request)`);
          }

          const serializedData = JSON.stringify(secondDataRequest);
          localStorage.setItem(
            `SecondReq-${currentIdApolice}-${idEmpresaCadastro}`,
            serializedData,
          );
          setIsSecondRequestDone(true);
        }

        const thirdDataRequest = {
          id_apolice: currentIdApolice,
          email: emailCobranca,
          primeiro_pagamento: dataPrimeiraCobranca,
          assinatura_asaas: '',
          cliente_asaas: '',
          id_empresa: idEmpresaCadastro,
        };

        const thirdResponse = await Api.post(
          `/empresa/apolice/${currentIdApolice}/criar-assinatura`,
          thirdDataRequest,
          { headers: { Authorization: authStr } },
        );

        if (!thirdResponse.data.ok) {
          console.log(thirdResponse.data.output);
          throw new Error(`${thirdResponse.data.message}(3ª request)`);
        }

        localStorage.removeItem(
          `SecondReq-${currentIdApolice}-${idEmpresaCadastro}`,
        );

        alertSuccess('Contrato efetivado com sucesso!');
        history.push(
          `/apolice-empresarial/${currentIdApolice}/${idEmpresaCadastro}`,
        );
      } catch (error) {
        alertError('Falha ao efetivar contrato');
        console.log(error.message);
      } finally {
        setLoading({
          efetivar: false,
        });
      }
    },
    [
      authStr,
      funcionarios,
      idApolice,
      idEmpresaCadastro,
      userId,
      history,
      idDistribuidor,
      idCorretor,
      nivelUsuario,
      desconto,
      isSecondRequestDone,
    ],
  );

  const handleChangePlan = useCallback(
    (produtoId, funcionarioId) => {
      setPlanoHasChanged(!planoHasChanged);
      setFuncionarios(
        funcionarios.map(funcionario =>
          funcionario.id === funcionarioId
            ? {
                ...funcionario,
                plano_id: produtoId,
              }
            : funcionario,
        ),
      );
    },
    [funcionarios, planoHasChanged],
  );

  const handleChangeDesconto = useCallback(
    value => {
      if (!Number.isNaN(value) && value >= 0) {
        if (tipoDesconto == 0 && value > 100) {
          alertWarning('O desconto não pode maior que 100%');
          return;
        }
        setDesconto(value);
      }
    },
    [tipoDesconto],
  );

  const getFuncionarios = useCallback(async () => {
    try {
      const response = await Api.get(`segurados/${idEmpresaCadastro}`, {
        headers: { Authorization: authStr },
      });

      if (response.data.ok) {
        setFuncionarios(
          response.data.output.map(funcionario => ({
            ...funcionario,
            plano_id: 0,
            valor: 0,
            valor_com_desconto: 0,
          })),
        );
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    } finally {
      setLoading({
        funcionarios: false,
      });
    }
  }, [idEmpresaCadastro, authStr]);

  const getProdutos = useCallback(async (idAssessoria) => {
    try {
      const response = await Api.get(`/produtos-assessoria/${idAssessoria}`, {
        headers: { Authorization: authStr },
      });

      if (response.data.ok) {
        setProdutos(response.data.output);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  }, [authStr]);

  const getDistribuidores = useCallback(async () => {
    try {
      const response = await Api.get(`/assessorias/`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setDistribuidorOptions(
          response.data.output.map(distribuidor => ({
            value: distribuidor.id,
            label: distribuidor.nome,
          })),
        );
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    } finally {
      setLoading({
        distribuidor: false,
        corretor: false,
      });
    }
  }, [authStr]);

  const getContatos = useCallback(async () => {
    try {
      const response = await Api.get(`/empresa/contatos/${idEmpresaCadastro}`, {
        headers: { Authorization: authStr },
      });

      if (response.data.ok) {
        setEmailCobrancaOptions(
          response.data.output.map(contato => ({
            value: contato.email,
            label: contato.email,
          })),
        );
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  }, [authStr, idEmpresaCadastro]);

  const getEmpresa = async () => {
    if (idEmpresaCadastro) {
      const response = await Api.get(`/empresa/${idEmpresaCadastro}`, {
        headers: { Authorization: authStr },
      });
      setIdAssessoria(response.data.output.id_assessoria);
    }
  };

  const getCorretores = useCallback(
    async value => {
      setLoading({
        corretor: true,
      });

      try {
        const response = await Api.get(
          `corretor-representante/assessoria/${value}`,
          { headers: { Authorization: authStr } },
        );

        if (response.data.ok) {
          setCorretorOptions(
            response.data.output.map(corretor => ({
              value: corretor.id,
              label: corretor.nome,
            })),
          );
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        alertError(error.message);
      } finally {
        setLoading({
          corretor: false,
        });
      }
    },
    [authStr],
  );

  const handleChangeDistribuidor = useCallback(
    async value => {
      if (value) {
        getCorretores(value);
      }
    },
    [getCorretores],
  );

  const getValorApolice = useCallback(async () => {
    try {
      const response = await Api.get(`/apolice/busca-valor/${idApolice}`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setResumoValores({
          valorTotalComDesconto: formatValueToPrice(response.data.output.valor),
        });
      }
    } catch (error) {
      alertError(error.message);
    }
  }, [authStr, idApolice]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (nivelUsuario == 4) {
      setIdDistribuidor(props.user.user.assessoria);
      setIdCorretor(props.user.user.corretor_representante);
    } else if (nivelUsuario == 2 || nivelUsuario == 5) {
      setIdDistribuidor(props.user.user.assessoria);
      getCorretores(props.user.user.assessoria);
    } else {
      getDistribuidores();
    }
    getEmpresa();

    if (idApolice) {
      getValorApolice();
    }
    // getProdutos();
    getFuncionarios();
    getContatos();
  }, []);

  useEffect(()=>{
      if (idAssessoria > 0) getProdutos(idAssessoria);
},[idAssessoria])

  return (
    <>
      <ToastContainer />
      <Page
        funcionarios={funcionarios}
        produtos={produtos}
        handleChangePlan={handleChangePlan}
        resumoValores={resumoValores}
        handleEfetivarContrato={handleEfetivarContrato}
        distribuidorOptions={distribuidorOptions}
        corretorOptions={corretorOptions}
        emailCobrancaOptions={emailCobrancaOptions}
        handleChangeDistribuidor={handleChangeDistribuidor}
        loading={loading}
        hasIdApoliceParams={!!idApolice}
        desconto={desconto}
        handleChangeDesconto={handleChangeDesconto}
        isPrimeiroDepGratuito={isPrimeiroDepGratuito}
        setIsPrimeiroDepGratuito={setIsPrimeiroDepGratuito}
        isUsuarioVendedor={nivelUsuario == 4}
        isUsuarioDistribuidor={nivelUsuario == 2 || nivelUsuario == 5}
        user={props.user.user}
        isSecondRequestDone={isSecondRequestDone}
        setTipoDesconto={setTipoDesconto}
        tipoDesconto={tipoDesconto}
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
