import React from 'react';
// import {
//   Row,
//   Col,
//   Input,
//   InputGroup,
//   InputGroupText,
//   InputGroupAddon,
// } from 'reactstrap';
// import {
//   // FaBuilding,
//   FaRoad,
//   FaInfo,
//   FaMapMarkerAlt,
//   FaGlobeAmericas,
// } from 'react-icons/fa';

// import InputMask from 'react-input-mask';

// import { useHistory } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';

import AssignmentIcon from '@material-ui/icons/Assignment';
import PinDropIcon from '@material-ui/icons/PinDrop';

import { makeStyles } from '@material-ui/core/styles';
// import { SectionFormHeader, PrependInputLabel } from '../styles';

import {
  CNPJTextField,
  CEPTextField,
  DefaultFormTextField,
} from '../../components/Inputs/TextFields';

const useStyles = makeStyles(() => ({
  sectionFormHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionFormTitle: {
    marginLeft: 20,
  },
  sectionFormBody: {
    flexDirection: 'row',
  },
}));

function CadastroConvenio(props) {
  const { handleConvenioOnChange, handleCepOnChange, empresa } = props;
  // const history = useHistory();
  const classes = useStyles();

  return (
    <Grid item xs="auto" container spacing={6}>
      {/* <Row className="mb-3">
        <Col>
          <SectionFormHeader>Dados da Empresa</SectionFormHeader>
        </Col>
      </Row> */}

      <Grid item xs="auto" container spacing={4}>
        <Grid item xs="auto" className={classes.sectionFormHeader}>
          <AssignmentIcon />
          <Typography variant="h3" className={classes.sectionFormTitle}>
            Dados da empresa
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
          spacing={2}
          container
          className={classes.sectionFormBody}
        >
          <Grid item xs={12} md={6}>
            <DefaultFormTextField
              id="convenio-nome"
              label="Nome da empresa conveniada"
              name="nome"
              value={empresa.nome}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CNPJTextField
              // style={{ marginLeft: '16px' }}
              id="convenio-cnpj"
              label="CNPJ"
              name="cnpj"
              value={empresa.cnpj}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <Row className="mb-4">
        <Col xs={12} md={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PrependInputLabel>CNPJ</PrependInputLabel>
              </InputGroupText>
            </InputGroupAddon>
            <InputMask
              mask="99.999.999/9999-99"
              type="text"
              name="cnpj"
              value={empresa.cnpj}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaBuilding />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Nome da Empresa"
              name="nome"
              required
              value={empresa.nome}
              onChange={e => handleConvenioOnChange(e)}
            />
          </InputGroup>
        </Col>
      </Row> */}
      {/* <hr /> */}
      <Grid item xs="auto" container spacing={4}>
        <Grid item xs="auto" className={classes.sectionFormHeader}>
          <PinDropIcon />
          <Typography variant="h3" className={classes.sectionFormTitle}>
            Endereço
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
          container
          spacing={2}
          className={classes.sectionFormBody}
        >
          <Grid item xs={12} md={2}>
            <CEPTextField
              id="convenio-cep"
              label="CEP"
              name="cep"
              value={empresa.cep}
              required
              onChange={e => handleCepOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <DefaultFormTextField
              id="convenio-logradouro"
              label="Rua"
              name="logradouro"
              value={empresa.logradouro}
              required
              onChange={e => handleCepOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultFormTextField
              type="number"
              id="convenio-numero-endereco"
              label="Número"
              name="numero_endereco"
              value={empresa.numero_endereco}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultFormTextField
              id="convenio-complemento-endereco"
              label="Complemento"
              name="complemento_endereco"
              value={empresa.complemento_endereco}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultFormTextField
              id="convenio-bairro"
              label="Bairro"
              name="bairro"
              value={empresa.bairro}
              required
              onChange={e => handleCepOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultFormTextField
              id="convenio-cidade"
              label="Cidade"
              name="cidade"
              value={empresa.cidade}
              required
              onChange={e => handleCepOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultFormTextField
              id="convenio-uf"
              label="Estado"
              name="uf"
              value={empresa.uf}
              required
              onChange={e => handleCepOnChange(e)}
              inputProps={{ maxLength: 2 }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Row className="mb-3">
        <Col>
          <SectionFormHeader>Informações de Endereço</SectionFormHeader>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={4} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PrependInputLabel>CEP</PrependInputLabel>
              </InputGroupText>
            </InputGroupAddon>
            <InputMask
              mask="99999-999"
              name="cep"
              value={empresa.cep}
              required
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaRoad />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="logradouro"
              value={empresa.logradouro}
              required
              placeholder="Rua"
              onChange={e => handleCepOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={2} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PrependInputLabel>Nº</PrependInputLabel>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="number"
              name="numero_endereco"
              value={empresa.numero_endereco}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={4} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaInfo />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="complemento_endereco"
              placeholder="Complemento"
              value={empresa.complemento_endereco}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={3} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaMapMarkerAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="bairro"
              placeholder="Bairro"
              value={empresa.bairro}
              onChange={e => handleCepOnChange(e)}
              required
            />
          </InputGroup>
        </Col>
        <Col md={3} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaMapMarkerAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="cidade"
              placeholder="Cidade"
              value={empresa.cidade}
              onChange={e => handleCepOnChange(e)}
              required
            />
          </InputGroup>
        </Col>
        <Col md={2} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaGlobeAmericas />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              size="2"
              maxLength="2"
              placeholder="UF"
              name="uf"
              value={empresa.uf}
              onChange={e => handleCepOnChange(e)}
              required
            />
          </InputGroup>
        </Col>
      </Row> */}
      {/* <hr /> */}
    </Grid>
  );
}
export default CadastroConvenio;
