import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Api from '../../services/api';
import { connect } from 'react-redux';
import Page from './Page';

function Controller(props) {

  let { idClient } = useParams();
  let history = useHistory();

  const idEmpresa = props.location.state.idEmpresa;
  const [ nomeFuncionario, setNomeFuncionario ] = useState();

  const [ funcionarios, setFuncionarios ] = useState({
    nome: '',
    cpf: '',
    data_nascimento: '',
    estado_civil: '',
    sexo: '',
    telefone: '',
    celular: '',
    email: '',
    origem: '',
    observacao: '',
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    id_produto: '',

  });
  const [ depClienteIndividual, setDepClienteIndividual ] = useState([]);

  const authStr = `Bearer ${props.user.user.token}`;

  useEffect(() => {
    if(idClient){
      getInfosFuncionario();
    }
  }, []);

  const getInfosFuncionario = async _ => {
    const restApi = await Api.get(`segurado/detalhe/${idClient}`, { headers: { Authorization: authStr }});

    const dpRestApi = await Api.get(`segurado/dependentes/${idClient}`, { headers: { Authorization: authStr }});
    let newDpArr = [];
    let newObj = {};

    if(dpRestApi !== undefined){
      if(dpRestApi.data.output.length > 0){
        dpRestApi.data.output.map(el => {

          newObj = {
            // tipoDependente: el.tipo,
            // parentesco: el.parentesco,
            // responsavel: el.titular,
            // cpfDependente: el.cpf,
            // nomeDependente: el.nome,
            // dataNascimentoDependente: el.data_nascimento,
            id: el.id,
            id_segurado: el.id_segurado,
            tipoDependente: el.tipo,
            parentesco: el.parentesco,
            cpfDependente: el.cpf,
            nomeDependente: el.nome,
            dataNascimentoDependente: el.data_nascimento,
            titular: el.titular,
          }

          newDpArr = [ ...newDpArr, newObj  ];
        })
        setDepClienteIndividual(newDpArr);
      }
    }

    setFuncionarios(restApi.data);

    // if(newDpArr.length !== 0){
    //   setDepClienteIndividual(newDpArr);
    // }

    setNomeFuncionario(restApi.data.output.nome ? restApi.data.output.nome : "Não encontrado" );

  }

  return (
    <div>
      {
        funcionarios.output&&
        <Page
          nomeFuncionario={nomeFuncionario}
          history={history}
          funcionarios={funcionarios.output}
          depClienteIndividual={depClienteIndividual}
          idEmpresa={idEmpresa}
        />
      }
    </div>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);
