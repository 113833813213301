import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import VoidContent from '../../assets/img/conteudo-vazio.svg';

const NoContent = ({ text = '', hasImage = false }) => {
  return (
    <Box
      component={Paper}
      display="flex"
      flexDirection="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      p={8}
    >
      {hasImage && (
        <Box component="figure" mb={4}>
          <img src={VoidContent} alt="Sem conteúdo" />
        </Box>
      )}
      <Box>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
};

export default NoContent;
