import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Page from './Page';
import api from '../../services/api';
import { checkSafePassword } from '../../services/service';
// import { alertError } from '../../components/Alerts/Alerts';

function Controller(props) {
  const { user } = props;
  const authStr = `Bearer ${user.user.token}`;

  const [policies, setPolicies] = useState({ loading: true, data: [] });
  const [faturas, setFaturas] = useState({
    loading: true,
    data: [],
    rows: 0,
  });
  const [primeirasFaturas, setPrimeirasFaturas] = useState({
    loading: true,
    data: [],
  });
  const [primeirasFaturasRenovacoes, setPrimeirasFaturasRenovacoes] = useState({
    loading: true,
    data: [],
  });
  const [assessorias, setAssessorias] = useState({ loading: true, data: [] });
  const [corretores, setCorretores] = useState({ loading: true, data: [] });
  const [inputs, setInputs] = useState({
    initDate: '',
    endDate: '',
    assessoriaSelected: 0,
    corretorSelected: 0,
  });
  const [apolicesDeclinadas, setApolicesDeclinadas] = useState({
    loading: true,
    data: [],
  });
  const [faturasEmpresariais, setFaturasEmpresariais] = useState({
    loading: true,
    data: [],
    rows: 0,
  });
  const [page, setPage] = useState(0);

  const history = useHistory();

  useEffect(() => {
    checkSafePassword(user);

    if (user.user.nivel_usuario == 1) {
      getAssessorias();
    }

    if (user.user.nivel_usuario == 5) {
      getAssessoriasMaster();
    }

    // if (user.user.nivel_usuario == 6) {
    //   getAssessoriasMasterPerfilCobranca();
    // }
  }, []);

  useEffect(() => {
    if (
      user.user.nivel_usuario == 2 ||
      user.user.nivel_usuario == 5 ||
      user.user.nivel_usuario == 6
    ) {
      if (inputs.corretorSelected !== 0) {
        fetchSeguradosApolices();
        getFaturas();
        getPrimeirasFaturas();
        // getPrimeirasFaturasRenovacoes();
        getApolicesDeclinadas();
        getFaturasEmpresariais();
      } else {
        setCorretores({ loading: false, data: [] });
        setPolicies({ loading: false, data: [] });
        setFaturas({ data: [], loading: false, rows: 0 });
        setPrimeirasFaturas({ loading: false, data: [] });
        setPrimeirasFaturasRenovacoes({ loading: false, data: [] });
        setApolicesDeclinadas({ loading: false, data: [] });
        setFaturasEmpresariais({ data: [], loading: false });
      }
    } else {
      fetchSeguradosApolices();
      getFaturas();
      getPrimeirasFaturas();
      // getPrimeirasFaturasRenovacoes();
      getApolicesDeclinadas();
      getFaturasEmpresariais();
    }
  }, [inputs.initDate, inputs.endDate, inputs.corretorSelected, page]);

  useEffect(() => {
    if (user.user.nivel_usuario == 2) {
      getAssessoriaByIdUsuario(user.user.usuario);
    }
  }, []);

  useEffect(() => {
    if (user.user.nivel_usuario == 6) {
      getAssessoriaByIdUsuarioPerfilCobranca(user.user.usuario);
    }
  }, []);

  useEffect(() => {
    getCorretores();
  }, [inputs.assessoriaSelected]);

  const fetchSeguradosApolices = async () => {
    setPolicies({ loading: true, data: [] });

    if (inputs.assessoriaSelected !== '' && inputs.assessoriaSelected !== 0) {
      const res = await api.get(
        `segurados/apolices/assessoria/${inputs.assessoriaSelected}/?corretor=${inputs.corretorSelected}`,
        { headers: { Authorization: authStr } },
      );
      if (res.data.ok) {
        setPolicies({ loading: false, data: res.data.output });
      } else {
        setPolicies({ loading: false, data: [] });
        // alertError(res.data.message);
        console.log('Erro ao buscar segurados apólices', res.data.message);
      }
    } else {
      const res = await api.get(`segurados/apolices/?status=Suspensa`, {
        headers: { Authorization: authStr },
      });
      if (res.data.ok) {
        setPolicies({ loading: false, data: res.data.output });
      } else {
        setPolicies({ loading: false, data: [] });
        // alertError('Erro ao buscar da API');
        console.log('Erro ao buscar da API');
      }
    }
  };

  const getApolicesDeclinadas = async () => {
    setApolicesDeclinadas({ loading: true, data: [] });
    const response = await api.get(
      `segurados/apolices/?status=Declinada+por+desistência&acessoria=${inputs.assessoriaSelected}&corretor=${inputs.corretorSelected}`,
      { headers: { Authorization: authStr } },
    );
    if (response.data.ok) {
      setApolicesDeclinadas({ loading: false, data: response.data.output });
    } else {
      setApolicesDeclinadas({ loading: false, data: [] });
      // alertError('Erro ao buscar da API');
    }
  };

  const getFaturas = async () => {
    setFaturas({ ...faturas, loading: true });

    if (user.user.nivel_usuario == 1 || inputs.corretorSelected > 0) {
      const response = await api.get(
        `/faturas-cobrancas/?initDate=${inputs.initDate}&endDate=${inputs.endDate}&corretor=${inputs.corretorSelected}`,
        { headers: { Authorization: authStr } },
      );
      if (response.data.ok) {
        setFaturas({
          loading: false,
          data: [...faturas.data, ...response.data.output],
          rows: response.data.rows,
        });
      } else {
        // alertError(`Erro ao buscar faturas da API: ${response.data.message}`);
        console.log(`Erro ao buscar faturas da API: ${response.data.message}`);
        setFaturas({ ...faturas, loading: false });
      }
    }
  };

  const getPrimeirasFaturas = async () => {
    setPrimeirasFaturas({ loading: true, data: [] });

    if (user.user.nivel_usuario == 1 || inputs.corretorSelected > 0) {
      const response = await api.get(
        `/faturas/primeiro-pagamento?corretor=${inputs.corretorSelected}`,
        { headers: { Authorization: authStr } },
      );
      if (response.data.ok) {
        setPrimeirasFaturas({ loading: false, data: response.data.output });
      } else {
        setPrimeirasFaturas({ loading: false, data: [] });
        console.log(
          `Erro ao buscar primeiras faturas da API: ${response.data.message}`,
        );
      }
    }
  };

  const getPrimeirasFaturasRenovacoes = async () => {
    setPrimeirasFaturasRenovacoes({ loading: true, data: [] });

    const response = await api.get(
      `/faturas/primeiro-pagamento-renovacoes?corretor=${inputs.corretorSelected}`,
      { headers: { Authorization: authStr } },
    );
    if (response.data.ok) {
      setPrimeirasFaturasRenovacoes({
        loading: false,
        data: response.data.output,
      });
    } else {
      setPrimeirasFaturasRenovacoes({ loading: false, data: [] });
      // alertError(
      //   `Erro ao buscar primeiras faturas da API: ${response.data.message}`,
      // );
      console.log(
        `Erro ao buscar primeiras faturas da API: ${response.data.message}`,
      );
    }
  };

  const getAssessorias = async () => {
    setAssessorias({ loading: true, data: [] });

    const response = await api.get(`/assessorias/`, {
      headers: { Authorization: authStr },
    });
    if (response.data.ok) {
      setAssessorias({ loading: false, data: response.data.output });
    } else {
      console.log(
        `Erro ao buscar assessorias da API: ${response.data.message}`,
      );
      setAssessorias({ loading: false, data: [] });
    }
  };

  const getAssessoriasMaster = async () => {
    try {
      const response = await api.get(
        `assessoria/usuario/${user.user.usuario}`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );

      if (response.data.ok) {
        const responseAssessoriasMaster = await api.get(
          `assessorias-master/${response.data.output.id}`,
          {
            headers: { Authorization: `Bearer ${user.user.token}` },
          },
        );

        if (responseAssessoriasMaster.data.ok) {
          setAssessorias({
            data: responseAssessoriasMaster.data.output,
            loading: false,
          });
        }
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAssessorias({
        data: [],
        loading: false,
      });
      console.log('Erro ao buscar distribuidor: ', error.message);
    }
  };

  const getAssessoriaByIdUsuario = async id => {
    setAssessorias({ loading: true, data: [] });

    const response = await api.get(`/assessoria/usuario/${id}`, {
      headers: { Authorization: authStr },
    });
    if (response.data.ok) {
      setAssessorias({ loading: false, data: response.data.output });
      setInputs({ ...inputs, assessoriaSelected: response.data.output.id });
    } else {
      setAssessorias({ loading: false, data: [] });

      // alertError(`Erro ao buscar assessoria da API: ${response.data.message}`);
      console.log(`Erro ao buscar assessoria da API: ${response.data.message}`);
    }
  };

  const getAssessoriaByIdUsuarioPerfilCobranca = async id => {
    setAssessorias({ loading: true, data: [] });

    const response = await api.get(`usuario/${id}`, {
      headers: { Authorization: authStr },
    });

    if (response.data.ok) {
      const responseAssessoria = await api.get(
        `/assessoria/${response.data.output.id_assessoria}`,
        {
          headers: { Authorization: authStr },
        },
      );
      if (responseAssessoria.data.ok) {
        const responseAssessoriaUsuario = await api.get(
          `/assessoria/usuario/${responseAssessoria.data.output.id_usuario}`,
          {
            headers: { Authorization: authStr },
          },
        );
        if (responseAssessoriaUsuario.data.ok) {
          setAssessorias({
            loading: false,
            data: responseAssessoriaUsuario.data.output,
          });
          setInputs({
            ...inputs,
            assessoriaSelected: responseAssessoriaUsuario.data.output.id,
          });
        } else {
          setAssessorias({ loading: false, data: [] });

          // alertError(`Erro ao buscar assessoria da API: ${response.data.message}`);
          console.log(
            `Erro ao buscar assessoria da API: ${response.data.message}`,
          );
        }
      }
    } else {
      throw new Error(response.data.message);
    }
  };

  const getCorretores = async () => {
    setCorretores({ loading: true, data: [] });
    if (inputs.assessoriaSelected !== '' && inputs.assessoriaSelected != 0) {
      const response = await api.get(
        `/corretor-representante/assessoria/${inputs.assessoriaSelected}`,
        { headers: { Authorization: authStr } },
      );

      if (response.data.ok) {
        setCorretores({ loading: false, data: response.data.output });

        if (response.data.output.length) {
          setInputs({
            ...inputs,
            corretorSelected: response.data.output[0].id,
          });
        }
      } else {
        setCorretores({ loading: false, data: [] });

        console.log(
          `Erro ao buscar corretores da API: ${response.data.message}`,
        );
      }
    } else {
      setCorretores({ loading: false, data: [] });
    }
  };

  const getFaturasEmpresariais = async () => {
    setFaturasEmpresariais({ ...faturasEmpresariais, loading: true });
    try {
      const response = await api.get(
        `/faturas-empresariais?initDate=${inputs.initDate}&endDate=${inputs.endDate}&corretor=${inputs.corretorSelected}`,
        {
          headers: { Authorization: authStr },
        },
      );
      setFaturasEmpresariais({
        loading: false,
        data: [...faturasEmpresariais.data, ...response.data.output],
        rows: response.data.rows,
      });

      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log('Erro ao buscar faturas empresariais: ', error.message);
      setFaturasEmpresariais({ ...faturasEmpresariais, loading: false });
    }
  };

  return (
    <>
      <Page
        policies={policies}
        faturas={faturas}
        setInputs={setInputs}
        inputs={inputs}
        primeirasFaturas={primeirasFaturas}
        history={history}
        assessorias={assessorias}
        corretores={corretores}
        nivelUsuario={user.user.nivel_usuario}
        apolicesDeclinadas={apolicesDeclinadas}
        faturasEmpresariais={faturasEmpresariais}
        setPage={setPage}
        setFaturas={setFaturas}
        user={user}
        setFaturasEmpresariais={setFaturasEmpresariais}
        primeirasFaturasRenovacoes={primeirasFaturasRenovacoes}
      />
    </>
  );
}

const mapStateToProps = store => ({ user: store.user });

export default connect(mapStateToProps)(Controller);
