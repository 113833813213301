import React, { useCallback, useMemo } from 'react';
import { Card, CardHeader, Input } from 'reactstrap';
import { FaUsers } from 'react-icons/fa';

import SimpleGrid from '../../components/TablesGrid/SimpleTable';
import formatValueToPrice from '../../utils/format';
import { OverflowContainer } from '../styles';
import { TitleCardComponent } from '../../components/TitleContent/styles';
import Loading from '../../components/Loading/Loading';

export default function ListaFuncionarios(props) {
  const { funcionarios, produtos, handleChangePlan, loading } = props;
  const plans = useCallback(
    funcionario => (
      <Input
        type="select"
        onChange={event => handleChangePlan(event.target.value, funcionario.id)}
      >
        <option value={0}>Nenhum</option>
        {produtos.map(produto => {
          const nomePlano = produto.nome;
          return (
            <option key={produto.id} value={produto.id}>
              {nomePlano}
            </option>
          );
        })}
      </Input>
    ),
    [produtos, handleChangePlan],
  );

  const columns = useMemo(
    () => [
      // { name: 'id', title: '#ID', getCellValue: row => (row.id) },
      { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
      {
        name: 'qtd_dependentes_tipo_1',
        title: 'Qtd. Dep.',
        getCellValue: row => `${row.qtd_dependentes_tipo_1} Dep(s)`,
      },
      {
        name: 'qtd_dependentes_tipo_2',
        title: 'Qtd. Titular 2',
        getCellValue: row => `${row.qtd_dependentes_tipo_2} Dep(s)`,
      },
      { name: 'plano', title: 'Plano', getCellValue: row => plans(row) },
      {
        name: 'valor',
        title: 'Valor',
        getCellValue: row => formatValueToPrice(row.valor),
      },
      {
        name: 'valor_com_desconto',
        title: 'Valor com Desconto',
        getCellValue: row => formatValueToPrice(row.valor_com_desconto),
      },
    ],
    [plans],
  );

  const tableColumnExtensions = [
    { columnName: 'nome', align: 'center' },
    { columnName: 'qtd_dependentes_tipo_1', align: 'center' },
    { columnName: 'qtd_dependentes_tipo_2', align: 'center' },
    { columnName: 'plano', align: 'center' },
    { columnName: 'valor', align: 'center' },
    { columnName: 'valor_com_desconto', align: 'center' },
  ];

  return (
    <>
      <Card className="mt-4">
        <CardHeader>
          <TitleCardComponent>
            <FaUsers className="mr-3" /> Lista de Funcionários
          </TitleCardComponent>
        </CardHeader>

        {/* <OverflowContainer > */}
        {loading ? (
          <Loading />
        ) : (
          <SimpleGrid
            height="320px"
            rows={funcionarios}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
          />
        )}
        {/* </OverflowContainer> */}
      </Card>
    </>
  );
}
