import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { Page } from './Page';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

import theme from '../../styles/theme';
import { checkSafePassword } from '../../services/service';

const Controller = props => {
  const { user } = props;
  const { assessoria, token } = user.user;
  const history = useHistory();
  const [leads, setLeads] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkSafePassword(props.user);
    getLeads();
  }, []);

  const getLeads = async _ => {
    const response = await Api.get(`lead/assessoria/${assessoria}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log('Response Leads - ', response);
    setLeads(response.data);
    setLoading(false);
  };

  const updateChangeStatus = async changedLead => {
    setLoading(true);

    try {
      const response = await Api.put(
        `lead/assessoria/${assessoria}/lead/${changedLead.id}`,
        changedLead,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.ok) {
        alertSuccess('Lead atualizado com sucesso!');
        getLeads();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao atualizar Lead', error.message);
    }

    setLoading(false);
  };

  const handleChangeStatus = (statusList, selectedLead) => {
    const newLead = Object.assign(selectedLead);

    if (
      statusList.name === 'pendente' &&
      selectedLead.acao_conclusao === statusList.value
    ) {
      newLead.acao_conclusao = statusList.newValue;
    } else {
      newLead.acao_conclusao = statusList.newValue;
    }
    updateChangeStatus(newLead);
  };

  return (
    <ThemeProvider theme={theme}>
      <Page
        history={history}
        leads={leads}
        loading={loading}
        token={token}
        handleChangeStatus={handleChangeStatus}
      />
    </ThemeProvider>
  );
};

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
