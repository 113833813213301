import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Col, Button } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';

export default function ClinicaClientes(props) {
  const columns = [
    { name: 'id', title: '#ID', getCellValue: row => parseInt(row.id) },
    { name: 'name', title: 'Nome', getCellValue: row => row.nome },
    { name: 'cpf', title: 'CPF', getCellValue: row => row.cpf },
    { name: 'telefone', title: 'Telefone', getCellValue: row => row.telefone },
    { name: 'cellphone', title: 'Celular', getCellValue: row => row.celular },
    { name: 'email', title: 'E-mail', getCellValue: row => row.email },
    { name: '', title: '', getCellValue: row => actions(row) },
  ];

  const actions = row => {
    return (
      <div>
        <Button
          color="primary"
          size="sm"
          outline
          onClick={() => props.history.push(`/clinica-cliente/detalhe/${row.id}`)}
        >
          <FaSearch />
        </Button>
      </div>
    );
  };

  return (
    <Col md={12}>
      {props.clientes.output && props.clientes.output.length > 0 ? (
        <TablesGrid
          rows={props.clientes.output}
          columns={columns}
          hiddenColumns={['nomes_dependentes']}
        />
      ) : (
        <span style={{ color: 'red' }}>{props.clientes.message}</span>
      )}
    </Col>
  );
}
