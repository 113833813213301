import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page';

function Controller(props) {

  let history = useHistory();
  let { id } = useParams();

  const [ beneficio, setBeneficio ] = useState({
    nome: '',
    descricao: '',
    contatoAcionamento: '',
    custo: '',
    clausulaPadrao: [],
  });

  useEffect(
    () => {
      getInfosBeneficio();
    }, []
  )

  const getInfosBeneficio = async _ => {
    const request = await Api.get(`terceiro/${id}`, { headers: { Authorization: `Bearer ${props.user.user.token}` }})
    console.log('a',request.data.output);
    if(request.data.ok){
      setBeneficio({
        id: request.data.output.id,
        nome: request.data.output.nome,
        descricao: request.data.output.descricao,
        contatoAcionamento: request.data.output.contatoAcionamento,
        custo: request.data.output.custo,
      });
    }
  }

  return (
    <>
      {beneficio &&
        (
          <Page
            beneficio={beneficio}
            setBeneficio={setBeneficio}
            history={history}
            token={props.user.user.token}
            />
        )
      }
    </>
  )
}

const maapStateToProps = store => ({
  user: store.user,
})

export default connect(maapStateToProps)(Controller);
