import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Spinner } from 'reactstrap';
// import {
//   FaUsers,
//   FaSearch,
//   FaDownload,
//   FaFileExcel,
//   FaFilePdf,
//   FaFileContract,
//   FaFileInvoiceDollar,
// } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  GridList,
  Paper,
  Typography,
  IconButton,
  Container,
  Link,
  Tooltip,
} from '@material-ui/core';
import HowToRegIcon from '@material-ui/icons/HowToReg';
// import {
//   FormatValorFatura,
//   FormatValorFaturaDesconto,
//   SectionFormHeader,
// } from '../styles';
import ReceiptIcon from '@material-ui/icons/Receipt';
import InfoIcon from '@material-ui/icons/Info';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';
// import SimpleTable from '../../components/TablesGrid/SimpleTable';
import formatValueToPrice from '../../utils/format';

import {
  ExportButton,
  // RegisterButton,
  ContractButton,
  DefaultButton,
} from '../../components/Buttons/Buttons';
import { SearchTextField } from '../../components/Inputs/TextFields';
import DataGridLayout from '../../components/TablesGrid/DataGrid';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function ResumoConveniados(props) {
  const { conveniados, infosConvenio, idEstipulante, authStr, loading } = props;
  const history = useHistory();
  const [filteredConveniados, setFilteredConveniados] = useState(conveniados);

  const [linkBaixarCSV, setLinkBaixarCSV] = useState('');
  const [linkBaixarPDF, setLinkBaixarPDF] = useState('');
  const [loadingExportarCSV, setLoadingExportarCSV] = useState(false);
  const [loadingExportarPDF, setLoadingExportarPDF] = useState(false);
  const [searchText, setSearchText] = useState('');

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = conveniados.filter(row => {
      return Object.keys(row).some(field => {
        if (
          field === 'nome_segurado' ||
          field === 'id_segurado' ||
          field === 'qtd_dependentes_tipo_1' ||
          field === 'qtd_dependentes_tipo_2'
        ) {
          return searchRegex.test(row[field]);
        }
        return '';
      });
    });
    setFilteredConveniados(filteredRows);
  };

  useEffect(() => {
    setFilteredConveniados(conveniados);
  }, [conveniados]);

  const exportarAtivosCSV = async () => {
    setLoadingExportarCSV(true);
    try {
      const response = await Api.get(
        `/convenio/${idEstipulante}/relatorio/ativos`,
        { headers: { Authorization: authStr } },
      );
      if (response.data.ok) {
        setLinkBaixarCSV(response.data.output.arquivo);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Error ao buscar CSV', error.message);
    }
    setLoadingExportarCSV(false);
  };

  const valorTotal = conveniados.reduce(
    (acc, current) => acc + current.valor,
    0,
  );

  const desconto = Number(infosConvenio.desconto_fatura_porcentagem / 100);
  const valorTotalComDesconto = valorTotal - valorTotal * desconto;
  const valorDesconto = valorTotal - valorTotalComDesconto;

  // const exportarAtivosPDF = async () => {
  //   setLoadingExportarPDF(true);
  //   try {
  //     const response = await Api.get(
  //       ``, // TODO Adicionar rota correta
  //       { headers: { Authorization: authStr } },
  //     );
  //     if (response.data.ok) {
  //       setLinkBaixarPDF(response.data.output.arquivo);
  //     } else {
  //       throw new Error(response.data.message);
  //     }
  //   } catch (error) {
  //     alertError('Erro ao buscar PDF', error.message);
  //   }
  //   setLoadingExportarPDF(false);
  // };

  const columns = [
    {
      field: 'nome_segurado',
      headerName: 'Nome do funcionário',
      flex: 0.095,
      minWidth: 180,
      headerAlign: 'center',
      sortable: false,
      renderCell: params => (
        <Grid container component="span" style={{ flexWrap: 'nowrap' }}>
          <Grid item>
            <Tooltip
              placement="top"
              title="Informações adicionais"
              aria-label="Informações adicionais"
            >
              <IconButton
                onClick={() =>
                  history.push({
                    pathname: `/segurado/detalhe/${params.row.id_segurado}`,
                    state: { idEstipulante },
                  })
                }
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Link
              component="button"
              color="inherit"
              underline="always"
              onClick={() =>
                history.push({
                  pathname: `/segurado/detalhe/${params.row.id_segurado}`,
                  state: { idEstipulante },
                })
              }
            >
              {params.value}
            </Link>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'id_segurado',
      headerName: 'ID',
      flex: 0.02,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'qtd_dependentes_tipo_1',
      headerName: 'Qte. Dep',
      flex: 0.04,
      minWidth: 92,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'qtd_dependentes_tipo_2',
      headerName: 'Qte. Titular 2',
      flex: 0.04,
      minWidth: 120,
      headerAlign: 'center',
      sortable: false,
    },
    // { name: 'nome', title: 'NOME', getCellValue: row => row.nome_segurado },
    // { name: 'cpf', title: '#ID', getCellValue: row => row.id_segurado },
    // {
    //   name: 'qtd_dependentes_tipo_1',
    //   title: 'Qtd. Dep.',
    //   getCellValue: row => row.qtd_dependentes_tipo_1,
    // },
    // {
    //   name: 'qtd_dependentes_tipo_2',
    //   title: 'Qtd. Titular 2',
    //   getCellValue: row => row.qtd_dependentes_tipo_2,
    // },
    // { name: 'tipo', title: 'Ações', getCellValue: row => actions(row) },
  ];

  // const actions = conveniado => (
  //   <div>
  //     <Button
  //       outline
  //       className="mr-2"
  //       size="sm"
  //       color="primary"
  //       onClick={() =>
  //         history.push({
  //           pathname: `/segurado/${conveniado.id_segurado}`,
  //           state: { idEstipulante },
  //         })
  //       }
  //     >
  //       <FaSearch />
  //     </Button>
  //   </div>
  // );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={7}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        style={{ lineHeight: 1, textAlign: 'left' }}
                      >
                        Valor mensal:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h1" component="p" align="left">
                        {Number.isNaN(valorTotalComDesconto)
                          ? 'R$ - '
                          : formatValueToPrice(valorTotalComDesconto)}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h1" component="span">
                        |
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="h2" component="p">
                        <b>
                          {Number.isNaN(valorDesconto)
                            ? 'R$ - '
                            : formatValueToPrice(valorDesconto)}
                        </b>
                      </Typography>
                      <Typography style={{ fontSize: '12px' }}>
                        de desconto
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} md={3} container alignItems="center" /> */}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Grid container spacing={2} justifyContent="flex-end">
                {/* // TODO Implementar lógica para exportar o contrato no back-end */}
                {/* <Grid item>
                  <ContractButton />
                </Grid> */}
                <Grid item>
                  <DefaultButton
                    color="primary"
                    icon={<ReceiptIcon />}
                    onClick={() =>
                      history.push(`/convenio/${idEstipulante}/faturas`)
                    }
                  >
                    Faturas
                  </DefaultButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <HowToRegIcon style={{ height: '20px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h2">Funcionários</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <SearchTextField
                    value={searchText}
                    onChange={e =>
                      e.target.value
                        ? requestSearch(e.target.value)
                        : requestSearch('')
                    }
                    clearSearch={() => requestSearch('')}
                    toolTipTitle="Considere pontos e acentos na pesquisa"
                  />
                </Grid>
                <Grid item>
                  {linkBaixarCSV ? (
                    <a
                      href={`${process.env.REACT_APP_API_ERP}/${linkBaixarCSV}`}
                      rel="noopener noreferrer"
                      download={`${process.env.REACT_APP_API_ERP}/${linkBaixarCSV}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <ExportButton>Baixar CSV</ExportButton>
                    </a>
                  ) : (
                    <ExportButton
                      onClick={() => exportarAtivosCSV()}
                      tooltipTitle="Exceto com faturas pendentes"
                    >
                      {loadingExportarCSV ? (
                        <>
                          <Spinner size="sm" /> Carregando...
                        </>
                      ) : (
                        <>Exportar</>
                      )}
                    </ExportButton>

                    // <Button
                    //   className="mb-3"
                    //   color="success"
                    //   outline
                    //   onClick={() => exportarAtivosCSV()}
                    //   disabled={loadingExportarCSV}
                    // >
                    //   {loadingExportarCSV ? (
                    //     <>
                    //       <Spinner size="sm" /> Carregando...
                    //     </>
                    //   ) : (
                    //     <>
                    //       <FaFileExcel /> Carregar Relação em CSV
                    //     </>
                    //   )}
                    // </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataGridLayout
                rows={filteredConveniados}
                columns={columns}
                loading={loading}
                // onRowClick={params =>
                //   history.push({
                //     pathname: `/segurado/${params.row.id_segurado}`,
                //     state: { idEstipulante },
                //   })
                // }
              />
              {/* <SimpleTable
                height="320px"
                rows={conveniados}
                columns={columns}
                loading={loading}
              /> */}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* <Card>
        {/* <Row className="text-left mt-2">
          <Col>
            <SectionFormHeader>
              <FaUsers className="mr-2" />
              Conveniados
            </SectionFormHeader>
          </Col>
        </Row>
        <hr />

        <>
           <Row>
            <Col>
              <SimpleTable
                height="320px"
                rows={conveniados}
                columns={columns}
                loading={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormatValorFatura>
                <p>Valor Total: </p>
                <h2>
                  <FaFileInvoiceDollar />
                  <span>{formatValueToPrice(valorTotalComDesconto)}</span>
                </h2>
              </FormatValorFatura>
              <FormatValorFaturaDesconto>
                <small>
                  Valor do desconto: {formatValueToPrice(valorDesconto)}
                </small>
              </FormatValorFaturaDesconto>
            </Col>
          </Row>
           <Row className="mt-4">
            <Col md={12}>
              {linkBaixarCSV ? (
                <a
                  href={`${process.env.REACT_APP_API_ERP}/${linkBaixarCSV}`}
                  rel="noopener noreferrer"
                  download={`${process.env.REACT_APP_API_ERP}/${linkBaixarCSV}`}
                >
                  <Button className="mb-3" color="success" size="sm" outline>
                    <FaDownload /> Baixar CSV
                  </Button>
                </a>
              ) : (
                <Button
                  className="mb-3"
                  color="success"
                  outline
                  onClick={() => exportarAtivosCSV()}
                  disabled={loadingExportarCSV}
                >
                  {loadingExportarCSV ? (
                    <>
                      <Spinner size="sm" /> Carregando...
                    </>
                  ) : (
                    <>
                      <FaFileExcel /> Carregar Relação em CSV
                    </>
                  )}
                </Button>
              )}
            </Col>
             <Col md={6}>
            {linkBaixarPDF ? (
              <a
                href={`${process.env.REACT_APP_API_ERP}/${linkBaixarPDF}`}
                rel="noopener noreferrer"
                download={`${process.env.REACT_APP_API_ERP}/${linkBaixarPDF}`}
              >
                <Button className="mb-3" color="danger" size="sm" outline>
                  <FaDownload /> Baixar PDF
                </Button>
              </a>
            ) : (
              <Button
                className="mb-3"
                color="danger"
                outline
                onClick={() => exportarAtivosPDF()}
                disabled={loadingExportarPDF}
              >
                {loadingExportarPDF ? (
                  <>
                    <Spinner size="sm" /> Carregando...
                  </>
                ) : (
                  <>
                    <FaFilePdf /> Carregar Relação em PDF
                  </>
                )}
              </Button>
            )}
          </Col>
          </Row>
        </>
      </Card> */}
    </Grid>
  );
}

export default ResumoConveniados;
