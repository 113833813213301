import React from 'react';
import { Card } from 'reactstrap';
import ClinicaCadastroCliente from '../../components/Forms/ClinicaCadastroCliente';

export default function ClinicaCadastrarCliente(props) {
  return (
    <Card style={{ marginTop: '30px' }}>
      <ClinicaCadastroCliente
        mostraInfoValores
        mostraInfoPagamento
        {...props}
      />
    </Card>
  );
}
