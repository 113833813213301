import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import Api from '../../services/api';
import Page from './Page';

import theme from '../../styles/theme';
import { checkSafePassword } from '../../services/service';

function Controller(props) {
  const { user } = props;
  const authStr = `Bearer ${user.user.token}`;

  const [result, setResult] = useState({ loading: true, data: [] });

  useEffect(() => {
    checkSafePassword(props.user);
    getConvenios();
  }, []);

  const getConvenios = async () => {
    const response = await Api.get('convenios/', {
      headers: { Authorization: authStr },
    });
    setResult({ loading: false, data: response.data.output });
  };

  return (
    <ThemeProvider theme={theme}>
      <Page resultConvenio={result} />
    </ThemeProvider>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
