import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#28B867',
      dark: '#0A7A3B',
      contrastText: '#FAFAFA',
    },
    secondary: {
      light: '#12E0D4',
      main: '#0EADA3',
      medium: '#0A7A73',
      dark: '#064F4B',
      contrastText: '#FAFAFA',
    },
    error: {
      main: '#E8322A',
      dark: '#CB444A',
    },
    grey: {
      100: '#C9C9C9',
      200: '#878787',
      300: '#525151',
      400: '#3A3A3A',
      500: '#2D2D2D',
      A100: '#2D2D2D',
    },
    text: {
      primary: '#525151',
      secondary: '#878787',
      disabled: '#C9C9C9',
      hint: '#C9C9C9',
    },
    background: {
      paper: '#FAFAFA',
      default: '#F4F3EF',
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '1.875rem', // 30px
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.25rem', // 20px
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.125rem', // 18px
      fontWeight: 500,
    },
    h4: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    // subtitle1: {},
    // subtitle2: {},
    body1: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 700,
    },
    button: {
      fontSize: '0.75rem', // 12px
      fontWeight: 700,
      textTransform: 'inherit',
    },
    caption: {
      fontSize: '0,625rem', // 10px
      fontWeight: 400,
    },
    overline: {
      fontSize: '0.625rem', // 10px
      fontWeight: 400,
    },
  },
});

theme.props = {
  // MuiInputLabel: {
  //   // shrink: true,
  // },
  // MuiInput: {
  //   disableUnderline: true,
  // },
  MuiButton: {
    disableElevation: true,
  },
  MuiTooltip: {
    arrow: true,
  },
};

theme.overrides = {
  MuiPaper: {
    root: {
      border: `1px solid #C9C9C9`,
      boxSizing: 'border-box',
    },
    elevation1: {
      boxShadow: '7px -7px 10px 0 rgb(0, 0, 0, 0.05)',
    },
  },
  MuiButton: {
    root: {
      height: 40,
      textTransform: 'none',
      font: theme.typography.button,
    },
    // containedPrimary: {},
    containedSecondary: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.medium,
      },
    },
  },
  MuiInputLabel: {
    root: {
      // margin: '0 0 10px',
      // padding: 0,
      fontSize: '0.875rem',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.primary,
      '&$focused': {
        color: theme.palette.text.primary,
      },
      // '&$shrink': {
      //   transform: 'scale(1)',
      // },
    },
    focused: {},
  },
  MuiOutlinedInput: {
    root: {
      height: 39,
      // marginTop: 30,
      // top: 30,
      color: '#525151',
      '& $notchedOutline': {
        borderColor: '#C9C9C9',
      },
      '&:hover $notchedOutline': {
        borderColor: '#525151',
      },
      '&$focused $notchedOutline': {
        borderColor: '#525151',
      },
      // padding: '0 16px',
      // border: '1px solid #C9C9C9',
      // outline: '1px solid transparent',
      // borderRadius: 3,
      background: '#FFF',
      // '&:hover': {
      //   border: '1px solid #525151',
      //   outline: '1px solid transparent',
      // },
      // '&$focused': {
      //   border: '1px solid #525151',
      //   outline: '1px solid #525151',
      // },
    },
  },
  // MuiSelect: {
  //   selectMenu: {
  //     anchorOrigin: {
  //       vertical: 'bottom',
  //       horizontal: 'left',
  //     },
  //     transformOrigin: {
  //       vertical: 'top',
  //       horizontal: 'left',
  //     },
  //     getContentAnchorEl: null,
  //   },
  // },
  MuiDataGrid: {
    root: {
      border: 'none',
      fontWeight: 300,
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        '& .MuiSvgIcon-root': {
          height: 15,
          width: 15,
        },
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: theme.typography.fontWeightBold,
      },
      '& .MuiDataGrid-iconSeparator': {
        opacity: 0,
      },
      '& .MuiDataGrid-columnsContainer': {
        borderBottom: '1px solid #525151',
      },
      '& .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--withRenderer': {
        '& .MuiSvgIcon-root': {
          height: 15,
          width: 15,
        },
      },
      '& .MuiDataGrid-cell--textLeft': {
        textAlign: 'center',
      },
      '& .MuiDataGrid-cell--textRight': {
        textAlign: 'center',
      },
      '& .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer': {
        justifyContent: 'center',
      },
    },
  },
};

export default theme;
