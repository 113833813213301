import React from 'react';
import { Box, Container, IconButton } from '@material-ui/core';
import { FaArrowLeft, FaFileExcel, FaFileInvoiceDollar } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';

import ComissoesTable from '../../views/ComissaoCorretor/ComissoesTable';
import Filtros from '../../views/ComissaoCorretor/Filtros';

import MaterialButton from '../../components/Buttons/MaterialButton';

function Page(props) {
  const {
    comissoes,
    user,
    filtros,
    setFiltros,
    idDistribuidor,
    getExcelCorretor,
    loadingExcelCorretor,
  } = props;

  const idCorretor = user.corretor_representante;

  const history = useHistory();

  const handleBaixarExcel = async id => {
    const excel = await getExcelCorretor(id);
    window.open(`${process.env.REACT_APP_API_ERP}/${excel.arquivo}`, '_blank');
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" my="1rem">
        <IconButton
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          <FaArrowLeft size="1.1rem" />
        </IconButton>
        <Box
          ml="1.5rem"
          fontSize="2.2rem"
          fontWeight="100"
          display="flex"
          alignItems="center"
        >
          <FaFileInvoiceDollar />
          <Box ml="0.7rem">Comissões</Box>
        </Box>
      </Box>
      <hr />
      <Filtros
        loadingComissoes={comissoes.loading}
        filtros={filtros}
        setFiltros={setFiltros}
        idDistribuidor={idDistribuidor}
      />
      <ComissoesTable rows={comissoes} />
      <Box display="flex" justifyContent="flex-end" pb="2rem">
        <MaterialButton
          variant="contained"
          onClick={() => handleBaixarExcel(idCorretor)}
          disabled={!comissoes.data.length || loadingExcelCorretor}
          loading={loadingExcelCorretor}
          color="dashboard"
        >
          <>
            <FaFileExcel />
            &nbsp;Baixar excel
          </>
        </MaterialButton>
      </Box>
    </Container>
  );
}

export default Page;
