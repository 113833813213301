import React, { useState } from 'react';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import {
  FaExclamationTriangle,
  FaCalendarAlt,
  FaPlus,
  FaUsers,
  FaRegTrashAlt,
  FaWrench,
} from 'react-icons/fa';
import styled from 'styled-components';
import Api from '../../../services/api';
import {
  SectionFormHeader,
  MsgInputError,
  LabelForm,
  MsgErrorContent,
  BodyFormComponent,
} from '../../../views/styles';
import { msg } from '../../../json/msg';
import { alertError } from '../../Alerts/Alerts';
import {
  verificaIdadeMinima,
  verificaIdadeLimite,
} from '../../../services/service';

const SpanDp = styled.span`
  font-weight: 700;
  font-size: 10px;
`;

function InfoDependentes(props) {
  const {
    setDependentInput,
    dependentInput,
    dependentList,
    setDependentList,
    clientInput,
    disabled,
    testaCPF,
    productSelected,
    transformaUpperCase,
    formatDate,
    setDeletaDep,
  } = props;

  const [invalidDependentCpf, setInvalidDependentCpf] = useState(false);
  const [parentArr, setParentArr] = useState([
    'Cônjuge',
    'Pai',
    'Mãe',
    'Sogro(a)',
    'Filho(a)',
  ]);
  const [disabledParent, setDisabledParent] = useState(true);
  const [modalUpdateDependente, setModalUpdateDependente] = useState(false);
  const [dependentNameToUpdate, setDependentNameToUpdate] = useState();
  const [dependenteEditado, setDependenteEditado] = useState([{}]);
  const [dependentListCopy, setDependentListCopy] = useState([{}]);
  const [indexDependenteEditar, setIndexDependenteEditar] = useState();

  const renderDependentParent = _ =>
    parentArr.map((el, i) => <option key={i}>{el}</option>);

  const deleteDependent = index => {
    setDeletaDep(true);
    setDependentList(
      dependentList.filter((dep, i) => {
        return i !== index;
      }),
    );
  };

  const toggleModalUpdateUser = (index, name) => {
    setIndexDependenteEditar(index);
    setModalUpdateDependente(!modalUpdateDependente);
    setDependentNameToUpdate(name);
    setDependentListCopy(dependentList);
  };

  const closeModal = index => {
    if (dependentListCopy[index].cpfDependente == '') {
      alertError('O cpf do dependente deve ser informado.');
      return false;
    }

    if (dependentListCopy[index].nomeDependente == '') {
      alertError('O nome do dependente deve ser preenchido ');
      return false;
    }
    setModalUpdateDependente(!modalUpdateDependente);
  };

  const handleDependentOnChange = event => {
    let { name, value, type } = event.target;
    value = transformaUpperCase(value, type);

    if (name === 'cpfDependente') {
      if (testaCPF(value) === false) {
        setInvalidDependentCpf(true);
      } else {
        setInvalidDependentCpf(false);
      }
    }
    if (name === 'tipoDependente') {
      if (value === '2') {
        const newArr = [...parentArr,  'Pai', 'Mãe', 'Sogro(a)', 'Tio(a)', 'Primo(a)', 'Outro'];
        setParentArr(newArr);
        setDependentInput({ ...dependentInput, [name]: value });
        setDisabledParent(false);
      } else if (value === '1') {
        setParentArr(['Cônjuge', 'Filho(a)', 'Pai', 'Mãe', 'Sogro(a)']);
        setDisabledParent(false);
        setDependentInput({ ...dependentInput, [name]: value });
      } else {
        setParentArr(['Cônjuge', 'Pai', 'Mãe', 'Sogro(a)', 'Filho(a)']);
        setDependentInput({ [name]: value });
        setDisabledParent(true);
      }
    } else {
      setDependentInput({ ...dependentInput, [name]: value });
    }
  };
  // Checa se o CPF já está cadastrado
  const handleOnBlur = async event => {
    const cpf = event.target.value;
    const cpfValid = await Api.post('cliente-individual/checkCpf', {
      cpf,
    });
    if (!cpfValid.data.ok) {
      alertError(cpfValid.data.message);
    }
  };

  const handleEditDependent = (event, index) => {
    let { name, value, type } = event.target;
    value = transformaUpperCase(value, type);

    const newArr = [...dependentListCopy];
    newArr[index][name] = value;

    setDependentListCopy(newArr);
  };

  const saveDependent = _ => {
    if (
      dependentInput.tipoDependente !== '2' &&
      dependentInput.titular === ''
    ) {
      alertError('O Responsável deve ser selecionado.');
      return false;
    }

    // if(dependentInput.cpfDependente === "" && dependentInput.tipoDependente === "2" ){
    //   alertError("O cpf do dependente deve ser informado.");
    //   return false;
    // }

    if (dependentInput.cpfDependente === '') {
      alertError('O cpf do dependente deve ser informado.');
      return false;
    }

    if (dependentInput.nomeDependente === '') {
      alertError('O nome do dependente deve ser preenchido ');
      return false;
    }
    if (dependentInput.parentesco === '') {
      alertError('O parentesco do dependente deve ser adicionado');
      return false;
    }
    if (dependentInput.dataNascimentoDependente === '') {
      alertError('A idade do dependente deve ser informada');
      return false;
    }

    if (productSelected.limite_idade !== '0') {
      if (
        dependentInput.tipoDependente === '2' ||
        dependentInput.parentesco === 'Cônjuge'
      ) {
        const resultVerificaIdadeMinima = verificaIdadeMinima(
          dependentInput.dataNascimentoDependente,
          14,
        );

        const parentesco = dependentInput.parentesco === 'Cônjuge' ? 'Cônjuge' : 'Titular 2';

        if (!resultVerificaIdadeMinima) {
          alertError(`A idade do ${parentesco} deve ser maior ou igual a 14 anos.`);
          return false;
        }

        const resultVerificaIdadeLimite = verificaIdadeLimite(
          dependentInput.dataNascimentoDependente,
          70,
        );
        if (!resultVerificaIdadeLimite) {
          alertError(`A idade do ${parentesco} deve ser menor ou igual a 70 anos.`);
          return false;
        }
      }

      if (
        (dependentInput.parentesco === 'Pai' ||
         dependentInput.parentesco === 'Mãe' ||
         dependentInput.parentesco === 'Sogro(a)') &&
         dependentInput.tipoDependente === '1'
        ) {
        const resultVerificaIdadeMinima = verificaIdadeMinima(
          dependentInput.dataNascimentoDependente,
          14,
        );
        if (!resultVerificaIdadeMinima) {
          alertError('A idade dos pais e sogros deve ser maior ou igual a 14 anos.');
          return false;
        }

        const resultVerificaIdadeLimite = verificaIdadeLimite(
          dependentInput.dataNascimentoDependente,
          80,
        );
        if (!resultVerificaIdadeLimite) {
          alertError(
            'A idade dos pais e sogros deve ser menor ou igual a 80 anos.',
          );
          return false;
        }
      }

      if (
        dependentInput.parentesco === 'Filho(a)' &&
        dependentInput.tipoDependente === '1'
      ) {
        const resultVerificaIdadeLimite = verificaIdadeLimite(
          dependentInput.dataNascimentoDependente,
          21,
        );
        if (!resultVerificaIdadeLimite) {
          alertError('A idade dos filhos deve ser menor ou igual a 21 anos.');
          return false;
        }
      }
    }

    let dpArr = [];
    dependentInput.titular =
      dependentInput.titular === '' ? clientInput.nome : dependentInput.titular;
    dpArr = [...dependentList, dependentInput];

    setDeletaDep(true);
    setDependentList(dpArr);

    setDependentInput({
      tipoDependente: '',
      parentesco: '',
      cpfDependente: '',
      nomeDependente: '',
      dataNascimentoDependente: '',
      titular: '',
    });
  };

  const renderDependentesList = _ =>
    dependentList ? (
      dependentList.map((el, index) => {
        let tipoDep = '';

        if (el.tipoDependente === '2') {
          tipoDep = 'Titular 2';
        } else {
          tipoDep = 'Dep.';
        }

        if (Object.keys(el).length > 0) {
          return (
            <Row key={index} className="mt-4" style={{ color: '#444' }}>
              <Col lg={2}>
                <SpanDp style={{ fontWeight: '600' }}>
                  {el.nomeDependente}
                </SpanDp>
              </Col>
              <Col lg={2}>
                <SpanDp>
                  {el.parentesco} de {el.titular}
                </SpanDp>
              </Col>
              <Col lg={3}>
                <SpanDp>
                  {el.cpfDependente} | {formatDate(el.dataNascimentoDependente)}
                </SpanDp>
              </Col>
              {/* <Col lg={3}>
              <SpanDp>{formatDate(el.dataNascimentoDependente)}</SpanDp>
            </Col> */}
              <Col lg={3}>
                <SpanDp>{tipoDep}</SpanDp>
              </Col>
              <Col lg={2}>
                <Button
                  color="danger"
                  outline
                  size="sm"
                  onClick={() => deleteDependent(index)}
                >
                  <FaRegTrashAlt />
                </Button>
                &nbsp;
                <Button
                  color="warning"
                  outline
                  size="sm"
                  onClick={() =>
                    toggleModalUpdateUser(index, el.nomeDependente)
                  }
                >
                  <FaWrench />
                </Button>
                {modalUpdateDependente && (
                  <Modal
                    isOpen={modalUpdateDependente}
                    toggle={toggleModalUpdateUser}
                  >
                    <ModalHeader toggle={toggleModalUpdateUser}>
                      Editar dependente {dependentNameToUpdate.split(' ')[0]}
                    </ModalHeader>
                    <ModalBody>
                      <BodyFormComponent>
                        <Input
                          type="text"
                          placeholder="Nome do Dependente"
                          name="nomeDependente"
                          value={
                            dependentListCopy[indexDependenteEditar]
                              .nomeDependente
                          }
                          disabled={disabled.nomeDependente}
                          onChange={e =>
                            handleEditDependent(e, indexDependenteEditar)
                          }
                        />
                        <Input
                          className="form-control"
                          type="date"
                          name="dataNascimentoDependente"
                          value={
                            dependentListCopy[indexDependenteEditar]
                              .dataNascimentoDependente
                          }
                          disabled={disabled.dataNascimentoDependente}
                          onChange={e =>
                            handleEditDependent(e, indexDependenteEditar)
                          }
                        />
                        <InputMask
                          className="form-control"
                          mask="999.999.999-99"
                          type="text"
                          placeholder="CPF"
                          name="cpfDependente"
                          value={
                            dependentListCopy[indexDependenteEditar]
                              .cpfDependente
                          }
                          disabled={disabled.cpfDependente}
                          onChange={e =>
                            handleEditDependent(e, indexDependenteEditar)
                          }
                          onBlur={e => handleOnBlur(e)}
                        />
                        {invalidDependentCpf && (
                          <>
                            <br />
                            &nbsp;
                            <MsgInputError>
                              <FaExclamationTriangle />
                              &nbsp;{msg.cpf_invalid}
                            </MsgInputError>
                          </>
                        )}
                      </BodyFormComponent>
                      <Row>
                        <Col className="text-right">
                          <Button
                            size="sm"
                            color="success"
                            onClick={() => closeModal(indexDependenteEditar)}
                          >
                            Fechar
                          </Button>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                )}
              </Col>
            </Row>
          );
        }
      })
    ) : (
      <Col className="mt-4">
        <MsgErrorContent>Sem Dependentes adicionados</MsgErrorContent>
      </Col>
    );

  return (
    <>
      <hr />
      <Row className="section-header">
        <Col>
          <SectionFormHeader>Informações de Dependentes</SectionFormHeader>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <LabelForm>Tipo de dependente</LabelForm>
          <Input
            type="select"
            name="tipoDependente"
            value={dependentInput.tipoDependente}
            disabled={disabled.tipoDependente}
            onChange={e => handleDependentOnChange(e)}
          >
            <option value="">Escolha o tipo de dependente</option>
            <option value="1">Dependente</option>
            <option value="2">Titular 2</option>
          </Input>
        </Col>
        <Col lg={6}>
          <LabelForm>Parentesco</LabelForm>
          <Input
            type="select"
            disabled={disabledParent}
            name="parentesco"
            value={dependentInput.parentesco}
            onChange={e => handleDependentOnChange(e)}
          >
            <option value="">Selecione o parentesco</option>
            {renderDependentParent()}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <LabelForm>CPF</LabelForm>
          <InputMask
            className="form-control"
            mask="999.999.999-99"
            type="text"
            placeholder="Informe o CPF"
            name="cpfDependente"
            value={dependentInput.cpfDependente}
            disabled={disabled.cpfDependente}
            onChange={e => handleDependentOnChange(e)}
            onBlur={e => handleOnBlur(e)}
          />
          {invalidDependentCpf && (
            <>
              <br />
              &nbsp;
              <MsgInputError>
                <FaExclamationTriangle />
                &nbsp;{msg.cpf_invalid}
              </MsgInputError>
            </>
          )}
        </Col>
        <Col lg={4}>
          <LabelForm>Nome do Dependente</LabelForm>
          <Input
            type="text"
            placeholder="Informe o nome do dependente"
            name="nomeDependente"
            value={dependentInput.nomeDependente}
            disabled={disabled.nomeDependente}
            onChange={e => handleDependentOnChange(e)}
          />
        </Col>
        <Col lg={4}>
          <LabelForm>Data de Nascimento</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaCalendarAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type="date"
              name="dataNascimentoDependente"
              value={dependentInput.dataNascimentoDependente}
              disabled={disabled.dataNascimentoDependente}
              onChange={e => handleDependentOnChange(e)}
            />
          </InputGroup>
        </Col>
      </Row>
      {dependentInput.tipoDependente === '1' && (
        <Row className="mt-4">
          <Col lg={4}>
            <LabelForm>Responsável</LabelForm>
            <Input
              type="select"
              name="titular"
              onChange={e => handleDependentOnChange(e)}
            >
              <option value="">Selecione</option>
              <option value={clientInput.nome}>{clientInput.nome}</option>
              {dependentList.map((el, i) => {
                if (el.nomeDependente !== undefined) {
                  if (el.tipoDependente == 2) {
                    return (
                      <option key={i} value={el.nomeDependente}>
                        {el.nomeDependente}
                      </option>
                    );
                  }
                }
              })}
            </Input>
          </Col>
        </Row>
      )}
      <Row className="mt-4">
        <Col>
          <Button
            color="success"
            size="sm"
            name="saveDependenteBtn"
            disabled={disabled.saveDependenteBtn}
            onClick={() => saveDependent()}
          >
            <FaPlus />
            &nbsp;&nbsp;Adicionar Dependente
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <LabelForm>
            <FaUsers /> Lista de Dependentes
          </LabelForm>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="pb-4">{renderDependentesList()}</Card>
        </Col>
      </Row>
    </>
  );
}

export default InfoDependentes;
