import React from 'react';
import { FaPeopleCarry } from 'react-icons/fa';
import { Row, Col, Input } from 'reactstrap';

function MenuAssessorias(props) {
  const { handleOnChange, nivelUsuario, usuario } = props;
  return (
    <>
      <Row>
        <Col lg={4}>
          <h5>
            <FaPeopleCarry />
            &nbsp;Selecione um distribuidor
          </h5>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Input
            type="select"
            name="idAssessoriaSelecionada"
            onChange={e => {
              handleOnChange(e);
            }}
          >
            <option value="">Selecione</option>
            {props.assessorias.output.map((el, i) => {
              if (nivelUsuario == 2 || nivelUsuario == 5) {
                if (el.id_usuario == usuario)
                  return (
                    <option key={i} value={el.id}>
                      {el.nome_fantasia ? el.nome_fantasia : '(Sem nome)'} /&nbsp;
                      {el.nome}
                    </option>
                  );
              } else {
                return (
                  <option key={i} value={el.id}>
                    {el.nome_fantasia ? el.nome_fantasia : '(Sem nome)'} /&nbsp;
                    {el.nome}
                  </option>
                );
              }
            })}
          </Input>
        </Col>
      </Row>
    </>
  );
}

export default MenuAssessorias;
