import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Badge,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  FaHeartbeat,
  FaCreditCard,
  FaCalendarAlt,
  FaExclamationTriangle,
} from 'react-icons/fa';
import Switch from '@material-ui/core/Switch';
import {
  SectionFormHeader,
  LabelForm,
  PrependInputLabel,
  MsgInputError,
} from '../../../views/styles';
import Api from '../../../services/api';
import { verificaCodigoDesconto, formatDate } from '../../../services/service';

function InfoPagamento(props) {
  const {
    clientPayment,
    setClientPayment,
    setDisabled,
    disabled,
    setConvenio,
    convenioList,
    convenio,
    setupInputConvenios,
    token,
    setProducts,
    products,
    edit,
    setProductSelected,
    setIdEstipulante,
    setNomeEstipulante,
    clientInput,
  } = props;
  const [invalidCupom, setInvalidCupom] = useState();

  // console.log('clientInput', clientInput.assessoria);

  const toggleTaxaAdesao = e => {
    setClientPayment({ ...clientPayment, taxaAdesao: e.target.checked });
  };

  useEffect(() => {
    if (clientInput.assessoria > 0) {
      getProduct(clientInput.assessoria);
    }
  }, [clientInput.assessoria]);

  const handleClientPayment = async event => {
    const { name, value } = event.target;

    if (name === 'forma_pagamento') {
      if (value === 'cartao-credito') {
        setDisabled({ dia_proximos_pagamentos: true, qtd_parcelas: true });
        setConvenio(false);
      } else if (value === 'debito-em-folha') {
        setDisabled({
          dia_proximos_pagamentos: true,
          qtd_parcelas: true,
          codigo_desconto: true,
        });
        setConvenio(true);
        setupInputConvenios();
      } else {
        setConvenio(false);
        setDisabled({
          dia_proximos_pagamentos: false,
          qtd_parcelas: false,
          codigo_desconto: false,
        });
      }
    }
    if (name === 'id_produto' && value !== '') {
      getPrecoProduto(value);
    } else {
      getPrecoProduto(clientPayment.id_produto);
    }
    if (name === 'estipulante') {
      const id = value.split('-')[0];
      const nome = value.split('-')[1];
      await setIdEstipulante(id);
      await setNomeEstipulante(nome);
    }
    if (name === 'codigo_desconto') {
      const result = await verificaCodigoDesconto(
        value,
        setInvalidCupom,
        token,
      );
      clientPayment.validade_codigo_desconto = result;
    }
    setClientPayment({ ...clientPayment, [name]: value });
  };

  const getProduct = async idAssessoria => {
    const fetchProducts = await Api.get(`produtos-assessoria/${idAssessoria}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    let productsArr = [];
    for (const i in fetchProducts.data.output) {
      if (fetchProducts.data.output[i].plano_empresarial == 0) {
        const tmpObj = {
          name: fetchProducts.data.output[i].nome,
          id: fetchProducts.data.output[i].id,
          price: fetchProducts.data.output[i].preco,
          priceDep1: fetchProducts.data.output[i].preco_dependente_tipo_1,
          priceDep2: fetchProducts.data.output[i].preco_dependente_tipo_2,
          limite_idade: fetchProducts.data.output[i].limite_idade,
          plano_empresarial: fetchProducts.data.output[i].plano_empresarial,
          produto_adicional: fetchProducts.data.output[i].produto_adicional,
        };

        productsArr = [...productsArr, tmpObj];
      }
    }

    setClientPayment({
      ...clientPayment,
      id_produto: productsArr[productsArr.length - 1].id,
    });
    setProducts(productsArr);
    getPrecoProduto(productsArr[productsArr.length - 1].id, productsArr); // ID do Plano Diamante. Quando carrega a página.

    const queryParams = new URLSearchParams(window.location.search);
    const idProduto = queryParams.get('idProduto');

    if (idProduto && idProduto > 0) {
      console.log('idProduto', idProduto);
      setClientPayment({ ...clientPayment, id_produto: idProduto });
    }
  };

  const getPrecoProduto = (idPlan, inicialProductArr) => {
    let productSelected = [];

    if (inicialProductArr) {
      productSelected = inicialProductArr.find(el => el.id === idPlan);
    } else {
      productSelected = products.find(el => el.id == idPlan);
    }
    setProductSelected(productSelected);
  };

  return (
    <>
      <hr className="mt-4" />
      {!edit && (
        <>
          <Row className="section-header">
            <Col>
              <SectionFormHeader>Informações de Pagamento</SectionFormHeader>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <LabelForm>Produto</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaHeartbeat />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  name="id_produto"
                  value={clientPayment.id_produto}
                  onChange={e => handleClientPayment(e)}
                  disabled={products.length > 0 ? false : true}
                >
                  {products.map((el, i) => (
                    <option key={i} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Forma de Pagamento *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCreditCard />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  required
                  name="forma_pagamento"
                  value={clientPayment.forma_pagamento}
                  onChange={e => handleClientPayment(e)}
                >
                  <option value="">Selecione</option>
                  <option value="carne-impresso">Carnê</option>
                  <option value="cartao-credito">Cartão de Crédito</option>
                  <option value="debito-em-folha">Débito em Folha</option>
                </Input>
              </InputGroup>
            </Col>
            {convenio && (
              <Col lg={4}>
                <LabelForm>Convênio</LabelForm>
                <Input
                  type="select"
                  name="estipulante"
                  onChange={e => handleClientPayment(e)}
                >
                  <option>Selecione</option>
                  {convenioList &&
                    convenioList.map((el, i) => {
                      return (
                        <option key={i} value={`${el.id}-${el.nome}`}>
                          {el.nome}
                        </option>
                      );
                    })}
                </Input>
              </Col>
            )}
            <Col lg={4}>
              <LabelForm>Primeiro Pagamento *</LabelForm>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCalendarAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-control"
                  required
                  type="date"
                  placeholder="Data Primeiro Pagamento"
                  name="primeiro_pagamento"
                  value={clientPayment.primeiro_pagamento}
                  onChange={e => handleClientPayment(e)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={4}>
              <LabelForm>Dia do Pagamento *</LabelForm>
              <InputGroup>
                <Input
                  type="select"
                  name="dia_proximos_pagamentos"
                  value={clientPayment.dia_proximos_pagamentos}
                  disabled={disabled.dia_proximos_pagamentos}
                  onChange={e => handleClientPayment(e)}
                >
                  <option value="">Selecione o dia para o pagamento</option>
                  <option value="10">Dia 10</option>
                  <option value="20">Dia 20</option>
                  <option value="30">Dia 30</option>
                </Input>
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Quantidade de Parcelas *</LabelForm>
              <InputGroup>
                <Input
                  type="select"
                  name="qtd_parcelas"
                  value={clientPayment.qtd_parcelas}
                  disabled={disabled.qtd_parcelas}
                  onChange={e => handleClientPayment(e)}
                >
                  <option>12</option>
                  <option>11</option>
                  <option>10</option>
                  <option>9</option>
                  <option>8</option>
                  <option>7</option>
                  <option>6</option>
                  <option>5</option>
                  <option>4</option>
                  <option>3</option>
                  <option>2</option>
                  <option value={1}>1 (25% de desconto à vista)</option>
                </Input>
                {/* <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <PrependInputLabel><FaCalendarAlt />PARCELAS</PrependInputLabel>
                  </InputGroupText>
                </InputGroupAddon> */}
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaHeartbeat />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={4}>
              <LabelForm>Código de Desconto &nbsp;</LabelForm>
              <Badge
                style={{
                  textDecoration: 'none',
                  color: '#ffffff',
                  cursor: 'help',
                }}
                id="UncontrolledTooltipExample"
                color="secondary"
              >
                {' '}
                Info
              </Badge>
              <UncontrolledTooltip
                placement="right"
                target="UncontrolledTooltipExample"
              >
                Código Aplicado Para Convênios
              </UncontrolledTooltip>
              <Input
                type="text"
                disabled={disabled.codigo_desconto}
                placeholder="Insira o Código de Desconto"
                name="codigo_desconto"
                value={clientPayment.clientDiscount}
                onChange={e => handleClientPayment(e)}
              />
              {invalidCupom && (
                <>
                  <br />
                  &nbsp;
                  <MsgInputError>
                    <FaExclamationTriangle />
                    &nbsp;Cupom não encontrado
                  </MsgInputError>
                </>
              )}
            </Col>
          </Row>
          { products.length > 0 ? (
          <Row className="mt-4">
            <Col lg={4}>
              <LabelForm>Taxa de adesão (R${clientPayment.valorTaxaAdesao}) &nbsp;</LabelForm>
              <Switch
                checked={clientPayment.taxaAdesao}
                onChange={toggleTaxaAdesao}
                name="taxaAdesao"
                size="small"
                color="secondary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <InputGroup>
                <Input
                  type="select"
                  name="valorTaxaAdesao"
                  value={clientPayment.valorTaxaAdesao}
                  // disabled={disabled.valorTaxaAdesao}
                  onChange={e => handleClientPayment(e)}
                >
                  <option value="30">30</option>
                  <option value="25">25</option>
                  <option value="20">20</option>
                  <option value="15">15</option>
                  <option value="10">10</option>
                </Input>
              </InputGroup>
            </Col>
          </Row>
          ) : null }
        </>
      )}
    </>
  );
}

export default InfoPagamento;
