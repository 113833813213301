import React, { useState, useEffect } from 'react';
import Page from './Page';
import api from '../../services/api';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Api from '../../services/api';
import { checkSafePassword } from '../../services/service';
import { alertSuccess } from '../../components/Alerts/Alerts';

function Controller(props) {

  const { token } = props.user.user;
  const authStr = `Bearer ${token}`;
  let history = useHistory();

  const [ produtos, setProdutos ] = useState([]);
  const [ cadastroProduto, setCadastroProduto ] = useState({
    nome: "",
    descricao: "",
    precoTitular: "",
    precoDep1: "",
    precoDep2: "",
    limiteIdade: "",
    id_grupo_vendas: "",
    contrato: ""
  });
  const [ text, setText ] = useState('');
  const [ checkedItems, setCheckedItems ] = useState({});
  const [ terceiros, setTerceiros ] = useState([{}]);
  const [ gruposVenda, setGruposVenda ] = useState([{}]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect( () => {
    checkSafePassword(props.user);
    getProdutos();
    getTerceiros();
    getGruposVenda();
  }, [] );

  const getTerceiros = async _ => {
    const request = await Api.get( "/terceiros/", { headers: { Authorization: authStr }});
    if(request.data.ok){
      setTerceiros(request.data.output);
    }
  }

  const getProdutos = async _ => {
    const responseProdutos = await api.get('todos-os-produtos/', { headers: { Authorization: authStr }});
    setProdutos(responseProdutos.data);
  }

  const getGruposVenda = async _ => {

    let fetchGrupoVendas = await Api.get('/grupovendas', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
    if(fetchGrupoVendas.data.ok) {
        setGruposVenda(fetchGrupoVendas.data.output);
    }
  }

  const salvarProdutos = async _ => {

    let arrBeneficios = [];
    Object.keys(checkedItems).map(checkedItem => {
      arrBeneficios.push(checkedItem);
    })

    cadastroProduto.arrBeneficios = arrBeneficios;
    cadastroProduto.ativo = 1;
    // cadastroProduto.limite_idade = 1;
    cadastroProduto.contrato = text;

    const result = await Api.post('/produto', cadastroProduto, { headers: { Authorization: authStr }});
    console.log("Result - ",result);
    if(result.data.ok){
      setModal(false);
      getProdutos();
      alertSuccess('Produto criado sucesso');
    }else{
      alert(result.data.message);
    }

  }

  const handleOnChange = e => {
    const { name, value } = e.target;
    setCadastroProduto({ ...cadastroProduto, [name]: value });
  }

  const handleCheck = e => {
    setCheckedItems({...checkedItems, [e.target.name] : e.target.checked });
  }

  return (
    <>
      {
        (produtos)&&
        <Page
          produtos={produtos}
          history={history}
          terceiros={terceiros}
          cadastroProduto={cadastroProduto}
          setCadastroProduto={setCadastroProduto}
          handleCheck={handleCheck}
          handleOnChange={handleOnChange}
          salvarProdutos={salvarProdutos}
          text={text}
          setText={setText}
          gruposVenda={gruposVenda}
          modal={modal}
          setModal={setModal}
          toggle={toggle}
          />
      }
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);
