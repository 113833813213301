import React from 'react';
import { Box, Container, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Skeleton } from '@material-ui/lab';
import { ListaUsuarios } from '../../views/Usuarios/ListaUsuarios';
import NoContent from '../../components/Card/NoContent';

const useStyles = makeStyles(() => ({
  container: {
    padding: '40px 30px 30px',
    '& > .MuiBox-root': {
      marginBottom: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  'article-header': {
    marginBottom: '1.875em',
  },
  'article-header--title': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gridColumnGap: '1em',
  },
  content: {
    padding: 0,
  },
}));

export const Page = props => {
  const { accounts } = props;
  const classes = useStyles();
  return (
    <Container
      component="article"
      role="main"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <header className={classes['article-header']}>
        <Box className={classes['article-header--title']} itemProp="title">
          <SupervisorAccountIcon />
          <Typography variant="h1">Usu&#225;rios</Typography>
        </Box>
      </header>

      {accounts?.error ? (
        <NoContent hasImage text={accounts?.error} />
      ) : (
        <Box component={Paper}>
          {accounts?.loading ? (
            <Box p={3}>
              <Box mb={1}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={200}
                  height={40}
                />
              </Box>
              <Box mb={1}>
                <Skeleton variant="rect" animation="wave" height={56} />
              </Box>
              <Box mb={1}>
                <Skeleton variant="rect" animation="wave" height={100} />
              </Box>
              <Box align="right">
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={200}
                  height={52}
                />
              </Box>
            </Box>
          ) : (
            <Box p={3}>
              <ListaUsuarios {...props} />
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};
