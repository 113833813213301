import React from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Skeleton } from '@material-ui/lab';

export function SearchTextField({
  classes,
  label,
  name,
  value,
  onChange,
  clearSearch,
  toolTipTitle = '',
  ...props
}) {
  return (
    <Tooltip title={toolTipTitle} aria-label={toolTipTitle}>
      <TextField
        variant="outlined"
        fullWidth
        label={label}
        // type="search"
        name={name}
        placeholder="Pesquisar..."
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ fontSize: '0.875rem' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                title="limpar"
                aria-label="Limpar"
                size="small"
                style={{ visibility: value ? 'visible' : 'hidden' }}
                onClick={clearSearch}
                edge="end"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Tooltip>
  );
}

export function DefaultFormTextField({
  classes,
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  onBlur,
  style,
  required,
  disabled,
  hasError = false,
  errorMessage = '',
  isOptional = false,
  inputMode = 'text',
  loading = false,
  type = 'text',
  ...props
}) {
  return (
    <>
      {loading ? (
        <>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" width="100%" height={40} />
        </>
      ) : (
        <div style={{ textAlign: 'left', ...style }}>
          <InputLabel htmlFor={id} error={hasError} disabled={disabled}>
            {label}{' '}
            {isOptional && (
              <span style={{ fontSize: '0.75em' }}>(Opcional)</span>
            )}
          </InputLabel>
          <TextField
            {...props}
            type={type}
            variant="outlined"
            fullWidth
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            disabled={disabled}
            error={hasError}
            helperText={errorMessage}
            inputProps={{
              inputMode,
            }}
          />
        </div>
      )}
    </>
  );
}

export function FilterTextField({
  classes,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  options,
  ...props
}) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      select
      label={label}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterListIcon style={{ fontSize: '0.875rem' }} />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      {...props}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function SelectTextField({
  classes,
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  onBlur,
  required,
  options,
  style,
  loading = false,
  hasError = false,
  errorMessage = '',
  inputRef = {},
  disabled,
  ...props
}) {
  return (
    <>
      {loading ? (
        <Box>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" width="100%" height={40} />
        </Box>
      ) : (
        <div style={{ textAlign: 'left', ...style }}>
          <InputLabel htmlFor={id} error={hasError} disabled={disabled}>
            {label}
          </InputLabel>
          <TextField
            variant="outlined"
            fullWidth
            select
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            options={options}
            error={hasError}
            helperText={errorMessage}
            inputRef={inputRef}
            disabled={disabled}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
            {...props}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
    </>
  );
}

export function CPFTextField({
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  onBlur,
  style,
  required,
  loading = false,
  hasError = false,
  errorMessage = '',
  inputRef = {},
  disabled
}) {
  return (
    <>
      {loading ? (
        <Box>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" width="100%" height={40} />
        </Box>
      ) : (
        <div style={{ textAlign: 'left', ...style }}>
          <InputLabel htmlFor={id} error={hasError} disabled={disabled}>
            {label}
          </InputLabel>
          <InputMask
            mask="999.999.999-99"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maskChar=""
            disabled={disabled}
          >
            {inputProps => (
              <TextField
                variant="outlined"
                fullWidth
                id={id}
                name={name}
                placeholder={placeholder}
                required={required}
                error={hasError}
                helperText={errorMessage}
                inputProps={{
                  ...inputProps,
                  inputMode: 'numeric',
                  maxLength: 14,
                }}
                inputRef={inputRef}
                disabled={disabled}
              />
            )}
          </InputMask>
        </div>
      )}
    </>
  );
}

export function CNPJTextField({
  classes,
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  style,
  required,
  disabled,
}) {
  return (
    <div style={{ textAlign: 'left', ...style }}>
      <InputLabel htmlFor={id}  disabled={disabled}>{label}</InputLabel>
      <InputMask mask="99.999.999/9999-99" value={value} onChange={onChange}  disabled={disabled}>
        {() => (
          <TextField
            variant="outlined"
            fullWidth
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
          />
        )}
      </InputMask>
    </div>
  );
}

export function CEPTextField({
  classes,
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  onBlur,
  style,
  required,
  loading = false,
  hasError = false,
  errorMessage = '',
  inputRef = {},
  disabled,
}) {
  return (
    <>
      {loading ? (
        <Box>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" width="100%" height={40} />
        </Box>
      ) : (
        <div style={{ textAlign: 'left', ...style }}>
          <InputLabel htmlFor={id} error={hasError} disabled={disabled}>
            {label}
          </InputLabel>
          <InputMask
            mask="99999-999"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maskChar=""
            disabled={disabled}

          >
            {inputProps => (
              <TextField
                variant="outlined"
                fullWidth
                id={id}
                name={name}
                placeholder={placeholder}
                required={required}
                error={hasError}
                helperText={errorMessage}
                inputProps={{
                  ...inputProps,
                  inputMode: 'numeric',
                  maxLength: 9,
                }}
                inputRef={inputRef}
                disabled={disabled}

              />
            )}
          </InputMask>
        </div>
      )}
    </>
  );
}

export function PhoneTextField({
  classes,
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  onBlur,
  style,
  required,
  isOptional = false,
  loading = false,
  hasError = false,
  errorMessage = '',
  inputRef = {},
  disabled,
}) {
  return (
    <>
      {loading ? (
        <Box>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" width="100%" height={40} />
        </Box>
      ) : (
        <div style={{ textAlign: 'left', ...style }}>
          <InputLabel htmlFor={id} error={hasError} disabled={disabled}>
            {label}{' '}
            {isOptional && (
              <span style={{ fontSize: '0.75em' }}>(Opcional)</span>
            )}
          </InputLabel>
          <InputMask
            mask="(99) 99999-9999"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maskChar=""
            disabled={disabled}
          >
            {inputProps => (
              <TextField
                id={id}
                name={name}
                variant="outlined"
                type="tel"
                error={hasError}
                helperText={errorMessage}
                inputProps={{ ...inputProps, inputMode: 'tel', maxLength: 15 }}
                fullWidth
                placeholder={placeholder}
                required={required}
                inputRef={inputRef}
                disabled={disabled}
              />
            )}
          </InputMask>
        </div>
      )}
    </>
  );
}

export function DateTextField({
  classes,
  id,
  name,
  label,
  value,
  placeholder = '',
  onChange,
  onBlur,
  style,
  required,
  isOptional = false,
  loading = false,
  hasError = false,
  errorMessage = '',
  inputRef = {},
  disabled,
}) {
  return (
    <>
      {loading ? (
        <Box>
          <Skeleton height={14} width="50%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" width="100%" height={40} />
        </Box>
      ) : (
        <div style={{ textAlign: 'left', ...style }}>
          <InputLabel htmlFor={id} error={hasError} disabled={disabled}>
            {label}{' '}
            {isOptional && (
              <span style={{ fontSize: '0.75em' }}>(Opcional)</span>
            )}
          </InputLabel>
          <InputMask
            mask="99/99/9999"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maskChar=""
            disabled={disabled}
          >
            {inputProps => (
              <TextField
                id={id}
                name={name}
                variant="outlined"
                type="tel"
                error={hasError}
                helperText={errorMessage}
                inputProps={{ ...inputProps, inputMode: 'tel', maxLength: 15 }}
                fullWidth
                placeholder={placeholder}
                required={required}
                inputRef={inputRef}
                disabled={disabled}
              />
            )}
          </InputMask>
        </div>
      )}
    </>
  );
}
