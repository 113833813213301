import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Card, Col, Row, Button, Spinner } from 'reactstrap';
import { FaUsers, FaSearch, FaRegWindowClose, FaPlus } from 'react-icons/fa';

import { CardComponent } from '../styles';

import SimpleTable from '../../components/TablesGrid/SimpleTable';
import Loading from '../../components/Loading/Loading';

import CadastrarAtendente from './CadastrarAtendente';

function AtendentesLista(props) {
  const history = useHistory();

  const { atendentes, loadingChangeStatus, submitChangeStatus, openModal, toggleModalExclusao, getAtendentes } =
    props;

  const [atendentesEditados, setAtendentesEditados] = useState([]);

  const [modalAtendente, setModalAtendente] = useState(false);
  const toggleModalAtendente = _ => {
    setModalAtendente(!modalAtendente);
  };

  useEffect(() => {
    setAtendentesEditados(atendentes.data);
  }, [atendentes]);

  const columns = [
    { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
    { name: 'status', title: 'Status', getCellValue: row => changeStatus(row) },
    { name: 'tipo', title: 'Funções', getCellValue: row => actions(row) },
  ];

  const actions = atendente => (
    <div>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="primary"
        onClick={() => openModal(atendente)}
      >
        <FaSearch />
      </Button>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="danger"
        onClick={() =>
          toggleModalExclusao(atendente)
        }
      >
        <FaRegWindowClose />
      </Button>
    </div>
  );

  const changeStatus = atendente => (
    <div className="text-center">
      {atendente.status == 1 ? 'Habilitado' : 'Desabilitado'}
    </div>
  );

  return (
    <Card>
      <CardComponent>
        <Row style={{marginTop: "0px"}}>
          <Col className="header-content text-left" md={9}>
            <h2>
              <FaUsers />
              &nbsp;&nbsp;Atendentes
            </h2>
          </Col>
          <Col md={3}>
          <Button
            color="danger"
            size="sm"
            onClick={toggleModalAtendente}
          >
            <FaPlus />
            &nbsp; Cadastrar Novo Atendente
          </Button>
          {modalAtendente && (
            <CadastrarAtendente
              clinicas={props.clinicas}
              modalAtendente={modalAtendente}
              toggleModalAtendente={toggleModalAtendente}
              gruposVenda={props.gruposVenda}
              clinica={props.clinica}
              getAtendentes={getAtendentes}
            />
          )}
          </Col>
        </Row>
      </CardComponent>
      <hr />
      <Row>
        <Col>
          {atendentes.loading ? (
            <Loading />
          ) : (
            <SimpleTable
              height="320px"
              rows={atendentesEditados}
              columns={columns}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default AtendentesLista;
