import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';

import Api from '../../services/api';
import Page from './Page';

import theme from '../../styles/theme';

function Controller(props) {
  const history = useHistory();
  const { id } = useParams();

  const [resultConvenio, setResultConvenio] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInfosConvenio();
  }, []);

  const getInfosConvenio = async _ => {
    const request = await Api.get(`convenio/${id}`, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    setResultConvenio(request.data.output);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    const response = await Api.post('convenio/editar', resultConvenio, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });

    if (response.data.ok) {
      setLoading(false);
      window.history.back();
    }
  };

  const handleOnChange = event => {
    const { name, value } = event.target;
    setResultConvenio({ ...resultConvenio, [name]: value });
  };

  return (
    <ThemeProvider theme={theme}>
      {resultConvenio && (
        <Page
          resultConvenio={resultConvenio}
          setResultConvenio={setResultConvenio}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          history={history}
          // token={props.user.user.token}
        />
      )}
    </ThemeProvider>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
