import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { FaSync } from 'react-icons/fa';
import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';

function ResgatarFaturasAsaas(props) {

  const [ loading, setLoading ] = useState(false);

  const ResgatarFaturasAsaas = async _ => {

    setLoading(true);
    const { token } = props.props.user.user;

    const authStr = `Bearer ${token}`;
    const result = await Api.get(`importacao-asaas/${props.idClienteAsaas}/apolice/${props.idApolice}`, { headers: { Authorization: authStr }} );

    if(result.data.ok){
      window.location.reload();
    }else{
      alertError(result.data.msg);
    }
    setLoading(false);
  }

  return (
    <div>
      <ToastContainer />
      <Button
        color="secondary"
        outline
        size="sm"
        onClick={() => ResgatarFaturasAsaas()}
        disabled={loading}
      >
        {
          loading
          ?
            <>
              <Spinner color="primary" size="sm" className="mr-2"/>
              <span>Resgatando Faturas...</span>
            </>
          :
            <>
              <FaSync className="mr-2"/>
              <span>Resgatar Faturas</span>
            </>
        }
      </Button>
    </div>
  )
}

export default ResgatarFaturasAsaas;
