import React, { useState } from 'react';
import { Col, Row, Button, Card } from 'reactstrap';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import SimpleTable from '../../components/TablesGrid/SimpleTable';
import MaterialSelect from '../../components/Inputs/MaterialSelect';
import Loading from '../../components/Loading/Loading';

export default function ListaClinicas(props) {
  const { clinicas, loading, toggle } = props;

  const history = useHistory();

  const columns = [
    {
      name: 'id',
      title: 'Id',
      getCellValue: row => row.id_clinica,
    },
    { name: 'cpf', title: 'Cnpj', getCellValue: row => row.cnpj },
    { name: 'nome', title: 'Nome', getCellValue: row => row.nome_fantasia },
    {
      name: 'tipo_clinica',
      title: 'Tipo',
      getCellValue: row => (row.tipo == 'master' ? 'Master' : 'Afiliado'),
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: row => (row.status == 1 ? 'Ativa' : 'Inativa'),
    },
    { name: '', title: '', getCellValue: row => actions(row) },
  ];

  const [filteredClinicas, setFilteredClinicas] = useState(clinicas);

  const actions = row => {
    return (
      <div className="btn-group">
        <Button
          size="sm"
          color="primary"
          outline
          onClick={() => history.push(`/clinica/${row.id_clinica}`)}
        >
          <FaSearch />
        </Button>
        &nbsp;
      </div>
    );
  };

  const handleChangeStatus = value => {
    if (value == -1) {
      setFilteredClinicas();
      return;
    }
    setFilteredClinicas(clinicas.filter(clinica => clinica.status == value));
  };

  return (
    <Col md={12}>
    <Card className="my-4">
      <Row className="px-3 mt-3 justify-content-between align-items-center">
        <Button color="success" size="sm" outline onClick={toggle}>
          <FaPlus />
          &nbsp;&nbsp;Cadastrar Nova Clínica
        </Button>
        {/* <MaterialSelect
          label="Status"
          handleChange={handleChangeStatus}
          options={[
            { value: -1, label: 'Todos' },
            { value: 1, label: 'Ativa' },
            { value: 0, label: 'Inativa' },
          ]}
        /> */}
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          {!loading ? (
            <SimpleTable
              height="500px"
              rows={filteredClinicas}
              columns={columns}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </Card>
    </Col>
  );
}
