import React, { useState, useCallback } from 'react';
// import {
//   // Button,
//   Card,
//   Col,
//   Row,
// } from 'reactstrap';
// import {
//   FaDownload,
//   FaFileExcel,
//   FaFileInvoiceDollar,
//   FaLink,
// } from 'react-icons/fa';
import { connect } from 'react-redux';

import { Grid, Typography, Button, Paper } from '@material-ui/core';

import ReceiptIcon from '@material-ui/icons/Receipt';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { makeStyles } from '@material-ui/core/styles';
// import { OverflowContainer, SectionFormHeader } from '../styles';
import { formatDate } from '../../services/service';
// import SimpleTable from '../../components/TablesGrid/SimpleTable';
import formatValueToPrice from '../../utils/format';
import { alertError } from '../../components/Alerts/Alerts';
import api from '../../services/api';

import { RegisterButton } from '../../components/Buttons/Buttons';
import DataGridLayout from '../../components/TablesGrid/DataGrid';

import theme from '../../styles/theme';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px 30px',
  },
  header: {
    alignItems: 'center',
  },
  titleHeader: {
    marginLeft: 20,
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
  paper: {
    padding: 30,
  },
  cancelFatura: {
    color: theme.palette.error.dark,
    fontWeight: theme.typography.fontWeightBold,

    '&.MuiButton-root.Mui-disabled': {
      color: theme.palette.grey[200],
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
}));

function Faturas(props) {
  const { idEstipulante, faturas, authStr, infosConvenio, infosConveniados } =
    props;
  const [loadingGerarBoleto, setLoadingGerarBoleto] = useState(false);

  const classes = useStyles();

  const gerarBoleto = async dataFatura => {
    setLoadingGerarBoleto(true);

    // TODO: repassar estes cálculos pro controller,
    // assim como devemos fazer no componente ResumoConveniados para evitar duplicidade
    const valorTotal = infosConveniados.reduce(
      (acc, current) => acc + current.valor,
      0,
    );

    const desconto = Number(infosConvenio.desconto_fatura_porcentagem / 100);
    const valorTotalComDesconto = valorTotal - valorTotal * desconto;
    const valorDesconto = valorTotal - valorTotalComDesconto;

    const data = {
      idEstipulante,
      dataFatura,
      valorTotal,
      valorTotalComDesconto,
      valorDesconto,
      percentualDesconto: infosConvenio.desconto_fatura_porcentagem,
    };

    try {
      const response = await api.post(`convenio/gerar-fatura/`, data, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        window.location.reload();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao gerar o boleto', error.message);
    }
    setLoadingGerarBoleto(false);
  };

  const gerarBoletoConfirm = useCallback(() => {
    const TodayDate = new Date();
    const day = TodayDate.getDate();
    let month = TodayDate.getMonth();
    const year = TodayDate.getFullYear();

    if (Number(infosConvenio.dia_proximos_pagamentos) < Number(day)) {
      month += 1; // próximo mês
    }

    const dataFatura = new Date(
      year,
      month,
      infosConvenio.dia_proximos_pagamentos,
    )
      .toISOString()
      .slice(0, 10);

    if (
      !window.confirm(
        `Alerta:\nUm boleto será gerado para ${
          infosConvenio.dia_proximos_pagamentos
        }/${month + 1}/${year}. Deseja continuar?`,
      )
    ) {
      return;
    }
    gerarBoleto(dataFatura);
  }, []);

  const cancelarFaturaConfirm = useCallback(id => {
    if (
      !window.confirm(`Alerta:\nTem certeza que deseja cancelar esta fatura?`)
    ) {
      return;
    }

    cancelarFatura(id);
  }, []);

  const cancelarFatura = async id => {
    try {
      const response = await api.post(
        `convenio/cancelar-fatura/`,
        { id },
        {
          headers: { Authorization: authStr },
        },
      );
      if (response.data.ok) {
        window.location.reload();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao cancelar o boleto', error.message);
    }
  };

  const columns = [
    {
      field: 'data_vencimento',
      headerName: 'Data de vencimento',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 200,
      sortable: false,
      valueFormatter: params => {
        const valueFormatted = formatDate(params.value);
        return valueFormatted;
      },
    },
    {
      field: 'valor',
      headerName: 'Valor da fatura',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 200,
      sortable: false,
      valueFormatter: params => {
        const valueFormatted = formatValueToPrice(params.value);
        return valueFormatted;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'link_fatura',
      headerName: 'Link da fatura',
      headerAlign: 'center',
      flex: 0.5,
      minWidth: 200,
      sortable: false,
      renderCell: params => (
        <>
          {/* <a
            href={params.row.link_arquivo}
            rel="noopener noreferrer"
            target="_blank"
            style={{
              textDecoration: 'none',
              color: theme.palette.secondary.main,
            }}
          >
            <OpenInNewIcon style={{ width: 20, height: 20, marginRight: 12 }} />
            clique aqui
          </a> */}
          <Button
            href={params.row.link_arquivo}
            target="_blank"
            rel="noopener noreferrer"
            startIcon={
              <OpenInNewIcon
                style={{ width: 20, height: 20, marginRight: 12 }}
              />
            }
            style={{
              // textDecoration: 'none',
              color: theme.palette.secondary.main,
            }}
          >
            clique aqui
          </Button>
        </>
      ),
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      headerAlign: 'center',
      width: 200,
      sortable: false,
      renderCell: params => (
        <>
          <Button
            className={classes.cancelFatura}
            onClick={() => cancelarFaturaConfirm(params.row.id)}
            disabled={params.row.status === 'Cancelada'}
            startIcon={<DeleteForeverIcon style={{ width: 20, height: 20 }} />}
          >
            Cancelar fatura
          </Button>
        </>
      ),
    },
  ];

  // const columns = [
  //   {
  //     name: 'valor',
  //     title: 'Valor',
  //     getCellValue: row => formatValueToPrice(row.valor),
  //   },
  //   {
  //     name: 'vencimento',
  //     title: 'Vencimento',
  //     getCellValue: row => formatDate(row.data_vencimento),
  //   },
  //   {
  //     name: 'status',
  //     title: 'Status',
  //     getCellValue: row => row.status,
  //   },
  //   { name: 'tipo', title: 'Ações', getCellValue: row => actions(row) },
  // ];

  // const actions = el => (
  //   <div>
  //     <a href={el?.link_arquivo} rel="noopener noreferrer" target="_blank">
  //       <FaLink />
  //       &nbsp;Link da fatura
  //     </a>
  //     &nbsp;&nbsp;&nbsp;
  //     <Button
  //       color="danger"
  //       onClick={() => cancelarFaturaConfirm(el.id)}
  //       disabled={el.status == 'Cancelada' ? 'disabled' : null}
  //     >
  //       Cancelar
  //     </Button>
  //   </div>
  // );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <RegisterButton
              // className="mb-3"
              onClick={() => gerarBoletoConfirm()}
              disabled={loadingGerarBoleto}
            >
              Gerar boleto
            </RegisterButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <ReceiptIcon style={{ height: '20px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h2">Faturas</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataGridLayout
                rows={faturas.data}
                columns={columns}
                loading={faturas.loading}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>

    // <>
    //   <Card className="my-4">
    //     <Row className="text-left mt-2">
    //       <Col>
    //         <SectionFormHeader>
    //           <FaFileInvoiceDollar className="mr-2" />
    //           Faturas
    //         </SectionFormHeader>
    //       </Col>
    //     </Row>
    //     <hr />
    //     <OverflowContainer className="mt-3" height="1200px">
    //       <DataGridLayout
    //         rows={faturas.data}
    //         columns={columns}
    //         loading={faturas.loading}
    //       />
    //       {/* <SimpleTable
    //         rows={faturas.data}
    //         columns={columns}
    //         loading={faturas.loading}
    //         height="1200px"
    //       /> */}
    //     </OverflowContainer>
    //     <Row>
    //       <Col className="d-flex justify-content-end">
    //         <Button
    //           className="mb-3"
    //           color="success"
    //           outline
    //           onClick={() => gerarBoletoConfirm()}
    //           disabled={loadingGerarBoleto}
    //         >
    //           Gerar boleto
    //         </Button>
    //       </Col>
    //     </Row>
    //   </Card>
    // </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Faturas);
