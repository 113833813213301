import React from 'react';

import { useHistory } from 'react-router-dom';

import { Container, Grid, Typography } from '@material-ui/core';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import { makeStyles } from '@material-ui/core/styles';
import { GoBackButton } from '../../components/Buttons/Buttons';

import Faturas from '../../views/Convenio/Faturas';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px 30px',
  },
  header: {
    alignItems: 'center',
  },
  titleHeader: {
    marginLeft: 20,
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
  paper: {
    padding: 20,
  },
}));

const Page = props => {
  const { idEstipulante, infosConvenio } = props;
  const { nome } = infosConvenio;
  const history = useHistory();

  const classes = useStyles();

  return (
    <Container
      component="section"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <Grid container>
        <Grid
          item
          component="header"
          xs={12}
          container
          spacing={4}
          className={classes.header}
        >
          <Grid item>
            <GoBackButton
              onClick={() => history.push(`/convenio/${idEstipulante}`)}
            />
          </Grid>
          <Grid item>
            <Grid container className={classes.header}>
              <BusinessCenterIcon />
              <Typography variant="h1" className={classes.titleHeader}>
                {nome}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item component="article" xs={12} className={classes.content}>
          <Faturas {...props} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page;
