import React, { useState } from 'react'
import { Button, Container, Row, Col } from 'reactstrap';
import { FaPlus, FaHandshake, FaExclamation } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import ModalCadastroParceiro from '../../components/Modal/ModalCadastroParceiro';
import ListaBeneficio from '../../views/Beneficios/ListaBeneficio';
import MaterialModal from '../../components/Modal/MaterialModal';
import { Warning } from '../../views/Apolice/styles';

export default function Page(props) {

  const { cadastrarBeneficio, handleOnChange, parceiro, terceirosList, excluirBeneficio } = props;
  const titleHeader = <><FaHandshake/>&nbsp;&nbsp; Benefícios</>;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [idItemExclusao, setIdItemExclusao] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [loadingExclusao, setLoadingExclusao] = useState(false);

  const toggleModalExclusao = (id) => {
    setIdItemExclusao(id);
    setModalExclusao(!modalExclusao);
  };

  const handleExcluirBeneficio = async () => {
    excluirBeneficio(idItemExclusao);
    setModalExclusao(!modalExclusao);
  };

  return (
    <Container fluid={true}>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="text-right">
        <Col>
          <Button color="success" size="sm" onClick={() => setModal(true)}><FaPlus /> Cadastrar Benefícios</Button>
        </Col>
      </Row>
      {
        (modal)&&
        <ModalCadastroParceiro
          toggle={toggle}
          modal={modal}
          cadastrarBeneficio={cadastrarBeneficio}
          handleOnChange={handleOnChange}
          parceiro={parceiro}
        />
      }
      <Row className="mb-4">
        <Col lg={12}>
          <ListaBeneficio
            terceirosList={terceirosList}
            toggleModalExclusao={toggleModalExclusao}
          />
        </Col>
      </Row>
      <MaterialModal
          toggle={toggleModalExclusao}
          modal={modalExclusao}
          handleConfirm={handleExcluirBeneficio}
          loading={loadingExclusao}
          title="Exclusão do benefício"
        >
          <>
            Tem certeza que deseja excluir este benefício?
            <Warning>
              <FaExclamation />
              <p>
                Aviso: ao confirmar esta ação todas as relações de produtos com este benefício serão desfeitas
              </p>
            </Warning>
          </>
      </MaterialModal>
    </Container>
  )
}
