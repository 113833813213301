import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaWrench } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import ClinicaEditarCliente from '../../views/ClinicaEditarCliente/ClinicaEditarCliente';

function Page(props) {
  const { history, clientInputEdit } = props;
  const titleHeader = clientInputEdit ? <><FaWrench/>&nbsp;&nbsp;Editar: {clientInputEdit.nome} </> : '';

  let clienteEnderecoInput = {
    cep: clientInputEdit.cep,
    logradouro: clientInputEdit.logradouro,
    numero_endereco: clientInputEdit.numero_endereco,
    complemento_endereco: clientInputEdit.complemento_endereco,
    bairro: clientInputEdit.bairro,
    cidade: clientInputEdit.cidade,
    uf: clientInputEdit.uf,
  }

  return (
    <Container fluid={true}>
      <Row className="text-left mt-4">
        <Col md={2}>
          <Button className="btn-back w-100" outline size="sm" color="danger" onClick={() => history.goBack()}>
            <FaArrowLeft/>&nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mt-4">
        {
          clientInputEdit&&
          <ClinicaEditarCliente
            clientInputEdit={clientInputEdit}
            clienteEnderecoInput={clienteEnderecoInput}
            history={history}
            edit={true}
          />
        }
      </Row>
    </Container>
  )
}

export default Page;
