import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Api from '../../services/api';

import Page from './Page';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';

function Client(props) {
  const [client, setClient] = useState();
  const [clinicaVendas, setClinicaVendas] = useState();
  const [loadingClinicaVenda, setLoadingClinicaVenda] = useState(false);
  const [clinicas, setClinicas] = useState();

  const authStr = `Bearer ${props.user.user.token}`;

  const { idClient } = useParams();
  const history = useHistory();

  useEffect(() => {
    inicia();
  }, []);

  const inicia = () => {
    getClient();
    getInfosClinicaVendas();
    getClinicas();
  };

  const getClient = async _ => {
    const result = await Api.get(`clinica-cliente/detalhe/${idClient}`, {
      headers: { Authorization: authStr },
    });
    setClient(result.data.output);
  };

  const getInfosClinicaVendas = async _ => {
    setLoadingClinicaVenda(true);
    const result = await Api.get(`clinica-cliente/${idClient}/clinicaVendas`, {
      headers: { Authorization: authStr },
    });
    setClinicaVendas(result.data);
    setLoadingClinicaVenda(false);
  };

  const getClinicas = async _ => {
    const response = await Api.get('/clinicas/', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    setClinicas(response.data.output);
    setLoadingClinicaVenda(false);
  };

  return (
    <>
      {client && (
        <Page
          client={client}
          clinicaVendas={clinicaVendas}
          idClient={idClient}
          loadingClinicaVenda={loadingClinicaVenda}
          history={history}
          token={props.user.user.token}
          clinicas={clinicas}
        />
      )}
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Client);
