import React from 'react';
import { Box } from '@material-ui/core';
import { Totalizadores } from './styles';
import formatValueToPrice from '../../utils/format';
import ExportarComissoes from './ExportarComissoes';

function TotalizadoresComissoes(props) {
  const { comissoes, nivelPermissao, exportar, linkBaixar, setModalBaixar, modalBaixar } = props;

  return (
    <Box textAlign="left" mb="2.5rem">
      <Totalizadores color="dashboard">
          <>
            {nivelPermissao == 'admin' ? (
              <p>
                Total do mês (Viver):
                <span>
                {formatValueToPrice(
                  comissoes.data.reduce((acc, current) => acc + current.valor_raiz, 0),
                )}
                </span>
              </p>
            ) : null}

            {nivelPermissao == 'admin' || nivelPermissao == 'master' ? (
              <p>
                Total do mês (Master):
                <span>
                {formatValueToPrice(
                  comissoes.data.reduce((acc, current) => acc + current.valor_assessoria_master, 0)
                )}
                </span>
              </p>
            ) : null}

            {nivelPermissao == 'admin' || nivelPermissao == 'master' || nivelPermissao == 'afiliado' ? (
              <p>
              Total do mês (Afiliado):
              <span>
              {formatValueToPrice(
                comissoes.data.reduce((acc, current) => acc + current.valor_assessoria, 0),
              )}
              </span>
            </p>
            ) : null}
            <p>
            <ExportarComissoes
              exportar={exportar}
              linkBaixar={linkBaixar}
              setModalBaixar={setModalBaixar}
              modalBaixar={modalBaixar}
              nivelPermissao={nivelPermissao}
              />
            </p>
          </>
      </Totalizadores>

      {/* <Box display="flex" justifyContent="space-between">
        <CardResumo card="valor" color="dashboard">
            <h5>Total do mês (Viver)</h5>
            <p>
              {formatValueToPrice(
                comissoes.data.reduce((acc, current) => acc + current.valor_raiz, 0),
              )}
            </p>
          </CardResumo>
          <CardResumo card="valor" color="dashboard">
            <h5>Total do mês (Master):</h5>
            <p>
              {formatValueToPrice(
                comissoes.data.reduce((acc, current) => acc + current.valor_assessoria_master, 0),
              )}
            </p>
          </CardResumo>
          <CardResumo card="valor" color="dashboard">
            <h5>Total do mês (Afiliado)</h5>
            <p>
              {formatValueToPrice(
                comissoes.data.reduce((acc, current) => acc + current.valor_assessoria, 0),
              )}
            </p>
          </CardResumo>
        </Box> */}
      </Box>
  );
}

export default TotalizadoresComissoes;
