import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FaRegSave } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { BodyFormComponent } from '../../views/styles';
import {
  testaCPF,
  verificaEmail,
} from '../../services/service';
import Api from '../../services/api';
import { alertSuccess, alertError } from '../Alerts/Alerts';
import InfoBasicoClinicaCliente from './infoBasicoClinicaCliente/InfoBasicoClinicaCliente';
import InfoEndereco from './infoEndereco/InfoEndereco';
import InfoPagamentoClinica from './infoPagamentoClinica/InfoPagamentoClinica';
import Loading from '../Loading/Loading';
import FinalPrice from '../FinalPrice/FinalPrice';

function ClinicaCadastroCliente(props) {
  const { user } = props;

  const id_usuario = user.user.usuario;

  const {
    edit,
    clientInputEdit,
    clienteEnderecoInput,
    mostraInfoPagamento,
    mostraInfoValores,
    modal,
    mostraInfoEndereco,
  } = props;

  const history = useHistory();
  const { idClient, idEmpresaCadastro } = useParams();

  const [loading, setLoading] = useState(false);
  const [idEstipulante, setIdEstipulante] = useState(1);
  const [nomeEstipulante, setNomeEstipulante] = useState('');

  const [clinicasList, setClinicasList] = useState([
    { id: '', name: '', id_usuario: '', tipo: '', id_clinica_master: ''},
  ]);
  const [clinicaSelected, setClinicaSelected] = useState({ id: '', name: '', id_usuario: '', tipo: '', id_clinica_master: '', desconto_convenio: 0 });

  const [clientInput, setClientInput] = useState({
    // TODO: Verificar envio de dados da clínica do usuário logado
    clinica:
      props.user.user.nivel_usuario == 1 ||
      props.user.user.nivel_usuario == 8
        ? ''
        : props.user.user.clinica,
    clinicaAtendente:
      props.user.user.nivel_usuario == 1 ||
      props.user.user.nivel_usuario == 8
        ? ''
        : props.user.user.clinica_atendente,
    nome: '',
    cpf: '',
    data_nascimento: '',
    estado_civil: '',
    sexo: '',
    telefone: '',
    celular: '',
    email: '',
    id_usuario,
    id_estipulante: idEstipulante,
    nome_estipulante: nomeEstipulante,
  });

  const [clienteEndereco, setClienteEndereco] = useState({
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
  });

  const [clientPayment, setClientPayment] = useState({
    forma_pagamento: '',
    primeiro_pagamento: new Date().toISOString().split('T')[0],
    dia_proximos_pagamentos: '',
    qtd_parcelas: 12,
    valor_venda: 0,
    modalidade: 'viver',
    nome_produto: ''
  });

  const [disabled, setDisabled] = useState({
    clinicaAtendente: true,
    dia_proximos_pagamentos: false,
    qtd_parcelas: false,
  });

  const [userEditParam, setUserEditParam] = useState('');

  useEffect(() => {
    if (edit) {
      setUserEditParam(idClient);
      setIdEstipulante(clientInput.id_estipulante);
    }
  }, [
    clientInputEdit,
    clientPayment,
  ]);

  const salvarCliente = async (edit) => {
    setLoading(true);

    if (clientInput.clinica === '') {
      alertError('Selecione a Clínica.');
      setLoading(false);
      return false;
    }

    if (clientInput.clinicaAtendente === '') {
      alertError('Selecione o Atendente.');
      setLoading(false);
      return false;
    }

    if (clientInput.nome === '') {
      alertError('O nome do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.cpf === '') {
      alertError('O CPF do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (!edit) {
      const cpf = clientInput.cpf;
      const cpfValid = await Api.post('clinica-cliente/checkCpf', {
        cpf,
      });
      if (!cpfValid.data.ok) {
        alertError(cpfValid.data.message);
        setLoading(false);
        return false;
      }
    }

    if (clientInput.data_nascimento === '') {
      alertError('A data de nascimento do cliente é obrigatória.');
      setLoading(false);
      return false;
    }

    if (clientInput.estado_civil === '') {
      alertError('O estado civil do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.sexo === '') {
      alertError('O gênero/sexo do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.telefone === '') {
      alertError('O Telefone é obrigatório.');
      setLoading(false);
      return false;
    }

    if (!verificaEmail(clientInput.email)) {
      alertError('Insira um e-mail válido nas informações do cliente.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.cep === '') {
      alertError('O cep é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.cep.length != 8) {
      alertError('O cep informado deve conter 8 digitos');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.logradouro === '') {
      alertError('A rua/logradouro é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.numero_endereco === '') {
      alertError('O número do endereço é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.complemento_endereco === '') {
      alertError('O complemento do endereço é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.bairro === '') {
      alertError('O bairro é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.cidade === '') {
      alertError('A cidade é obrigatória.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.uf === '') {
      alertError('O estado é obrigatório.');
      setLoading(false);
      return false;
    }

    if (!edit) {
      if (clientPayment.forma_pagamento === '') {
        alertError('Selecione a forma de pagamento.');
        setLoading(false);
        return false;
      }

      if (clientPayment.primeiro_pagamento === '') {
        alertError('Informe a data do primeiro pagamento.');
        setLoading(false);
        return false;
      }

      if (clientPayment.valor_venda <= 0) {
        alertError('O valor precisa ser maior que 0.');
        setLoading(false);
        return false;
      }
    }

    clientInput.numero_endereco = parseInt(clientInput.numero_endereco, 10);
    clientInput.complemento_endereco = parseInt(
      clientInput.complemento_endereco,
      10,
    );

    if (idEstipulante !== '1') {
      clientInput.id_estipulante = idEstipulante;
      clientInput.nome_estipulante = nomeEstipulante;
    }

    clientInput.id_usuario = id_usuario;

    const newObjSave = Object.assign(
      clientInput,
      clienteEndereco,
      clientPayment,
    );
    let resultReq = '';

    if (edit) {
        resultReq = await Api.put(`clinica-cliente/${idClient}`, newObjSave, {
          headers: { Authorization: `Bearer ${props.user.user.token}` },
        });
    } else {
      resultReq = await Api.post('clinica-cliente/cadastrar-cliente', newObjSave, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
    }

    if (resultReq.data.ok) {
      setLoading(false);
      alertSuccess('Cliente salvo com sucesso');

      if (edit) {
        history.push(`../clinica-cliente/detalhe/${userEditParam}`);
      } else {
        history.push(`/clinica-cliente/detalhe/${resultReq.data.output.idCliente}`);
      }
    } else {
      alertError(resultReq.data.message);
      setLoading(false);
    }
  };

  return (
    <BodyFormComponent>
      <InfoBasicoClinicaCliente
        clientInput={clientInput}
        setClientInput={setClientInput}
        testaCPF={testaCPF}
        edit={edit}
        disabled={disabled}
        setDisabled={setDisabled}
        token={props.user.user.token}
        clientInputEdit={clientInputEdit}
        nivelUsuario={props.user.user.nivel_usuario}
        usuario={props.user.user.usuario}
        idClinicaDoAtendente={props.user.user.clinica ? props.user.user.clinica : null}
        clinicaAtendente={props.user.user.clinica_atendente ? props.user.user.clinica_atendente : null}
        clinicasList={clinicasList}
        setClinicasList={setClinicasList}
        setClinicaSelected={setClinicaSelected}
      />
      {mostraInfoEndereco && (
        <InfoEndereco
          clienteEndereco={clienteEndereco}
          setClienteEndereco={setClienteEndereco}
          edit={edit}
          token={props.user.user.token}
          clienteEnderecoInput={clienteEnderecoInput}
        />
      )}
      {mostraInfoPagamento && (
        <InfoPagamentoClinica
          clientPayment={clientPayment}
          edit={edit}
          disabled={disabled}
          setDisabled={setDisabled}
          token={props.user.user.token}
          setClientPayment={setClientPayment}
          setIdEstipulante={setIdEstipulante}
          setNomeEstipulante={setNomeEstipulante}
          clientInput={clientInput}
          setClinicaSelected={setClinicaSelected}
        />
      )}

      {mostraInfoValores && !edit && clientPayment.valor_venda > 0 && (
        <Row className="text-right">
          <Col lg={12} className="mb-4 mt-4">
            <FinalPrice
            price={clientPayment.valor_venda ? clientPayment.valor_venda : 0}
            name={clientPayment.nome_produto ? clientPayment.nome_produto : 'Venda Clínica'}
            />
          </Col>
        </Row>
      )}
      {loading ? (
        <Row className="text-center">
          <Col>
            <Loading loading={loading} />
          </Col>
        </Row>
      ) : (
        <Row className="text-right mt-4">
          <Col lg={12} className="btn-content mt-4">
            {!modal && (
              <Button
                color="danger"
                size="sm"
                className="mr-2"
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            )}
            {edit ? (
              <Button color="success" size="sm" onClick={() => salvarCliente(true)}>
                <FaRegSave />
                &nbsp;Editar Cliente
              </Button>
            ) : (
              <Button color="success" size="sm" onClick={() => salvarCliente(false)}>
                <FaRegSave />
                &nbsp;Cadastrar Cliente
              </Button>
            )}
          </Col>
        </Row>
      )}
    </BodyFormComponent>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(ClinicaCadastroCliente);
