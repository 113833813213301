import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {
  FaPercent,
  FaRegMoneyBillAlt,
  FaVuejs,
  FaWallet,
} from 'react-icons/fa';
import { CardComponent } from '../styles';
import Loading from '../../components/Loading/Loading';

function ComissaoAssessoria(props) {
  const { assessoria } = props;

  return (
    <Card>
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaPercent className="mr-2" />
              Comissões - Asaas
            </h2>
          </Col>
        </Row>
        <hr />
        {assessoria.loading ? (
          <Loading />
        ) : (
          <Row className="text-left mt-2">
            {/* <Col xs={12}>
              <p>
                <b>Primeira Parcela: </b>
                {assessoria.comissao.primeira_parcela} %
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <b>Carteira: </b>
                {assessoria.comissao.carteira} %
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <b>Pagamento à vista: </b>
                {assessoria.comissao.pagamento_a_vista} %
              </p>
            </Col> */}
            <Col xs={12}>
              <p>
                <b>ID Subconta Asaas: </b>
                {assessoria.data.id_asaas_subconta
                  ? assessoria.data.id_asaas_subconta
                  : 'Não cadastrado'}
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <b>1ª Parcela: </b>
                {assessoria.data.comissao_primeira_parcela_asaas} %
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <b>Demais cobranças: </b>
                {assessoria.data.comissao_asaas} %
              </p>
            </Col>
          </Row>
        )}
      </CardComponent>
    </Card>
  );
}

export default ComissaoAssessoria;
