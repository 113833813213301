import React from 'react';
// import { Container, Row, Col, Button } from 'reactstrap';
// import { FaArrowLeft, FaWrench } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import TitleContent from '../../components/TitleContent/TitleContent';
import EditarConvenio from '../../views/EditarConvenio/EditarConvenio';

import { ReactComponent as EditIcon } from '../../assets/img/icon/editar.svg';

import { GoBackButton, SaveButton } from '../../components/Buttons/Buttons';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px 30px',
  },
  header: {
    // display: 'flex',
    alignItems: 'center',
    // marginTop: theme.spacing(2),
    // paddingTop: 10,
  },
  titleHeader: {
    marginLeft: theme.spacing(2),
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
  paper: {
    padding: 30,
  },
}));

const Page = props => {
  const { handleSubmit } = props;
  const history = useHistory();
  const { nome } = props.resultConvenio;

  const classes = useStyles();

  // const titleHeader = (
  //   <>
  //     <FaWrench />
  //     &nbsp;&nbsp;Editar: {nome}
  //   </>
  // );

  return (
    // <Container fluid>
    //   <Row className="mt-4">
    //     <Col md={2}>
    //       <Button
    //         className="btn-back w-100"
    //         outline
    //         color="danger"
    //         size="sm"
    //         onClick={() => history.goBack()}
    //       >
    //         <FaArrowLeft />
    //         &nbsp;&nbsp;Voltar
    //       </Button>
    //     </Col>
    //   </Row>
    //   <Row className="text-left">
    //     <Col md={12}>
    //       <TitleContent titleHeader={titleHeader} />
    //     </Col>
    //   </Row>
    //   <Row className="mb-4 pb-4">
    //     <Col>
    //       <EditarConvenio {...props} />
    //     </Col>
    //   </Row>
    // </Container>

    <Container
      component="section"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <Grid container>
        <Grid
          item
          component="header"
          xs="auto"
          container
          spacing={4}
          className={classes.header}
        >
          <Grid item>
            <GoBackButton onClick={() => history.goBack()} />
          </Grid>
          <Grid item>
            <Grid container className={classes.header}>
              <EditIcon fill="#525151" />
              <Typography variant="h1" className={classes.titleHeader}>
                Editar informações do convênio: <span>{nome}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item component="article" xs="auto" className={classes.content}>
          <Paper className={classes.paper}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <EditarConvenio {...props} />
                <Grid
                  item
                  xs="auto"
                  container
                  spacing={6}
                  justifyContent="flex-end"
                >
                  <Grid item xs="auto">
                    <SaveButton onClick={handleSubmit} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page;
