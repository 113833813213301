import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BodyFormComponent, LabelForm } from '../styles';
import { alertError } from '../../components/Alerts/Alerts';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import Api from '../../services/api';

function ModalEnviaCsv(props) {
  const { modalImportar, toggleImportar, idEmpresa, token } = props;
  const [inputFile, setInputFile] = useState({
    funcionarios: [],
  });
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const enviaArquivo = async e => {
    e.preventDefault();
    setLoading(true);

    if (inputFile.funcionarios.name.split('.')[1] === 'csv') {
      const dataForm = new FormData();
      dataForm.append('funcionarios', inputFile.funcionarios);

      const response = await Api.post(
        `empresa/${idEmpresa}/funcionarios`,
        dataForm,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (response.data.ok) {
        window.location.reload();
        history.push(`/empresa/${idEmpresa}`);
      } else {
        alertError(response.data.message);
      }
    } else {
      alertError('O arquivo importado deve ser ter a extensão CSV');
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={modalImportar} toggle={toggleImportar}>
      <ModalHeader toggle={toggleImportar}>Importar Funcionários</ModalHeader>
      <ModalBody>
        <BodyFormComponent>
          <Form onSubmit={enviaArquivo} method="post">
            <Row>
              <Col>
                <LabelForm>
                  Insira o arquivo CSV para importar os funcionários.
                </LabelForm>
                <Input
                  type="file"
                  name="funcionarios"
                  onChange={e =>
                    setInputFile({ funcionarios: e.target.files[0] })
                  }
                />
              </Col>
            </Row>
            <Row className="text-right mt-2">
              <Col md={6} className="text-center">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  href="https://docs.google.com/spreadsheets/d/10aykgNCiVDvkHC6C2CR7TVzn-9VPtXGbTaXepW5VNek/edit?usp=sharing"
                  target="_blank"
                >
                  Acessar exemplo
                </Button>
              </Col>
              <Col md={6}>
                <SubmitBtn
                  label="Enviar Arquivo"
                  labelOnClick="Enviando"
                  loading={loading}
                  typeSubmit="submit"
                  metodo={enviaArquivo}
                  color="#0A7A73"
                />
              </Col>
            </Row>
          </Form>
        </BodyFormComponent>
      </ModalBody>
    </Modal>
  );
}

export default ModalEnviaCsv;
