import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';
import { FaFile, FaFileContract, FaRegEdit } from 'react-icons/fa';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';

import { DetailPaymentContent } from '../Apolice/styles';
import { CardComponent } from '../styles';
import { DetalhesContainer } from './styles';

import { formatDate } from '../../services/service';
import api from '../../services/api';
import formatValueToPrice from '../../utils/format';

import Loading from '../../components/Loading/Loading';
import MaterialModal from '../../components/Modal/MaterialModal';
import {
  alertError,
  alertSuccess,
  alertWarning,
} from '../../components/Alerts/Alerts';

function DetalhesConfigApolice(props) {
  const {
    detalhesApolice,
    configPayment,
    valorApolice,
    motivoCancelamento,
    idApolice,
    user,
    getDetalhesApolice,
    getConfigPayment,
    getValorApolice,
  } = props;

  const [modalCancelamento, setModalCancelamento] = useState(false);
  const [modalEditarDesconto, setModalEditarDesconto] = useState(false);
  const [valorDesconto, setValorDesconto] = useState('');
  const [loadingEditarDesconto, setLoadingEditarDesconto] = useState(false);
  const [loadingCarta, setLoadingCarta] = useState(false);
  const [salvarApoliceEffect, setSalvarApoliceEffect] = useState(false);

  const toggleModalCancelamento = () => {
    setModalCancelamento(!modalCancelamento);
  };
  const toggleModalEditarDesconto = () => {
    setModalEditarDesconto(!modalEditarDesconto);
  };

  const baixaCartaCancelamento = async () => {
    setLoadingCarta(true);
    const result = await api.get(
      `/apolice/${idApolice}/gerar-carta-cancelamento`,
      { headers: { Authorization: `Bearer ${props.user.user.token}` } },
    );
    if (result.data.ok) {
      window.open(
        `${process.env.REACT_APP_API_ERP}/${result.data.output.path}`,
      );
    } else {
      alertError(`Erro ao baixar a carta: ${result.data.message}`);
    }
    setLoadingCarta(false);
  };

  const confirmCarta = () => {
    const form = document.forms.formCancelar;
    const inputConfirm = form.confirmCarta.checked;
    const motivo = form.motivoCancelamento.value;

    if (motivo == '') {
      alertWarning('Selecione o motivo.');
      return;
    }

    if (!inputConfirm) {
      alertWarning('Você deve confirmar que coletou a assinatura do cliente.');
      return;
    }

    setApoliceInput({ ...apoliceInput, status: 'Cancelada' });

    setSalvarApoliceEffect(true);
  };

  const [loading, setLoading] = useState(false);

  const [apoliceInput, setApoliceInput] = useState({
    status: detalhesApolice.status,
    inicioVigencia: detalhesApolice.inicio_vigencia,
    periodoVigencia: 12,
    documento: [],
    id_apolice: detalhesApolice.id,
    motivo: '',
    anotacaoStatus: '',
    formaPgto: configPayment.forma_pagamento,
    proximosPgto: configPayment.dia_proximos_pagamentos,
    idUsuario: parseInt(user.user.nivel_usuario, 10),
  });

  const salvarApolice = async () => {
    setLoading(true);
    const dataForm = new FormData();

    Object.keys(apoliceInput).map(el => {
      dataForm.append(el, apoliceInput[el]);
    });
    try {
      const response = await api.post(`apolice/${idApolice}/status`, dataForm, {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if (response.data.ok) {
        setLoading(false);
        alertSuccess('Apólice cancelada com sucesso!');
        toggleModalCancelamento();
        getDetalhesApolice();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  const handleAlterarDescontoValor = async () => {
    setLoadingEditarDesconto(true);
    if (!valorDesconto || valorDesconto < 0 || Number.isNaN(valorDesconto)) {
      alertError('Erro: Desconto negativo ou inválido');
      setLoadingEditarDesconto(false);
      return;
    }
    try {
      const response = await api.post(
        `/config-pagamento/${configPayment.id}/editar-desconto-valor`,
        {
          descontoValor: valorDesconto,
        },
        { headers: { Authorization: `Bearer ${props.user.user.token}` } },
      );
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      alertSuccess('Desconto alterado com sucesso');
      toggleModalEditarDesconto();
      getConfigPayment();
      getValorApolice();
    } catch (error) {
      console.log('Erro ao alterar desconto em valor: ', error.message);
      alertError('Erro ao alterar desconto em valor');
    }
    setLoadingEditarDesconto(false);
  };

  useEffect(() => {
    if (salvarApoliceEffect) {
      salvarApolice();
    }
  }, [salvarApoliceEffect]);

  return (
    <Card className="mb-4 pb-2">
      <ToastContainer />

      <DetailPaymentContent>
        <Form className="formApoliceStatus">
          <CardComponent className="mb-3">
            <Col className="header-content text-left">
              <h2>
                <FaFileContract className="mr-2" />
                Apólice {detalhesApolice.id}
              </h2>
            </Col>
          </CardComponent>

          {Object.keys(configPayment).length &&
          Object.keys(detalhesApolice).length ? (
            <>
              <DetalhesContainer>
                <p>
                  <span>Valor:</span> {formatValueToPrice(valorApolice)}{' '}
                </p>
                <p>
                  <span>Status:</span> {detalhesApolice.status}
                </p>
                <p>
                  <span>Quantidade de Parcelas:</span>{' '}
                  {configPayment?.qtd_parcelas}
                </p>
                <p>
                  <span>Primeiro Pagamento:</span>{' '}
                  {formatDate(configPayment?.primeiro_pagamento)}
                </p>
                <p>
                  <span>Início Vigência:</span>{' '}
                  {formatDate(detalhesApolice.inicio_vigencia)}
                </p>
                <p>
                  <span>Próximos Pagamentos:</span> Dia{' '}
                  {configPayment?.dia_proximos_pagamentos}
                </p>
                <p>
                  <span>Dependente Gratuito?</span>{' '}
                  {configPayment?.DEP_1_GRATIS === '0' ? 'Não' : 'Sim'}
                </p>
                <p>
                  <span>Desconto titular: </span>{' '}
                  {configPayment?.desconto_titular} (%)
                </p>
                <p>
                  <span>Desconto em valor: </span>{' '}
                  {formatValueToPrice(configPayment?.desconto_valor)}
                  <IconButton
                    className="p-0"
                    onClick={toggleModalEditarDesconto}
                  >
                    <FaRegEdit />
                  </IconButton>
                </p>
                <p>
                  <span>ID Cliente ASAAS:</span> {configPayment?.id_cliente_asaas}{' '}
                </p>
              </DetalhesContainer>
              {user.user.nivel_usuario == 1 && (
                <Col
                  md={12}
                  className="d-flex justify-content-center mb-2 mt-4"
                >
                  {detalhesApolice.status != 'Cancelada' && (
                    <Button color="danger" onClick={toggleModalCancelamento}>
                      Cancelar Apólice
                    </Button>
                  )}
                </Col>
              )}
            </>
          ) : (
            <Row className="justify-content-center">
              <Loading />
            </Row>
          )}
          <Modal isOpen={modalCancelamento} toggle={toggleModalCancelamento}>
            <ModalHeader toggle={toggleModalCancelamento}>
              Endosso de Cancelamento
            </ModalHeader>
            <ModalBody>
              <form name="formCancelar">
                <p>
                  O endosso de cancelamento é um conjunto de ações no sistema
                  para cancelar uma apólice.
                </p>

                <Col className="mb-4 mt-3 text-left">
                  <p>
                    <span style={{ color: '#f44b3f' }}>
                      <FaFile />
                      &nbsp;&nbsp;Motivo de Cancelamento
                    </span>
                  </p>
                </Col>
                <Col>
                  <Input
                    type="select"
                    name="motivoCancelamento"
                    value={apoliceInput.motivo}
                    onChange={e =>
                      setApoliceInput({
                        ...apoliceInput,
                        motivo: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                  >
                    <option value=""> - Selecione - </option>
                    {motivoCancelamento &&
                      motivoCancelamento.map((motivo, index) => (
                        <option key={index}>{motivo.descricao}</option>
                      ))}
                  </Input>
                </Col>
                <Col className="mt-4 text-left">
                  <Input
                    type="textarea"
                    placeholder="Mais informações"
                    value={apoliceInput.anotacaoStatus}
                    onChange={e =>
                      setApoliceInput({
                        ...apoliceInput,
                        anotacaoStatus: e.target.value,
                      })
                    }
                  />
                </Col>
                <Row className="" style={{ marginTop: '3rem' }}>
                  <Col>
                    <Button
                      size="sm"
                      disabled={loadingCarta}
                      color="info"
                      onClick={baixaCartaCancelamento}
                    >
                      Baixar carta de cancelamento{' '}
                      {loadingCarta && <Spinner color="secondary" size="sm" />}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4 text-left">
                    <p>
                      <span>
                        <FaFile />
                        &nbsp;&nbsp;Anexar Documento
                      </span>
                    </p>
                    <Input
                      type="file"
                      name="documento"
                      onChange={e =>
                        setApoliceInput({
                          ...apoliceInput,
                          documento: e.target.files[0],
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                  <Col md={12}>
                    <Input
                      type="checkbox"
                      style={{ margin: 0 }}
                      name="confirmCarta"
                    />
                    <p style={{ marginLeft: '1rem' }}>
                      Confirmo que imprimi a{' '}
                      <strong style={{ fontWeight: 700 }}>
                        Carta de Cancelamento
                      </strong>{' '}
                      e coletei a assinatura do cliente.
                    </p>
                  </Col>
                </Row>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={confirmCarta} disabled={loading}>
                {!loading ? (
                  'Confirmar'
                ) : (
                  <>
                    Confirmando <Spinner color="primary" size="sm" />
                  </>
                )}
              </Button>
              <Button color="secondary" onClick={toggleModalCancelamento}>
                Voltar
              </Button>
            </ModalFooter>
          </Modal>
        </Form>
        <MaterialModal
          toggle={toggleModalEditarDesconto}
          modal={modalEditarDesconto}
          title="Alterar Desconto em Valor"
          handleConfirm={handleAlterarDescontoValor}
          loading={loadingEditarDesconto}
        >
          <form>
            <TextField
              id="novo-desconto"
              type="number"
              label="Novo desconto"
              min={0}
              step={0.01}
              value={valorDesconto}
              onChange={e => setValorDesconto(e.target.value)}
              required
              placeholder="10,00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </form>
        </MaterialModal>
      </DetailPaymentContent>
    </Card>
  );
}

const mapStateToProp = store => ({
  user: store.user,
});

export default connect(mapStateToProp)(DetalhesConfigApolice);
