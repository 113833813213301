import React from 'react';
import { Box, FormControl, makeStyles, TextField } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import MaterialButton from '../../components/Buttons/MaterialButton';
import MaterialSelect from '../../components/Inputs/MaterialSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
}));

function Filtros(props) {
  const {
    loadingComissoes,
    setPeriodo,
    periodo,
    setPesquisa,
    pesquisa,
    setStatusPagamento,
    statusPagamento,
    setClinicaMaster,
    clinicaMaster,
    setClinicaAfiliada,
    clinicaAfiliada,
    setFireGetComissoes,
    fireGetComissoes,
    clinicaMasterSelect,
    clinicaAfiliadaSelect,
    nivelPermissao
  } = props;

  const history = useHistory();

  const classes = useStyles();

  const clinicaMasterItens = clinicaMasterSelect.reduce(function(filtered, option) {
    if (option.tipo == 'master') {
       var item = { value: option.id, label: option.razao_social }
       filtered.push(item);
    }
    return filtered;
  }, []);
  clinicaMasterItens.unshift({ value: 'Todos', label: 'Todos' });

  const clinicaAfiliadaItens = clinicaAfiliadaSelect.reduce(function(filtered, option) {
    if (option.tipo == 'afiliado') {
       var item = { value: option.id, label: option.razao_social }
       filtered.push(item);
    }
    return filtered;
  }, []);
  clinicaAfiliadaItens.unshift({ value: 'Todos', label: 'Todos' });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      mt="2rem"
      mb="1.5rem"
      flexWrap="wrap"
    >
      <FormControl className={classes.formControl} style={{width: '70%'}}>
        <TextField
          name="search"
          label="Procurar"
          id="search"
          type="text"
          value={pesquisa ? pesquisa : ''}
          className={classes.textField}
          onChange={e => {
            setPesquisa(e.target.value);
          }}
          inputlabelprops={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl} style={{width: '24%'}}>
        <TextField
          name="periodo"
          label="Período"
          id="periodo"
          type="month"
          value={periodo ? periodo : ''}
          className={classes.textField}
          onChange={e => {
            setPeriodo(e.target.value);
          }}
          inputlabelprops={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl} style={{width: '18%'}}>
      <MaterialSelect
          name="status"
          label="Status"
          id="status"
          value={statusPagamento ? statusPagamento : ''}
          onChange={e => {
            setStatusPagamento(e.target.value);
          }}
          options={[
            { value: 'Todos', label: 'Todos' },
            { value: 'Paga', label: 'Paga' },
            { value: 'Pendente', label: 'Pendente' },
          ]}
          inputlabelprops={{
            shrink: true,
          }}
        />
      </FormControl>
      {nivelPermissao == 'admin' ? (
        <FormControl className={classes.formControl} style={{width: '30%'}}>
        <MaterialSelect
            name="clinicaMaster"
            label="Clínica Master"
            id="clinicaMaster"
            value={clinicaMaster ? clinicaMaster : ''}
            onChange={e => {
              setClinicaMaster(e.target.value);
            }}
            options={clinicaMasterItens}
            inputlabelprops={{
              shrink: true,
            }}
          />
        </FormControl>
      ) : null }

      {nivelPermissao == 'admin' || nivelPermissao == 'master'  ? (
        <FormControl className={classes.formControl} style={{width: '30%'}}>
        <MaterialSelect
            name="clinicaAfiliada"
            label="Clínica Afiliada"
            id="clinicaAfiliada"
            value={clinicaAfiliada ? clinicaAfiliada : ''}
            onChange={e => {
              setClinicaAfiliada(e.target.value);
            }}
            options={clinicaAfiliadaItens}
            inputlabelprops={{
              shrink: true,
            }}
          />
        </FormControl>
      ) : null }

      <Box mt="12px">
        <MaterialButton
          variant="contained"
          color="dashboard"
          onClick={() => {
            let url = `?periodo=${periodo}`;

            if (pesquisa) {
              url += `&pesquisa=${pesquisa}`;
            }

            if (statusPagamento) {
              url += `&status=${statusPagamento}`;
            }

            if (clinicaMaster) {
              url += `&master=${clinicaMaster}`;
            }

            if(clinicaAfiliada) {
              url += `&afiliado=${clinicaAfiliada}`;
            }

            history.push(url);
            setFireGetComissoes(!fireGetComissoes);
          }}
          loading={loadingComissoes}
        >
          <FaSearch />
          &nbsp;&nbsp;Buscar
        </MaterialButton>
      </Box>
    </Box>
  );
}

export default Filtros;
