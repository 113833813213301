import React from 'react';
import { Box } from '@material-ui/core';
import { Totalizadores } from './styles';
import formatValueToPrice from '../../utils/format';
import ExportarComissoes from './ExportarComissoes';

function TotalizadoresComissoes(props) {
  const {
    comissoes,
    nivelPermissao,
    exportar,
    linkBaixar,
    setModalBaixar,
    modalBaixar
  } = props;

  return (
    <Box textAlign="left" mb="2.5rem">
      <Totalizadores color="dashboard">
          <>
            {/* {nivelPermissao == 'admin' ? (
              <p>
                Total do mês (Viver):
                <span>
                {formatValueToPrice(
                  comissoes.data.reduce((acc, current) => acc + current.valor_raiz, 0),
                )}
                </span>
              </p>
            ) : null} */}

            {nivelPermissao == 'admin' || nivelPermissao == 'master' ? (
              <p>
                Total do mês (Master):
                <span>
                {formatValueToPrice(
                  comissoes.data.reduce((acc, current) => acc + current.valor_clinica_master, 0)
                )}
                </span>
              </p>
            ) : null}

            {nivelPermissao == 'admin' || nivelPermissao == 'master' || nivelPermissao == 'afiliado' ? (
              <p>
              Total do mês (Afiliado):
              <span>
              {formatValueToPrice(
                comissoes.data.reduce((acc, current) => acc + current.valor_clinica, 0),
              )}
              </span>
            </p>
            ) : null}
          </>
      </Totalizadores>
      </Box>
  );
}

export default TotalizadoresComissoes;
