import api from "../services/api";

/* eslint-disable no-param-reassign */
export const validateCPF = cpf => {
  // Validar se é String
  if (typeof cpf !== 'string') return false;

  // Tirar formatação
  cpf = cpf.replace(/[^\d]+/g, '');

  // Validar se tem tamanho 11
  if (cpf.length !== 11) return false;

  // Valida se é uma sequência de digitos repetidos
  if (cpf.match(/(\d)\1{10}/)) return false;

  // String para Array, e transforma digitos em números
  cpf = cpf.split('').map(el => +el);

  const rest = count =>
    ((cpf
      .slice(0, count - 12)
      // Calcular Soma dos dígitos e multiplicar por 10
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      // Pegar o resto por 11
      11) %
    // Transformar de 10 para 0
    10;

  return rest(10) === cpf[9] && rest(11) === cpf[10];
};

export const validarEmailExistente = async (email) => {
  try {
    const response = await api.post('usuario/validar-email', {
      email: email,
    });

    if (response.status === 200) {
      if (response.data.ok) {
        const { ok } = response.data;

        if (ok) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  } catch (err) {
    return true;
  }
};
