import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Card,
  Spinner,
  Alert,
  Form,
  Button,
} from 'reactstrap';
import { FaHeart, FaExclamationCircle } from 'react-icons/fa';
import { saveUser } from '../../actions/user';
import { FormLoginComponent, LabelForm } from '../styles';
import Api from '../../services/api';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import { useEffect } from 'react';

function Login(props) {
  const location = useLocation();
  const history = useHistory();

  const [loginUser, setLoginUser] = useState({
    loginUserEmail: '',
    loginUserPass: '',
    loginUserPassCheck: '',
  });
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [loading, setLoading] = useState(false);

  const [showLogin, setShowLogin] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);

  const handleOnChange = event => {
    const { name, value } = event.target;
    setLoginUser({ ...loginUser, [name]: value });
  };

  const submitLoginUser = async e => {
    e.preventDefault();
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('email', loginUser.loginUserEmail);
    bodyFormData.set('senha', loginUser.loginUserPass);

    const response = await Api.post('usuario/login', bodyFormData);

    if (response.data.ok) {
      props.saveUser(response.data.output);
      window.location.href = '/home';
    } else {
      setNotAuthorized(true);
      setMessageError(response.data.message);
      setLoading(false);
    }
  };

  const forgotMyPassword = () => {
    setShowLogin(false);
    setShowForgotPassword(true);
  };

  const sendEmailRecoverPassword = async e => {
    e.preventDefault();
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('email', loginUser.loginUserEmail);

    const response = await Api.post('usuario/esqueci-minha-senha', bodyFormData);

    if (response.data.ok) {
      alertSuccess('Um email foi enviado com as instruções para a redefinição da senha');
      setLoading(false);
    } else {
      alertError('Não existe um usuário no ERP com este email');
      setLoading(false);
    }

    setShowLogin(true);
    setShowForgotPassword(false);

  };

  const submitPasswordChange = async e => {
    e.preventDefault();
    setLoading(true);

    if (loginUser.loginUserPass != loginUser.loginUserPassCheck) {
      alertError("Verifique os campos de senha");
      return false;
    }

    const queryParams = new URLSearchParams(window.location.search)
    const hash = queryParams.get('hash')

    const bodyFormData = new FormData();
    bodyFormData.set('senha', loginUser.loginUserPass);
    bodyFormData.set('hash', hash);

    const response = await Api.post('usuario/alterar-senha-recuperacao', bodyFormData);

    if (response.data.ok) {
      history.push("/");
      setShowLogin(true);
      setShowForgotPassword(false);
      setShowRecoverPassword(false);
      setLoading(false);
      alertSuccess('A senha foi alterada com sucesso');

      // Login automático??
      // props.saveUser(response.data.output);
    } else {
      setShowLogin(false);
      setShowForgotPassword(true);
      setShowRecoverPassword(false);
      setLoading(false);
      alertError("Ocorreu um erro ao editar a senha, tente recuperar a senha novamente.");
    }
  };

  useEffect(() => {

    if (location.pathname == '/recuperar-senha') {
      setShowLogin(false);
      setShowForgotPassword(false);
      setShowRecoverPassword(true);
    }

  });

  return (
    <>
      <FormLoginComponent>
        {showLogin ? (
        <Card>
          <Form onSubmit={submitLoginUser} method="post">
            <Row className="pb-4">
              <Col>
                <Input
                  type="text"
                  name="loginUserEmail"
                  placeholder="E-mail"
                  value={loginUser.loginUserEmail}
                  onChange={e => handleOnChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row className="pb-4">
              <Col>
                <Input
                  type="password"
                  name="loginUserPass"
                  placeholder="Senha"
                  value={loginUser.loginUserPass}
                  onChange={e => handleOnChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row>
              {loading ? (
                <Col className="text-center">
                  <Spinner color="primary" />
                </Col>
              ) : (
                <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                  <Button
                    className="btn-login"
                    type="submit"
                    style={{ backgroundColor: 'transparent', border: '0px' }}
                  >
                    {/* <Col className="btn-login" onClick={() => submitLoginUser()}> */}
                    ENTRAR
                    {/* </Col> */}
                  </Button>
                  <Link
                    className="link-esqueci-minha-senha"
                    type="link"
                    to=""
                    onClick={() => forgotMyPassword()}
                  >
                    Esqueci minha senha
                  </Link>
                </Col>
              )}
            </Row>

            {notAuthorized && (
              <Alert color="danger" className="mt-4">
                <FaExclamationCircle />
                &nbsp;&nbsp;{messageError}{' '}
              </Alert>
            )}
          </Form>
          </Card>
          ) : null}

          {showForgotPassword ? (
          <Card className="card-esqueci-minha-senha">
            <Form onSubmit={sendEmailRecoverPassword} method="post">
              <Row className="pb-4">
                <Col>
                <LabelForm>Informe o email para recuperação</LabelForm>
                  <Input
                    type="text"
                    name="loginUserEmail"
                    placeholder="E-mail"
                    value={loginUser.loginUserEmail}
                    onChange={e => handleOnChange(e)}
                    required
                  />
                </Col>
              </Row>
              <Row>
                {loading ? (
                  <Col className="text-center">
                    <Spinner color="primary" />
                  </Col>
                ) : (
                  <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <Button
                      className="btn-login"
                      type="submit"
                      style={{ backgroundColor: 'transparent', border: '0px' }}
                    >
                      Recuperar senha
                    </Button>
                  </Col>
                )}
              </Row>

              {notAuthorized && (
                <Alert color="danger" className="mt-4">
                  <FaExclamationCircle />
                  &nbsp;&nbsp;{messageError}{' '}
                </Alert>
              )}
            </Form>
          </Card>
          ) : null}

        {showRecoverPassword ? (
        <Card>
          <Form onSubmit={submitPasswordChange} method="post">
            <Row className="pb-4">
            <Col>
                <Input
                  type="password"
                  name="loginUserPass"
                  placeholder="Informe a nova senha"
                  value={loginUser.loginUserPass}
                  onChange={e => handleOnChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row className="pb-4">
              <Col>
                <Input
                  type="password"
                  name="loginUserPassCheck"
                  placeholder="Confirmar nova senha"
                  value={loginUser.loginUserPassCheck}
                  onChange={e => handleOnChange(e)}
                  required
                />
              </Col>
            </Row>
            <Row>
              {loading ? (
                <Col className="text-center">
                  <Spinner color="primary" />
                </Col>
              ) : (
                <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                  <Button
                    className="btn-login"
                    type="submit"
                    style={{ backgroundColor: 'transparent', border: '0px' }}
                  >
                    ALTERAR SENHA
                  </Button>

                </Col>
              )}
            </Row>

            {notAuthorized && (
              <Alert color="danger" className="mt-4">
                <FaExclamationCircle />
                &nbsp;&nbsp;{messageError}{' '}
              </Alert>
            )}
          </Form>
          </Card>
          ) : null}

        <Col className="footer pl-2 pr-2 mt-2">
          <span>
            © 2022 Feito com <FaHeart style={{ color: '#ff0000b5' }} /> por
            Planos Viver Administrativo
          </span>
          <br />
          <span>© Todos os direitos reservados. </span>
          <br />
        </Col>
      </FormLoginComponent>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

const mapDispatchToProps = {
  saveUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
