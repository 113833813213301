import React from 'react';
// import { Container, Row, Col, Card, Form } from 'reactstrap';
// import { FaBuilding, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { Container, Grid, Paper, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
// import TitleContent from '../../components/TitleContent/TitleContent';
import CadastroConvenio from '../../views/CadastroConvenio/CadastroConvenio';
// import { BodyFormComponent } from '../../views/styles';
// import SubmitBtn from '../../components/Buttons/SubmitBtn';
import CadastroContatoConvenio from '../../views/CadastroConvenio/CadastrarContatoConvenio';
import CadastroBeneficios from '../../views/CadastroConvenio/CadastroBeneficios';

import { GoBackButton, SaveButton } from '../../components/Buttons/Buttons';

// const titleHeader = (
//   <>
//     <FaBuilding />
//     <FaPlus style={{ width: '20px', height: '20px' }} />
//     Cadastrar Convênio
//   </>
// );

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px 30px',
  },
  header: {
    // display: 'flex',
    alignItems: 'center',
    // paddingTop: 10,
  },
  titleHeader: {
    marginLeft: theme.spacing(2),
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
  paper: {
    padding: 30,
  },
}));

const Page = props => {
  const { cadastrarEmpresa, loading } = props;
  const history = useHistory();

  const classes = useStyles();

  return (
    // <Container className="pb-4" fluid>
    //   <Row className="text-left" style={{ alignItems: 'center' }}>
    //     <Col md={12}>
    //       <TitleContent titleHeader={titleHeader} />
    //     </Col>
    //   </Row>
    //   <Card style={{ marginTop: '30px' }}>
    //     <BodyFormComponent>
    //       <Form onSubmit={cadastrarEmpresa}>
    //         <CadastroConvenio {...props} />
    //         <CadastroContatoConvenio {...props} />
    //         <hr />
    //         <CadastroBeneficios {...props} />
    //         <Row className="text-right mt-4">
    //           <Col md={12}>
    //             <SubmitBtn
    //               loading={loading}
    //               label="Cadastrar Empresa"
    //               labelOnClick="Salvando"
    //               typeSubmit="submit"
    //               metodo={cadastrarEmpresa}
    //               color="#0A7A73"
    //             />
    //           </Col>
    //         </Row>
    //       </Form>
    //     </BodyFormComponent>
    //   </Card>
    // </Container>
    <Container
      component="section"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <Grid container>
        <Grid
          item
          component="header"
          xs="auto"
          container
          spacing={4}
          className={classes.header}
        >
          <Grid item>
            <GoBackButton onClick={() => history.goBack()} />
          </Grid>
          <Grid item>
            <Grid container className={classes.header}>
              <AddBoxIcon />
              <Typography variant="h1" className={classes.titleHeader}>
                Cadastrar novo convênio
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item component="article" xs="auto" className={classes.content}>
          <Paper className={classes.paper}>
            <form onSubmit={cadastrarEmpresa}>
              <Grid container spacing={6}>
                <CadastroConvenio {...props} />
                <CadastroContatoConvenio {...props} />
                <CadastroBeneficios {...props} />
                <Grid
                  item
                  xs="auto"
                  container
                  spacing={6}
                  justifyContent="flex-end"
                >
                  <Grid item xs="auto">
                    <SaveButton onClick={cadastrarEmpresa} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page;
