import React from 'react';

import {
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Typography,
  Tooltip,
  Grid,
} from '@material-ui/core';

import { Cancel } from '@material-ui/icons';
import SchoolIcon from '@material-ui/icons/School';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    position: 'relative',
    padding: '30px 30px 15px',
  },
  titleHeader: {
    marginLeft: 20,
  },
  closeIcon: {
    position: 'absolute',
    top: 20,
    right: 18,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  content: {
    padding: '15px 30px 30px',
  },
}));

function DialogVideo(props) {
  const { title, onClose, open, link, toggleIsVideo, ...other } = props;

  const classes = useStyles();

  const embedVideo = link.includes('embed');

  const treatedLink = embedVideo
    ? link
    : `https://youtube.com/embed/${link.split('v=')[1].split('&')[0]}`;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="xl"
      aria-labelledby="video-dialog"
      open={open}
      {...other}
      scroll="body"
    >
      <DialogTitle id="video-dialog" className={classes.dialogTitle}>
        <Grid container>
          <SchoolIcon fontSize="small" />
          <Typography variant="h2" className={classes.titleHeader}>
            {title}
          </Typography>
          <Tooltip title="Fechar">
            <IconButton
              className={classes.closeIcon}
              onClick={() => handleClose()}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {link && toggleIsVideo && (
          <iframe
            title={title}
            type="text/html"
            width="640"
            height="360"
            frameBorder="0"
            src={embedVideo ? link : treatedLink}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DialogVideo;
