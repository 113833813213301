import React, { useState } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import { FaBoxes, FaPlus } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import ListaProdutos from '../../views/Produtos/ListaProdutos';
import ModalCadastroProduto from '../../components/Modal/ModalCadastroProduto';

function Page(props) {

  const { produtos, terceiros, cadastroProduto, setCadastroProduto, handleOnChange, salvarProdutos, handleCheck, text, setText, gruposVenda, modal, setModal, toggle } = props;
  const titleHeader = <><FaBoxes/>&nbsp;&nbsp; Produtos</>;

  return (
    <Container fluid={true}>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mt-4 text-right">
        <Col lg={12}>
          <Button size="sm" color="success" onClick={() => setModal(true)}><FaPlus/> &nbsp; Cadastrar Produto</Button>
          </Col>
      </Row>
      {
        (modal)&&
        <ModalCadastroProduto
          toggle={toggle}
          modal={modal}
          terceiros={terceiros}
          cadastroProduto={cadastroProduto}
          setCadastroProduto={setCadastroProduto}
          handleCheck={handleCheck}
          handleOnChange={handleOnChange}
          salvarProdutos={salvarProdutos}
          text={text}
          setText={setText}
          gruposVenda={gruposVenda}
        />
      }
      <Row className="mb-4">
        <ListaProdutos {...props} />
      </Row>

    </Container>
  )
}

export default Page;
