import React, { useState, useEffect } from 'react';
// import { Col, Row, Button, Card } from 'reactstrap';
// import {
//   FaSearch,
//   // FaPlus
// } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
// import MaterialSelect from '../../components/Inputs/MaterialSelect';

import { Grid, Paper, IconButton, Link, Tooltip } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import {
  SearchTextField,
  FilterTextField,
} from '../../components/Inputs/TextFields';
import { RegisterButton, ExportButton } from '../../components/Buttons/Buttons';
// import SimpleTable from '../../components/TablesGrid/SimpleTable';

import theme from '../../styles/theme';

import DataGridLayout from '../../components/TablesGrid/DataGrid';

const statusField = [
  {
    value: -1,
    label: 'Todos',
  },
  {
    value: 1,
    label: 'Ativos',
  },
  {
    value: 0,
    label: 'Inativos',
  },
];

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const Convenios = props => {
  const history = useHistory();
  const { resultConvenio } = props;
  const [filteredEmpresas, setFilteredEmpresas] = useState(resultConvenio.data);
  const [searchText, setSearchText] = useState('');

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = resultConvenio.data.filter(row => {
      return Object.keys(row).some(field => {
        if (field === 'nome' || field === 'cnpj' || field === 'id') {
          return searchRegex.test(row[field]);
        }
        return '';
      });
    });
    setFilteredEmpresas(filteredRows);
  };

  useEffect(() => {
    setFilteredEmpresas(resultConvenio.data);
  }, [resultConvenio]);

  const columns = [
    {
      field: 'nome',
      headerName: 'Nome do convênio',
      headerAlign: 'center',
      flex: 1,
      minWidth: 280,
      sortable: false,
      renderCell: params => (
        <Grid container justifyContent="space-between">
          <Grid item>
            <Link
              component="button"
              color="inherit"
              underline="always"
              onClick={() => history.push(`/convenio/${params.row.id}`)}
            >
              {params.value}
            </Link>
          </Grid>
          <Grid item>
            <Tooltip
              placement="top"
              title="Informações adicionais"
              aria-label="Informações adicionais"
            >
              <IconButton
                onClick={() => history.push(`/convenio/${params.row.id}`)}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      headerAlign: 'center',
      flex: 0.7,
      minWidth: 160,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Identificador (ID)',
      headerAlign: 'center',
      flex: 0.6,
      minWidth: 140,
      sortable: false,
      type: 'number',
      valueFormatter: params => {
        const valueFormatted = parseInt(params.value, 10);
        return valueFormatted;
      },
    },
    {
      field: 'qtdVidas',
      headerName: 'Quantidade de vidas',
      headerAlign: 'center',
      flex: 0.8,
      minWidth: 160,
      sortable: false,
      valueGetter: params =>
        params.row.nomes_funcionarios === null
          ? 0
          : params.row.nomes_funcionarios.split(',').length,
    },
    {
      // TODO alterar as cores da descrição conforme o tipo do status
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      valueFormatter: params => {
        const valueFormatted = params.value === 1 ? 'Ativo' : 'Inativo';
        return valueFormatted;
      },
    },
  ];

  // const columns = [
  //   { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
  //   { name: 'cpf', title: 'CNPJ', getCellValue: row => row.cnpj },
  //   { name: 'id', title: '#ID', getCellValue: row => parseInt(row.id, 10) },
  //   {
  //     name: 'status',
  //     title: 'Status',
  //     getCellValue: row => (row.status == 1 ? 'Ativos' : 'Inativos'),
  //   },
  //   { name: '', title: 'Ações', getCellValue: row => actions(row) },
  // ];

  // const actions = row => {
  //   return (
  //     <div className="btn-group">
  //       <Button
  //         size="sm"
  //         color="primary"
  //         outline
  //         onClick={() => history.push(`/convenio/${row.id}`)}
  //       >
  //         <FaSearch />
  //       </Button>
  //       &nbsp;
  //     </div>
  //   );
  // };

  // const handleChangeStatus = value => {
  //   if (value == -1) {
  //     setFilteredEmpresas(resultConvenio.data);
  //     return;
  //   }
  //   setFilteredEmpresas(
  //     resultConvenio.data.filter(empresa => empresa.status == value),
  //   );
  // };

  const handleChangeStatus = e => {
    if (e.target.value === -1) {
      setFilteredEmpresas(resultConvenio.data);
      return;
    }

    setFilteredEmpresas(
      resultConvenio.data.filter(empresa => empresa.status == e.target.value),
    );
  };

  return (
    // <Card className="my-4 w-100">
    //   <Row className="px-3 mt-3 justify-content-between align-items-center">
    //     <Button
    //       color="success"
    //       size="sm"
    //       outline
    //       onClick={() => history.push('/cadastrar-convenio')}
    //     >
    //       <FaPlus />
    //       &nbsp;&nbsp;Cadastrar Convênio
    //     </Button>
    //     <MaterialSelect
    //       label="Status"
    //       handleChange={handleChangeStatus}
    //       options={[
    //         { value: -1, label: 'Todos' },
    //         { value: 1, label: 'Ativos' },
    //         { value: 0, label: 'Inativos' },
    //       ]}
    //     />
    //   </Row>
    //   <Row className="mt-4 mb-4">
    //     <Col xs={12}>
    //       <SimpleTable
    //         height="500px"
    //         rows={filteredEmpresas}
    //         columns={columns}
    //         loading={resultConvenio.loading}
    //       />
    //     </Col>
    //   </Row>
    // </Card>

    <Grid container spacing={2}>
      <Grid item xs="auto" container>
        <Grid item xs={12} md={6} align="left">
          <SearchTextField
            value={searchText}
            onChange={e =>
              e.target.value ? requestSearch(e.target.value) : requestSearch('')
            }
            clearSearch={() => requestSearch('')}
            toolTipTitle="Considere pontos e acentos na pesquisa"
            style={{ width: '201px' }}
          />
          <FilterTextField
            style={{ width: '201px', marginLeft: theme.spacing(2) }}
            name="status"
            label=""
            placeholder="Status"
            onChange={handleChangeStatus}
            options={statusField}
            defaultValue={1}
          />
        </Grid>
        <Grid item xs={12} md={6} align="right">
          {/* // TODO implementar lógica do botão exportar no back-end */}
          {/* <ExportButton /> */}
          <RegisterButton
            onClick={() => history.push('/cadastrar-convenio')}
            style={{ marginLeft: theme.spacing(2) }}
          >
            Cadastrar novo convênio
          </RegisterButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 30 }}>
          <DataGridLayout
            rows={filteredEmpresas}
            columns={columns}
            loading={resultConvenio.loading}
            checkboxSelection
            disableSelectionOnClick
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Convenios;
