import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaUserPlus } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import ClientRegisterRenovation from '../../views/CadastroClienteRenovacao/CadastroClienteRenovacao';

const titleHeader = (
  <>
    <FaUserPlus />
    &nbsp;&nbsp;Cadastro Cliente de Renovação
  </>
);

export default function Page(props) {
  return (
    <Container className="mb-4" fluid>
      <Row className="text-left" style={{ alignItems: 'center' }}>
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ClientRegisterRenovation
            {...props}
            mostraInfoEndereco
            mostraInfoDependente
          />
        </Col>
      </Row>
    </Container>
  );
}
