import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import Page from './Page';
// import { sendFormData } from '../../services/getApi';
import Api from '../../services/api';
import { ordenaPorId } from '../../services/service';
// import { msg } from '../../json/msg';
// import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

import theme from '../../styles/theme';

function Controller(props) {
  const { id } = useParams();
  const { user } = props;
  const authStr = `Bearer ${user.user.token}`;

  const [loading, setLoading] = useState(false);
  const [infosConvenio, setInfosConvenio] = useState({
    loading: true,
    data: {},
  });
  const [infosConveniados, setInfosConveniados] = useState({
    loading: true,
    data: [],
  });
  const [assessoria, setAssessoria] = useState([{ id: '', name: '' }]);
  // const [btnDisabled, setBtnDisabled] = useState(false);
  const [params, setParams] = useState({
    idProduto: 0,
  });
  const [faturas, setFaturas] = useState({ loading: true, data: [] });

  useEffect(() => {
    getConvenio();
    // getContatos();
    getConveniados();
    // buscarAssessoria();
    getFaturas();
  }, [params]);

  const getConvenio = async () => {
    if (id) {
      const response = await Api.get(`/convenio/${id}`, {
        headers: { Authorization: authStr },
      });
      setInfosConvenio({ loading: false, data: response.data.output });
    }
  };

  const getConveniados = async () => {
    const response = await Api.get(
      `/segurados/apolices/?id_estipulante=${id}`,
      {
        headers: { Authorization: authStr },
      },
    );
    if (Object.keys(response.data.output).length > 0) {
      ordenaPorId(response.data.output);
    }
    setInfosConveniados({ loading: false, data: response.data.output });
  };

  // const buscarAssessoria = async () => {
  //   const request = await Api.get(`/assessorias/`, {
  //     headers: { Authorization: authStr },
  //   });
  //   let assessoriaArr = [];
  //   for (const i in request.data.output) {
  //     const tmpObj = {
  //       name: request.data.output[i].nome,
  //       id: request.data.output[i].id,
  //       id_usuario: request.data.output[i].id_usuario,
  //     };
  //     assessoriaArr = [...assessoriaArr, tmpObj];
  //   }
  //   setAssessoria(assessoriaArr);
  // };

  const getFaturas = async () => {
    try {
      const response = await Api.get(`/convenio/faturas/${id}`, {
        // TODO colocar rota correta
        headers: { Authorization: authStr },
      });
      if (!response.data.ok) {
        throw new Error(response.data.message);
      }
      setFaturas({ loading: false, data: response.data.output });
    } catch (error) {
      console.log('Erro: ', error.message);
      setFaturas({ loading: false, data: [] });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Page
        infosConvenio={infosConvenio.data}
        infosConveniados={infosConveniados.data}
        idEstipulante={id}
        loading={loading}
        // params={params}
        // setParams={setParams}
        // btnDisabled={btnDisabled}
        authStr={authStr}
        faturas={faturas}
      />
    </ThemeProvider>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
