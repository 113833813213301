import { Box, Container, IconButton } from '@material-ui/core';
import React from 'react';
import { FaArrowLeft, FaFileInvoiceDollar } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Filtros from '../../views/Comissoes/Filtro';
import ComissoesTable from '../../views/Comissoes/ComissoesTable';

function Page(props) {
  const {
    comissoes,
    setPeriodo,
    periodo,
    getExcel,
    setFireGetComissoes,
    fireGetComissoes,
  } = props;

  const history = useHistory();

  const columns = [
    { field: 'id_assessoria', headerName: 'ID', width: 90 },
    {
      field: 'numPrimeirasFaturas',
      headerName: 'Primeiras Faturas',
      width: 150,
    },
    {
      field: 'numDemaisFaturas',
      headerName: 'Demais Faturas',
      width: 150,
      editable: true,
    },
    {
      field: 'valor',
      headerName: 'valor',
      type: 'number',
      width: 110,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      width: 160,
      valueGetter: params =>
        `${params.getValue(params.id, 'firstName') || ''} ${
          params.getValue(params.id, 'lastName') || ''
        }`,
    },
  ];

  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" my="1rem">
        <IconButton
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          <FaArrowLeft size="1.1rem" />
        </IconButton>
        <Box
          ml="1.5rem"
          fontSize="2.2rem"
          fontWeight="100"
          display="flex"
          alignItems="center"
        >
          <FaFileInvoiceDollar />
          <Box ml="0.7rem">Comissões de vendas</Box>
        </Box>
      </Box>
      <hr />
      <Filtros
        loadingComissoes={comissoes.loading}
        setPeriodo={setPeriodo}
        periodo={periodo}
        setFireGetComissoes={setFireGetComissoes}
        fireGetComissoes={fireGetComissoes}
      />
      <ComissoesTable
        rows={comissoes}
        columns={columns}
        periodo={periodo}
        getExcel={getExcel}
      />
    </Container>
  );
}

export default Page;
