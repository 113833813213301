import React, { useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';

import CardMaterialApoio from '../../components/MaterialApoio/Card';

import VoidContent from '../../assets/img/conteudo-vazio.svg';

import useStyles from './styles';

function ListaTreinamentos(props) {
  const { materialApoio, getListMaterialApoio } = props;

  const classes = useStyles();

  const MessageSemMaterialApoio = () => {
    return (
      <Grid item sm={12} className={classes.gridVoidlistaMaterialApoio}>
        <img src={VoidContent} alt="Abas sem conteúdo" />
        <Typography variant="body2" className={classes.titleVoidMaterialApoio}>
          Que pena!
        </Typography>
        <Typography variant="body1">
          Ainda não há materiais de treinamento cadastrados
        </Typography>
      </Grid>
    );
  };

  useEffect(() => {
    getListMaterialApoio('treinamento');
  }, []);

  return (
    <>
      {materialApoio.length === 0 ? (
        <MessageSemMaterialApoio />
      ) : (
        materialApoio &&
        materialApoio.map(treinamento => {
          return (
            <Grid item sm={12} md={6} lg={4} xl={3} key={treinamento.id}>
              <CardMaterialApoio
                {...props}
                idMaterial={treinamento.id}
                img={treinamento.link_imagem}
                nome={treinamento.nome}
                descricao={treinamento.descricao}
                tipoApresentacao={treinamento.tipo_apresentacao}
                linkApresentacao={treinamento.link}
              />
            </Grid>
          );
        })
      )}
    </>
  );
}

export default ListaTreinamentos;
