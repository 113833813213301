import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Page from './Page';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

function Controller(props) {
  const { idAssessoria } = useParams();

  const { user } = props;
  const { token } = user.user;

  const history = useHistory();

  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [assessorias, setAssessorias] = useState([]);

  const [assessoria, setAssessoria] = useState({
    data: {},
    comissao: {},
    dados_bancarios: {},
    endereco: {},
    loading: true,
  });

  const [vendedores, setVendedores] = useState({
    data: [],
    loading: true,
  });

  const [ gruposVenda, setGruposVenda ] = useState([{}]);

  useEffect(() => {
    if (idAssessoria !== 0) {
      getDadosAssessoria();
      getVendedores();
      getGruposVenda();
      buscarAssessorias();
    }
  }, [idAssessoria]);

  const getDadosAssessoria = async () => {
    try {
      const responseAssessoria = await Api.get(`/assessoria/${idAssessoria}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const idPessoa = responseAssessoria.data.output.id_pessoa;
      const idUsuario = responseAssessoria.data.output.id_usuario;

      if (responseAssessoria.data.ok) {
        const responseComissao = await Api.get(`/comissao/${idUsuario}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const responseEndereco = await Api.get(`/enderecos/${idPessoa}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setAssessoria({
          data: responseAssessoria.data.output,
          comissao: responseComissao.data.output,
          endereco: responseEndereco.data.output[0],
          loading: false,
        });
      }
    } catch (error) {
      history.goBack()
    }
  };

  const getVendedores = async () => {
    setVendedores({ data: [], loading: true });

    try {
      const response = await Api.get(
        `/corretor-representante/assessoria/${idAssessoria}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.ok) {
        setVendedores({ data: response.data.output, loading: false });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log('Erro ao buscar vendedores', error.message);
      setVendedores({ data: [], loading: false });
    }
  };

  const getGruposVenda = async _ => {
    let fetchGrupoVendas = await Api.get('/grupovendas', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
    if(fetchGrupoVendas.data.ok) {
        setGruposVenda(fetchGrupoVendas.data.output);
    }
  }

  const buscarAssessorias = async () => {
    const request = await Api.get('/assessorias/', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setAssessorias(request.data);
  };

  const submitChangeStatus = async vendedoresEditados => {
    setLoadingChangeStatus(true);
    try {
      const response = await Api.put(
        '/corretor-representante',
        vendedoresEditados,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.ok) {
        alertSuccess('Vendedor(es) editado(s) com sucesso!');
        getVendedores();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao editar vendedor(es)', error.message);
    }
    setLoadingChangeStatus(false);
  };

  const excluirVendedor = async data => {
    const response = await Api.post('corretor-representante/deletar', data, { headers: { Authorization: `Bearer ${token}` }} );
    if(response.data.ok){
      alertSuccess('Vendedor excluído com sucesso.');
      getVendedores();
    } else {
      alertError(`${response.data.message}`);
    }
  }

  return (
    <>
      <Page
        assessoria={assessoria}
        vendedores={vendedores}
        submitChangeStatus={submitChangeStatus}
        loadingChangeStatus={loadingChangeStatus}
        getVendedores={getVendedores}
        getDadosAssessoria={getDadosAssessoria}
        gruposVenda={gruposVenda}
        user={user}
        excluirVendedor={excluirVendedor}
        assessorias={assessorias}
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
