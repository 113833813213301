import React, { useState, useEffect } from 'react'
import Page from './Page';
import Api from '../../services/api';
import { connect } from 'react-redux';
import { checkSafePassword, deixaSomenteInteiros } from '../../services/service';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

function Controller(props) {

  const { token } = props.user.user;
  const authStr = `Bearer ${token}`;

  const [ terceirosList, setTerceirosList ] = useState([{}]);
  const [ parceiro, setParceiro ] = useState({
    nome: '',
    descricao: '',
    contatoAcionamento: '',
    custo: '',
    clausulaPadrao: [],
  });

  useEffect(
    () => {
      checkSafePassword(props.user);
      getTerceiros();
    }, []
  )

  const getTerceiros = async _ => {
    const request = await Api.get( "/terceiros/", { headers: { Authorization: authStr }});
    if(request.data.ok){
      setTerceirosList(request.data.output);
    }
  }

  const cadastrarBeneficio = async _ => {
    const result = await Api.post("/terceiro", parceiro, { headers: { Authorization: authStr }});
    if(result.data.ok){
      window.location.reload();
    }else{
      alertError(result.data.message);
    }
  }

  // const confirmarExclusao = (id) => {
  //   if (id) {
  //     const ask = window.confirm(
  //       'Tem certeza que deseja excluir este benefício?',
  //     );
  //     if (ask) {
  //       excluirBeneficio(id);
  //     }
  //     return false;
  //   }
  // };

  const excluirBeneficio = async id => {

    const response = await Api.post('terceiro/deletar', {id: id}, { headers: { Authorization: `Bearer ${token}` }} );

    if(response.data.ok){
      alertSuccess('Beneficio excluído com sucesso.');
      getTerceiros();
    }
  }

  const handleOnChange = e => {
    let { name, value } = e.target;
    if(name === 'contatoAcionamento'){
      value = deixaSomenteInteiros(value);
    }
    setParceiro({ ...parceiro, [name]: value });
  }

  return (
    <>
      <Page
        parceiro={parceiro}
        handleOnChange={handleOnChange}
        cadastrarBeneficio={cadastrarBeneficio}
        terceirosList={terceirosList}
        excluirBeneficio={excluirBeneficio}
      />
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);
