import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Row, Col, Button, FormGroup, Label } from 'reactstrap';
import { SectionFormHeader, LabelForm  } from '../../views/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Switch } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

function ModalCadastroProduto(props) {

  const {
    toggle,
    modal,
    cadastroProduto,
    handleOnChange,
    handleCheck,
    terceiros,
    salvarProdutos,
    text,
    setText,
    gruposVenda,
    setCadastroProduto
  } = props;

  const handleEditorChange = (content, editor) => {
    setText(content.target.getContent());
    setCadastroProduto({ ...cadastroProduto, contrato: content.target.getContent() });
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const toggleLimiteIdade = e => {
    setCadastroProduto({ ...cadastroProduto, limiteIdade: e.target.checked });
  };

  return (
    <Modal size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}> <SectionFormHeader>Seu Produto</SectionFormHeader> </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <LabelForm>Nome Produto</LabelForm>
            <Input type="text" name="nome" value={cadastroProduto.nome} onChange={e => handleOnChange(e) }/>
          </Col>
          <Col className="mt-3" lg={12}>
            <LabelForm>Descrição</LabelForm>
            <Input type="textarea" name="descricao" value={cadastroProduto.descricao} onChange={e => handleOnChange(e)} />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" lg={12}>
            <LabelForm>Grupos de Venda</LabelForm>
            <Input type="select" className="input-filter-dist" name="id_grupo_vendas" onChange={e => handleOnChange(e)}>
              <option value="">Escolha um Grupo de Venda</option>
              {
                gruposVenda.map((gpVenda, i) => {
                  return <option key={i} value={gpVenda.id}>{gpVenda.nome}</option>
                })
              }
            </Input>
          </Col>
        </Row>
        <Row className="mt-3" lg={12}>
          <Col>
            <LabelForm>Benefícios</LabelForm>

            {
              terceiros.map((terceiro,i) => {

                return(
                  <Col lg={4} key={i} className="mt-2 mb-4">
                    <FormGroup check>
                      <Label check>
                        <Input id={terceiro.id} name={terceiro.id} type="checkbox" onChange={e => handleCheck(e)} />{' '}{terceiro.nome}
                      </Label>
                    </FormGroup>
                  </Col>
                )
              })
            }
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={3}>
            <LabelForm>Preço Titular</LabelForm>
            <Input type="number" name="precoTitular" value={cadastroProduto.precoTitular} onChange={e => handleOnChange(e)} />
          </Col>
          <Col lg={3}>
            <LabelForm>Preço Dep.1</LabelForm>
            <Input type="number" name="precoDep1" value={cadastroProduto.precoDep1} onChange={e => handleOnChange(e)} />
          </Col>
          <Col lg={3}>
            <LabelForm>Preço Dep.2</LabelForm>
            <Input type="number" name="precoDep2" value={cadastroProduto.precoDep2} onChange={e => handleOnChange(e)} />
          </Col>
          <Col lg={3}>
            <LabelForm>Possui limite de idade? &nbsp;</LabelForm>
            <Switch
              checked={cadastroProduto.limiteIdade}
              onChange={toggleLimiteIdade}
              name="limiteIdade"
              size="small"
              color="secondary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <LabelForm>Contrato</LabelForm>
            {/* <ReactQuill
              theme="snow"
              name="text"
              value={text}
              onChange={(content, delta, source, editor) => setText(content)}
              // onChange={e => handleQuillChange(e)}
              modules={modules}
              formats={formats}
            /> */}
            <Editor
              apiKey='j76q3j3i4r0fwsilb6y0n04ylc4p0iet4j9nv3yakpv4z8a0'
              // onInit={(evt, editor) => editorRef.current = editor}
              onChange={handleEditorChange}
              initialValue=''
              init={{
                  height: 500,
                  menubar: false,
                  plugins: [],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }
              }
            />
          </Col>
        </Row>

        <Row className="text-right mt-4">
          <Col>
            <Button color="success" size="sm" onClick={() => salvarProdutos()}>Salvar Produto</Button>
          </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default ModalCadastroProduto;
