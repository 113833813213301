import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaUserFriends } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
import CadastrarClinica from '../../views/Clinicas/CadastrarClinica';
import Loading from '../../components/Loading/Loading';
import ListaClinicas from '../../views/Clinicas/ListaClinicas';

function Page(props) {
  const { clinicas, loading, getClinicas, gruposVenda } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const titleHeader = (
    <>
      <FaUserFriends />
      &nbsp;&nbsp;Clínicas:
    </>
  );
  return (
    <Container fluid>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
        <Col className="text-right mt-2" md={12}>
          {modal && (
            <CadastrarClinica
              modal={modal}
              toggle={toggle}
              getClinicas={getClinicas}
              clinicas={clinicas}
              loadingClinicas={loading}
              gruposVenda={gruposVenda}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        {loading ? (
          <Col className="text-center">
            <Loading loading={loading} />
          </Col>
        ) :
          <ListaClinicas
            clinicas={clinicas.output}
            loading={loading}
            toggle={toggle}
          />
        }
      </Row>
    </Container>
  );
}

export default Page;
