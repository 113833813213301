import React from 'react';

import { Grid, Typography } from '@material-ui/core';

// import {
//   Row,
//   Col,
//   Input,
//   InputGroup,
//   InputGroupText,
//   InputGroupAddon,
// } from 'reactstrap';
// import { FaUser, FaPhone, FaMobileAlt, FaEnvelope } from 'react-icons/fa';
// import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
// import { SectionFormHeader } from '../styles';

import {
  DefaultFormTextField,
  PhoneTextField,
} from '../../components/Inputs/TextFields';

const useStyles = makeStyles(() => ({
  sectionFormHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionFormTitle: {
    marginLeft: 20,
  },
  sectionFormBody: {
    flexDirection: 'row',
  },
}));

function CadastroContatoConvenio(props) {
  const { contato, handleContatoOnChange } = props;

  const classes = useStyles();

  return (
    <Grid item xs="auto" container spacing={6}>
      <Grid item xs="auto" container spacing={4}>
        <Grid item xs="auto" className={classes.sectionFormHeader}>
          <PhoneIcon />
          <Typography variant="h3" className={classes.sectionFormTitle}>
            Contato da empresa e responsáveis
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
          spacing={2}
          container
          className={classes.sectionFormBody}
        >
          <Grid item xs={12} md={6}>
            <DefaultFormTextField
              id="contato-nome-responsavel"
              label="Nome do responsável"
              name="nome_responsavel"
              value={contato.nome_responsavel}
              required
              onChange={e => handleContatoOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultFormTextField
              id="contato-cargo"
              label="Cargo/ função"
              name="cargo"
              value={contato.cargo}
              required
              onChange={e => handleContatoOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneTextField
              id="contato-telefone1"
              label="Telefone principal"
              name="telefone"
              value={contato.telefone}
              required
              onChange={e => handleContatoOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneTextField
              id="contato-telefone2"
              label="Telefone secundário"
              name="celular"
              value={contato.celular}
              onChange={e => handleContatoOnChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultFormTextField
              type="email"
              id="contato-email"
              label="E-mail"
              name="email"
              value={contato.email}
              required
              onChange={e => handleContatoOnChange(e)}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <Row className="mb-3">
        <Col md={12}>
          <SectionFormHeader>Informações do Contato</SectionFormHeader>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaUser />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              required
              name="nome_responsavel"
              value={contato.nome_responsavel}
              placeholder="Nome do Responsável"
              onChange={e => handleContatoOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaPhone />
              </InputGroupText>
            </InputGroupAddon>
            <InputMask
              mask="(99) 99999-9999"
              required
              className="form-control"
              name="telefone"
              value={contato.telefone}
              placeholder="Telefone"
              onChange={e => handleContatoOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={6} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaMobileAlt />
              </InputGroupText>
            </InputGroupAddon>
            <InputMask
              mask="(99) 99999-9999"
              name="celular"
              value={contato.celular}
              className="form-control"
              placeholder="Celular"
              onChange={e => handleContatoOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={12} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaEnvelope />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="email"
              required
              name="email"
              value={contato.email}
              placeholder="E-mail"
              onChange={e => handleContatoOnChange(e)}
            />
          </InputGroup>
        </Col>
      </Row> */}
    </Grid>
  );
}

export default CadastroContatoConvenio;
