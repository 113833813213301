import React from 'react';

// import { useHistory } from 'react-router-dom';
import { Paper, Tooltip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import NoContent from '../../components/Card/NoContent';
import DataGridLayout from '../../components/TablesGrid/DataGrid';

export default function EdicaoSeguradosApolices(props) {
  const {
    setCheckedApolices,
    checkedApolices,
    // handleChangeCheckbox,
    tipoAcao,
    policies,
  } = props;

  // const history = useHistory();

  const columns = [
    {
      field: 'id_apolice',
      headerName: 'Apólice',
      headerAlign: 'center',
      flex: 0.3,
      minWidth: 50,
      sortable: false,
      renderCell: params => (
        <Tooltip
          placement="top"
          title="Ver mais em outra aba."
          aria-label="Ver mais em outra aba."
        >
          <Link
            to={`/segurado/detalhe/${params.row?.id_segurado}`}
            target="_blank"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {params.value}
            <OpenInNewIcon style={{ fontSize: 14, marginLeft: 8 }} />
          </Link>
        </Tooltip>
      ),
    },
    {
      field: 'cpf_segurado',
      headerName: 'CPF',
      headerAlign: 'center',
      // flex: 0.4,
      minWidth: 130,
      sortable: false,
    },
    {
      field: 'nome_segurado',
      headerName: 'Nome',
      headerAlign: 'center',
      flex: 0.6,
      minWidth: 160,
      sortable: false,
    },
    {
      field: 'inicio_vigencia',
      headerName: 'Início vigência',
      headerAlign: 'center',
      // flex: 0.4,
      minWidth: 130,
      sortable: false,
    },
    {
      field: 'vendedor',
      headerName: 'Consultor',
      headerAlign: 'center',
      // flex: 0.5,
      minWidth: 165,
      sortable: false,
    },
    {
      field: 'descricao_apolice',
      headerName: 'Descrição apólice',
      headerAlign: 'center',
      // flex: 0.5,
      minWidth: 150,
      sortable: false,
    },
    {
      // TODO alterar as cores da descrição conforme o tipo do status
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 0.4,
      minWidth: 140,
      sortable: false,
      // valueFormatter: params => {
      //   const valueFormatted = params.value === 1 ? 'Ativo' : 'Inativo';
      //   return valueFormatted;
      // },
    },
  ];

  return (
    <>
      {policies?.length > 0 ? (
        <form name="acoesApolices">
          <input type="hidden" name="tipoAcao" value={tipoAcao} />
          <Paper style={{ padding: 8 }}>
            <DataGridLayout
              rows={policies}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              hasToolbar
              density="compact"
              hideSelectedRowCount={false}
              onSelectionModelChange={ids => {
                const apolicesIds = ids.map(id => {
                  const apolices = policies.find(i => i.id === id);
                  return apolices.id_apolice;
                });
                setCheckedApolices(apolicesIds);
              }}
              selectionModel={checkedApolices}
            />
          </Paper>
        </form>
      ) : (
        <NoContent text="Nenhuma apólice encontrada" hasImage />
      )}
    </>
  );
}
