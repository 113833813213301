import { Box, Container, IconButton } from '@material-ui/core';
import React from 'react';
import { FaArrowLeft, FaFileExcel, FaFileInvoiceDollar } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import Filtros from '../../views/ComissoesSplit/Filtro';
import ComissoesTable from '../../views/ComissoesSplit/ComissoesTable';
import TotalizadoresComissoes from '../../views/ComissoesSplit/TotalizadoresComissoes';
import ExportarComissoes from '../../views/ComissoesSplit/ExportarComissoes';

function Page(props) {
  const {
    comissoes,
    pesquisa,
    setPesquisa,
    setPeriodo,
    periodo,
    setStatusPagamento,
    statusPagamento,
    setDistribuidorMaster,
    distribuidorMaster,
    setDistribuidorAfiliado,
    distribuidorAfiliado,
    // getExcel,
    setFireGetComissoes,
    fireGetComissoes,
    nivelPermissao,
    distribuidorMasterSelect,
    distribuidorAfiliadoSelect,
    exportar,
    linkBaixar,
    setModalBaixar,
    modalBaixar,
  } = props;

  const history = useHistory();

  // const columns = [
  //   {
  //     field: 'id_fatura',
  //     headerName: 'ID Fatura',
  //     width: 90
  //   },
  //   {
  //     field: 'id_assessoria',
  //     headerName: 'ID Assessoria',
  //     width: 150,
  //   },
  //   {
  //     field: 'numDemaisFaturas',
  //     headerName: 'Demaisss Faturas',
  //     width: 150,
  //     editable: true,
  //   },
  //   {
  //     field: 'valor',
  //     headerName: 'valor',
  //     type: 'number',
  //     width: 110,
  //   },
  //   {
  //     field: 'actions',
  //     headerName: 'Ações',
  //     sortable: false,
  //     width: 160,
  //     valueGetter: params =>
  //       `${params.getValue(params.id, 'firstName') || ''} ${
  //         params.getValue(params.id, 'lastName') || ''
  //       }`,
  //   },
  // ];

  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" my="1rem">
        <IconButton
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          <FaArrowLeft size="1.1rem" />
        </IconButton>
        <Box
          ml="1.5rem"
          fontSize="2.2rem"
          fontWeight="100"
          display="flex"
          alignItems="center"
        >
          <FaFileInvoiceDollar />
          <Box ml="0.7rem">Relatório de Split</Box>
        </Box>
      </Box>
      <hr />
      <Filtros
        loadingComissoes={comissoes.loading}
        setPesquisa={setPesquisa}
        pesquisa={pesquisa}
        setPeriodo={setPeriodo}
        periodo={periodo}
        setStatusPagamento={setStatusPagamento}
        statusPagamento={statusPagamento}
        setDistribuidorMaster={setDistribuidorMaster}
        distribuidorMaster={distribuidorMaster}
        setDistribuidorAfiliado={setDistribuidorAfiliado}
        distribuidorAfiliado={distribuidorAfiliado}
        setFireGetComissoes={setFireGetComissoes}
        fireGetComissoes={fireGetComissoes}
        distribuidorMasterSelect={distribuidorMasterSelect}
        distribuidorAfiliadoSelect={distribuidorAfiliadoSelect}
        nivelPermissao={nivelPermissao}
      />
      <ComissoesTable
        rows={comissoes}
        periodo={periodo}
        nivelPermissao={nivelPermissao}
      />
      <TotalizadoresComissoes
        nivelPermissao={nivelPermissao}
        comissoes={comissoes}
        exportar={exportar}
        linkBaixar={linkBaixar}
        setModalBaixar={setModalBaixar}
        modalBaixar={modalBaixar}
      />
    </Container>
  );
}

export default Page;
