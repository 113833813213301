import React from 'react';

// import { Container, Row, Col, Button } from 'reactstrap';
// import { FaArrowLeft, FaBuilding } from 'react-icons/fa';
// import TitleContent from '../../components/TitleContent/TitleContent';

// import { useHistory } from 'react-router-dom';

import { Container, Grid, Typography } from '@material-ui/core';

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import { makeStyles } from '@material-ui/core/styles';
import Convenios from '../../views/Convenios/BuscaConvenio';

// const titleHeader = (
//   <>
//     <FaBuilding />
//     &nbsp;&nbsp;Convênios
//   </>
// );

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px 30px',
  },
  header: {
    alignItems: 'center',
  },
  titleHeader: {
    marginLeft: 20,
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
}));

const Page = props => {
  // const history = useHistory();

  const classes = useStyles();

  return (
    // <Container fluid>
    //   <Row className="mt-4">
    //     <Col md={2}>
    //       <Button
    //         className="btn-back w-100"
    //         outline
    //         color="danger"
    //         size="sm"
    //         onClick={() => history.goBack()}
    //       >
    //         <FaArrowLeft />
    //         &nbsp;&nbsp;Voltar
    //       </Button>
    //     </Col>
    //   </Row>
    //   <Row className="text-left">
    //     <Col md={12}>
    //       <TitleContent titleHeader={titleHeader} />
    //     </Col>
    //   </Row>
    //   <Row className="mb-4">
    //     <Convenios {...props} />
    //   </Row>
    // </Container>
    <Container
      component="section"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <Grid container>
        <Grid
          item
          component="header"
          xs={12}
          container
          className={classes.header}
        >
          <BusinessCenterIcon />
          <Typography variant="h1" className={classes.titleHeader}>
            Convênios
          </Typography>
        </Grid>
        <Grid item component="article" xs={12} className={classes.content}>
          <Convenios {...props} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page;
