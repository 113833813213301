import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  gridVoidlistaMaterialApoio: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  titleVoidMaterialApoio: {
    marginTop: 30,
  },
}));

export default useStyles;
