import React from 'react';
import { FaEdit, FaRegWindowClose, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Col, Button } from 'reactstrap';
import { alertSuccess } from '../../components/Alerts/Alerts';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import api from '../../services/api';
import { formatDate } from '../../services/service';

export default function ListaParceiros(props) {

  const history = useHistory();

  const { terceirosList, toggleModalExclusao } = props;

  const actions = beneficio => (
    <div>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="primary"
        onClick={() =>
          history.push({
            pathname: `/beneficio/${beneficio.id}`,
          })
        }
      >
        <FaEdit />
      </Button>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="danger"
        onClick={() =>
          toggleModalExclusao(beneficio.id)
        }
      >
        <FaRegWindowClose />
      </Button>
    </div>
  );

  let columns = [
    // { name: 'id', title: 'Id', getCellValue: row => parseInt(row.id), width: "10px" },
    { name: 'nome', title: 'Nome', getCellValue: row => (row.nome) },
    { name: 'descricao', title: 'Descrição', getCellValue: row => (row.descricao) },
    { name: 'custo', title: 'Custo', getCellValue: row => (row.custo ? 'R$ ' + Number(row.custo).toFixed(2): '-') },
    { name: 'created', title: 'Criado em', getCellValue: row => (row.created ? formatDate(row.created.split(" ")[0]) : '') },
    { name: 'Ações', title: '', getCellValue: row => (actions(row))},
  ];

  return (
    <>
      <Col lg={12}>
        <TablesGrid rows={terceirosList} columns={columns} />
      </Col>
    </>
  )
}
