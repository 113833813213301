import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { FaRegMoneyBillAlt } from 'react-icons/fa';
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';

import { PrependInputLabel, SectionFormHeader } from '../styles';

import {
  DefaultFormTextField,
  SelectTextField,
} from '../../components/Inputs/TextFields';

const useStyles = makeStyles(() => ({
  sectionFormHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionFormTitle: {
    marginLeft: 20,
  },
  sectionFormBody: {
    flexDirection: 'row',
  },
}));

function CadastroBeneficios(props) {
  const { empresa, handleConvenioOnChange } = props;

  const classes = useStyles();

  return (
    <Grid item xs="auto" container spacing={6}>
      <Grid item xs="auto" container spacing={4}>
        <Grid item xs="auto" className={classes.sectionFormHeader}>
          <DescriptionIcon />
          <Typography variant="h3" className={classes.sectionFormTitle}>
            Informações dos benefícios
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
          spacing={2}
          container
          className={classes.sectionFormBody}
        >
          <Grid item xs={12} md={4}>
            <DefaultFormTextField
              type="number"
              id="empresa-dia-proximos-pagamentos"
              label="Dia do vencimento"
              name="dia_proximos_pagamentos"
              value={empresa.dia_proximos_pagamentos}
              required
              onChange={e => handleConvenioOnChange(e)}
              InputProps={{ inputProps: { min: 1, max: 31 } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultFormTextField
              id="empresa-desconto-fatura-porcentagem"
              label="Porcentagem de desconto na fatura"
              name="desconto_fatura_porcentagem"
              value={empresa.desconto_fatura_porcentagem}
              required
              onChange={e => handleConvenioOnChange(e)}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
                inputProps: { min: 0, max: 100, step: 0.01 },
              }}
              // endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectTextField
              id="empresa-primeiro-dep-gratis"
              name="primeiro_dep_gratis"
              label="Tem primeiro dependente gratuito?"
              value={empresa.primeiro_dep_gratis}
              required
              onChange={e => handleConvenioOnChange(e)}
              options={[
                { value: 1, label: 'Sim' },
                { value: 0, label: 'Não' },
              ]}
            />
          </Grid>
          {/* // TODO Implementar upload do contrato do convênio (Front e Back-end) */}
        </Grid>
      </Grid>

      {/* <Row className="mb-3">
        <Col md={12}>
          <SectionFormHeader>Informações dos Benefícios</SectionFormHeader>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={2} className="mt-2">
          <InputGroup>
            <Input
              type="number"
              min={0}
              max={31}
              step={1}
              placeholder="Dia do vencimento"
              name="dia_proximos_pagamentos"
              value={empresa.dia_proximos_pagamentos}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col md={5} className="mt-2">
          <InputGroup>
            <Input
              type="number"
              min={0}
              max={100}
              step={0.01}
              placeholder="Desconto na fatura"
              name="desconto_fatura_porcentagem"
              value={empresa.desconto_fatura_porcentagem}
              required
              onChange={e => handleConvenioOnChange(e)}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <PrependInputLabel>%</PrependInputLabel>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col md={5} className="mt-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PrependInputLabel>
                  <FaRegMoneyBillAlt />
                </PrependInputLabel>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="select"
              name="primeiro_dep_gratis"
              value={empresa.primeiro_dep_gratis}
              required
              onChange={e => handleConvenioOnChange(e)}
            >
              <option value="">Primeiro Dependente Gratuito?</option>
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </Input>
          </InputGroup>
        </Col>
      </Row> */}
    </Grid>
  );
}

export default CadastroBeneficios;
