import React from 'react';
import ClinicaCadastroCliente from '../../components/Forms/ClinicaCadastroCliente';
import { Card } from 'reactstrap';

function ClinicaEditarCliente(props) {

  const { clientInputEdit } = props;
  let mostraInfoEndereco = true;

  if(clientInputEdit.id_estipulante === 1){
    mostraInfoEndereco = true;
  }

  return (
    <Card style={{ marginTop: "30px", marginBottom: "30px", width: "100%" }}>
      <ClinicaCadastroCliente
        {...props}
        mostraInfoEndereco={mostraInfoEndereco}
        mostraInfoPagamento={true}
      />
    </Card>
  )
}

export default ClinicaEditarCliente;
