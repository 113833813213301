import React from 'react';

import { Button, CircularProgress, Tooltip } from '@material-ui/core';

import AddBoxIcon from '@material-ui/icons/AddBox';
import CLoudDownloadIcon from '@material-ui/icons/CloudDownload';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveIcon from '@material-ui/icons/Save';
import AssignmentIcon from '@material-ui/icons/Assignment';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as EditIcon } from '../../assets/img/icon/editar.svg';

import theme from '../../styles/theme';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  goBackButton: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  deleteButtonSmall: {
    height: 20,
    width: 20,
    minWidth: 20,
    padding: 0,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

export function DefaultButton({
  classes,
  color,
  icon,
  onClick,
  children,
  ...props
}) {
  return (
    <Button
      variant="contained"
      color={color}
      startIcon={icon}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}

export function RegisterButton({ classes, onClick, children, ...props }) {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddBoxIcon />}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}

export function ExportButton({
  classes,
  onClick,
  children = 'Exportar',
  tooltipTitle = '',
  ...props
}) {
  return (
    <Tooltip title={tooltipTitle} aria-label={tooltipTitle}>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<CLoudDownloadIcon />}
        onClick={onClick}
        {...props}
      >
        {children}
      </Button>
    </Tooltip>
  );
}

export function GoBackButton({ onClick, ...props }) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      startIcon={<NavigateBeforeIcon />}
      onClick={onClick}
      className={classes.goBackButton}
      {...props}
    >
      Voltar
    </Button>
  );
}

export function NextButton({ classes, onClick, ...props }) {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<NavigateNextIcon />}
      onClick={onClick}
      {...props}
    >
      Próximo
    </Button>
  );
}

export function SaveButton({
  classes,
  onClick,
  loadingPage = false,
  ...props
}) {
  return loadingPage ? (
    <Skeleton variant="rect" width={100} height={40} />
  ) : (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      startIcon={<SaveIcon />}
      onClick={onClick}
      {...props}
    >
      Salvar
    </Button>
  );
}

export function EditButton({ classes, onClick, ...props }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<EditIcon fill={theme.palette.background.paper} />}
      onClick={onClick}
      {...props}
    >
      Editar
    </Button>
  );
}

export function ContractButton({
  classes,
  onClick,
  children = 'Contrato',
  ...props
}) {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<AssignmentIcon />}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}

export function DeleteButtonSmall({ onClick, loading, ...props }) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      onClick={onClick}
      className={classes.deleteButtonSmall}
      {...props}
    >
      {loading ? (
        <CircularProgress size="1rem" />
      ) : (
        <DeleteForeverIcon style={{ height: 14, width: '100%' }} />
      )}
    </Button>
  );
}
