import React from 'react';
import { Card } from 'reactstrap';
import CadastroClienteRenovacao from '../../components/Forms/CadastroClienteRenovacao';

export default function ClientRegisterRenovation(props) {
  return (
    <Card style={{ marginTop: '30px' }}>
      <CadastroClienteRenovacao
        mostraInfoValores
        mostraInfoPagamento
        mostraInfoDependentes
        clienteEmpresarial={false}
        {...props}
      />
    </Card>
  );
}
