import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaExclamation, FaPeopleCarry, FaWrench } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import TitleContent from '../../components/TitleContent/TitleContent';
import InformacoesClinica from '../../views/Clinica/InformacoesClinica';
import ContatosClinica from '../../views/Clinica/ContatosClinica';
import AtendentesLista from '../../views/Clinica/AtendentesLista';
import DocumentosClinica from '../../views/Clinica/DocumentosClinica';
import ModalEditarClinicaAtendente from '../../views/Clinica/ModalEditarAtendente';
import EditarClinica from '../../views/Clinica/EditarClinica';
import ComissaoClinica from '../../views/Clinica/ComissaoClinica';
import MaterialModal from '../../components/Modal/MaterialModal';
import { Warning } from '../../views/Apolice/styles';

function Page(props) {
  const {
    clinica,
    atendentes,
    submitChangeStatus,
    loadingChangeStatus,
    getAtendentes,
    getDadosClinica,
    gruposVenda,
    user,
    excluirAtendente,
    clinicas
  } = props;

  const history = useHistory();

  const [ModalEditarAtendente, setModalEditarAtendente] = useState(false);
  const [modalEditarClinica, setModalEditarClinica] = useState(false);
  const [atendenteSelected, setAtendenteSelected] = useState({});

  const openModal = vendedor => {
    setAtendenteSelected(vendedor);
    setModalEditarAtendente(true);
  };

  const closeModal = () => {
    setModalEditarAtendente(!ModalEditarAtendente);
  };

  const toggleModalEditar = () => {
    setModalEditarClinica(!modalEditarClinica);
  };


  const [idItemExclusao, setIdItemExclusao] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);

  const toggleModalExclusao = (id) => {
    setIdItemExclusao(id);
    setModalExclusao(!modalExclusao);
  };

  const handleexcluirAtendente = async () => {
    excluirAtendente(idItemExclusao);
    setModalExclusao(!modalExclusao);
  };

  const titleHeader = (
    <>
      <FaPeopleCarry />
      &nbsp;&nbsp;Clínica: {clinica.data.nome_fantasia}
    </>
  );
  return (
    <Container fluid>
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            color="danger"
            size="sm"
            onClick={() => history.goBack()}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col xs="auto" className="mt-3">
          <Button
            color="success"
            outline
            size="sm"
            className="mb-2"
            onClick={() => toggleModalEditar()}
            disabled={clinica.loading}
          >
            <FaWrench />
            {clinica.loading ? '  Carregando...' : '  Editar'}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <InformacoesClinica clinica={clinica} />
          <ComissaoClinica clinica={clinica} />
        </Col>
        <Col md={6}>
          <ContatosClinica clinica={clinica} />
          <DocumentosClinica clinica={clinica} user={user} />
        </Col>
      </Row>
      <Row className="pb-4 mt-4 ">
        <Col>
          <AtendentesLista
            atendentes={atendentes}
            submitChangeStatus={submitChangeStatus}
            loadingChangeStatus={loadingChangeStatus}
            openModal={openModal}
            toggleModalExclusao={toggleModalExclusao}
            gruposVenda={gruposVenda}
            clinicas={clinicas}
            clinica={clinica}
            getAtendentes={getAtendentes}
          />
        </Col>
      </Row>
      <ModalEditarClinicaAtendente
        modal={ModalEditarAtendente}
        toggle={closeModal}
        atendente={atendenteSelected}
        getAtendentes={getAtendentes}
      />
      <EditarClinica
        toggle={toggleModalEditar}
        modal={modalEditarClinica}
        clinica={clinica}
        getClinica={getDadosClinica}
        gruposVenda={gruposVenda}
      />
      <MaterialModal
          toggle={toggleModalExclusao}
          modal={modalExclusao}
          handleConfirm={handleexcluirAtendente}
          loading={false}
          title="Exclusão de atendente"
        >
          <>
            Tem certeza que deseja excluir este atendente?
          </>
      </MaterialModal>
    </Container>
  );
}

export default Page;
