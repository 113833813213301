import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Page } from './Page';
import theme from '../../styles/theme';
import api from '../../services/api';

const Controller = props => {
  const history = useHistory();
  const { user } = props;
  const authStr = `Bearer ${user.user.token}`;

  const [accounts, setAccounts] = useState({
    data: [],
    loading: true,
    error: '',
  });

  const getAccounts = async () => {
    try {
      const response = await api.get(`/usuarios/todos`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setAccounts({ data: response.data.output, loading: false });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setAccounts({ error: error.message, loading: false, data: [] });
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Page history={history} accounts={accounts} />
    </ThemeProvider>
  );
};

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
