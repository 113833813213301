import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from './Page';
import Api from '../../services/api';
import { checkSafePassword } from '../../services/service';

function Controller(props) {
  const [clinicas, setClinicas] = useState();
  const [loading, setLoading] = useState(true);
  const [gruposVenda, setGruposVenda] = useState([{}]);

  useEffect(() => {
    checkSafePassword(props.user);
    getClinicas();
    getGruposVenda();
  }, []);

  const getClinicas = async _ => {
    setLoading(true);
    const response = await Api.get('/clinicas/', {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });
    setClinicas(response.data);
    setLoading(false);
  };

  const getGruposVenda = async _ => {
    let fetchGrupoVendas = await Api.get('/grupovendas', { headers: { Authorization: `Bearer ${props.user.user.token}` }});
    if(fetchGrupoVendas.data.ok) {
        setGruposVenda(fetchGrupoVendas.data.output);
    }
  }

  return (
    <>
      <Page
        clinicas={clinicas}
        loading={loading}
        getClinicas={getClinicas}
        gruposVenda={gruposVenda}
      />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
