import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import Page from './Page';
import Api from '../../services/api';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import { msg } from '../../json/msg';

import theme from '../../styles/theme';
import { checkSafePassword } from '../../services/service';

function Controller(props) {
  const { nivel_usuario } = props.user.user;
  const { token } = props.user.user;
  const authStr = `Bearer ${token}`;
  const history = useHistory();

  const defaultMaterialApoio = {
    id: 0,
    nome: '',
    descricao: '',
    linkImagem: null,
    tipo: '',
    tipoApresentacao: '',
    link: '',
    imagemCapa: [],
  };

  const [tipoMaterial, setTipoMaterial] = useState('treinamento');

  const [actionType, setActionType] = useState('cadastrar');

  const [materialApoio, setMaterialApoio] = useState([]);
  const [cadastroMaterialApoio, setCadastroMaterialApoio] =
    useState(defaultMaterialApoio);

  const [modalAddEditMaterialApoio, setModalAddEditMaterialApoio] =
    useState(false);
  const toggleModalAddEditMaterialApoio = () =>
    setModalAddEditMaterialApoio(!modalAddEditMaterialApoio);

  const [dialogDeleteMaterialApoio, setDialogDeleteMaterialApoio] =
    useState(false);
  const toggleDialogDeleteMaterialApoio = () =>
    setDialogDeleteMaterialApoio(!dialogDeleteMaterialApoio);

  const showModalAddMaterialApoio = () => {
    setActionType('cadastrar');
    setCadastroMaterialApoio(defaultMaterialApoio);
    toggleModalAddEditMaterialApoio();
  };

  const showModalEditMaterialApoio = id => {
    setActionType('editar');
    getInfosMaterialApoio(id);
    toggleModalAddEditMaterialApoio();
  };

  const showDialogDeleteMaterialApoio = () => {
    toggleDialogDeleteMaterialApoio();
  };

  const confirmDeleteMaterialApoio = id => {
    deleteMaterialApoio(id);
    toggleDialogDeleteMaterialApoio();
  };

  const getListMaterialApoio = async tipo => {
    const responseMaterialApoio = await Api.get(
      `/material-apoio?tipo=${tipo}`,
      {
        headers: { Authorization: authStr },
      },
    );
    setMaterialApoio(responseMaterialApoio.data.output);
  };

  const getInfosMaterialApoio = async id => {
    const request = await Api.get(`material-apoio/${id}`, {
      headers: { Authorization: authStr },
    });
    if (request.data.ok) {
      setCadastroMaterialApoio({
        id: request.data.output.id ? request.data.output.id : 0,
        nome: request.data.output.nome ? request.data.output.nome : '',
        descricao: request.data.output.descricao
          ? request.data.output.descricao
          : '',
        linkImagem: request.data.output.link_imagem
          ? request.data.output.link_imagem
          : '',
        tipo: request.data.output.tipo ? request.data.output.tipo : '',
        tipoApresentacao: request.data.output.tipo_apresentacao
          ? request.data.output.tipo_apresentacao
          : '',
        link: request.data.output.link ? request.data.output.link : '',
      });
    }
  };

  const salvarMaterialApoio = async _ => {
    let url = '/material-apoio';
    if (cadastroMaterialApoio.id > 0) {
      url = '/material-apoio/editar';
    }

    const dataForm = new FormData();

    Object.keys(cadastroMaterialApoio).map(el => {
      dataForm.append(el, cadastroMaterialApoio[el]);
    });

    const result = await Api.post(url, dataForm, {
      headers: { Authorization: authStr },
    });
    if (result.data.ok) {
      alertSuccess(
        actionType === 'cadastrar'
          ? 'Material de Apoio cadastrado com sucesso'
          : 'Material de Apoio atualizado com sucesso',
      );
      toggleModalAddEditMaterialApoio();
      getListMaterialApoio(tipoMaterial);
    } else {
      alertError(result.data.message);
    }
  };

  const deleteMaterialApoio = async idMaterial => {
    if (idMaterial) {
      const result = await Api.post(
        `material-apoio/deletar`,
        { id: idMaterial },
        {
          headers: { Authorization: authStr },
        },
      );

      if (result.data.ok) {
        alertSuccess('Material de apoio excluído com sucesso');
        getListMaterialApoio(tipoMaterial);
      } else {
        alertError(msg.error_system);
      }
    } else {
      alertError('ID do Material de Apoio não encontrado');
    }
  };

  const handleOnChangeInputFields = e => {
    const { name, value } = e.target;

    if (name == 'imagemCapa') {
      setCadastroMaterialApoio({
        ...cadastroMaterialApoio,
        imagemCapa: e.target.files[0],
        linkImagem: '',
      });
    } else {
      setCadastroMaterialApoio({ ...cadastroMaterialApoio, [name]: value });
    }
  };

  useEffect(() => {
    checkSafePassword(props.user);
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {materialApoio && (
          <Page
            nivelUsuario={nivel_usuario}
            history={history}
            modalAddEditMaterialApoio={modalAddEditMaterialApoio}
            setModalAddEditMaterialApoio={setModalAddEditMaterialApoio}
            toggleModalAddEditMaterialApoio={toggleModalAddEditMaterialApoio}
            materialApoio={materialApoio}
            cadastroMaterialApoio={cadastroMaterialApoio}
            setCadastroMaterialApoio={setCadastroMaterialApoio}
            handleOnChangeInputFields={handleOnChangeInputFields}
            salvarMaterialApoio={salvarMaterialApoio}
            tipoMaterial={tipoMaterial}
            setTipoMaterial={setTipoMaterial}
            getListMaterialApoio={getListMaterialApoio}
            actionType={actionType}
            setActionType={setActionType}
            getInfosMaterialApoio={getInfosMaterialApoio}
            showModalAddMaterialApoio={showModalAddMaterialApoio}
            showModalEditMaterialApoio={showModalEditMaterialApoio}
            showDialogDeleteMaterialApoio={showDialogDeleteMaterialApoio}
            dialogDeleteMaterialApoio={dialogDeleteMaterialApoio}
            toggleDialogDeleteMaterialApoio={toggleDialogDeleteMaterialApoio}
            confirmDeleteMaterialApoio={confirmDeleteMaterialApoio}
          />
        )}
      </ThemeProvider>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
