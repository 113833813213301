import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Button,
  Form,
  Spinner,
  UncontrolledTooltip,
  InputGroup,
} from 'reactstrap';
import {
  FaFileContract,
  FaSearch,
  FaRetweet,
  FaPlus,
  FaExclamationTriangle,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Switch } from '@material-ui/core';
import {
  CardComponent,
  OverflowContainer,
  LabelForm,
  TableContent,
  MsgInputError,
} from '../styles';

import Api from '../../services/api';
import { alertError, alertWarning } from '../../components/Alerts/Alerts';

import {
  somaDatas,
  dateDiffInDays,
  renovaApolice,
  verificaCodigoDesconto,
  formatDate,
} from '../../services/service';

function SeguradoApolice(props) {
  const {
    apolices,
    history,
    client,
    produtos,
    idEstipulante,
    idCliente,
    assessorias,
  } = props;
  const authStr = `Bearer ${props.user.user.token}`;
  const [invalidCupom, setInvalidCupom] = useState();

  const [renovarInput, setRenovarInput] = useState('');
  const [modalConfigApolice, setModalConfigApolice] = useState(false);
  const [idApolice, setIdApolice] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salesMan, setSalesman] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [clientPayment, setClientPayment] = useState({
    id_produto: 3,
    forma_pagamento: '',
    primeiro_pagamento: new Date().toISOString().split('T')[0],
    dia_proximos_pagamentos: '',
    qtd_parcelas: 12,
    codigo_desconto: '',
    id_usuario: parseInt(props.user.user.usuario),
    corretorRepresentante:
      props.user.user.nivel_usuario == 3
        ? props.user.user.corretor_representante
        : 0,
    id_assessoria: '',
    id_cliente: parseInt(idCliente),
    convenio: false,
    taxaAdesao: true,
    valorTaxaAdesao: 30
  });

  const [disabled, setDisabled] = useState({
    dia_proximos_pagamentos: false,
    qtd_parcelas: false,
  });

  const toggle = () => setModal(!modal);

  const renovarApolice = idApolice => {
    setModal(true);
    setIdApolice(idApolice);
  };

  const confirmaRenovacao = async _ => {
    if (renovarInput === 'RENOVAR') {
      setDisabledBtn(true);
      setLoading(true);
      const response = await Api.post(`apolice/${idApolice}/renovar`, '', {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
      if (response.data.ok) {
        window.location.reload();
      } else {
        alertError('Erro ao renovar apolice');
      }
    } else {
      alertWarning('O campo deve ser igual a palavra RENOVAR');
    }
  };

  const criarApolice = async () => {
    setLoading(true);
    try {
      const response = await Api.post(`apolice/nova`, clientPayment, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
      if (response.data.ok) {
        window.location.reload();
      } else {
        const errorsMessages = JSON.parse(response.data.errors);

        const errors = [];
        for (let i = 0; i < errorsMessages.length; i++) {
          errors.push(new Error(errorsMessages[i]));
        }

        if (errors.length > 0) {
          throw errors;
        }
      }
    } catch (error) {
      for (let i = 0; i < error.length; i++) {
        alertError(error[i].message);
      }
    }
    setLoading(false);
  };

  const handleOnChange = async event => {
    const { value } = event.target;

    const vendedor = await Api.get(
      `corretor-representante/assessoria/${value}`,
      {
        headers: { Authorization: authStr },
      },
    );

    let vendedorArr = [];
    for (const i in vendedor.data.output) {
      const tmpObj = {
        id_pessoa: vendedor.data.output[i].id_pessoa,
        id: vendedor.data.output[i].id,
        nome: vendedor.data.output[i].nome,
      };
      vendedorArr = [...vendedorArr, tmpObj];
    }
    setSalesman(vendedorArr);
    setClientPayment({ ...clientPayment, id_assessoria: value });
  };

  const handleChangeSalesMan = e => {
    const { value } = e.target;
    setClientPayment({ ...clientPayment, corretorRepresentante: value });
  };

  const handleClientPayment = async event => {
    const { name, value } = event.target;

    if (name === 'forma_pagamento') {
      if (value === 'cartao-credito') {
        setDisabled({ dia_proximos_pagamentos: true, qtd_parcelas: true });
      } else {
        setDisabled({
          dia_proximos_pagamentos: false,
          qtd_parcelas: false,
          codigo_desconto: false,
        });
      }
    }

    if (name === 'codigo_desconto') {
      const result = await verificaCodigoDesconto(
        value,
        setInvalidCupom,
        props.user.user.token,
      );
      clientPayment.validade_codigo_desconto = result;
    }

    setClientPayment({ ...clientPayment, [name]: value });
  };

  const toggleTaxaAdesao = e => {
    setClientPayment({ ...clientPayment, taxaAdesao: e.target.checked });
  };

  return (
    <Card>
      <ToastContainer />
      {modalConfigApolice && (
        <Modal
          isOpen={modalConfigApolice}
          size="lg"
          toggle={() => setModalConfigApolice(!modalConfigApolice)}
        >
          <ModalHeader
            toggle={() => setModalConfigApolice(!modalConfigApolice)}
          >
            <FaFileContract /> Configurar Apólice
          </ModalHeader>
          <ModalBody>
            <Form>
              {props.user.user.nivel_usuario != 3 && (
                <Row className="mb-4">
                  <Col md={12}>
                    <LabelForm>Descrição</LabelForm>
                    <p>
                      Ao criar uma nova apólice, automaticamente o sistema irá
                      cancelar a última e todas as suas faturas.
                    </p>
                  </Col>
                  <Col md={6}>
                    <LabelForm>Escolha um distribuidor *</LabelForm>
                    <Input
                      type="select"
                      name="id_assessoria"
                      onChange={handleOnChange}
                      required
                    >
                      <option value="0">Escolha um distribuidor</option>
                      {assessorias.map((el, i) => {
                        return (
                          <option key={i} value={el.id_assessoria}>
                            {el.nome}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <LabelForm>Escolha o Consultor *</LabelForm>
                    <Input
                      type="select"
                      name=""
                      onChange={handleChangeSalesMan}
                      required
                    >
                      <option value="0">Escolha o consultor</option>

                      {salesMan.map((el, i) => {
                        return (
                          <option key={i} value={el.id}>
                            {el.nome}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>
              )}
              <Row className="mb-4">
                <Col>
                  <LabelForm>Produto *</LabelForm>

                  <Input
                    type="select"
                    name="id_produto"
                    value={clientPayment.id_produto}
                    onChange={e =>
                      setClientPayment({
                        ...clientPayment,
                        id_produto: parseInt(e.target.value),
                      })
                    }
                    required
                  >
                    {props.user.user.nivel_usuario == 1 && (
                      <>
                        <option value="51">Plano Prata (Antigo)</option>
                        <option value="52">Plano Ouro (Antigo)</option>
                        <option value="53">Plano Diamante (Antigo)</option>
                      </>
                    )}
                    {produtos.map((el, i) => (
                      <option key={i} value={el.id}>
                        {el.nome}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <LabelForm>Forma de Pagamento *</LabelForm>

                  <Input
                    type="select"
                    name="forma_pagamento"
                    value={clientPayment.forma_pagamento}
                    onChange={e => handleClientPayment(e)}
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="carne-impresso">Carnê</option>
                    <option value="cartao-credito">Cartão de Crédito</option>
                    <option value="debito-em-folha">Débito em Folha</option>
                  </Input>
                </Col>
                <Col lg={6}>
                  <LabelForm>Primeiro Pagamento *</LabelForm>

                  <Input
                    className="form-control"
                    type="date"
                    placeholder="Data Primeiro Pagamento"
                    name="primeiro_pagamento"
                    value={clientPayment.primeiro_pagamento}
                    onChange={e =>
                      setClientPayment({
                        ...clientPayment,
                        primeiro_pagamento: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={6}>
                  <LabelForm>Próximos Pagamentos *</LabelForm>
                  <Input
                    type="select"
                    name="dia_proximos_pagamentos"
                    value={clientPayment.dia_proximos_pagamentos}
                    disabled={disabled.dia_proximos_pagamentos}
                    onChange={e =>
                      setClientPayment({
                        ...clientPayment,
                        dia_proximos_pagamentos: parseInt(e.target.value),
                      })
                    }
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="10">Dia 10</option>
                    <option value="20">Dia 20</option>
                    <option value="30">Dia 30</option>
                  </Input>
                </Col>
                <Col lg={6}>
                  <LabelForm>Quantidade de Parcelas *</LabelForm>

                  <Input
                    type="select"
                    name="qtd_parcelas"
                    value={clientPayment.qtd_parcelas}
                    disabled={disabled.qtd_parcelas}
                    onChange={e =>
                      setClientPayment({
                        ...clientPayment,
                        qtd_parcelas: e.target.value,
                      })
                    }
                    required
                  >
                    <option>12</option>
                    <option>11</option>
                    <option>10</option>
                    <option>9</option>
                    <option>8</option>
                    <option>7</option>
                    <option>6</option>
                    <option>5</option>
                    <option>4</option>
                    <option>3</option>
                    <option>2</option>
                    <option>1</option>
                  </Input>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={4}>
                  <LabelForm>Código de Desconto &nbsp;</LabelForm>
                  <Badge
                    style={{
                      textDecoration: 'none',
                      color: '#ffffff',
                      cursor: 'help',
                    }}
                    id="UncontrolledTooltipExample"
                    color="secondary"
                  >
                    {' '}
                    Info
                  </Badge>
                  <UncontrolledTooltip
                    placement="right"
                    target="UncontrolledTooltipExample"
                  >
                    Código Aplicado Para Convênios
                  </UncontrolledTooltip>
                  <Input
                    type="text"
                    disabled={disabled.codigo_desconto}
                    placeholder="Insira o Código de Desconto"
                    name="codigo_desconto"
                    value={clientPayment.clientDiscount}
                    onChange={e => handleClientPayment(e)}
                  />
                  {invalidCupom && (
                    <>
                      <br />
                      &nbsp;
                      <MsgInputError>
                        <FaExclamationTriangle />
                        &nbsp;Cupom não encontrado
                      </MsgInputError>
                    </>
                  )}
                </Col>
                <Col lg={4}>
                  <LabelForm>
                    Taxa de adesão (R${clientPayment.valorTaxaAdesao}) &nbsp;
                  </LabelForm>
                  <Switch
                    checked={clientPayment.taxaAdesao}
                    onChange={toggleTaxaAdesao}
                    name="taxaAdesao"
                    size="small"
                    color="secondary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                <InputGroup>
                  <Input
                    type="select"
                    name="valorTaxaAdesao"
                    value={clientPayment.valorTaxaAdesao}
                    onChange={e => handleClientPayment(e)}
                  >
                    <option value="30">30</option>
                    <option value="25">25</option>
                    <option value="20">20</option>
                    <option value="15">15</option>
                    <option value="10">10</option>
                  </Input>
                </InputGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner color="primary" size="sm" />
            ) : (
              <>
                <Button color="success" onClick={() => criarApolice()}>
                  Salvar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      )}
      <CardComponent>
        <Col className="header-content">
          <h2>
            <FaFileContract style={{ color: '#DA2626' }} />
            &nbsp;&nbsp;Apólices
          </h2>
        </Col>
        <OverflowContainer>
          <TableContent>
            <Row>
              <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                {apolices.output.length > 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>#ID</th>
                        <th>Descrição</th>
                        <th>Status</th>
                        <th>Consultor</th>
                        <th>Valor</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {apolices.output.map((el, i) => {
                        let statusLabel = 'secondary';

                        let renovar = '';
                        if (el.inicio_vigencia)
                          renovar = renovaApolice(
                            el.periodo_vigencia,
                            el.inicio_vigencia,
                          );

                        if (el.status === 'Em Vigência') {
                          statusLabel = 'success';
                        } else if (el.status === 'Cancelada') {
                          statusLabel = 'danger';
                        }

                        return (
                          <tr key={i}>
                            <td>{el.id_apolice}</td>
                            <td>
                              <Badge color="primary">{el.nome}</Badge>
                            </td>
                            <td>
                              <Badge color={statusLabel}>{el.status}</Badge>
                            </td>
                            <td>{el.vendedor}</td>
                            <td>R$ {el.valor}</td>
                            <td
                              style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                              }}
                            >
                              <Button
                                size="sm"
                                outline
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/apolice/${el.id_apolice}/${el.id_estipulante}/${client.id}`,
                                  )
                                }
                              >
                                <FaSearch style={{ cursor: 'pointer' }} />
                              </Button>
                              {renovar && statusLabel === 'success' && (
                                <Button
                                  size="sm"
                                  color="warning"
                                  onClick={() => renovarApolice(el.id_apolice)}
                                >
                                  <FaRetweet />
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <span style={{ color: 'red' }}>{apolices.message}</span>
                )}
              </Col>
            </Row>
          </TableContent>
        </OverflowContainer>
      </CardComponent>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Renovar Apólice</ModalHeader>
        <ModalBody>
          <Label>Se você deseja renovar essa fatura escreva "RENOVAR"</Label>
          <Input
            type="text"
            name="renovarInput"
            value={renovarInput}
            onChange={e => setRenovarInput(e.target.value)}
          />
          <Col className="text-right mt-2">
            {loading ? (
              <Spinner color="primary" size="sm" />
            ) : (
              <Button
                size="sm"
                disabled={disabledBtn}
                onClick={() => confirmaRenovacao()}
              >
                Confirmar
              </Button>
            )}
          </Col>
        </ModalBody>
      </Modal>
      {props.user.user.nivel_usuario != 3 && (
        <Row className="mt-4 mb-3">
          <Col className="text-right">
            <Button
              color="success"
              size="sm"
              onClick={() => setModalConfigApolice(true)}
              title="Ao criar uma nova apólice, automaticamente o sistema irá cancelar a atual e todas as suas faturas."
            >
              <FaPlus /> Nova Apólice
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(SeguradoApolice);
