import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap';
import {
  FaHeadset,
  FaUser,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
} from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { BodyFormComponent, SectionFormHeader } from '../styles';
import SubmitBtn from '../../components/Buttons/SubmitBtn';

function CadastrarContatoEmpresa(props) {
  const {
    modalContatos,
    openModalCadastroContato,
    cadastrarContatoForm,
    contato,
    handleContatoOnChange,
    loading,
  } = props;

  return (
    <div>
      {/* <Button color="danger" onClick={openModalCadastroContato}></Button> */}
      <Modal isOpen={modalContatos} toggle={openModalCadastroContato} size="lg">
        <Form onSubmit={cadastrarContatoForm}>
          <ModalHeader toggle={openModalCadastroContato}>
            <FaHeadset />
            &nbsp;&nbsp;Cadastro do Responsável da Empresa
          </ModalHeader>
          <ModalBody>
            <BodyFormComponent>
              <Row className="mb-4">
                <Col md={12}>
                  <SectionFormHeader>Informações do Contato</SectionFormHeader>
                </Col>
              </Row>
              <Col md={12} className="mt-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUser />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    name="nome_responsavel"
                    value={contato.nome_responsavel}
                    placeholder="Nome do Responsável"
                    onChange={e => handleContatoOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col md={6} className="mt-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    required
                    className="form-control"
                    name="telefone"
                    value={contato.telefone}
                    placeholder="Telefone"
                    onChange={e => handleContatoOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col md={6} className="mt-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaMobileAlt />
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputMask
                    mask="(99) 99999-9999"
                    name="celular"
                    value={contato.celular}
                    className="form-control"
                    placeholder="Celular"
                    onChange={e => handleContatoOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col md={12} className="mt-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaEnvelope />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    required
                    name="email"
                    value={contato.email}
                    placeholder="E-mail"
                    onChange={e => handleContatoOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Col md={12} className="mt-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUser />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    name="cargo"
                    value={contato.cargo}
                    placeholder="Cargo"
                    onChange={e => handleContatoOnChange(e)}
                  />
                </InputGroup>
              </Col>
              <Row>
                <Col md={12} className="mt-4">
                  <SubmitBtn
                    label="Cadastrar Contato"
                    labelOnClick="aguarde..."
                    loading={loading}
                    typeSubmit="submit"
                    metodo={cadastrarContatoForm}
                    color="#0A7A73"
                  />
                </Col>
              </Row>
            </BodyFormComponent>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
}

export default CadastrarContatoEmpresa;
