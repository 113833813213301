import React, { useCallback } from 'react';

import {
  FaCalendarAlt,
  FaEnvelope,
  FaUserTie,
  FaRegMoneyBillAlt,
  FaFileAlt,
  FaMinus,
} from 'react-icons/fa';
import {
  Card,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';

import { CustomInput } from '../../components/Forms/CustomInput';
import { TitleCardComponent } from '../../components/TitleContent/styles';
import { InputRow } from './styles';

export default function EfetivarContratoForm(props) {
  const {
    register,
    errors,
    distribuidorOptions,
    corretorOptions,
    emailCobrancaOptions,
    handleChangeDistribuidor,
    loading,
    hasIdApoliceParams,
    handleChangeDesconto,
    setIsPrimeiroDepGratuito,
    isPrimeiroDepGratuito,
    isUsuarioVendedor,
    setValue,
    isUsuarioDistribuidor,
    user,
    isSecondRequestDone,
    setTipoDesconto,
    desconto,
  } = props;

  const handleChange = useCallback(
    value => {
      handleChangeDistribuidor(value);
      setValue('distribuidor', value, { shouldValidate: true });
      setValue('corretor', '');
    },
    [handleChangeDistribuidor, setValue],
  );

  return (
    <div className="d-flex justify-content-center mt-4">
      <Card>
        <CardHeader>
          <TitleCardComponent>
            <FaFileAlt className="mr-3" /> Dados da Proposta
          </TitleCardComponent>
        </CardHeader>
        <input
          className="d-none"
          {...register('isUsuarioVendedor')}
          value={
            isUsuarioDistribuidor || isUsuarioVendedor || hasIdApoliceParams
          }
        />
        <input
          className="d-none"
          {...register('isSecondReqDone')}
          value={!!isSecondRequestDone}
        />
        {isUsuarioVendedor || hasIdApoliceParams || (
          <InputRow>
            {!isUsuarioDistribuidor ? (
              <CustomInput
                label="Distribuidor"
                error={errors.distribuidor}
                {...register('distribuidor')}
                type="select"
                options={distribuidorOptions}
                onChange={event => handleChange(event.target.value)}
                loading={loading.distribuidor}
              >
                <FaUserTie />
              </CustomInput>
            ) : (
              <div className="font-weight-bold text-left">
                <Label>Distribuidor</Label>
                <InputGroup className="text-left mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaUserTie />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder={user.primeiro_nome_usuario} disabled />
                </InputGroup>
              </div>
            )}
            <CustomInput
              label="Corretor"
              error={errors.corretor}
              {...register('corretor')}
              type="select"
              options={corretorOptions}
              loading={loading.corretor}
              onChange={event =>
                setValue('corretor', event.target.value, {
                  shouldValidate: true,
                })
              }
            >
              <FaUserTie />
            </CustomInput>
          </InputRow>
        )}
        <InputRow>
          <CustomInput
            label="1º Dependente Gratuito?"
            error={errors.primeiroDepGratuito}
            {...register('primeiroDepGratuito')}
            type="select"
            options={[
              { value: '1', label: 'Sim' },
              { value: '0', label: 'Não' },
            ]}
            onChange={event => {
              setIsPrimeiroDepGratuito(event.target.value);
              setValue('primeiroDepGratuito', event.target.value, {
                shouldValidate: true,
              });
            }}
            value={isPrimeiroDepGratuito}
            disabled={isSecondRequestDone}
          >
            <FaRegMoneyBillAlt />
          </CustomInput>
          <CustomInput
            label="Tipo do Desconto"
            error={errors.tipoDesconto}
            {...register('tipoDesconto')}
            type="select"
            disabled={isSecondRequestDone}
            onChange={event => {
              setTipoDesconto(event.target.value);
              setValue('tipoDesconto', event.target.value, {
                shouldValidate: true,
              });
              handleChangeDesconto('');
            }}
            options={[
              { value: 0, label: 'Porcentagem' },
              { value: 1, label: 'Valor' },
            ]}
          />
          <CustomInput
            label="Desconto"
            type="number"
            required
            min={0}
            step={0.01}
            placeholder="0.0"
            onChange={event => {
              handleChangeDesconto(event.target.value);
            }}
            disabled={isSecondRequestDone}
            value={desconto}
          >
            <FaMinus />
          </CustomInput>
        </InputRow>
        <InputRow>
          <CustomInput
            label="Data da Primeira Cobrança"
            error={errors.dataPrimeiraCobranca}
            {...register('dataPrimeiraCobranca')}
            type="date"
            onChange={event => {
              setValue('dataPrimeiraCobranca', event.target.value, {
                shouldValidate: true,
              });
            }}
          >
            <FaCalendarAlt />
          </CustomInput>
          <CustomInput
            label="Email de Cobrança"
            error={errors.emailCobranca}
            {...register('emailCobranca')}
            type="select"
            options={emailCobrancaOptions}
            onChange={event => {
              setValue('emailCobranca', event.target.value, {
                shouldValidate: true,
              });
            }}
          >
            <FaEnvelope />
          </CustomInput>
        </InputRow>
      </Card>
    </div>
  );
}
