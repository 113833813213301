import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  // Container,
  Row,
  Col,
  // Button,
} from 'reactstrap';
// import { FaArrowLeft, FaBuilding } from 'react-icons/fa';

import { Container, Grid, Typography } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

// import TitleContent from '../../components/TitleContent/TitleContent';
// import CadastrarFuncionario from '../../views/Empresa/CadastrarFuncionario';
import { makeStyles } from '@material-ui/core/styles';
import CadastrarContatoEmpresa from '../../views/Empresa/CadastrarContatoEmpresa';
import InformacoesConvenio from '../../views/Convenio/InformacoesConvenio';
import ResumoConveniados from '../../views/Convenio/ResumoConveniados';
import ContatosConvenio from '../../views/Convenio/ContatosConvenio';
import Faturas from '../../views/Convenio/Faturas';

import { GoBackButton } from '../../components/Buttons/Buttons';
// import theme from '../../theme';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px 30px',
  },
  header: {
    alignItems: 'center',
  },
  titleHeader: {
    marginLeft: theme.spacing(2),
  },
  content: {
    marginTop: 30,
    padding: 0,
  },
}));

function Page(props) {
  const {
    modalContatos,
    infosConveniados,
    openModalCadastroFuncionario,
    openModalCadastroContato,
    infosContatos,
    deletarContato,
    contato,
    cadastrarContatoForm,
    handleContatoOnChange,
    id,
    loading,
    btnDisabled = { btnDisabled },
    infosConvenio,
    authStr,
    faturas,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  // const titleHeader = (
  //   <>
  //     <FaBuilding />
  //     &nbsp;&nbsp;{infosConvenio.data && infosConvenio.data.nome}{' '}
  //   </>
  // );

  const [modalImportar, setModalImportar] = useState(false);
  const toggleImportar = () => setModalImportar(!modalImportar);

  return (
    <Container
      component="section"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <Grid container>
        <Grid
          item
          component="header"
          xs={12}
          container
          spacing={4}
          className={classes.header}
        >
          <Grid item>
            <GoBackButton onClick={() => history.push('/convenios')} />
          </Grid>
          <Grid item>
            <Grid container className={classes.header}>
              <BusinessCenterIcon />
              <Typography variant="h1" className={classes.titleHeader}>
                Convênios
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item component="article" xs={12} className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <InformacoesConvenio
                infosConvenio={infosConvenio.data}
                loading={infosConvenio.loading}
                deletarContato={deletarContato}
                infosContatos={infosContatos.data}
                openModalCadastroContato={openModalCadastroContato}
                idEstipulante={id}
                loadingContato={infosContatos.loading}
                modalContatos={modalContatos}
                handleContatoOnChange={handleContatoOnChange}
                contato={contato}
                // loading={loading}
                cadastrarContatoForm={cadastrarContatoForm}
              />
              {modalContatos && (
                // <Row>
                //   <Col sm={12}>
                <CadastrarContatoEmpresa
                  openModalCadastroContato={openModalCadastroContato}
                  modalContatos={modalContatos}
                  handleContatoOnChange={handleContatoOnChange}
                  contato={contato}
                  loading={loading}
                  cadastrarContatoForm={cadastrarContatoForm}
                />
                //   </Col>
                // </Row>
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              <ResumoConveniados
                // openModalCadastroFuncionario={openModalCadastroFuncionario}
                conveniados={infosConveniados.data}
                toggleImportar={toggleImportar}
                modalImportar={modalImportar}
                idEstipulante={id}
                infosConvenio={infosConvenio.data}
                loading={infosConveniados.loading}
                authStr={authStr}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <ContatosConvenio
                deletarContato={deletarContato}
                infosContatos={infosContatos.data}
                openModalCadastroContato={openModalCadastroContato}
                idEstipulante={id}
                loading={infosContatos.loading}
              />
              {modalContatos && (
                <Row>
                  <Col sm={12}>
                    <CadastrarContatoEmpresa
                      openModalCadastroContato={openModalCadastroContato}
                      modalContatos={modalContatos}
                      handleContatoOnChange={handleContatoOnChange}
                      contato={contato}
                      loading={loading}
                      cadastrarContatoForm={cadastrarContatoForm}
                    />
                  </Col>
                </Row>
              )}
            </Grid> */}
            {/* <Grid item xs={12}>
              <Faturas
                faturas={faturas}
                authStr={authStr}
                idEstipulante={id}
                conveniados={infosConveniados.data}
                infosConvenio={infosConvenio.data}
              />
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Container className="pb-5" fluid> */}
        {/* <Row className="mt-4">
      <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            color="danger"
            size="sm"
            onClick={() => history.push('/convenios')}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row> */}
        {/* <Row className="text-left mt-2 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row> */}
        {/* <Row className="mt-4">
        <Col md={6} className="mb-4">
          <InformacoesConvenio
            infosConvenio={infosConvenio.data}
            loading={infosConvenio.loading}
          />
        </Col>
        <Col md={6} className="mb-4">
          <ContatosConvenio
            deletarContato={deletarContato}
            infosContatos={infosContatos.data}
            openModalCadastroContato={openModalCadastroContato}
            idEstipulante={id}
            loading={infosContatos.loading}
          />
          {modalContatos && (
            <Row>
              <Col sm={12}>
                <CadastrarContatoEmpresa
                  openModalCadastroContato={openModalCadastroContato}
                  modalContatos={modalContatos}
                  handleContatoOnChange={handleContatoOnChange}
                  contato={contato}
                  loading={loading}
                  cadastrarContatoForm={cadastrarContatoForm}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ResumoConveniados
            openModalCadastroFuncionario={openModalCadastroFuncionario}
            conveniados={infosConveniados.data}
            toggleImportar={toggleImportar}
            modalImportar={modalImportar}
            idEstipulante={id}
            infosConvenio={infosConvenio.data}
            loading={infosConveniados.loading}
            authStr={authStr}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Faturas
            faturas={faturas}
            authStr={authStr}
            idEstipulante={id}
            conveniados={infosConveniados.data}
            infosConvenio={infosConvenio.data}
          />
        </Col>
      </Row> */}
      </Grid>
    </Container>
  );
}

export default Page;
