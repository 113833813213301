import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import {
  FaUserAlt,
  FaExclamationTriangle,
  FaCalendarAlt,
  FaPhone,
  FaMobileAlt,
  FaEnvelope,
} from 'react-icons/fa';
// import moment from 'moment';
import Api from '../../../services/api';
import {
  SectionFormHeader,
  LabelForm,
  MsgInputError,
} from '../../../views/styles';
import EscolhaClinica from '../EscolhaClinica';
import { msg } from '../../../json/msg';
import { alertWarning, alertError } from '../../Alerts/Alerts';
import { verificaEmail } from '../../../services/service';

const InfoBasicoClinicaCliente = props => {
  const {
    clientInput,
    edit,
    setClientInput,
    nivelUsuario,
    usuario,
    idClinicaDoAtendente,
    clinicaAtendente,
    testaCPF,
    disabled,
    setDisabled,
    token,
    clientInputEdit,
    clinicasList,
    setClinicasList,
    setClinicaSelected
  } = props;

  const authStr = `Bearer ${token}`;
  const [invalidUserCpf, setInvalidUserCpf] = useState(false);
  const [salesman, setSalesman] = useState();

  let mostraCampos = true;

  useEffect(() => {
    if (edit) setClientInput(clientInputEdit);
    getClinicas();
  }, []);

  const updateClinicaAtendente = async (idClinica) => {
    const atendente = await Api.get(
      `clinica-atendente/clinica/${idClinica}`,
      { headers: { Authorization: authStr } },
    );

    let atendenteArr = [];
    for (const i in atendente.data.output) {
      const tmpObj = {
        id_pessoa: atendente.data.output[i].id_pessoa,
        id: atendente.data.output[i].id,
        nome: atendente.data.output[i].nome,
      };
      atendenteArr = [...atendenteArr, tmpObj];
    }
    setSalesman(atendenteArr);
    setDisabled({ clinicaAtendente: false });
  }

  const handleOnChange = async event => {
    let { name, value, type } = event.target;

    if (name === 'cpf') {
      const cpfOnlyNumbers = value.replace(/\D/g, '');
      if (cpfOnlyNumbers.length > 10) {
        if (testaCPF(value) === false) {
          setInvalidUserCpf(true);
        } else {
          setInvalidUserCpf(false);
        }
      }
    }

    if (name === 'clinica' && value !== '') {
      updateClinicaAtendente(value);
      var clinicaSelected = clinicasList.find(function(post, index) {
        if(post.id == value)
          return true;
      });
      setClinicaSelected(clinicaSelected);
    }

    setClientInput({ ...clientInput, [name]: value });
  };

  // checa se o CPF já está cadastrado
  const handleOnBlur = async event => {
    const cpf = event.target.value;
    const cpfValid = await Api.post('clinica-cliente/checkCpf', {
      cpf,
    });
    if (!cpfValid.data.ok) {
      alertWarning(cpfValid.data.message);
    }
  };

  const handleEmailOnBlur = e => {
    if (!verificaEmail(e.target.value)) {
      alertError('Insira um e-mail válido para continuar.');
    }
  };

  const getClinicas = async _ => {
    const clinicas = await Api.get(`clinicas/`, {
      headers: { Authorization: authStr },
    });

    let clinicasArr = [];
    for (const i in clinicas.data.output) {
      const tmpObj = {
        name: clinicas.data.output[i].nome,
        id: clinicas.data.output[i].id,
        id_usuario: clinicas.data.output[i].id_usuario,
        tipo: clinicas.data.output[i].tipo,
        razao_social: clinicas.data.output[i].razao_social,
        id_clinica_master: clinicas.data.output[i].id_clinica_master
      };
      clinicasArr = [...clinicasArr, tmpObj];
    }
    setClinicasList(clinicasArr);

    if (nivelUsuario == 7 && clientInput.clinica > 0) {
      var clinicaSelected = clinicasArr.find(function(post, index) {
        if(post.id == clientInput.clinica)
          return true;
      });
      setClinicaSelected(clinicaSelected);
    }
  };

  return (
    <>
      {!edit && (nivelUsuario == 1 || nivelUsuario == 8 || nivelUsuario == 9) && (
        <>
          <Row className="section-header">
            <Col>
              <SectionFormHeader>Informações do Atendente</SectionFormHeader>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <EscolhaClinica
                clinica={clinicasList}
                clientInput={clientInput}
                handleOnChange={handleOnChange}
                nivelUsuario={nivelUsuario}
                usuario={usuario}
                idClinicaDoAtendente={idClinicaDoAtendente}
              />
            </Col>
            <Col md={6}>
              <LabelForm>Atendente *</LabelForm>
              <Input
                type="select"
                name="clinicaAtendente"
                disabled={disabled.clinicaAtendente}
                value={clientInput.clinicaAtendente}
                onChange={e => handleOnChange(e)}
              >
                <option value="">Escolha o atendente</option>
                {salesman &&
                  salesman.map((el, i) => {
                    if (nivelUsuario == 7) {
                      if (el.id == clinicaAtendente) {
                        return (
                          <option key={i} value={el.id}>
                            {el.nome}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={i} value={el.id}>
                          {el.nome}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
          </Row>
          <hr />
        </>
      )}
      <Row className="section-header">
        <Col>
          <SectionFormHeader>Informações do Cliente</SectionFormHeader>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <LabelForm>Nome *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaUserAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe o nome"
              name="nome"
              value={clientInput.nome}
              onChange={e => handleOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={6}>
          <LabelForm>CPF *</LabelForm>
          <InputGroup>
            <InputMask
              className="form-control"
              type="text"
              mask="999.999.999-99"
              placeholder="Informe o CPF"
              name="cpf"
              value={clientInput.cpf}
              onChange={e => handleOnChange(e)}
              onBlur={e => handleOnBlur(e)}
            />
          </InputGroup>
          {invalidUserCpf && (
            <>
              <br />
              &nbsp;
              <MsgInputError>
                <FaExclamationTriangle />
                &nbsp;{msg.cpf_invalid}
              </MsgInputError>
            </>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={4}>
          <LabelForm>Data de Nascimento *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaCalendarAlt />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type="date"
              name="data_nascimento"
              value={clientInput.data_nascimento}
              onChange={e => handleOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={4}>
          <LabelForm>Estado Civil *</LabelForm>
          <Input
            type="select"
            name="estado_civil"
            value={clientInput.estado_civil}
            onChange={e => handleOnChange(e)}
          >
            <option>Escolha o estado civil</option>
            <option>Solteiro(a)</option>
            <option>Casado(a)/União Estável</option>
            <option>Divorciado(a)</option>
            <option>Viúvo(a)</option>
          </Input>
        </Col>
        <Col lg={4}>
          <LabelForm>Gênero *</LabelForm>
          <Input
            type="select"
            name="sexo"
            value={clientInput.sexo}
            onChange={e => handleOnChange(e)}
          >
            <option>Selecione</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </Input>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <LabelForm>Telefone *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaPhone />
              </InputGroupText>
            </InputGroupAddon>
            <InputMask
              className="form-control"
              mask="(99) 99999-9999"
              type="text"
              placeholder="Informe o telefone"
              name="telefone"
              value={clientInput.telefone}
              onChange={e => handleOnChange(e)}
            />
          </InputGroup>
        </Col>
        <Col lg={6}>
          <LabelForm>Telefone 2</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaMobileAlt />
              </InputGroupText>
            </InputGroupAddon>
            <InputMask
              className="form-control"
              mask="(99) 99999-9999"
              type="text"
              placeholder="Informe o telefone 2"
              name="celular"
              value={clientInput.celular}
              onChange={e => handleOnChange(e)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <LabelForm>Email *</LabelForm>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaEnvelope />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Informe o e-mail"
              name="email"
              value={clientInput.email}
              onChange={e => handleOnChange(e)}
              onBlur={e => handleEmailOnBlur(e)}
            />
          </InputGroup>
        </Col>
      </Row>
      {mostraCampos && (
        <Row>
          <Col>
            <LabelForm>Observação</LabelForm>
            <Input
              type="textarea"
              name="observacao"
              value={clientInput.observacao}
              onChange={e => handleOnChange(e)}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default InfoBasicoClinicaCliente;
