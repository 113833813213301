import React, { useState } from 'react';

import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
} from 'reactstrap';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import EdicaoSeguradosApolices from '../../views/EdicaoSeguradosApolices/EdicaoSeguradosApolices';
import { alertError } from '../../components/Alerts/Alerts';
import NoContent from '../../components/Card/NoContent';
import { DefaultButton } from '../../components/Buttons/Buttons';
import { FilterTextField } from '../../components/Inputs/TextFields';

const useStyles = makeStyles(() => ({
  container: {
    padding: '40px 30px 30px',
    '& > .MuiBox-root': {
      marginBottom: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  'article-header': {
    marginBottom: '1.875em',
  },
  'article-header--title': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gridColumnGap: '1em',
  },
  content: {
    // marginTop: 30,
    padding: 0,
  },
}));

const Page = props => {
  const {
    loading,
    apolices,
    renovacoes,
    params,
    setParams,
    checkedApolices,
    setCheckedApolices,
    handleAlterarEmMassa,
    handleRenovarEmMassa,
    baixarCartoesBoasVindas,
    loadingBaixarCartoes,
    modalAcao,
    toggleModalAcao,
    modalRenovacao,
    toggleModalRenovacao,
    // handleGerarExcel,
  } = props;
  const classes = useStyles();
  // const [modalAcao, setModalAcao] = useState(false);
  // const [modalRenovacao, setModalRenovacao] = useState(false);
  const [acao, setAcao] = useState(0);

  // const toggleModalAcao = _ => {
  //   setModalAcao(!modalAcao);
  // };

  // const toggleModalRenovacao = _ => {
  //   setModalRenovacao(!modalRenovacao);
  // };

  const mudarFiltroStatus = e => {
    let nextStatus = '';

    switch (e.target.value) {
      case 'Pedido de Cartão':
        nextStatus = 'Envelope em Produção';
        break;
      case 'Envelope em Produção':
        nextStatus = 'Cartão Enviado';
        break;
      case 'Cartão Enviado':
        nextStatus = 'Em Vigência';
        break;
      default:
        nextStatus = '';
        break;
    }
    setParams({
      ...params,
      apoliceStatus: e.target.value,
      nextStatus,
    });
  };

  const handleAcao = () => {
    const form = document.forms.acoesApolices;
    if (!form) {
      return;
    }
    const { elements } = form;

    if (elements.tipoAcao.value === 'apolice') {
      toggleModalAcao();
    } else if (elements.tipoAcao.value === 'renovacao') {
      toggleModalRenovacao();
    }
  };

  const handleBaixarCartoes = () => {
    // const form = document.forms.acoesApolices;
    // if (!form) {
    //   return;
    // }
    // const { elements } = form;
    // const inputs = [];
    // // Iterate over the form controls
    // for (let i = 0; i < elements.length; i++) {
    //   if (
    //     elements[i].nodeName === 'INPUT' &&
    //     elements[i].type === 'checkbox' &&
    //     elements[i].checked === true
    //   ) {
    //     // Update text input
    //     console.log('...elements[i].id', elements[i].id);
    //     inputs.push(elements[i].id);
    //   }
    // }

    // setCheckedApolices(inputs);

    if (checkedApolices.length === 0) {
      // if (inputs.length == 0) {
      alertError('Selecione pelo menos uma apólice.');
      return;
    }

    if (
      window.confirm(
        'Deseja mesmo baixar os cartões? Esta ação pode levar alguns minutos.',
      )
    ) {
      baixarCartoesBoasVindas();
    }
  };

  return (
    <Container
      component="article"
      role="main"
      maxWidth="xl"
      disableGutters
      className={classes.container}
    >
      <header className={classes['article-header']}>
        <Box className={classes['article-header--title']} itemProp="title">
          <EditIcon />
          <Typography variant="h1">Ações de Apólices</Typography>
        </Box>
      </header>
      <Box component={Paper} p={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item component="nav" xs="auto" container spacing={2}>
            <Grid item>
              <DefaultButton
                color="primary"
                disabled={acao === 1}
                onClick={() => setAcao(1)}
              >
                Kit do Cliente
              </DefaultButton>
            </Grid>
            <Grid item>
              <DefaultButton
                color="primary"
                disabled={acao === 2}
                onClick={() => {
                  setAcao(2);
                }}
              >
                Renovações
              </DefaultButton>
            </Grid>
          </Grid>
          {acao === 1 && (
            <Grid item xs="auto" container spacing={2} alignItems="center">
              <Grid item>
                <FilterTextField
                  onChange={mudarFiltroStatus}
                  value={params.apoliceStatus}
                  // defaultValue=""
                  placeholder="Filtrar por status"
                  options={[
                    // { value: '', label: 'Filtrar por status' },
                    { value: 'Pedido de Cartão', label: 'Pedido de Cartão' },
                    {
                      value: 'Envelope em Produção',
                      label: 'Envelope em Produção',
                    },
                    { value: 'Cartão Enviado', label: 'Cartão Enviado' },
                  ]}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: () =>
                      params.apoliceStatus !== ''
                        ? params.apoliceStatus
                        : 'Filtrar por status',
                  }}
                  style={{ minWidth: 201 }}
                />
              </Grid>
              {params.apoliceStatus !== '' && (
                <Grid item>
                  <DefaultButton color="secondary" onClick={handleAcao}>
                    Executar
                  </DefaultButton>
                </Grid>
              )}
              {params.apoliceStatus === 'Envelope em Produção' && (
                <Grid item>
                  <DefaultButton
                    color="secondary"
                    disabled={loadingBaixarCartoes}
                    onClick={handleBaixarCartoes}
                  >
                    {loadingBaixarCartoes ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      'Baixar Cartões de Boas-Vindas'
                    )}
                  </DefaultButton>
                </Grid>
              )}
              {/* {params.apoliceStatus === 'Cartão Enviado' && (
                      <Col md={6} className="text-right">
                        <Button
                          color="success"
                          size="sm"
                          onClick={handleGerarExcel}
                        >
                          Gerar Excel
                        </Button>
                      </Col>
                    )} */}
              {/* </Grid> */}
            </Grid>
          )}

          {acao === 2 && (
            <Grid item xs="auto" container spacing={2}>
              <Grid item>
                <DefaultButton
                  color="secondary"
                  onClick={handleAcao}
                  disabled={props.renovacoes.length === 0}
                >
                  Renovar
                </DefaultButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box>
        {acao === 0 && <NoContent text="Escolha a ação" />}

        {acao === 1 && params.apoliceStatus !== '' && (
          <EdicaoSeguradosApolices
            tipoAcao="apolice"
            policies={apolices}
            history={props.history}
            setCheckedApolices={setCheckedApolices}
            checkedApolices={checkedApolices}
          />
        )}
        {acao === 1 && params.apoliceStatus === '' && (
          <NoContent text="Escolha um status" />
        )}
        {acao === 2 && (
          <EdicaoSeguradosApolices
            tipoAcao="renovacao"
            policies={props.renovacoes}
            history={props.history}
            setCheckedApolices={setCheckedApolices}
            checkedApolices={checkedApolices}
          />
        )}

        {modalAcao && (
          <Modal isOpen={modalAcao} toggle={toggleModalAcao}>
            <ModalHeader toggle={toggleModalAcao}>
              Mudar status da Apólices
            </ModalHeader>
            <ModalBody>
              <p>
                Esta ação irá mudar o status de todas as apólices selecionadas
                para{' '}
                <strong style={{ fontWeight: 'bold' }}>
                  {params.nextStatus}{' '}
                </strong>
                .
              </p>

              <Container className="themed-container mt-3">
                {checkedApolices.map((el, index) => {
                  const info = apolices.find(item => {
                    return item.id_apolice === el;
                  });

                  return (
                    <Row key={el}>
                      <Link
                        to={`/segurado/detalhe/${info?.id_segurado}`}
                        target="_blank"
                        key={index}
                      >
                        Apólice {info.id_apolice} ( {info?.status} )
                      </Link>
                    </Row>
                  );
                })}
                {checkedApolices.length === 0 && (
                  <Row className="mt-3 text-center">
                    <Col>Nenhuma apólice selecionada.</Col>
                  </Row>
                )}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" size="sm" onClick={toggleModalAcao}>
                Cancelar
              </Button>
              <Button
                color="warning"
                size="sm"
                onClick={handleAlterarEmMassa}
                disabled={loading}
              >
                {loading ? <Spinner size="sm" color="light" /> : 'Confirmar'}
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {modalRenovacao && (
          <Modal isOpen={modalRenovacao} toggle={toggleModalRenovacao}>
            <ModalHeader toggle={toggleModalRenovacao}>
              Renovar Apólices
            </ModalHeader>
            <ModalBody>
              <p>
                Todas as apólices selecionadas serão renovadas. <br /> Esta ação
                pode levar alguns minutos.
              </p>

              <Container className="themed-container mt-3">
                {checkedApolices.map((el, index) => {
                  const info = renovacoes.find(item => {
                    return item.id_apolice == el;
                  });

                  return (
                    <Row>
                      <Link
                        to={`/segurado/detalhe/${info?.id_segurado}`}
                        target="_blank"
                        key={index}
                      >
                        Apólice {el} ( {info?.status} )
                      </Link>
                    </Row>
                  );
                })}
                {checkedApolices.length === 0 && (
                  <Row className="mt-3 text-center">
                    <Col>Nenhuma apólice selecionada.</Col>
                  </Row>
                )}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" size="sm" onClick={toggleModalRenovacao}>
                Cancelar
              </Button>
              <Button
                color="warning"
                size="sm"
                onClick={handleRenovarEmMassa}
                disabled={loading}
              >
                {loading ? <Spinner size="sm" color="light" /> : 'Confirmar'}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Box>
    </Container>
  );
};

export default Page;
