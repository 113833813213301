import React from 'react';
import { connect } from 'react-redux';
import Page from './Page';

function Controller(props) {
  return (
    <>
      <Page props={props} />
    </>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
