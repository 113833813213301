import React, { useState, useEffect } from 'react';

import { Grid, LinearProgress } from '@material-ui/core';

import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridOverlay,
  GridToolbarExport,
} from '@material-ui/data-grid/';

import { SearchTextField } from '../Inputs/TextFields';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function CustomToolbar(props) {
  const { value, onChange, clearSearch } = props;
  return (
    <GridToolbarContainer style={{ marginBottom: 16 }}>
      <Grid container spacing={2}>
        <Grid item md={10} lg={10}>
          <SearchTextField
            value={value}
            onChange={onChange}
            clearSearch={clearSearch}
            toolTipTitle="Considere pontos e acentos na pesquisa"
            // style={{ width: '200px' }}
            style={{ display: "flex", justifyContent: "flex-start" }}
          />
        </Grid>
        <Grid item md={2} lg={2}>
          <GridToolbarExport />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const DataGridLayout = ({
  rows,
  columns,
  loading,
  density = 'standard',
  pageSize = 10,
  checkboxSelection = false,
  disableSelectionOnClick = false,
  hasToolbar = false,
  hideSelectedRowCount = true,
  onSelectionModelChange = null,
  className = null,
  getCellClassName = null,
  sortField = null,
  sortDirection = null,
}) => {
  const [rowsState, setRowsState] = useState(rows);
  const [columnsState, setColumnsState] = useState(columns);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setRowsState(rows);
    setColumnsState(columns);
  }, [rows, columns]);

  const [sortModel, setSortModel] = useState([
    {
      field: sortField ?? columnsState[0].field,
      sort: sortDirection,
    },
  ]);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = rows.filter(row => {
      return Object.keys(row).some(field => {
        // if (field === 'nome' || field === 'cnpj' || field === 'id') {
        return searchRegex.test(row[field]);
        // }
        // return '';
      });
    });
    setRowsState(filteredRows);
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
      }}
    >
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <DataGrid
          disableColumnMenu
          autoHeight
          density={density}
          localeText={ptBR.props.MuiDataGrid.localeText}
          rows={rowsState}
          columns={columnsState}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10]}
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick={disableSelectionOnClick}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            Toolbar: hasToolbar ? CustomToolbar : false,
          }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: event => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
          loading={loading}
          hideFooterSelectedRowCount={hideSelectedRowCount}
          onSelectionModelChange={onSelectionModelChange}
          className={className}
          getCellClassName={getCellClassName}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
        />
      </div>
    </div>
  );
};

export default DataGridLayout;
