import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {
  FaAddressBook,
  FaMailBulk,
  FaMobileAlt,
  FaPhoneSquareAlt,
  FaUser,
} from 'react-icons/fa';
import { CardComponent } from '../styles';
import Loading from '../../components/Loading/Loading';

function ContatosClinica(props) {
  const { clinica } = props;

  return (
    <Card>
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaAddressBook className="mr-2" />
              Pessoa/Contato
            </h2>
          </Col>
        </Row>
        <hr />
        {clinica.loading ? (
          <Loading />
        ) : (
          <Row className="text-left mt-2">
            <Col xs={12}>
              <p>
                <FaUser className="ml-2" /> <b>Nome:</b> {clinica.data.nome}
              </p>
            </Col>
            <Col xs={12}>
              <p>
                <FaPhoneSquareAlt className="ml-2" /> <b>Telefone:</b>{' '}
                {clinica.data.telefone}
              </p>
            </Col>
            {clinica.data.celular && (
              <Col xs={12}>
                <p>
                  <FaMobileAlt className="ml-2" /> <b>Celular:</b>{' '}
                  {clinica.data.celular}
                </p>
              </Col>
            )}
            <Col xs={12}>
              <p>
                <FaMailBulk className="ml-2" /> <b>Email:</b>{' '}
                {clinica.data.email}
              </p>
            </Col>
          </Row>
        )}
      </CardComponent>
    </Card>
  );
}

export default ContatosClinica;
