import { Box, Container, IconButton } from '@material-ui/core';
import React from 'react';
import { FaArrowLeft, FaFileExcel, FaFileInvoiceDollar } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import Filtros from '../../views/ClinicasRelatorioSplit/Filtro';
import ComissoesTable from '../../views/ClinicasRelatorioSplit/ComissoesTable';
import TotalizadoresComissoes from '../../views/ClinicasRelatorioSplit/TotalizadoresComissoes';

function Page(props) {
  const {
    comissoes,
    pesquisa,
    setPesquisa,
    setPeriodo,
    periodo,
    setStatusPagamento,
    statusPagamento,
    setClinicaMaster,
    clinicaMaster,
    setClinicaAfiliada,
    clinicaAfiliada,
    // getExcel,
    setFireGetComissoes,
    fireGetComissoes,
    nivelPermissao,
    clinicaMasterSelect,
    clinicaAfiliadaSelect,
    exportar,
    linkBaixar,
    setModalBaixar,
    modalBaixar,
  } = props;

  const history = useHistory();

  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" my="1rem">
        <IconButton
          variant="outlined"
          color="secondary"
          onClick={() => history.goBack()}
        >
          <FaArrowLeft size="1.1rem" />
        </IconButton>
        <Box
          ml="1.5rem"
          fontSize="2.2rem"
          fontWeight="100"
          display="flex"
          alignItems="center"
        >
          <FaFileInvoiceDollar />
          <Box ml="0.7rem">Relatório de Split (Vendas Clínicas)</Box>
        </Box>
      </Box>
      <hr />
      <Filtros
        loadingComissoes={comissoes.loading}
        setPesquisa={setPesquisa}
        pesquisa={pesquisa}
        setPeriodo={setPeriodo}
        periodo={periodo}
        setStatusPagamento={setStatusPagamento}
        statusPagamento={statusPagamento}
        setClinicaMaster={setClinicaMaster}
        clinicaMaster={clinicaMaster}
        setClinicaAfiliada={setClinicaAfiliada}
        clinicaAfiliada={clinicaAfiliada}
        setFireGetComissoes={setFireGetComissoes}
        fireGetComissoes={fireGetComissoes}
        clinicaMasterSelect={clinicaMasterSelect}
        clinicaAfiliadaSelect={clinicaAfiliadaSelect}
        nivelPermissao={nivelPermissao}
      />
      <ComissoesTable
        rows={comissoes}
        periodo={periodo}
        nivelPermissao={nivelPermissao}
      />
      <TotalizadoresComissoes
        nivelPermissao={nivelPermissao}
        comissoes={comissoes}
        exportar={exportar}
        linkBaixar={linkBaixar}
        setModalBaixar={setModalBaixar}
        modalBaixar={modalBaixar}
      />
    </Container>
  );
}

export default Page;
