import Styled from 'styled-components'

export const Warning = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 320px;
  margin: 1rem auto 1rem;
  padding: 10px;


  font-size: 0.7rem;
  text-align: center;

  border-radius: 5px;
  background-color: #eee;

  svg {
    color: #e4cd05;
    font-size: 1rem;
    margin-left: 0.5rem;
  }

  p {
    margin: 0;
  }
`;

