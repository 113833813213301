import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '40px 30px',
  },
  header: {
    paddingBottom: 10,
  },
  titleHeader: {
    marginLeft: 30,
  },
  actions: {
    justifyContent: 'flex-end',
    position: 'relative',
  },
  // addButton: {
  //   // justifyContent: 'center',
  //   // textAlign: 'center',
  //   // alignItems: 'center',
  //   // height: 40,
  //   // padding: 16,
  //   position: 'absolute',
  //   top: '-20px',
  //   right: 0,
  //   // '& .MuiButton-label': {
  //   //   marginLeft: 3,
  //   // },
  // },
  tabList: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.secondary.medium,
      height: 1,
    },
  },
  tabName: {
    fontSize: '1.25rem',
    lineHeight: 1.465,
    color: theme.palette.text.primary,
    padding: '8px 30px',
    '&.MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #C9C9C9',
      borderRadius: '5px 5px 0px 0px',
    },
  },
  tabPanel: {
    padding: 0,
    minHeight: 330,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #C9C9C9',
    borderRadius: '0 5px 5px 5px',
    boxShadow: '7px -7px 10px rgba(0, 0, 0, 0.05)',
  },
  gridList: {
    display: 'flex',
    margin: 0,
    padding: 14,
    width: '100%',
    minHeight: 330,
    alignContent: 'center',
  },
}));

export default useStyles;
