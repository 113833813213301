import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import CustomSkeleton from '../../components/Loading/Skeleton';
import formatValueToPrice from '../../utils/format';
import { formatDate } from '../../services/service';

function MaterialUITable(props) {
  const { rows, periodo, getExcel, nivelPermissao } = props;

  const [rowsState, setRowsState] = useState(rows.data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    downloadButton: {
      color: '#2e6d09',
    },
  });

  // const handleBaixarExcel = async id => {
  //   setLoadingExcel(true);
  //   const excel = await getExcel(id);
  //   window.open(`${process.env.REACT_APP_API_ERP}/${excel.arquivo}`, '_blank');
  //   setLoadingExcel(false);
  // };

  useEffect(() => {
    setRowsState(rows.data);
  }, [rows]);

  const classes = useStyles();

  return (
    <Box textAlign="left" mb="1rem">
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="simple table" style={{tableLayout: 'fixed'}}>
          <TableHead style={{backgroundColor: '#d9d9d9'}}>
            <TableRow>
              <TableCell width="100px">ID Fatura</TableCell>
              <TableCell width="120px">Status Fatura</TableCell>
              <TableCell width="200px">Cliente</TableCell>
              <TableCell width="200px">Distribuidor</TableCell>

              {nivelPermissao == 'admin' ? (
                <TableCell width="160px">Distribuidor Master</TableCell>
              ) : null}

              <TableCell width="100px">ID Apólice</TableCell>
              <TableCell width="120px">Valor Fatura</TableCell>
              <TableCell width="120px">Valor Líquido</TableCell>

              {nivelPermissao == 'admin' ? (
                <TableCell width="120px">Valor - Viver</TableCell>
              ) : null}

              {nivelPermissao == 'admin' || nivelPermissao == 'master' ? (
                <TableCell width="160px">Valor - Distr. Master</TableCell>
              ) : null}

              <TableCell width="170px">Valor - Distr. Afiliado</TableCell>
              <TableCell width="200px">Vencimento Fatura</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.loading ? (
              <>
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomSkeleton height={20} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomSkeleton height={20} />
                  </TableCell>
                </TableRow>
              </>
            ) : rowsState.length ? (
              (rowsPerPage > 0
                ? rowsState.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : rowsState
              ).map((row, i) => {

                return (
                  <TableRow key={i}>
                    <TableCell>{row.id_fatura}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.nome_cliente}</TableCell>
                    <TableCell>{row.razao_social_distribuidor}</TableCell>

                    {nivelPermissao == 'admin' ? (
                      <TableCell>{row.razao_social_distribuidor_master}</TableCell>
                    ) : null}

                    <TableCell>{row.id_apolice}</TableCell>
                    <TableCell>{formatValueToPrice(row.valor_fatura)}</TableCell>
                    <TableCell>{formatValueToPrice(row.valor_fatura - row.taxa_cobranca)}</TableCell>

                    {nivelPermissao == 'admin' ? (
                      <TableCell>{formatValueToPrice(row.valor_raiz - row.outras_taxas)}</TableCell>
                    ) : null}

                    {nivelPermissao == 'admin' || nivelPermissao == 'master' ? (
                      <TableCell>{formatValueToPrice(row.valor_assessoria_master)}</TableCell>
                    ) : null}

                    <TableCell>{formatValueToPrice(row.valor_assessoria)}</TableCell>
                    <TableCell>{row.data_vencimento ? formatDate(row.data_vencimento) : '-'}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>Nenhuma comissão encontrada</TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[50, 100, 200, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rowsState.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Faturas por página:"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MaterialUITable;
