import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../services/service';

function Controller(props) {

  let { idClient } = useParams();
  let history = useHistory();

  const authStr = `Bearer ${props.user.user.token}`;
  const [ infosClinicaCliente, setInfosClinicaCliente ] = useState('');

  useEffect(() => {
    getInfosClinicaCliente();
  },[]);

  const getInfosClinicaCliente = async _ => {
    const restApi = await Api.get(`/clinica-cliente/detalhe/${idClient}`, { headers: { Authorization: authStr }});
    restApi.data_nascimento = formatDate(restApi.data.output.data_nascimento)

    setInfosClinicaCliente(restApi.data);
  }

  return (
    <>
      {
        infosClinicaCliente.output&&
        (
          <Page
            clientInputEdit={infosClinicaCliente.output}
            history={history}
          />
        )
      }
    </>
  )
}

const mapStateToProps = store => ({
  user: store.user
})

export default connect(mapStateToProps)(Controller);
