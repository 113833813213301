import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, InputGroup, InputGroupAddon, InputGroupText, FormGroup } from 'reactstrap';
import { BodyFormComponent, LabelForm } from '../styles';
import { msg } from '../../json/msg';
import { connect } from 'react-redux';
import Api from '../../services/api';
import { FaExclamation, FaKey } from 'react-icons/fa';
import SubmitBtn from '../../components/Buttons/SubmitBtn';
import { Button, Paper, FormControl, Grid, Card, CardContent, TextField, Container, CircularProgress, InputAdornment, IconButton} from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import { alertWarning, alertError, alertSuccess } from '../../components/Alerts/Alerts';
import { ToastContainer } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import 'react-toastify/dist/ReactToastify.css';
import { Warning } from './styles';

const useStyles = makeStyles(theme => ({
    paperSize: {
        height: "300px",
        paddingTop: "20px",
    },
    inputMargin: {
        marginBottom: "10px",
    },
    inputSize: {
        width: "400px",
    },
    buttonLocation: {
        marginTop: "20px",
    },
}));

function AlterarSenhaUsuario(props) {

    const classes = useStyles();
    const [ loading, setLoading ] = useState(false);

    const [ userPass, setUserPass ] = useState({
        oldPass: '',
        newPass: '',
        newPass2: ''
    })
    const [ showPassword, setShowPassword ] = useState(false);

    const handleOnChange = event => {
        const { name, value } = event.target;
        setUserPass({ ...userPass, [name]: value })
    }

    const handleClickShowPassword = () => {
        setShowPassword({ ...showPassword, showPassword: !showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async e => {
        setLoading(true)
        e.preventDefault();

        const authStr = `Bearer ${props.user.user.token}`;

        if(userPass.newPass !== userPass.newPass2){
            alertWarning("As senhas novas digitadas não coincidem. Tente novamente.");
            setLoading(false);
            return false;
        }

        if (!userPass.newPass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) {
          alertWarning("As senha deve conter pelomenos 1 letra maiúscula, 1 letra minúscula e 1 número.");
          setLoading(false);
          return false;
        }

        let bodyFormData = new FormData();
        bodyFormData.set('senhaAntiga', userPass.oldPass);
        bodyFormData.set('novaSenha', userPass.newPass);
        bodyFormData.set('novaSenha2', userPass.newPass2);
        bodyFormData.set('id',props.user.user.usuario);

        const resultReq = await Api.post('usuario/alterar-senha', bodyFormData, { headers: { Authorization: authStr }});

        if(resultReq.data.ok){
            alertSuccess(resultReq.data.message);
            setUserPass({
                oldPass: '',
                newPass: '',
                newPass2: ''
            });
        }else{
            alertError(resultReq.data.message);
        }
        setLoading(false);
    }

    const [senhaFraca, setSenhaFraca] = useState(0);


useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search)
  const m = queryParams.get('m')

  if (m == 'senha-fraca') {
    setSenhaFraca(1);
  }
});
    return(
        <Grid>
            <Paper className={classes.paperSize}>
                <Container>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} className={classes.inputMargin}>
                            {senhaFraca ? (
                              <Warning>
                                <FaExclamation />
                                <p>
                                  Aviso: É necessário alterar sua senha (utilize senhas com letras maiúsculas, minúsculas e números).
                                </p>
                              </Warning>
                            ) : null}

                            <TextField
                                required
                                type={showPassword ? 'text' : 'password'}
                                value={userPass.oldPass}
                                name="oldPass"
                                label="Senha Antiga"
                                variant="outlined"
                                className={classes.inputSize}
                                onChange={e => handleOnChange(e)}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.inputMargin}>
                            <TextField required type="password" value={userPass.newPass} name="newPass" label="Senha Nova" variant="outlined" className={classes.inputSize} onChange={e => handleOnChange(e)}/>
                        </Grid>
                        <Grid item xs={12} className={classes.inputMargin}>
                            <TextField required type="password" value={userPass.newPass2} name="newPass2" label="Repita a nova senha" variant="outlined" className={classes.inputSize} onChange={e => handleOnChange(e)}/>
                        </Grid>

                        <Button type="submit" variant="contained" color="primary" className={classes.buttonLocation}>
                            {
                                loading
                                ?
                                (<CircularProgress color="secondary" />)
                                :
                                (<span>Trocar</span>)
                            }
                        </Button>
                    </form>
                </Container>
            </Paper>
        </Grid>
    )
}

const mapStateToProps = store => ({
    user: store.user,
  });

  export default connect(mapStateToProps)(AlterarSenhaUsuario);


