import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import {
  FaPercent,
  FaRegMoneyBillAlt,
  FaVuejs,
  FaWallet,
} from 'react-icons/fa';
import { CardComponent } from '../styles';
import Loading from '../../components/Loading/Loading';

function ComissaoClinica(props) {
  const { clinica } = props;

  return (
    <Card className="mt-4">
      <CardComponent>
        <Row className="justify-content-between m-0 p-0">
          <Col className="header-content mb-2" xs="auto">
            <h2 className="m-0 align-baseline">
              <FaPercent className="mr-2" />
              Comissões
            </h2>
          </Col>
        </Row>
        <hr />
        {clinica.loading ? (
          <Loading />
        ) : (
          <Row className="text-left mt-2">
            <Col xs={12}>
              <p>
                <b>ID Subconta Asaas: </b>
                {clinica.data.id_asaas_subconta
                  ? clinica.data.id_asaas_subconta
                  : 'Não cadastrado'}
              </p>
            </Col>
            { clinica.data.tipo == 'afiliado' ? (
                <>
                  <Col xs={12}>
                    <p>
                      <b>Viver: </b>
                      {clinica.data.comissao_viver} %
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p>
                      <b>Convênio: </b>
                      {clinica.data.comissao_convenio} %
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p>
                      <b>Particular: </b>
                      {clinica.data.comissao_particular} %
                    </p>
                  </Col>
                </>
              ): null
            }
          </Row>
        )}
      </CardComponent>
    </Card>
  );
}

export default ComissaoClinica;
