import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaArrowLeft, FaExclamation, FaPeopleCarry, FaWrench } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import TitleContent from '../../components/TitleContent/TitleContent';
import InformacoesAssessoria from '../../views/Assessoria/InformacoesAssessoria';
import ContatosAssessoria from '../../views/Assessoria/ContatosAssessoria';
import VendedoresLista from '../../views/Assessoria/VendedoresLista';
import ModalEditarVendedor from '../../views/Assessoria/ModalEditarVendedor';
import EditarAssessoria from '../../views/Assessoria/EditarAssessoria';
import ComissaoAssessoria from '../../views/Assessoria/ComissaoAssessoria';
import DadosBancariosAssessoria from '../../views/Assessoria/DadosBancariosAssessoria';
import MaterialModal from '../../components/Modal/MaterialModal';
import { Warning } from '../../views/Apolice/styles';

function Page(props) {
  const {
    assessoria,
    vendedores,
    submitChangeStatus,
    loadingChangeStatus,
    getVendedores,
    getDadosAssessoria,
    gruposVenda,
    user,
    excluirVendedor,
    assessorias
  } = props;

  const history = useHistory();

  const [modalEditarVendedor, setModalEditarVendedor] = useState(false);
  const [modalEditarAssessoria, setModalEditarAssessoria] = useState(false);
  const [vendedorSelected, setVendedorSelected] = useState({});

  const openModal = vendedor => {
    setVendedorSelected(vendedor);
    setModalEditarVendedor(true);
  };

  const closeModal = () => {
    setModalEditarVendedor(!modalEditarVendedor);
  };

  const toggleModalEditar = () => {
    setModalEditarAssessoria(!modalEditarAssessoria);
  };


  const [idItemExclusao, setIdItemExclusao] = useState(false);
  const [modalExclusao, setModalExclusao] = useState(false);
  const [loadingAlterarPagamento, setLoadingAlterarPagamento] = useState(false);

  const toggleModalExclusao = (id) => {
    setIdItemExclusao(id);
    setModalExclusao(!modalExclusao);
  };

  const handleExcluirVendedor = async () => {
    excluirVendedor(idItemExclusao);
    setModalExclusao(!modalExclusao);
  };

  const titleHeader = (
    <>
      <FaPeopleCarry />
      &nbsp;&nbsp;{assessoria.data.nome_fantasia}
    </>
  );
  return (
    <Container fluid>
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            color="danger"
            size="sm"
            onClick={() => history.goBack()}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left">
        <Col md={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row>
        <Col xs="auto" className="mt-3">
          <Button
            color="success"
            outline
            size="sm"
            className="mb-2"
            onClick={() => toggleModalEditar()}
            disabled={assessoria.loading}
          >
            <FaWrench />
            {assessoria.loading ? '  Carregando...' : '  Editar'}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <InformacoesAssessoria assessoria={assessoria} />
        </Col>
        <Col md={6}>
          <ContatosAssessoria assessoria={assessoria} />
        </Col>
        {user.user.nivel_usuario == 1 && (
        <Col md={6} className="mt-4">
          <ComissaoAssessoria assessoria={assessoria} />
        </Col>
        )}
      </Row>
      <Row className="pb-4 mt-4 ">
        <Col>
          <VendedoresLista
            vendedores={vendedores}
            submitChangeStatus={submitChangeStatus}
            loadingChangeStatus={loadingChangeStatus}
            openModal={openModal}
            toggleModalExclusao={toggleModalExclusao}
            gruposVenda={gruposVenda}
            assessorias={assessorias}
            assessoria={assessoria}
            getVendedores={getVendedores}
          />
        </Col>
      </Row>
      <ModalEditarVendedor
        modal={modalEditarVendedor}
        toggle={closeModal}
        vendedor={vendedorSelected}
        getVendedores={getVendedores}
      />
      <EditarAssessoria
        toggle={toggleModalEditar}
        modal={modalEditarAssessoria}
        assessoria={assessoria}
        getAssessoria={getDadosAssessoria}
        gruposVenda={gruposVenda}
      />
      <MaterialModal
          toggle={toggleModalExclusao}
          modal={modalExclusao}
          handleConfirm={handleExcluirVendedor}
          loading={loadingAlterarPagamento}
          title="Exclusão de vendedor"
        >
          <>
            Tem certeza que deseja excluir este vendedor?
          </>
      </MaterialModal>
    </Container>
  );
}

export default Page;
