import React, { useState } from 'react';

import { Button, Tab, Grid, Container, Typography } from '@material-ui/core';

import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

// import AddBoxIcon from '@material-ui/icons/AddBox';
import SchoolIcon from '@material-ui/icons/School';
import { RegisterButton } from '../../components/Buttons/Buttons';

import ListaTreinamentos from '../../views/MaterialApoio/ListaTreinamentos';
import ListaMaterialVendas from '../../views/MaterialApoio/ListaMaterialVendas';
import ModalAddMaterialApoio from '../../components/MaterialApoio/ModalCadastro';

import useStyles from './styles';

function Page(props) {
  const {
    nivelUsuario,
    modalAddEditMaterialApoio,
    setTipoMaterial,
    actionType,
    showModalAddMaterialApoio,
  } = props;

  const classes = useStyles();

  const [tabMaterialApoio, setTabMaterialApoio] = useState('treinamento');

  const handleOnChangeTabMaterialApoio = (event, newTabMaterialApoio) => {
    setTabMaterialApoio(newTabMaterialApoio);
    setTipoMaterial(newTabMaterialApoio);
  };

  const handleOnClickAddMaterialApoio = () => {
    showModalAddMaterialApoio();
  };

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid
        container
        component="header"
        alignItems="center"
        className={classes.header}
      >
        <SchoolIcon />
        <Typography variant="h1" className={classes.titleHeader}>
          Material de apoio
        </Typography>
      </Grid>
      <Grid container className={classes.actions}>
        {nivelUsuario && nivelUsuario == 1 && (
          <Grid item>
            <RegisterButton
              onClick={handleOnClickAddMaterialApoio}
              // className={classes.addButton}
            >
              Adicionar material de apoio
            </RegisterButton>
          </Grid>
          // <Button
          //   variant="contained"
          //   color="primary"
          //   onClick={handleOnClickAddMaterialApoio}
          //   startIcon={<AddBoxIcon />}
          //   className={classes.addButton}
          // >
          //   <Typography variant="button">
          //     Adicionar material de apoio
          //   </Typography>
          // </Button>
        )}
      </Grid>
      <TabContext value={tabMaterialApoio}>
        <TabList
          onChange={handleOnChangeTabMaterialApoio}
          aria-label="Abas de Material de Apoio"
          className={classes.tabList}
        >
          <Tab
            label="Treinamentos"
            value="treinamento"
            className={classes.tabName}
          />
          <Tab
            label="Vendas"
            value="material-de-venda"
            className={classes.tabName}
          />
        </TabList>

        {modalAddEditMaterialApoio && actionType === 'cadastrar' && (
          <ModalAddMaterialApoio {...props} />
        )}

        <TabPanel value="treinamento" className={classes.tabPanel}>
          <Grid container spacing={4} className={classes.gridList}>
            <ListaTreinamentos {...props} />
          </Grid>
        </TabPanel>
        <TabPanel value="material-de-venda" className={classes.tabPanel}>
          <Grid container spacing={4} className={classes.gridList}>
            <ListaMaterialVendas {...props} />
          </Grid>
        </TabPanel>
      </TabContext>
    </Container>
  );
}

export default Page;
