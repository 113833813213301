import React, { useState } from 'react';
import {
  Card,
  Col,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Button,
  Form,
  Spinner,
  UncontrolledTooltip,
  InputGroup,
  InputGroupText,
  InputGroupAddon
} from 'reactstrap';
import {
  FaFileContract,
  FaSearch,
  FaRetweet,
  FaPlus,
  FaExclamationTriangle,
  FaCreditCard,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Switch } from '@material-ui/core';
import {
  CardComponent,
  OverflowContainer,
  LabelForm,
  TableContent,
  MsgInputError,
} from '../styles';

import Api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';


function ClinicaClienteVenda(props) {
  const {
    clinicaVendas,
    history,
    client,
    idEstipulante,
    idCliente,
    clinicas,
  } = props;
  const authStr = `Bearer ${props.user.user.token}`;

  const [modalConfigClinicaVenda, setModalConfigClinicaVenda] = useState(false);
  const [idClinicaVenda, setIdClinicaVenda] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salesMan, setSalesman] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [clientPayment, setClientPayment] = useState({
    id_produto: 0,
    forma_pagamento: '',
    primeiro_pagamento: new Date().toISOString().split('T')[0],
    dia_proximos_pagamentos: '',
    qtd_parcelas: 12,
    id_usuario: parseInt(props.user.user.usuario),
    id_clinica: '',
    id_cliente: parseInt(idCliente),
    valor_venda: 0,
    modalidade: 'viver'
  });

  const criarClinicaVenda = async () => {
    setLoading(true);
    try {
      const response = await Api.post(`clinica-clientes/nova-venda`, clientPayment, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
      if (response.data.ok) {
        window.location.reload();
      } else {
        const errorsMessages = JSON.parse(response.data.errors);

        const errors = [];
        for (let i = 0; i < errorsMessages.length; i++) {
          errors.push(new Error(errorsMessages[i]));
        }

        if (errors.length > 0) {
          throw errors;
        }
      }
    } catch (error) {
      for (let i = 0; i < error.length; i++) {
        alertError(error[i].message);
      }
    }
    setLoading(false);
  };

  const handleOnChange = async event => {
    const { value } = event.target;

    const atendente = await Api.get(
      `clinica-atendente/clinica/${value}`,
      {
        headers: { Authorization: authStr },
      },
    );

    let atendenteArr = [];
    for (const i in atendente.data.output) {
      const tmpObj = {
        id_pessoa: atendente.data.output[i].id_pessoa,
        id: atendente.data.output[i].id,
        nome: atendente.data.output[i].nome,
      };
      atendenteArr = [...atendenteArr, tmpObj];
    }
    setSalesman(atendenteArr);

    var clinicaSelected = clinicas.find(function(post, index) {
      if(post.id == value)
        return true;
    });

    setClientPayment({ ...clientPayment, id_clinica: value, desconto_convenio: clinicaSelected.desconto_convenio });
  };

  const handleChangeSalesMan = e => {
    const { value } = e.target;
    setClientPayment({ ...clientPayment, clinicaAtendente: value });
  };

  const handleClientPayment = async event => {
    const { name, value } = event.target;
    setClientPayment({ ...clientPayment, [name]: value });
  };

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Card>
      <ToastContainer />
      {modalConfigClinicaVenda && (
        <Modal
          isOpen={modalConfigClinicaVenda}
          size="lg"
          toggle={() => setModalConfigClinicaVenda(!modalConfigClinicaVenda)}
        >
          <ModalHeader
            toggle={() => setModalConfigClinicaVenda(!modalConfigClinicaVenda)}
          >
            <FaFileContract /> Cadastrar nova venda
          </ModalHeader>
          <ModalBody>
            <Form>
              {props.user.user.nivel_usuario != 3 && (
                <Row className="mb-4">
                  <Col md={6}>
                    <LabelForm>Escolha uma clínica *</LabelForm>
                    <Input
                      type="select"
                      name="id_clinica"
                      onChange={handleOnChange}
                      required
                    >
                      <option value="0">Escolha uma clínica</option>
                      {clinicas.map((el, i) => {
                        if (el.tipo == 'afiliado') {
                          return (
                            <option key={i} value={el.id_clinica}>
                              {el.nome_fantasia} / {el.nome}
                            </option>
                          );
                        }
                      })}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <LabelForm>Escolha o atendente *</LabelForm>
                    <Input
                      type="select"
                      name=""
                      onChange={handleChangeSalesMan}
                      required
                    >
                      <option value="0">Escolha o atendente</option>

                      {salesMan.map((el, i) => {
                        return (
                          <option key={i} value={el.id}>
                            {el.nome}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </Row>
              )}
              <Row className="mb-4">
                <Col>
                  <LabelForm>Nome do Produto *</LabelForm>
                  <InputGroup>
                    <Input
                      type="text"
                      required
                      placeholder="Informe o nome do produto"
                      name="nome_produto"
                      value={clientPayment.nome_produto}
                      onChange={e => handleClientPayment(e)}
                    />
                </InputGroup>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg={6}>
                  <LabelForm>Forma de Pagamento *</LabelForm>

                  <Input
                    type="select"
                    name="forma_pagamento"
                    value={clientPayment.forma_pagamento}
                    onChange={e => handleClientPayment(e)}
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="carne-impresso">Carnê</option>
                    {/* <option value="cartao-credito">Cartão de Crédito</option> */}
                  </Input>
                </Col>
                <Col lg={6}>
                  <LabelForm>Data do pagamento *</LabelForm>

                  <Input
                    className="form-control"
                    type="date"
                    placeholder="Data Primeiro Pagamento"
                    name="primeiro_pagamento"
                    value={clientPayment.primeiro_pagamento}
                    onChange={e =>
                      setClientPayment({
                        ...clientPayment,
                        primeiro_pagamento: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <LabelForm>Modalidade *</LabelForm>
                  <InputGroup>
                    <Input
                      type="select"
                      required
                      name="modalidade"
                      value={clientPayment.modalidade}
                      onChange={e => handleClientPayment(e)}
                    >
                      <option value="">Selecione</option>
                      <option value="viver">Viver</option>
                      <option value="convenio">Convênio</option>
                      <option value="particular">Particular</option>
                    </Input>
                  </InputGroup>
                </Col>
                <Col lg={4}>
                  <LabelForm>Valor *</LabelForm>
                  <InputGroup>
                    <Input
                      type="number"
                      required
                      placeholder="Informe o valor"
                      name="valor_venda"
                      value={clientPayment.valor_venda}
                      onChange={e => handleClientPayment(e)}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner color="primary" size="sm" />
            ) : (
              <>
                <Button color="success" onClick={() => criarClinicaVenda()}>
                  Salvar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      )}
      <CardComponent  style={{paddingTop: "15px"}}>
        <Col className="header-content">
          <h2>
            <FaFileContract style={{ color: '#DA2626' }} />
            &nbsp;&nbsp;Vendas
          </h2>
        </Col>
        <OverflowContainer>
          <TableContent>
            <Row>
              <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                {clinicaVendas.output.length > 0 ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>#ID</th>
                        <th>Tipo</th>
                        <th>Status</th>
                        <th>Atendente</th>
                        <th>Produto</th>
                        <th>Valor</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {clinicaVendas.output.map((el, i) => {
                        let statusLabel = 'secondary';

                        if (el.status === 'Pago') {
                          statusLabel = 'success';
                        } else if (el.status === 'Cancelada') {
                          statusLabel = 'danger';
                        }

                        return (
                          <tr key={i}>
                            <td>{el.id_clinica_venda}</td>
                            <td >
                              <Badge color="primary">{capitalizeFirst(el.modalidade == 'convenio' ? 'Convênio' : el.modalidade)}</Badge>
                            </td>
                            <td>
                              <Badge color={statusLabel}>{el.status}</Badge>
                            </td>
                            <td>{el.nome_atendente}</td>
                            <td>{el.nome_produto}</td>
                            <td><a href={el.link_arquivo} target="_blank">R$ {el.valor}</a></td>
                            {/* <td
                              style={{
                                paddingLeft: '0px',
                                paddingRight: '0px',
                              }}
                            >
                              <Button
                                size="sm"
                                outline
                                color="primary"
                                onClick={() =>
                                  history.push(
                                    `/clinicaVenda/${el.id_clinica_venda}/${el.id_estipulante}/${client.id}`,
                                  )
                                }
                              >
                                <FaSearch style={{ cursor: 'pointer' }} />
                              </Button>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <span style={{ color: 'red' }}>{clinicaVendas.message}</span>
                )}
              </Col>
            </Row>
          </TableContent>
        </OverflowContainer>
      </CardComponent>
      <Row className="mt-4 mb-3">
        <Col className="text-right">
          <Button
            color="success"
            size="sm"
            onClick={() => setModalConfigClinicaVenda(true)}
            title="Nova venda"
          >
            <FaPlus /> Nova venda
          </Button>
        </Col>
        </Row>
    </Card>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(ClinicaClienteVenda);
