import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as cep from 'cep-promise';
import { ToastContainer } from 'react-toastify';
import Page from './Page';
import { sendFormData } from '../../services/getApi';
import Api from '../../services/api';
import {
  verificaIdadeLimite,
  verificaIdadeMinima,
  ordenaPorId,
} from '../../services/service';
import { msg } from '../../json/msg';
import {
  alertWarning,
  alertError,
  alertSuccess,
} from '../../components/Alerts/Alerts';

function Controller(props) {
  const { idEstipulante } = useParams();
  const { token } = props.user.user;

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContatos, setModalContatos] = useState(false);
  const [infosEmpresas, setInfosEmpresas] = useState({});
  const [infosContatos, setInfosContatos] = useState({});
  const [infosFuncionarios, setInfosFuncionarios] = useState({});
  const [infosApolice, setInfosApolice] = useState({});
  const [resultBuscaProd, setResultBuscaProd] = useState({});
  const [assessoria, setAssessoria] = useState([{ id: '', name: '' }]);
  const [funcAtivos, setFuncAtivos] = useState([]);
  const [funcInativos, setFuncInativos] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [params, setParams] = useState({
    idProduto: 0,
  });

  const [funcionarios, setFuncionarios] = useState({
    nome: '',
    cpf: '',
    data_nascimento: '',
    estado_civil: '',
    sexo: '',
    telefone: '',
    celular: '',
    email: '',
    origem: '',
    observacao: '',
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    id_produto: '',
    id_estipulante: parseInt(idEstipulante, 10),
  });
  const [dependentes, setDependentes] = useState([
    {
      tipoDependente: '',
      parentesco: '',
      responsavel: '',
      cpfDependente: '',
      nomeDependente: '',
      dataNascimentoDependente: '',
    },
  ]);
  const [inputResponsavelDep, setInputResponsavelDep] = useState(false);
  const [dependentList, setDependentList] = useState([]);
  const [contato, setContato] = useState({
    nome_responsavel: '',
    telefone: '',
    celular: '',
    email: '',
    cargo: '',
    id_estipulante: idEstipulante,
  });

  const authStr = `Bearer ${props.user.user.token}`;

  useEffect(() => {
    getEmpresa();
    getContatos();
    getFuncionarios();
    getApolices();
    buscarProdutos();
    buscarAssessoria();
    getFuncionariosInativos();
    getFuncionariosAtivos();
  }, [params]);

  const getEmpresa = async () => {
    if (idEstipulante) {
      const request = await Api.get(`/empresa/${idEstipulante}`, {
        headers: { Authorization: authStr },
      });
      setInfosEmpresas(request.data);
    }
  };

  const getContatos = async () => {
    const request = await Api.get(`/empresa/contatos/${idEstipulante}`, {
      headers: { Authorization: authStr },
    });
    setInfosContatos(request.data);
  };

  const getFuncionarios = async () => {
    const request = await Api.get(`/segurados/${idEstipulante}`, {
      headers: { Authorization: authStr },
    });
    if (Object.keys(request.data.output).length > 0) {
      ordenaPorId(request.data.output);
    }
    setInfosFuncionarios(request.data);
  };

  const getFuncionariosInativos = async () => {
    const request = await Api.get(`/segurados/${idEstipulante}?ativo=0`, {
      headers: { Authorization: authStr },
    });
    if (request.data.ok) setFuncInativos(request.data.output);
  };

  const getFuncionariosAtivos = async () => {
    const request = await Api.get(
      `/segurados/${idEstipulante}?ativo=1&idProduto=${params.idProduto}`,
      { headers: { Authorization: authStr } },
    );
    if (request.data.ok) {
      setFuncAtivos(request.data.output);
    } else {
      setFuncAtivos([]);
    }
  };

  const getApolices = async () => {
    const request = await Api.get(`apolices/estipulante/${idEstipulante}`, {
      headers: { Authorization: authStr },
    });
    setInfosApolice(request.data);
  };

  const buscarProdutos = async () => {
    const request = await Api.get(`/produtos/`, {
      headers: { Authorization: authStr },
    });
    if (request.data) {
      setResultBuscaProd(request.data);
    } else {
      setResultBuscaProd([]);
    }
  };

  const buscarAssessoria = async () => {
    const request = await Api.get(`/assessorias/`, {
      headers: { Authorization: authStr },
    });
    let assessoriaArr = [];
    for (const i in request.data.output) {
      const tmpObj = {
        name: request.data.output[i].nome,
        id: request.data.output[i].id,
        id_usuario: request.data.output[i].id_usuario,
      };
      assessoriaArr = [...assessoriaArr, tmpObj];
    }
    setAssessoria(assessoriaArr);
  };

  const handleDependentesOnChange = e => {
    const { name, value } = e.target;
    setDependentes({ ...dependentes, [name]: value });
    if (name === 'tipoDependente') {
      if (value === '1') {
        setInputResponsavelDep(true);
        setDependentes({ ...dependentes, [name]: value });
      } else {
        setInputResponsavelDep(false);
      }
    }
  };

  const handleFuncionarioOnChange = e => {
    const { name, value } = e.target;
    if (name === 'cep') {
      cep(value).then(vl => {
        setFuncionarios({
          ...funcionarios,
          logradouro: vl.street,
          cidade: vl.city,
          bairro: vl.neighborhood,
          uf: vl.state,
        });
      });
    }
    setFuncionarios({ ...funcionarios, [name]: value });
  };

  const handleContatoOnChange = e => {
    const { name, value } = e.target;
    setContato({ ...contato, [name]: value });
  };

  const openModalCadastroFuncionario = () => setModal(!modal);

  const openModalCadastroContato = () => setModalContatos(!modalContatos);

  const cadastrarFuncionarioForm = async event => {
    setLoading(true);
    event.preventDefault();

    let dependentes = [];

    const resultVerificaIdadeLimite = verificaIdadeLimite(
      funcionarios.data_nascimento,
      75,
    );

    if (!resultVerificaIdadeLimite) {
      alertWarning('A Idade do titular deve ser menor ou igual a 75 anos');
      return false;
    }
    const resultVerificaIdadeMinima = verificaIdadeMinima(
      funcionarios.data_nascimento,
      14,
    );
    if (!resultVerificaIdadeMinima) {
      alertWarning('Idade do titular deve ser maior ou igual a 14 anos');
      return false;
    }

    if (Object.keys(dependentList).length > 0) {
      dependentList.map(el => {
        dependentes = [
          ...dependentes,
          {
            cpf: el.cpfDependente,
            titular: el.responsavel,
            nome: el.nomeDependente,
            data_nascimento: el.dataNascimentoDependente,
            parentesco: el.parentesco,
            tipo: el.tipoDependente,
          },
        ];
      });
    }

    funcionarios.dependentes = dependentes;

    const result = await Api.post('segurado', funcionarios, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });

    if (result.data.ok) {
      setModal(!modal);
      alertSuccess('Funcionário cadastrado com sucesso');
      getFuncionarios();
    } else {
      alertError(result.data.message);
      return false;
    }
    setLoading(false);
  };

  const adicionarDependente = () => {
    let newDependentArr = [];

    const depObj = {
      tipoDependente: dependentes.tipoDependente,
      parentesco: dependentes.parentesco,
      nomeDependente: dependentes.nomeDependente,
      dataNascimentoDependente: dependentes.dataNascimentoDependente,
      cpfDependente: dependentes.cpfDependente,
      responsavel: dependentes.responsavel
        ? dependentes.responsavel
        : funcionarios.nome,
    };

    newDependentArr = [...newDependentArr, depObj];
    setDependentList(newDependentArr);
    setDependentes({
      tipoDependente: '',
      parentesco: '',
      nomeDependente: '',
      dataNascimentoDependente: '',
      responsavel: '',
      cpfDependente: '',
    });
  };

  const cadastrarContatoForm = async e => {
    setLoading(true);
    e.preventDefault();

    const result = await sendFormData('empresa/contato', contato, props);

    if (result.data.ok) {
      window.location.reload();
    } else {
      return alertError(msg.error_system);
    }
    setLoading(false);
  };

  const deletarContato = async idContato => {
    if (idContato) {
      const result = await Api.delete(`empresa/contato/${idContato}`, {
        headers: { Authorization: authStr },
      });

      if (result.data.ok) {
        alertSuccess('Contato exlcuído com sucesso');
        getContatos();
      } else {
        alertError(msg.error_system);
      }
    } else {
      alertError('ID do Contato não encontrado');
    }
  };

  const handleMudarProdutoFuncionario = async () => {
    const form = document.forms.formMudarProduto;
    const idSegurado = form.idSegurado.value;
    const idApolice = form.idApolice.value;
    const idProduto = form.idProduto.value;

    setBtnDisabled(true);

    setTimeout(() => {}, 2000);

    if (!idSegurado) {
      alertWarning('Funcionário não selecionado.');
      setBtnDisabled(false);
      return;
    }

    if (idApolice === '') {
      alertWarning('Apólice não selecionada.');
      setBtnDisabled(false);
      return;
    }

    if (idProduto === '') {
      alertWarning('Produto não selecionado.');
      setBtnDisabled(false);
      return;
    }

    if (!window.confirm('Deseja mesmo adicionar este funcionário?')) {
      setBtnDisabled(false);
      return;
    }

    const obj = {
      id_apolice: idApolice,
      id_produto: idProduto,
      id_segurado: idSegurado,
      id_usuario: props.user.user.usuario,
    };

    const response = await Api.post(`apolice/produto-segurado/ativar`, obj, {
      headers: { Authorization: authStr },
    });
    if (response.data.ok) {
      alertSuccess('Sucesso');
      window.location.reload();
    } else {
      alertWarning(`Erro ao mudar de produto: ${response.data.message}`);
    }

    setBtnDisabled(false);
  };

  const getFuncionarioApolices = async idFuncionario => {
    const response = await Api.get(
      `segurado/${idFuncionario}/apolices-produtos`,
      { headers: { Authorization: authStr } },
    );

    return response.data;
  };

  const inactivatePlanoFuncionario = async planoFuncionario => {
    const data = {
      ...planoFuncionario,
      id_usuario: props.user.user.usuario,
    };

    const response = await Api.post(`apolice/produto-segurado/inativar`, data, {
      headers: { Authorization: authStr },
    });

    if (response.data.ok) {
      alertSuccess('Funcionário inativado da apólice com sucesso!');
    }

    return response.data;
  };

  return (
    <div>
      <ToastContainer />
      <Page
        infosEmpresas={infosEmpresas}
        infosFuncionarios={infosFuncionarios}
        infosContatos={infosContatos}
        deletarContato={deletarContato}
        infosApolice={infosApolice}
        resultBuscaProd={resultBuscaProd}
        assessoria={assessoria}
        idEstipulante={idEstipulante}
        modal={modal}
        setModal={setModal}
        modalContatos={modalContatos}
        openModalCadastroFuncionario={openModalCadastroFuncionario}
        openModalCadastroContato={openModalCadastroContato}
        handleFuncionarioOnChange={handleFuncionarioOnChange}
        funcionarios={funcionarios}
        cadastrarFuncionarioForm={cadastrarFuncionarioForm}
        adicionarDependente={adicionarDependente}
        inputResponsavelDep={inputResponsavelDep}
        dependentList={dependentList}
        handleContatoOnChange={handleContatoOnChange}
        handleDependentesOnChange={handleDependentesOnChange}
        dependentes={dependentes}
        contato={contato}
        cadastrarContatoForm={cadastrarContatoForm}
        token={token}
        loading={loading}
        getFuncionarios={getFuncionarios}
        getApolices={getApolices}
        funcAtivos={funcAtivos}
        funcInativos={funcInativos}
        params={params}
        setParams={setParams}
        handleMudarProdutoFuncionario={handleMudarProdutoFuncionario}
        btnDisabled={btnDisabled}
        getFuncionarioApolices={getFuncionarioApolices}
        inactivatePlanoFuncionario={inactivatePlanoFuncionario}
      />
    </div>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
