import React, { useState } from 'react';
import * as cep from 'cep-promise';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';

import Page from './Page';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';
import api from '../../services/api';

import theme from '../../styles/theme';

function Controller(props) {
  const { user } = props;

  const authStr = `Bearer ${user.user.token}`;

  const history = useHistory();
  const [convenio, setConvenio] = useState({
    cnpj: '',
    nome: '',
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    id_usuario: user.user.usuario,
    desconto_fatura_porcentagem: '',
    primeiro_dep_gratis: '',
  });

  const [contato, setContato] = useState({
    nome_responsavel: '',
    cargo: '',
    telefone: '',
    celular: '',
    email: '',
    id_estipulante: '',
  });

  const [loading, setLoading] = useState(false);

  const handleConvenioOnChange = event => {
    const { name, value } = event.target;
    setConvenio({ ...convenio, [name]: value });
  };

  const handleContatoOnChange = e => {
    const { name, value } = e.target;
    setContato({ ...contato, [name]: value });
  };

  const handleCepOnChange = event => {
    const { name, value } = event.target;
    const valueFixed = value.replace(/[_-]/g, '');
    if (valueFixed.length === 8 && name === 'cep') {
      cep(valueFixed).then(vl => {
        setConvenio({
          ...convenio,
          [name]: value,
          logradouro: vl.street,
          cidade: vl.city,
          bairro: vl.neighborhood,
          uf: vl.state,
        });
      });
    } else {
      setConvenio({ ...convenio, [name]: value });
    }
  };

  const cadastrarEmpresa = async e => {
    e.preventDefault();

    try {
      const response = await api.post('convenio', convenio, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        alertSuccess('Convênio cadastrado com sucesso!');
        cadastrarContatoForm(response.data.output.idEstipulante);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Erro ao cadastrar convênio');
    }
  };

  const cadastrarContatoForm = async idConvenio => {
    setLoading(true);
    const formData = {
      ...contato,
      id_estipulante: idConvenio,
    };
    try {
      const response = await api.post('convenio/contato', formData, {
        headers: { Authorization: authStr },
      });

      if (response.data.ok) {
        history.push(`/convenio/${idConvenio}`);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError('Falha ao cadastrar contato do convênio');
    }

    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Page
        empresa={convenio}
        handleConvenioOnChange={handleConvenioOnChange}
        handleCepOnChange={handleCepOnChange}
        cadastrarEmpresa={cadastrarEmpresa}
        handleContatoOnChange={handleContatoOnChange}
        contato={contato}
        loading={loading}
      />
    </ThemeProvider>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
