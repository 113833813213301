import React, { useState } from 'react';
// import {
//   Card,
//   Input,
//   Row,
//   Col,
//   InputGroup,
//   InputGroupAddon,
//   InputGroupText,
//   Form,
// } from 'reactstrap';
// import {
//   FaBuilding,
//   FaRoad,
//   FaInfo,
//   FaMapMarkerAlt,
//   FaMapMarkedAlt,
//   FaGlobeAmericas,
//   FaRegMoneyBillAlt,
//   FaFileUpload,
// } from 'react-icons/fa';
// import InputMask from 'react-input-mask';
// import {
//   BodyFormComponent,
//   SectionFormHeader,
//   PrependInputLabel,
// } from '../styles';
// import SubmitBtn from '../../components/Buttons/SubmitBtn';

import { Grid, Typography } from '@material-ui/core';

import AssignmentIcon from '@material-ui/icons/Assignment';
import PinDropIcon from '@material-ui/icons/PinDrop';

import { makeStyles } from '@material-ui/core/styles';
// import { SaveButton } from '../../components/Buttons/Buttons';

import {
  CNPJTextField,
  CEPTextField,
  DefaultFormTextField,
} from '../../components/Inputs/TextFields';

// import Api from '../../services/api';

const useStyles = makeStyles(() => ({
  sectionFormHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionFormTitle: {
    marginLeft: 20,
  },
  sectionFormBody: {
    flexDirection: 'row',
  },
}));

function EditarConvenio(props) {
  const { handleOnChange, handleSubmit } = props;
  const { setResultConvenio, resultConvenio, token } = props;
  const {
    cnpj,
    nome,
    cep,
    numero_endereco,
    logradouro,
    complemento_endereco,
    bairro,
    cidade,
    uf,
    desconto_fatura_porcentagem,
    primeiro_dep_gratis,
    dia_proximos_pagamentos,
    formaLiberacaoDebito, // TODO, verificar implementação.
  } = resultConvenio;
  // const [loading, setLoading] = useState(false);

  const classes = useStyles();

  // const handleOnChange = event => {
  //   const { name, value } = event.target;
  //   setResultConvenio({ ...resultConvenio, [name]: value });
  // };

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const response = await Api.post('convenio/editar', resultConvenio, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });

  //   if (response.data.ok) {
  //     setLoading(false);
  //     window.history.back();
  //   }
  // };

  return (
    <Grid item xs="auto" container spacing={6}>
      {/* <Paper style={{ padding: 30, marginTop: '30px' }}> */}
      {/* <Grid container> */}
      {/* <form onSubmit={handleSubmit}> */}
      <Grid item xs="auto" container spacing={4}>
        <Grid item xs={12} className={classes.sectionFormHeader}>
          <AssignmentIcon />
          <Typography variant="h3" className={classes.sectionFormTitle}>
            Dados da empresa
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
          spacing={2}
          container
          className={classes.sectionFormBody}
        >
          <Grid item xs={12} md={6}>
            <DefaultFormTextField
              id="editar-convenio-nome"
              label="Nome da empresa conveniada"
              name="nome"
              value={nome}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CNPJTextField
              id="editar-convenio-cnpj"
              label="CNPJ"
              name="cnpj"
              value={cnpj}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs="auto" container spacing={4}>
        <Grid item xs="auto" className={classes.sectionFormHeader}>
          <PinDropIcon />
          <Typography variant="h3" className={classes.sectionFormTitle}>
            Endereço
          </Typography>
        </Grid>
        <Grid
          item
          xs="auto"
          container
          spacing={2}
          className={classes.sectionFormBody}
        >
          <Grid item xs={12} md={2}>
            <CEPTextField
              id="editar-convenio-cep"
              label="CEP"
              name="cep"
              value={cep}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <DefaultFormTextField
              id="convenio-logradouro"
              label="Rua"
              name="logradouro"
              value={logradouro}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultFormTextField
              type="number"
              id="editar-convenio-numero-endereco"
              label="Número"
              name="numero_endereco"
              value={numero_endereco}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultFormTextField
              id="editar-convenio-complemento-endereco"
              label="Complemento"
              name="complemento_endereco"
              value={complemento_endereco}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultFormTextField
              id="editar-convenio-bairro"
              label="Bairro"
              name="bairro"
              value={bairro}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultFormTextField
              id="editar-convenio-cidade"
              label="Cidade"
              name="cidade"
              value={cidade}
              required
              onChange={e => handleOnChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DefaultFormTextField
              id="editar-convenio-uf"
              label="Estado"
              name="uf"
              value={uf}
              required
              onChange={e => handleOnChange(e)}
              inputProps={{ maxLength: 2 }}
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={12} align="right" style={{ marginTop: '20px' }}>
            <SaveButton onClick={e => handleSubmit(e)} />
          </Grid> */}
        {/* </form>
      </Grid> */}
        {/* </Paper> */}
      </Grid>
    </Grid>

    // <Card style={{ marginTop: '30px' }}>
    //   <Form onSubmit={handleSubmit}>
    //     <BodyFormComponent>
    //       <Row className="mt-2 mb-4">
    //         <Col md={12} className="mb-3">
    //           <SectionFormHeader>Dados do Convênio</SectionFormHeader>
    //         </Col>
    //         <Col md={6} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <PrependInputLabel>CNPJ</PrependInputLabel>
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <InputMask
    //               mask="99.999.999/9999-99"
    //               type="text"
    //               name="cnpj"
    //               value={cnpj}
    //               placeholder="Cnpj"
    //               onChange={e => handleOnChange(e)}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <FaBuilding />
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="text"
    //               name="nome"
    //               value={nome}
    //               placeholder="Nome da Empresa"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //       </Row>
    //       <hr />
    //       <Row className="mb-3">
    //         <Col md={12}>
    //           <SectionFormHeader>Informações de Endereço</SectionFormHeader>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col md={4} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <PrependInputLabel>CEP</PrependInputLabel>
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <InputMask
    //               mask="99999-999"
    //               name="cep"
    //               value={cep}
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col md={5} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <FaRoad />
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               name="logradouro"
    //               value={logradouro}
    //               placeholder="Rua"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col md={3} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>Nº</InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="number"
    //               name="numero_endereco"
    //               value={numero_endereco}
    //               placeholder="Rua"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //       </Row>
    //       <Row className="mb-4">
    //         <Col md={4} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <FaInfo />
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="text"
    //               name="complemento_endereco"
    //               value={complemento_endereco}
    //               placeholder="Complemento"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col md={3} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <FaMapMarkerAlt />
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="text"
    //               value={bairro}
    //               name="bairro"
    //               placeholder="Bairro"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col md={3} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <FaMapMarkedAlt />
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="text"
    //               value={cidade}
    //               name="cidade"
    //               placeholder="Cidade"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col md={2} className="mb-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <FaGlobeAmericas />
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="text"
    //               value={uf}
    //               maxLength="2"
    //               name="uf"
    //               placeholder="uf"
    //               onChange={e => {
    //                 handleOnChange(e);
    //               }}
    //             />
    //           </InputGroup>
    //         </Col>
    //       </Row>
    //       <hr />
    //       <Row className="mb-3">
    //         <Col md={12}>
    //           <SectionFormHeader>Informações dos Benefícios</SectionFormHeader>
    //         </Col>
    //       </Row>
    //       <Row className="mb-4">
    //         <Col md={2} className="mt-2">
    //           <InputGroup>
    //             <Input
    //               type="number"
    //               min={0}
    //               max={31}
    //               step={1}
    //               placeholder="Dia do vencimento"
    //               name="dia_proximos_pagamentos"
    //               value={dia_proximos_pagamentos}
    //               required
    //               onChange={e => handleOnChange(e)}
    //             />
    //           </InputGroup>
    //         </Col>
    //         <Col md={5} className="mt-2">
    //           <InputGroup>
    //             <Input
    //               type="number"
    //               min={0}
    //               max={100}
    //               step={0.01}
    //               placeholder="Desconto na fatura"
    //               name="desconto_fatura_porcentagem"
    //               value={desconto_fatura_porcentagem}
    //               required
    //               onChange={e => handleOnChange(e)}
    //             />
    //             <InputGroupAddon addonType="append">
    //               <InputGroupText>
    //                 <PrependInputLabel>%</PrependInputLabel>
    //               </InputGroupText>
    //             </InputGroupAddon>
    //           </InputGroup>
    //         </Col>
    //         <Col md={5} className="mt-2">
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <PrependInputLabel>
    //                   <FaRegMoneyBillAlt />
    //                 </PrependInputLabel>
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               type="select"
    //               name="primeiroDepGratuito"
    //               value={primeiro_dep_gratis}
    //               required
    //               onChange={e => handleOnChange(e)}
    //             >
    //               <option value="">Primeiro Dependente Gratuito?</option>
    //               <option value={1}>Sim</option>
    //               <option value={0}>Não</option>
    //             </Input>
    //           </InputGroup>
    //         </Col>
    //       </Row>
    //       {/* <hr />
    //       <Row className="mb-3">
    //         <Col>
    //           <SectionFormHeader>
    //             Forma de liberação do débito
    //           </SectionFormHeader>
    //         </Col>
    //       </Row>
    //       <Row className="mb-4">
    //         <Col className="mt-2" xs={12}>
    //           <InputGroup>
    //             <InputGroupAddon addonType="prepend">
    //               <InputGroupText>
    //                 <PrependInputLabel>
    //                   <FaFileUpload />
    //                 </PrependInputLabel>
    //               </InputGroupText>
    //             </InputGroupAddon>
    //             <Input
    //               className="w-75 h-100 p-1"
    //               type="file"
    //               name="formaLiberacaoDebito"
    //               value={formaLiberacaoDebito}
    //               required
    //               onChange={e => handleOnChange(e)}
    //             />
    //           </InputGroup>
    //         </Col>
    //       </Row> */}
    //       <Row className="mt-4 text-right">
    //         <Col>
    //           <SubmitBtn
    //             label="Salvar Alterações"
    //             labelOnClick="Aguarde..."
    //             loading={loading}
    //             typeSubmit="submit"
    //             metodo={handleSubmit}
    //             color="#0A7A73"
    //           />
    //         </Col>
    //       </Row>
    //     </BodyFormComponent>
    //   </Form>
    // </Card>
  );
}

export default EditarConvenio;
