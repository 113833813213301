import styled from 'styled-components';
import theme from '../../utils/theme';

export const Totalizadores = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  h3 {
    margin: 0.5rem 2rem 0 0;
  }

  p {
    margin: 0.5rem 0.5rem 0 0;
    padding: 0.3rem 0 0.3rem 0.5rem;
    / * background-color: #e0e0e0; */
    border-radius: 5px;
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 50px;

    span {
      color: #fff;
      font-weight: bold;
      background-color: ${props => theme[props.color]};
      border-radius: 5px;
      padding: 0.3rem 0.5rem;
      margin-left: 10px;
    }
  }

  div {
    background-color: transparent;
  }
`;

export const CardResumo = styled.div`
  width: 100%;
  max-width: 180px;
  text-align: center;
  background-color: ${props =>
    props.card === 'valor' ? theme[props.color] : '#e0e0e0'};
  border-radius: 0.5rem;
  color: ${props => props.card === 'valor' && '#fff'};

  & + div {
    margin-left: 0.5rem;
  }

  h5 {
    font-weight: ${props => props.card === 'valor' && 'bold'};
    border-bottom: 2px solid #fff;
    padding: 0.5rem;
    font-size: 1.0rem;
  }

  p {
    font-size: 1.4rem;
    font-weight: ${props => props.card === 'valor' && 'bold'};
    display: block;
    margin: 0.5rem auto;
  }
`;
