import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import theme from '../../styles/theme';
import Page from './Page';
import api from '../../services/api';
import { formatDate } from '../../services/service';
import { alertError, alertSuccess } from '../../components/Alerts/Alerts';

const Controller = props => {
  const { id } = useParams();
  const { user, history } = props;
  const { token, usuario, nivel_usuario } = user.user;
  const authStr = `Bearer ${token}`;
  const defaultAccount = {
    // Dados do usuário
    user: {
      id: 0,
      nome: '',
      email: '',
      senha: '',
      nivel: 1,
      id_assessoria: null,
      ativo: true
    },
    // Dados do nível do usuário
    // nivel: {
    //   descricao: '',
    // },
    // // Dados da assessoria
    // assessoria: {
    //   razao_social: '',
    // },
    // Dados da pessoa
    person: {
      cpf: '',
      estado_civil: '',
      sexo: '',
      data_nascimento: '',
      telefone: '',
      celular: '',
      numero_endereco: 0,
      complemento_endereco: '',
      id_endereco: 0,
    },
    // Dados do endereço
    address: {
      cep: '',
      logradouro: '',
      cidade: '',
      bairro: '',
      uf: '',
    },
  };
  const [account, setAccount] = useState({
    user: defaultAccount.user,
    // nivel: defaultAccount.nivel,
    // assessoria: defaultAccount.assessoria,
    person: defaultAccount.person,
    address: defaultAccount.address,
    loading: true,
  });

  const [isEditing, setIsEditing] = useState(false);

  const salvarUsuario = async (data) => {
    try {
      const response = await api.put(`usuario`, data, {
        headers: { Authorization: `Bearer ${user.user.token}` },
      });

      if (response.data.ok) {
        history.goBack();
        alertSuccess('Usuário atualizado com sucesso!');
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  useEffect(() => {
    getAccount(id);
  }, [id]);

  const getAccount = async selectedUserId => {
    try {
      const response = await api.get(`/usuario/${selectedUserId}/relacoes`, {
        headers: { Authorization: authStr },
      });
      if (response.data.ok) {
        setAccount({
          user: {
            id: Number(response.data.output[0].id),
            email: response.data.output[0].email,
            senha: response.data.output[0].senha,
            nivel: Number(response.data.output[0].nivel),
            nome: response.data.output[0].nome,
            id_assessoria: Number(response.data.output[0].id_assessoria),
            ativo: response.data.output[0].ativo == 1 ? true : false
          },
          nivel: {
            descricao: response.data.output[0].descricao_nivel,
          },
          assessoria: {
            razao_social: response.data.output[0].razao_social || '',
          },
          person: {
            id: Number(response.data.output[0].id_pessoa) || 0,
            cpf: response.data.output[0].cpf || '',
            estado_civil: response.data.output[0].estado_civil || '',
            sexo: response.data.output[0].sexo || '',
            data_nascimento: response.data.output[0].data_nascimento
              ? formatDate(response.data.output[0].data_nascimento)
              : undefined,
            telefone: response.data.output[0].telefone || '',
            celular: response.data.output[0].celular || '',
            numero_endereco:
              Number(response.data.output[0].numero_endereco) || 0,
            complemento_endereco:
              response.data.output[0].complemento_endereco || '',
            id_endereco: Number(response.data.output[0].id_endereco) || 0,
          },
          address: {
            cep: response.data.output[0].cep || '',
            logradouro: response.data.output[0].logradouro || '',
            cidade: response.data.output[0].cidade || '',
            bairro: response.data.output[0].bairro || '',
            uf: response.data.output[0].uf || '',
          },
          loading: false,
        });
      }
    } catch (error) {
      setAccount({
        user: {},
        // nivel: {},
        // assessoria: {},
        person: {},
        address: {},
        loading: false,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Page
        history={history}
        paramsId={id}
        sessionUserId={usuario}
        sessionUserLevel={nivel_usuario}
        loadingPage={account.loading}
        account={account}
        salvarUsuario={salvarUsuario}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </ThemeProvider>
  );
};

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
