import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import api from '../../services/api';
import { alertError } from '../../components/Alerts/Alerts';
import Page from './Page';
import formatValueToPrice from '../../utils/format';
import { checkSafePassword, padLeadingZeros } from '../../services/service';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

function Controller(props) {
  const { user } = props;
  const { assessoria, corretor_representante } = user.user;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const periodoParams = urlParams.get('periodo');

  const [assessorias, setAssessorias] = useState({
    error: '',
    data: [],
    loading: true,
  });

  const [comissoes, setComissoes] = useState({
    error: '',
    data: [],
    loading: false,
  });

  const [filtros, setFiltros] = useState({
    distribuidor: assessoria,
    vendedor: corretor_representante,
    periodo:
      periodoParams ?? `${currentYear}-${padLeadingZeros(currentMonth, 2)}`,
  });

  const [comissaoDistribuidor, setComissaoDistribuidor] = useState({
    error: '',
    data: [],
    loading: false,
  });

  const [regraComissao, setRegraComissao] = useState({
    data: {},
    loading: true,
    error: '',
  });

  const [idUsuario, setIdUsuario] = useState(0);

  // const [assessoria, setAssessoria] = useState({});

  const [loadingExcelCorretor, setLoadingExcelCorretor] = useState(false);

  // const getAssessorias = async () => {
  //   try {
  //     const response = await api.get('/assessorias/', {
  //       headers: { Authorization: `Bearer ${user.user.token}` },
  //     });

  //     if (response.data.ok) {
  //       setAssessorias({
  //         data: response.data.output,
  //         loading: false,
  //       });
  //     } else {
  //       throw new Error(response.data.message);
  //     }
  //   } catch (error) {
  //     setAssessorias({
  //       data: [],
  //       loading: false,
  //     });
  //     console.log(error.message);
  //   }
  // };

  const getComissoes = async () => {
    setComissoes({
      data: [],
      loading: true,
    });

    try {
      const response = await api.get(
        `/relatorio-venda/${filtros.periodo}/assessoria/${filtros.distribuidor}/corretor/${filtros.vendedor}`,
        { headers: { Authorization: `Bearer ${user.user.token}` } },
      );
      if (response.data.ok) {
        setComissoes({
          data: response.data.output
            .filter(comissao => comissao.idCorretor === filtros.vendedor)
            .map(comissao => ({
              ...comissao,
              totalCarteira: formatValueToPrice(comissao.totalCarteira),
            })),
          loading: false,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setComissoes({
        data: [],
        loading: false,
        error: error.message,
      });
      console.log('Erro ao buscar comissões dos Corretores: ', error.message);
    }
  };

  const getRegraComissao = async () => {
    try {
      const response = await api.get(`/comissao/${idUsuario}`, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });

      if (response.data.ok) {
        setRegraComissao({
          loading: false,
          data: {
            primeiraParcela: response.data.output.primeira_parcela,
            carteira: response.data.output.carteira,
            pagamentoAVista: response.data.output.pagamento_a_vista,
          },
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setRegraComissao({
        loading: false,
        data: {},
      });
      console.log('Erro ao buscar regra de comissão: ', error.message);
    }
  };

  // const getAssessoria = async () => {
  //   try {
  //     const response = await api.get(`assessoria/usuario/${idUsuario}`, {
  //       headers: { Authorization: `Bearer ${user.user.token}` },
  //     });
  //     if (response.data.ok) {
  //       setAssessoria(response.data.output);
  //     } else {
  //       throw new Error(response.data.message);
  //     }
  //   } catch (error) {
  //     setAssessoria({});
  //     console.log('Erro ao buscar distribuidor: ', error.message);
  //   }
  // };

  const getExcelCorretor = async idCorretor => {
    setLoadingExcelCorretor(true);
    try {
      const response = await api.get(
        `relatorio-venda/${filtros.periodo}/corretor/${idCorretor}/comissoes/excel`,
        {
          headers: { Authorization: `Bearer ${user.user.token}` },
        },
      );

      if (response.data.ok) {
        setLoadingExcelCorretor(false);

        return response.data.output;
      }
      throw new Error(response.data.message);
    } catch (error) {
      setLoadingExcelCorretor(false);

      alertError('Erro ao baixar relatório do vendedor: ', error.message);
    }
  };

  useEffect(() => {
    checkSafePassword(props.user);
    getComissoes();
  }, []);

  useEffect(() => {
    if (idUsuario) {
      getRegraComissao();
      // getAssessoria();
    }
  }, [idUsuario]);

  return (
    <Page
      assessorias={assessorias}
      getComissoes={getComissoes}
      comissoes={comissoes}
      user={user.user}
      filtros={filtros}
      setFiltros={setFiltros}
      comissaoDistribuidor={comissaoDistribuidor}
      idDistribuidor={assessoria}
      regraComissao={regraComissao}
      assessoria={assessoria}
      getExcelCorretor={getExcelCorretor}
      loadingExcelCorretor={loadingExcelCorretor}
    />
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(Controller);
