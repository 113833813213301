import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { FaRegSave, FaPlus } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { BodyFormComponent } from '../../views/styles';
import {
  testaCPF,
  formatDate,
  alteraValorCompra,
  verificaIdadeLimite,
  transformaUpperCase,
  verificaEmail,
} from '../../services/service';
import Api from '../../services/api';
import { alertSuccess, alertError, alertWarning } from '../Alerts/Alerts';
import InfoBasicoCliente from './infoBasicoCliente/InfoBasicoCliente';
import InfoEndereco from './infoEndereco/InfoEndereco';
import InfoDependentes from './infoDependentes/InfoDependentes';
import InfoPagamentoRenovacao from './infoPagamentoRenovacao/InfoPagamentoRenovacao';
import Loading from '../Loading/Loading';
import FinalPrice from '../FinalPrice/FinalPrice';

function CadastroClienteRenovacao(props) {
  const { user } = props;

  const id_usuario = user.user.usuario;

  const {
    edit,
    editFunc,
    clientInputEdit,
    clienteEnderecoInput,
    depClienteIndividual,
    mostraInfoPagamento,
    mostraInfoValores,
    clienteEmpresarial,
    getFuncionarios,
    openModalCadastroFuncionario,
    modal,
    idEmpresa,
    mostraInfoEndereco,
    mostraInfoDependente,
    direcionaCadastroFunc,
    empresaInfo,
    setDirecionaCadastroFunc,
  } = props;

  const history = useHistory();
  const { idClient, idEmpresaCadastro } = useParams();

  const [loading, setLoading] = useState(false);
  const [idEstipulante, setIdEstipulante] = useState(1);
  const [nomeEstipulante, setNomeEstipulante] = useState('');
  const [deletaDep, setDeletaDep] = useState(false);

  const [clientInput, setClientInput] = useState({
    assessoria:
      props.user.user.nivel_usuario == 1 ||
      props.user.user.nivel_usuario == 2 ||
      props.user.user.nivel_usuario == 5
        ? ''
        : props.user.user.assessoria,
    corretorRepresentante:
      props.user.user.nivel_usuario == 1 ||
      props.user.user.nivel_usuario == 2 ||
      props.user.user.nivel_usuario == 5
        ? ''
        : props.user.user.corretor_representante,
    nome: '',
    cpf: '',
    data_nascimento: '',
    estado_civil: '',
    sexo: '',
    telefone: '',
    celular: '',
    email: '',
    origem: '',
    observacao: '',
    quem_paga: 'titular',
    id_usuario,
    id_estipulante: idEstipulante,
    nome_estipulante: nomeEstipulante,
  });

  const [productSelected, setProductSelected] = useState('');

  const [clienteEndereco, setClienteEndereco] = useState({
    cep: '',
    logradouro: '',
    numero_endereco: '',
    complemento_endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
  });

  const [dependentInput, setDependentInput] = useState({
    tipoDependente: '',
    parentesco: '',
    cpfDependente: '',
    nomeDependente: '',
    dataNascimentoDependente: '',
    titular: '',
  });

  const [clientPayment, setClientPayment] = useState({
    id_produto: 3,
    forma_pagamento: '',
    primeiro_pagamento: new Date().toISOString().split('T')[0],
    dia_proximos_pagamentos: '',
    qtd_parcelas: 12,
    codigo_desconto: '',
    taxaAdesao: false,
  });

  const [products, setProducts] = useState([
    {
      id: '',
      name: '',
      price: '',
      priceDep1: '',
      priceDep2: '',
      plano_empresarial: '',
      produto_adicional: '',
    },
  ]);

  const [newPlanPrice, setNewPlanPrice] = useState();

  const [convenio, setConvenio] = useState(false);
  const [convenioList, setConvenioList] = useState();

  const [disabled, setDisabled] = useState({
    corretorRepresentante: true,
    dia_proximos_pagamentos: false,
    qtd_parcelas: false,
    codigo_desconto: false,
    tipoDependente: true,
    cpfDependente: true,
    nomeDependente: true,
    dataNascimentoDependente: true,
    saveDependenteBtn: true,
  });

  const [userEditParam, setUserEditParam] = useState('');
  const [dependentList, setDependentList] = useState([]);

  const resetState = () => {
    setClientInput({
      nome: '',
      cpf: '',
      data_nascimento: '',
      estado_civil: '',
      sexo: '',
      telefone: '',
      celular: '',
      email: '',
      origem: '',
      observacao: '',
      quem_paga: 'titular',
      id_usuario,
      id_estipulante: idEstipulante,
      nome_estipulante: nomeEstipulante,
    });
    setDependentInput({
      tipoDependente: '',
      parentesco: '',
      cpfDependente: '',
      nomeDependente: '',
      dataNascimentoDependente: '',
      titular: '',
    });
    setDependentList([]);
  };

  const calcularPrecoTotal = () => {
    setNewPlanPrice(
      alteraValorCompra(
        productSelected.price,
        productSelected.priceDep1,
        productSelected.priceDep2,
        dependentList,
        clientPayment.qtd_parcelas,
        clientPayment.validade_codigo_desconto,
        convenio,
        clientPayment.taxaAdesao,
      ),
    );
  };

  useEffect(() => {
    calcularPrecoTotal();

    if (edit) {
      if (!deletaDep) {
        setDependentList(depClienteIndividual);
      }
      setUserEditParam(idClient);
      setDisabled({
        tipoDependente: false,
        cpfDependente: false,
        nomeDependente: false,
        dataNascimentoDependente: false,
        saveDependenteBtn: false,
      });
    }

    // Para pegar o endereço da empresa quando vem da tela de cadastro da Empresa -> Contato Empresa -> Funcionário
    // if(direcionaCadastroFunc || clienteEmpresarial){
    if (direcionaCadastroFunc) {
      setIdEstipulante(idEmpresaCadastro);
      setClienteEndereco({
        cep: empresaInfo.cep,
        logradouro: empresaInfo.logradouro,
        numero_endereco: empresaInfo.numero_endereco,
        complemento_endereco: empresaInfo.complemento_endereco,
        bairro: empresaInfo.bairro,
        cidade: empresaInfo.cidade,
        uf: empresaInfo.uf,
      });
      // Quando adicionar um funcionário novo, com a empresa já cadastrada
    } else if (clienteEmpresarial && edit) {
      setClienteEndereco({
        cep: clientInputEdit.cep,
        logradouro: clientInputEdit.logradouro,
        numero_endereco: clientInputEdit.numero_endereco,
        complemento_endereco: clientInputEdit.complemento_endereco,
        bairro: clientInputEdit.bairro,
        cidade: clientInputEdit.cidade,
        uf: clientInputEdit.uf,
      });
    }
  }, [
    depClienteIndividual,
    clientInputEdit,
    clientPayment,
    dependentList,
    productSelected,
    deletaDep,
    convenioList,
  ]);

  // Função chamada quando a forma de pagamento for Débito em Folha E tiver convênios cadastrados.
  const setupInputConvenios = async () => {
    const fetchConvenios = await Api.get(`convenios/?status=1`, {
      headers: { Authorization: `Bearer ${props.user.user.token}` },
    });

    if (fetchConvenios !== undefined) {
      if (fetchConvenios.data.ok) {
        setConvenio(true);
        setConvenioList(fetchConvenios.data.output);
      }
    }
  };

  const salvarCliente = async adicionarMaisSegEmp => {
    setLoading(true);
    let dependentes = [];

    if (clientInput.assessoria === '') {
      alertError('Selecione o distribuidor.');
      setLoading(false);
      return false;
    }

    if (clientInput.corretorRepresentante === '') {
      alertError('Selecione o Corretor.');
      setLoading(false);
      return false;
    }

    if (!verificaEmail(clientInput.email)) {
      alertError('Insira um e-mail válido nas informações do cliente.');
      setLoading(false);
      return false;
    }

    if (clientInput.data_nascimento === '') {
      alertError('A data de nascimento do cliente é obrigatória.');
      setLoading(false);
      return false;
    }

    if (clientInput.estado_civil === '') {
      alertError('O estado civil do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.sexo === '') {
      alertError('O gênero/sexo do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.cpf === '') {
      alertError('O CPF do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.nome === '') {
      alertError('O nome do cliente é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clientInput.telefone === '') {
      alertError('O Telefone é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.complemento_endereco === '') {
      alertError('O complemento do endereço é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.numero_endereco === '') {
      alertError('O número do endereço é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.logradouro === '') {
      alertError('A rua/logradouro é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.cidade === '') {
      alertError('A cidade é obrigatória.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.bairro === '') {
      alertError('O bairro é obrigatório.');
      setLoading(false);
      return false;
    }

    if (clienteEndereco.uf === '') {
      alertError('O estado é obrigatório.');
      setLoading(false);
      return false;
    }

    // if (!clienteEmpresarial) {
    //   if (productSelected.limite_idade !== '0') {
    //     const resultVerificaIdadeMinima = verificaIdadeLimite(
    //       clientInput.data_nascimento,
    //       75,
    //     );
    //     if (!resultVerificaIdadeMinima) {
    //       alertError(
    //         'A idade do Cliente/Titular deve ser menor ou igual a 75 anos.',
    //       );
    //       setLoading(false);
    //       return false;
    //     }
    //   }
    // }

    if (!edit) {
      if (!clienteEmpresarial) {
        if (clientPayment.forma_pagamento === '') {
          alertError('Selecione a forma de pagamento.');
          setLoading(false);
          return false;
        }

        if (clientPayment.primeiro_pagamento === '') {
          alertError('Informe a data do primeiro pagamento.');
          setLoading(false);
          return false;
        }

        if (
          clientPayment.dia_proximos_pagamentos === '' &&
          clientPayment.forma_pagamento === 'carne-impresso'
        ) {
          alertError('Selecione o dia do pŕoximo pagamento');
          setLoading(false);
          return false;
        }
      }
    }

    if (Object.keys(dependentList).length > 0) {
      dependentList.map(el => {
        if (edit) {
          dependentes = [
            ...dependentes,
            {
              id: el.id,
              cpf: el.cpfDependente,
              titular: el.titular,
              nome: el.nomeDependente,
              data_nascimento: el.dataNascimentoDependente,
              parentesco: el.parentesco,
              tipo: el.tipoDependente,
            },
          ];
        } else {
          dependentes = [
            ...dependentes,
            {
              id: 0,
              cpf: el.cpfDependente,
              titular: el.titular,
              nome: el.nomeDependente,
              data_nascimento: el.dataNascimentoDependente,
              parentesco: el.parentesco,
              tipo: el.tipoDependente,
            },
          ];
        }
      });
    }

    clientInput.numero_endereco = parseInt(clientInput.numero_endereco, 10);
    clientInput.complemento_endereco = parseInt(
      clientInput.complemento_endereco,
      10,
    );

    if (idEstipulante !== '1') {
      clientInput.id_estipulante = idEstipulante;
      clientInput.nome_estipulante = nomeEstipulante;
    }

    if (clienteEmpresarial) {
      clientInput.id_estipulante = parseInt(idEmpresa, 10);
      clientInput.origem = 'empresarial';
      clientInput.observacao = '';
    }

    clientInput.convenio = convenio;
    clientInput.dependentes = dependentes;
    clientInput.id_usuario = id_usuario;

    const newObjSave = Object.assign(
      clientInput,
      clienteEndereco,
      clientPayment,
    );
    let resultReq = '';

    if (edit) {
      if (clienteEmpresarial) {
        // resultReq = await Api.post(`segurado`, newObjSave, { headers: { Authorization: `Bearer ${props.user.user.token}` }});
        resultReq = await Api.put(`segurado/${idClient}`, newObjSave, {
          headers: { Authorization: `Bearer ${props.user.user.token}` },
        });
      } else {
        resultReq = await Api.put(`segurado/${idClient}`, newObjSave, {
          headers: { Authorization: `Bearer ${props.user.user.token}` },
        });
      }
    } else if (clienteEmpresarial) {
      resultReq = await Api.post('segurado', newObjSave, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
    } else {
      resultReq = await Api.post('cliente-renovacao', newObjSave, {
        headers: { Authorization: `Bearer ${props.user.user.token}` },
      });
    }

    if (clienteEmpresarial) {
      if (resultReq.data.ok) {
        if (modal) {
          // Tela de Empresa, card de Lista de funcionários.
          openModalCadastroFuncionario(!modal);
          getFuncionarios();
        } else if (direcionaCadastroFunc) {
          if (adicionarMaisSegEmp) {
            setDirecionaCadastroFunc(true);
            resetState();
            alertSuccess('Salvo com sucesso');
          } else {
            history.push(`/empresa/${idEstipulante}`);
          }
        } else {
          alertSuccess('Salvo com sucesso');
        }
      } else {
        alertError(resultReq.data.message);
      }
      setLoading(false);
    } else if (resultReq.data.ok) {
      setLoading(false);
      alertSuccess('Cliente salvo com sucesso');

      if (edit) {
        if (editFunc) {
          history.push(`../funcionario/${idClient}`);
        } else {
          history.push(`../segurado/detalhe/${userEditParam}`);
        }
      } else {
        window.open(resultReq.data.output.url, '_blank');
        history.push(`/segurado/detalhe/${resultReq.data.output.idCliente}`);
      }
    } else {
      alertError(resultReq.data.message);
      setLoading(false);
    }
  };

  const finalizaContinua = () => {
    if (clientInput.nome !== '') {
      const ask = window.confirm(
        'Você começou a cadastrar um segurado. Tem certeza que deseja continuar antes de finalizar inclusão?',
      );
      if (ask) {
        history.push(`/cadastrar-plano-funcionarios/${idEstipulante}`);
      }
      return false;
    }
    history.push(`/cadastrar-plano-funcionarios/${idEstipulante}`);
  };

  return (
    <BodyFormComponent>
      <InfoBasicoCliente
        clientInput={clientInput}
        setClientInput={setClientInput}
        testaCPF={testaCPF}
        edit={edit}
        disabled={disabled}
        setDisabled={setDisabled}
        token={props.user.user.token}
        clientInputEdit={clientInputEdit}
        transformaUpperCase={transformaUpperCase}
        nivelUsuario={props.user.user.nivel_usuario}
        nomeUsuario={props.user.user.primeiro_nome_usuario}
        usuario={props.user.user.usuario}
        idAssessoriaDoCorretor={props.user.user.assessoria}
        corretorRepresentante={props.user.user.corretor_representante}
        direcionaCadastroFunc={direcionaCadastroFunc}
        clienteEmpresarial={clienteEmpresarial}
      />
      {mostraInfoEndereco && (
        <InfoEndereco
          clienteEndereco={clienteEndereco}
          setClienteEndereco={setClienteEndereco}
          edit={edit}
          token={props.user.user.token}
          clienteEnderecoInput={clienteEnderecoInput}
        />
      )}
      {mostraInfoDependente && (
        <InfoDependentes
          dependentInput={dependentInput}
          clientInput={clientInput}
          setDependentInput={setDependentInput}
          disabled={disabled}
          testaCPF={testaCPF}
          setDependentList={setDependentList}
          dependentList={dependentList}
          productSelected={productSelected}
          transformaUpperCase={transformaUpperCase}
          formatDate={formatDate}
          setDeletaDep={setDeletaDep}
        />
      )}
      {mostraInfoPagamento && (
        <InfoPagamentoRenovacao
          clientPayment={clientPayment}
          dependentList={dependentList}
          edit={edit}
          disabled={disabled}
          setDisabled={setDisabled}
          token={props.user.user.token}
          setProducts={setProducts}
          products={products}
          setConvenio={setConvenio}
          convenio={convenio}
          setClientPayment={setClientPayment}
          setupInputConvenios={setupInputConvenios}
          setProductSelected={setProductSelected}
          convenioList={convenioList}
          setIdEstipulante={setIdEstipulante}
          setNomeEstipulante={setNomeEstipulante}
        />
      )}

      {mostraInfoValores && !edit && (
        <Row className="text-right">
          <Col lg={12} className="mb-4 mt-4">
            <FinalPrice price={newPlanPrice} />
          </Col>
        </Row>
      )}
      {loading ? (
        <Row className="text-center">
          <Col>
            <Loading loading={loading} />
          </Col>
        </Row>
      ) : (
        <Row className="text-right mt-4">
          <Col lg={12} className="btn-content mt-4">
            {!clienteEmpresarial && !direcionaCadastroFunc && !modal && (
              <Button
                color="danger"
                size="sm"
                className="mr-2"
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            )}
            {edit ? (
              <Button color="success" size="sm" onClick={() => salvarCliente()}>
                <FaRegSave />
                &nbsp;Editar Cliente
              </Button>
            ) : clienteEmpresarial && direcionaCadastroFunc && !modal ? (
              <>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => salvarCliente(true)}
                >
                  <FaPlus />
                  &nbsp;Incluir mais Funcionários
                </Button>
                &nbsp;
                <Row className="text-center mt-2">
                  <Col>
                    <Button
                      color="danger"
                      size="lg"
                      onClick={() => finalizaContinua()}
                    >
                      <FaRegSave />
                      &nbsp;Finalizar Inclusões
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <Button color="success" size="sm" onClick={() => salvarCliente()}>
                <FaRegSave />
                &nbsp;Cadastrar Renovação
              </Button>
            )}
          </Col>
        </Row>
      )}
    </BodyFormComponent>
  );
}

const mapStateToProps = store => ({
  user: store.user,
});

export default connect(mapStateToProps)(CadastroClienteRenovacao);
