import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FaUsers, FaArrowLeft } from 'react-icons/fa';
import TitleContent from '../../components/TitleContent/TitleContent';
// import SeguradoInfo from '../../views/Segurado/SeguradoInfo';
import ClinicaClienteInfo from '../../views/ClinicaCliente/ClinicaClienteInfo';
import ClinicaClienteVenda from '../../views/ClinicaCliente/ClinicaClienteVenda';
import SeguradoDependente from '../../views/Segurado/SeguradoDependente';
import EndossosEdicao from '../../views/Segurado/EndossosEdicao';
import Loading from '../../components/Loading/Loading';
// import endossosEdicao from '../../views/Segurado/EndossosEdicao';

export default function Page(props) {
  const { clinicas } = props;

  const titleHeader = props.client ? (
    <>
      <FaUsers />
      &nbsp;&nbsp; {props.client.nome}{' '}
    </>
  ) : (
    ''
  );

  return (
    <Container className="pb-4" fluid>
      <Row className="mt-4">
        <Col md={2}>
          <Button
            className="btn-back w-100"
            outline
            size="sm"
            color="danger"
            onClick={() => props.history.goBack()}
          >
            <FaArrowLeft />
            &nbsp;&nbsp;Voltar
          </Button>
        </Col>
      </Row>
      <Row className="text-left mt-4 mb-4">
        <Col lg={12}>
          <TitleContent titleHeader={titleHeader} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={5}>
          <ClinicaClienteInfo
            idClient={props.idClient}
            client={props.client}
            history={props.history}
          />
        </Col>
        <Col lg={7}>
          {props.loadingClinicaVenda ? (
            <Col className="text-center">
              <Loading loading={props.loadingClinicaVenda} />
            </Col>
          ) : (
            props.clinicaVendas && (
              <ClinicaClienteVenda
                clinicaVendas={props.clinicaVendas}
                history={props.history}
                client={props.client}
                idEstipulante={props.client}
                idCliente={props.idClient}
                clinicas={clinicas}
              />
            )
          )}
        </Col>
      </Row>
    </Container>
  );
}
