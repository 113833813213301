import React from 'react';
import { Badge } from 'reactstrap';
import TablesGrid from '../../components/TablesGrid/TablesGrid';
import ActionButton from '../../components/TablesGrid/ActionButton';
import { Col } from 'reactstrap';

export default function MinhasVendas(props) {

  let columns = [
    { name: 'clientName', title: 'Nome', getCellValue: row => (row.cliente) },
    { name: 'monthPrice', title: 'Valor Mensal', getCellValue: row => (row.valorMensal) },
    { name: 'status', title: 'Status', getCellValue: row => (row.status) },
    { name: 'dateRegister', title: 'Data Cadastro', getCellValue: row => (row.dataCriacao) },
    { name: 'firstPayment', title: '1º Pagamento', getCellValue: row => (row.dataVenda) },
    { name: 'corretorRepresentante', title: 'Corretor', getCellValue: row => (row.corretorRepresentante) },
    { name: 'statusPayment', title: 'Status', getCellValue: row => {
      if(row.statusPrimeiroPagamento === 'Pago') {
        return <h6><Badge color="success">{row.statusPrimeiroPagamento}</Badge></h6>;
      }else{
        return <h6><Badge color="danger">{row.statusPrimeiroPagamento}</Badge></h6>;
      }
    }},
    { name: '', title: '', getCellValue: row => (actions(row))},
  ];

  const actions = (row) => {
    let url = `/segurado/detalhe/${row.idCliente}`;
    return <ActionButton pathname={url}/>
  }

  return (
    <Col md={12}>
      <TablesGrid rows={props.sales} columns={columns}/>
    </Col>
  );
}
