import styled from 'styled-components';

export const MsgInputError = styled.div`
  margin-top: 10px;
  float: left;
  font-size: 11px;
  color: red;
`;

export const OverflowContainer = styled.div`
  max-height: ${props => (props.height ? props.height : '200px')};
  width: ${props => (props.width ? props.width : 'auto')};
  overflow: auto;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    width: 10px;
    border: none;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #edf2f4;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #8d99ae; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none; /* creates padding around scroll thumb */
  }
`;

export const FormLoginComponent = styled.div`
  padding-top: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  height: 500px;

  input {
    height: 50px;
    padding: 10px 20px;
    margin-top: 5px;
    font-size: 15px;
    background-color: #f7f7f7;
    outline: 0;
    border-radius: 5px !important;
  }
  input::placeholder {
    font-family: 'Archivo';
    opacity: 0.7;
  }
  input:active,
  input:focus {
    background-color: transparent;
    box-shadow: 0 0 0 0;
    border-color: #0a7a73;
  }
  .card {
    width: 40%;
    height: 250px;
    padding-top: 40px;
    background-color: #ffffffad;
    border-radius: 5px;
    box-shadow: 10px 14px 38px -16px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 10px 14px 38px -16px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 10px 14px 38px -16px rgba(0, 0, 0, 0.25);
  }
  .link-esqueci-minha-senha {
    margin-top: 2px;
    margin-right: 2px;
    float: right;
    color: #1a88ff
  }
  .card-esqueci-minha-senha {
    width: 40%;
    height: 170px !important;
    padding-top: 40px;
    background-color: #ffffffad;
    border-radius: 5px;
    box-shadow: 10px 14px 38px -16px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 10px 14px 38px -16px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 10px 14px 38px -16px rgba(0, 0, 0, 0.25);
  }
  .btn-login {
    width: 100%;
    font-size: 20px;
    padding: 10px;
    font-family: 'Poppins';
    color: #ffffff;
    background-color: #0a7a73 !important;
    font-weight: 700;
    cursor: pointer;
    border-top: 1px solid #dcdcdc;
  }
  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-end;
    color: #ffffff;
    top: 30px;
    font: 0.8rem 'Poppins';
  }

  @media (max-width: 575.98px) {
    .card {
      width: 100%;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export const BodyFormComponent = styled.div`
  padding: 20px;
  text-align: left;

  .section-header {
    margin-bottom: 40px;
  }

  input,
  select {
    height: 30px;
    padding: 0px 10px;
    margin-top: 5px;
    border-radius: 2px;
    border: 1px solid #bfbfbf;
    font-size: 13px;
  }

  input[type='radio'] {
    height: unset;
    cursor: pointer;
  }

  input[type='checkbox'] {
    height: auto;
  }

  input:active,
  input:focus {
    border: 1px solid rgb(10, 122, 115) !important;
    -webkit-box-shadow: 0px 0px 11px -4px rgba(30, 93, 85, 1);
    -moz-box-shadow: 0px 0px 11px -4px rgba(30, 93, 85, 1);
    box-shadow: 0px 0px 11px -4px rgba(30, 93, 85, 1);
  }
  .input-group {
    align-items: baseline !important;
  }
  .input-group-prepend {
    border-radius: 2px;
    height: 30px;
  }
  .input-group-text > svg {
    height: 8px;
  }
  .btn-content {
    margin-top: 30px;
  }
  .form-control-file {
    border: 0px;
    padding: 0px;
  }
`;

export const WellBootstrap = styled.div`
  width: 100%;
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
`;

export const DependentListRow = styled.div`
  display: flex;

  .tituloParentesco {
    display: block;
    padding: 8px !important;
    font-weight: 700;
  }
`;

export const PrependInputLabel = styled.span`
  font-size: 13px;
  font-weight: 700;
`;

export const LabelForm = styled.label`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
`;

export const DetailLabelInput = styled.span`
  font-size: 9px;
`;

export const SectionFormHeader = styled.span`
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 3px;
  color: #538a88;
  font-family: 'Titillium';
`;

export const FooterContent = styled.div`
  color: #d5d5d5;
  font-family: 'Titillium';
  margin-top: 20px;

  .container-fluid {
    width: auto !important;
  }
`;

export const MsgSuccessContent = styled.div`
  color: green;
  font-size: 12px;
`;

export const MsgErrorContent = styled.div`
  color: #ff0000bf;
  font-size: 12px;
  font-weight: 700;
`;

export const MsgErrorDataTable = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-family: Titillium;
  font-size: 16px;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 16px;
`;

export const CardComponent = styled.div`
  padding-top: 30px;
  font-family: 'Archivo';

  b {
    font-weight: 700;
  }
  h2 {
    color: #538a88;
  }
  h6 {
    font-weight: 800;
  }

  .row {
    margin-top: 30px;
  }
  .card-body {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  .header-content {
    text-align: left;
  }
  span,
  td {
    font-size: 11px;
  }
  .cnpj-content {
    font-size: 12px;
  }
  .nav-tabs {
    cursor: pointer;
    padding-left: 14px;
  }
  .nav-tabs > .nav-item {
    background-color: #ececec;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }

  .nav li.active a,
  .nav li a:hover,
  .nav li.active a:focus,
  .nav li.active a:hover {
    background-color: #dcdcdc;
  }

  .nav-tabs > .nav-item > .nav-link {
    font-size: 15px;
    color: #27478dd9;
  }
`;

export const TitleSection = styled.div`
  text-align: left;
  font-size: 22px;
  word-wrap: initial;
  font-weight: 200 !important;
`;

export const Label = styled.span`
  font-weight: 700;
  font-size: 13px;
`;

export const FormatValorAtrasadoFatura = styled.div`
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }

  p {
    font-size: 2rem;
    margin: 0 1rem 0 0;
  }

  b {
    font-weight: 600;
    font-size: 23px;
    font-family: 'Roboto', sans-serif !important;
  }
  span {
    font-size: 41px;
    font-family: 'Futura', sans-serif;
  }
  svg {
    color: #0a7a73;
    margin-right: 1rem;
  }
`;

export const TableContent = styled.div`
  .table > thead > tr > th {
    font-size: 10px;
    font-weight: 600;
  }
  .table > tbody > tr > td {
    font-size: 10px;
  }
`;

export const IndexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
`;

export const IndexDashboard = styled.div`
  --color-title: #ffffff;
  --color-border-bottom-title: #ffffff;
  max-width: 350px;
  flex-grow: 1;
  margin: 0 1rem 1.5rem;

  .card {
    background: linear-gradient(90deg, #2f867f 0, #41b5ac 100%) !important;
    border: none;
  }

  .card-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
    border-bottom: 1px solid var(--color-border-bottom-title);

    font: 400 18px Archivo;
    color: var(--color-title);
    letter-spacing: 0.05rem;
  }

  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.5rem 1rem;

    background: transparent;
    color: var(--color-title);
  }

  .card-body-value {
    font: 500 48px Poppins;
    line-height: 55px;
  }

  .tooltip-title {
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
  }
`;

export const CardDashboard = styled.div`
  --color-title: #ffffff;
  --color-border-bottom-title: #ffffff;

  .card {
    background: linear-gradient(90deg, #2f867f 0, #41b5ac 100%) !important;
    border: none;
  }

  .card-title {
    background: transparent;
    border-bottom: 1px solid var(--color-border-bottom-title);

    text-align: center;
    font: 500 22px Archivo;
    color: var(--color-title);
    letter-spacing: 0.05rem;
  }

  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px 10px;

    background: transparent;
    color: var(--color-title);
  }

  .media-container {
    text-align: left;
    margin: 0.5rem 0;
    @media (max-width: 576px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .media-title {
    font: 500 16px Poppins;
  }

  .media-valor {
    font: 600 24px Poppins !important;
    margin-left: 0.5rem;
    letter-spacing: 0.08rem;
  }

  .card-graph {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .card-graph div {
    margin: 1rem 0;
  }
`;

export const ButtonCardFooter = styled.div`
  background-color: #0a7a73 !important;
`;

export const InputContainer = styled.div`
  margin: 0.3rem 0.5rem;
  /* min-width: 100px; */
`;

export const FormatValorFatura = styled.div`
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }

  p {
    font-size: 2rem;
    margin: 0 1rem 0 0;
  }

  b {
    font-weight: 600;
    font-size: 23px;
    font-family: 'Roboto', sans-serif !important;
  }
  span {
    font-size: 41px;
    font-family: 'Futura', sans-serif;
  }
  svg {
    color: #0a7a73;
    margin-right: 1rem;
  }
`;

export const FormatValorFaturaDesconto = styled.div`
  margin-top: 5px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  small {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
  }
`;
