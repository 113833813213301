import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Card, Col, Row, Button, Spinner } from 'reactstrap';
import { FaUsers, FaSearch, FaRegWindowClose, FaPlus } from 'react-icons/fa';

import { CardComponent } from '../styles';

import SimpleTable from '../../components/TablesGrid/SimpleTable';
import Loading from '../../components/Loading/Loading';

import CadastrarCorretorRespresentante from '../../views/RelatorioVendas/CadastrarCorretorRepresentante';

function VendedoresLista(props) {
  const history = useHistory();

  const { vendedores, loadingChangeStatus, submitChangeStatus, openModal, toggleModalExclusao, getVendedores } =
    props;

  const [vendedoresEditados, setVendedoresEditados] = useState([]);

  const [modalCorretor, setModalCorretor] = useState(false);
  const toggleModalCorretor = _ => {
    setModalCorretor(!modalCorretor);
  };

  useEffect(() => {
    setVendedoresEditados(vendedores.data);
  }, [vendedores]);

  const columns = [
    { name: 'nome', title: 'NOME', getCellValue: row => row.nome },
    { name: 'status', title: 'Status', getCellValue: row => changeStatus(row) },
    { name: 'tipo', title: 'Funções', getCellValue: row => actions(row) },
  ];

  const actions = vendedor => (
    <div>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="primary"
        onClick={() => openModal(vendedor)}
      >
        <FaSearch />
      </Button>
      <Button
        outline
        className="mr-1"
        size="sm"
        color="danger"
        onClick={() =>
          toggleModalExclusao(vendedor)
        }
      >
        <FaRegWindowClose />
      </Button>
    </div>
  );

  const changeStatus = vendedor => (
    <div className="text-center">
      {vendedor.status == 1 ? 'Habilitado' : 'Desabilitado'}
    </div>
  );

  return (
    <Card>
      <CardComponent>
        <Row style={{marginTop: "0px"}}>
          <Col className="header-content text-left" md={9}>
            <h2>
              <FaUsers />
              &nbsp;&nbsp;Vendedores
            </h2>
          </Col>
          <Col md={3}>
          <Button
            color="danger"
            size="sm"
            onClick={toggleModalCorretor}
          >
            <FaPlus />
            &nbsp; Cadastrar Novo Vendedor
          </Button>
          {modalCorretor && (
            <CadastrarCorretorRespresentante
              assessorias={props.assessorias}
              modalCorretor={modalCorretor}
              toggleModalCorretor={toggleModalCorretor}
              gruposVenda={props.gruposVenda}
              assessoria={props.assessoria}
              getVendedores={getVendedores}
            />
          )}
          </Col>
        </Row>
      </CardComponent>
      <hr />
      <Row>
        <Col>
          {vendedores.loading ? (
            <Loading />
          ) : (
            <SimpleTable
              height="320px"
              rows={vendedoresEditados}
              columns={columns}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default VendedoresLista;
