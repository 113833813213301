import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import DataGridLayout from '../../components/TablesGrid/DataGrid';
import useStyles from './styles';
import theme from '../../styles/theme';
import { SearchTextField } from '../../components/Inputs/TextFields';
import { RegisterButton, ExportButton } from '../../components/Buttons/Buttons';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const ListaUsuarios = props => {
  const { accounts, history } = props;
  const rows = accounts.data;
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(accounts.data);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = accounts.data.filter(row => {
      return Object.keys(row).some(field => {
        if (field === 'nome' || field === 'email' || field === 'id') {
          return searchRegex.test(row[field]);
        }
        return '';
      });
    });
    setFilteredUsers(filteredRows);

  }

  useEffect(() => {
    setFilteredUsers(accounts.data);
  }, [accounts]);

  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 0.5,
      minWidth: 240,
      renderCell: params => (

        <Grid container component="span" style={{ flexWrap: 'nowrap' }}>
          <Grid item>
            <Tooltip
              placement="top"
              title="Informações adicionais"
              aria-label="Informações adicionais"
            >
              <IconButton
                onClick={() => {
                  history.push(`/usuario/${params.row.id}`);
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button
              style={{ fontWeight: 'normal', fontSize: '1em' }}
              onClick={() => history.push(`/usuario/${params.row.id}`)}
            >
              {params.value}
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.4,
      minWidth: 240,
    },
    {
      field: 'razao_social',
      headerName: 'Distribuidora',
      sortable: false,
      flex: 0.3,
      minWidth: 240,
    },
    {
      field: 'descricaoNivel',
      headerName: 'Nível Acesso',
      minWidth: 170,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs="auto" container>
        <Grid item xs={12} md={6} align="left">
          <SearchTextField
            value={searchText}
            onChange={e =>
              e.target.value ? requestSearch(e.target.value) : requestSearch('')
            }
            clearSearch={() => requestSearch('')}
            toolTipTitle="Considere pontos e acentos na pesquisa"
            style={{ width: '201px' }}
          />
        </Grid>
        <Grid item xs={12} md={6} align="right">
          <RegisterButton
            onClick={() => history.push('cadastrar-usuario')}
            style={{ marginLeft: theme.spacing(2) }}
          >
            Cadastrar novo usuário
          </RegisterButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: 30 }}>
          <DataGridLayout
            rows={filteredUsers}
            columns={columns}
            // hasToolbar
            disableSelectionOnClick
            className={classes.root}
            getCellClassName={params => {
              if (
                params.field === 'nome' ||
                params.field === 'email' ||
                params.field === 'razao_social' ||
                params.field === 'descricaoNivel'
              )
                return 'left';
              return true;
            }}
            sortField="nome"
            sortDirection="asc"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
